import React, { ReactElement } from 'react'
import { SelectVersionPane } from 'components/SetupGuide/Components'
import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'

import { Button } from 'ui'
import useQueryString from 'utils/useQueryString'
import SetupGuideDialog from '../Components/SetupGuideDialog'
import { Flex } from '@theme-ui/components'

export default function AppleTVVersionSelector(): ReactElement {
  const { qs, setupGuideNav } = useQueryString()
  return (
    <SetupGuideDialog title="Apple TV" isLeftContentVisible>
      <SelectVersionPane>
        <Flex sx={{ width: '18.5rem', flexDirection: 'column', gap: '2.4rem' }}>
          <Button
            ariaLabel="tvOS13 or older"
            variant="newSecondary"
            sx={{
              height: '3.8rem',
            }}
            onClick={(): void => {
              setupGuideNav({
                ...qs,
                helpPane: SetupOs.APPLE_TV_OS_13,
                setupOs: SetupOs.APPLE_TV_OS_13,
                step: '0',
              })
            }}
          >
            tvOS13 or older
          </Button>
          <Button
            ariaLabel="tvOS14+"
            variant="newSecondary"
            sx={{
              width: '100%',
              height: '3.8rem',
            }}
            onClick={(): void => {
              setupGuideNav({
                ...qs,
                helpPane: SetupOs.APPLE_TV_OS_14,
                setupOs: SetupOs.APPLE_TV_OS_14,
                step: '0',
              })
            }}
          >
            tvOS14+
          </Button>
        </Flex>
      </SelectVersionPane>
    </SetupGuideDialog>
  )
}
