import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { trackingApi } from 'store/api/campaignTracking'
import { paymentsApi } from 'store/api/billing/payments'

export interface TrackingState {
  cid?: string
  entryPage?: string
  isEntryPixelRequestExecuted?: boolean
  isPlansPixelRequestExecuted?: boolean
  isSignupPixelRequestExecuted?: boolean
  // this flag is set before calling register/onboard and is checked before triggering signup pixel request
  shouldTriggerSignupPixelRequest?: boolean
  trackingRedirectURL?: string
  trackingPromoCode?: string
  shouldForceTrial?: boolean
  customHTML?: string
}

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState: {} as TrackingState,
  reducers: {
    setCampaignID(state, action: PayloadAction<string>): void {
      state.cid = action.payload
    },
    setEntryPage(state, action: PayloadAction<string>): void {
      state.entryPage = action.payload
    },
    resetTrackingData(state): void {
      state.trackingRedirectURL = undefined
      state.trackingPromoCode = undefined
    },
    triggerSignupPixelRequest(state): void {
      state.shouldTriggerSignupPixelRequest = true
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(trackingApi.endpoints.startCampaignTracking.matchFulfilled, (state, action) => {
        state.shouldForceTrial = action.payload.body?.force_trial

        const payloadBody = action.payload.body
        const actionData = payloadBody?.action
        const actionPath = action.meta.arg.originalArgs.path

        if (actionData) {
          if (!state.isEntryPixelRequestExecuted && actionData.redirect) {
            state.trackingRedirectURL = actionData.redirect
          }
          if (!state.isPlansPixelRequestExecuted && actionData.promo_code) {
            state.trackingPromoCode = actionData.promo_code
          }
        }

        if (!state.isSignupPixelRequestExecuted && payloadBody?.post_conversion) {
          state.customHTML = payloadBody.post_conversion
        }

        if (actionPath === 'plan' || actionPath === 'pricing') {
          state.isPlansPixelRequestExecuted = true
        } else if (actionPath === 'signup') {
          state.isSignupPixelRequestExecuted = true
          state.shouldTriggerSignupPixelRequest = false
        } else {
          state.isEntryPixelRequestExecuted = true
        }
      })
      // reset force_trial after successful signup for trial
      .addMatcher(paymentsApi.endpoints.activateTrialOrFreePromoCode.matchFulfilled, state => {
        state.shouldForceTrial = undefined
      })
  },
})

export const { setCampaignID, setEntryPage, resetTrackingData, triggerSignupPixelRequest } =
  trackingSlice.actions
