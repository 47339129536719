import React, { ReactElement } from 'react'
import type { SimpleActionMenuProps } from 'ui/Dropdown/SimpleActionMenu'
import { default as SimpleActionMenu } from 'ui/Dropdown/SimpleActionMenu'

/**
 * Current standard layout for a menu with icons: border, background, spacing.
 * Use `<IconButtonContent>` for menu item children when you need start/end icons.
 * Uses `<SimpleActionMenu>` under the hood now.
 */
export default function IconMenu({
  sxButton,
  sxContainer,
  shouldHighlightOnHover,
  ...props
}: SimpleActionMenuProps): ReactElement {
  return (
    <SimpleActionMenu
      sxButton={{
        mx: 0,
        border: '1px solid',
        ...sxButton,
      }}
      sxContainer={{
        borderRadius: '1.2rem',
        p: '0.4rem',
        ...sxContainer,
      }}
      shouldHighlightOnHover={shouldHighlightOnHover}
      {...props}
    />
  )
}
