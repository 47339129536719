import React, { ReactElement, useEffect } from 'react'
import { navigate } from 'gatsby'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import LegacyIPDataRow from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/LegacyIPDataRow'
import { Button, Flex, Text } from 'theme-ui'
import { Svg, ExternalLink } from 'ui'
import useQueryString from 'utils/useQueryString'
import { useLocation } from '@reach/router'
import GlobeIcon from 'images/globe.svg'
import { checkCurrentIp } from 'store/defaultRule'
import { setSelectedDeviceId } from 'store/access'
import { HighlightProtocolInSetupGuide } from 'store/api/devices/devices.interface'

export interface LegacyResolverData {
  ipv4Primary?: string
  ipv4Secondary?: string
  ipv6Primary?: string
  ipv6Secondary?: string
}
export default function LegacyResolver({
  highlight,
  legacyResolverData,
}: {
  highlight?: HighlightProtocolInSetupGuide[]
  legacyResolverData: LegacyResolverData
}): ReactElement {
  const { qs } = useQueryString()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const setupOs = qs.setupOs
  const isFreeDNS = location.pathname.includes('free-dns')

  const currentIPTypeDetails = useAppSelector(s => s.defaultRule.currentIPTypeDetails)

  const isIPv6Network =
    !!currentIPTypeDetails?.isFulfilled &&
    !!currentIPTypeDetails?.ipv6?.type &&
    !!currentIPTypeDetails?.ipv6?.ip

  const currentIp = isIPv6Network ? currentIPTypeDetails?.ipv6?.ip : currentIPTypeDetails?.ipv4?.ip

  const v6Highlighted = highlight?.includes(HighlightProtocolInSetupGuide.IPv6) && !!isIPv6Network
  const v4Highlighted =
    highlight?.includes(HighlightProtocolInSetupGuide.IPv4) &&
    (!isIPv6Network || !highlight?.includes(HighlightProtocolInSetupGuide.IPv6))

  useEffect(() => {
    dispatch(checkCurrentIp())
  }, [dispatch])

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          flexShrink: 0,
          borderRadius: '16px',
          gap: '1.2rem',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            color: v4Highlighted ? 'greenApple' : 'white',
          }}
          data-testid="ipv4"
        >
          <LegacyIPDataRow
            title="IPv4"
            primary={legacyResolverData.ipv4Primary}
            secondary={legacyResolverData.ipv4Secondary}
            isHighlighted={v4Highlighted}
          />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            color: v6Highlighted ? 'greenApple' : 'aliceBlue',
          }}
          data-testid="ipv6"
        >
          <LegacyIPDataRow
            title="IPv6"
            primary={legacyResolverData.ipv6Primary}
            secondary={legacyResolverData.ipv6Secondary}
            isHighlighted={v6Highlighted}
          />
        </Flex>
      </Flex>
      {setupOs && !isFreeDNS && (
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            my: '2.4rem',
            gap: '1.6rem',
            mx: '2.4rem',
          }}
        >
          <Flex sx={{ flexDirection: 'column', flex: 1 }}>
            <Text
              variant="size12Weight700Line138Letter048"
              sx={{
                mb: '0.8rem',
                textTransform: 'uppercase',
                color: 'aliceBlue',
              }}
            >
              limitation warning
            </Text>
            <Text variant="size12Weight400Line138Letter048" sx={{ color: 'aliceBlue60' }}>
              Legacy DNS requires your source IP to be updated at all times, otherwise your rules
              will not be enforced.&ensp;
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/ip-not-authorized`}
                sx={{
                  color: 'aliceBlue60',
                  textDecoration: 'underline',
                  ':hover': {
                    color: 'aliceBlue',
                  },
                }}
              >
                Learn more
              </ExternalLink>
            </Text>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'space-between',
              gap: '0.8rem',
            }}
          >
            <Flex sx={{ flexDirection: 'column', gap: '0.8rem' }}>
              <Text
                variant="size12Weight700Line138Letter048"
                sx={{
                  textTransform: 'uppercase',
                }}
              >
                current ip
              </Text>
              <Text variant="size12Weight400Line138Letter048" sx={{ color: 'aliceBlue60' }}>
                {currentIp}
              </Text>
            </Flex>
            <Button
              data-testid="legacy-resolver-manage-ips-button"
              variant="newSecondary"
              onClick={() => {
                dispatch(setSelectedDeviceId(qs.deviceId))
                navigate(`/dashboard/ips/${qs.deviceId}`)
              }}
              sx={{
                width: '19.4rem',
                height: '3.8rem',
                fontSize: ['1.2rem', 'unset'],
              }}
            >
              <Svg svg={GlobeIcon} sx={{ mr: '0.8rem' }} />
              <Text>Manage IPs</Text>
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
