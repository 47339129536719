import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ServiceData } from 'store/api/services'

export interface ServicesState {
  showActiveOnly?: boolean
  showCardView?: boolean
  selectedService: ServiceData | null
}

const initialState: ServicesState = {
  selectedService: null,
  showActiveOnly: false,
  showCardView: true,
}

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    selectService(state, action: PayloadAction<ServiceData>): void {
      state.selectedService = action.payload
    },
    setShowActiveOnly(state, action: PayloadAction<boolean>): void {
      state.showActiveOnly = action.payload
    },
    setShowCardView(state, action: PayloadAction<boolean>): void {
      state.showCardView = action.payload
    },
  },
})

export const { selectService, setShowActiveOnly, setShowCardView } = servicesSlice.actions
