import React, { Dispatch, ReactElement } from 'react'
import AnalyticsIcon from 'images/profileManagement/settings/analytics.svg'
import { ExternalLink } from 'ui'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Box } from 'theme-ui'
import AnalyticsDropdown from './SettingsDropdowns/AnalyticsDropdown'
import SettingsItem from './SettingsItem'
import { ActionType } from './SettingsState'
import { StatLevel } from 'store/api/analytics/analytics.interface'

export default function AnalyticsSettingsItem({
  settingsStats,
  settingsDispatch,
}: {
  settingsStats?: StatLevel
  settingsDispatch: Dispatch<ActionType>
}): ReactElement {
  return (
    <SettingsItem
      testId="analytics"
      icon={AnalyticsIcon}
      title="Analytics"
      description="Log DNS queries and generate activity reports."
      descriptionLink={
        <ExternalLink
          to={`${DOCS_CONTROL_D_DOMAIN}/docs/analytics-levels`}
          sx={{
            color: 'aliceBlue60',
            cursor: 'pointer',
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
          }}
        >
          Learn more
        </ExternalLink>
      }
      actionContent={
        <Box
          data-testid="settings-analytics-dropdown-container"
          sx={{ position: 'relative', flexShrink: 0 }}
          className="reset-tooltip"
        >
          <AnalyticsDropdown
            deviceStats={settingsStats}
            settingsDispatch={settingsDispatch}
            boundaryDataTestId="advanced-settings"
          />
        </Box>
      }
    />
  )
}
