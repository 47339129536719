import { useConsumeProvisionMutation, useValidateDeviceUIDMutation } from 'store/api/provision'
import { Resolvers } from 'store/api/devices/devices.interface'

export default function useGetProvisioningState(): {
  type?: string
  resolver?: Resolvers
  uid?: string
  icon?: string
} {
  const consumeMutationData = useConsumeProvisionMutation({ fixedCacheKey: 'consumeProvision' })
  const consumeData = consumeMutationData[1].data

  const validationMutationData = useValidateDeviceUIDMutation({
    fixedCacheKey: 'validateDeviceUID',
  })
  const validationData = validationMutationData[1].data

  return {
    ...validationData,
    resolver: validationData?.resolver || consumeData?.devices.resolvers,
  }
}
