import React, { useMemo, useState } from 'react'
import { TwoFactorStep } from 'store/twofa'
import AccountPasswordStep from 'components/Organization/TwoFa/Steps/AccountPasswordStep'
import EnableTwoFaStep from 'components/Organization/TwoFa/Steps/EnableTwoFa'
import ScanOrEnterCodeStep from 'components/Organization/TwoFa/Steps/ScanOrEnterCodeStep'
import ModalDialog from 'ui/NewModalDialog'
import { Flex } from 'theme-ui'

export default function TwoFaModalDialog(): React.ReactElement {
  const [step, setStep] = useState(TwoFactorStep.PASSWORD_CHECK)

  const stepContent = useMemo(() => {
    switch (step) {
      case TwoFactorStep.PASSWORD_CHECK:
        return <AccountPasswordStep setStep={setStep} />
      case TwoFactorStep.SCAN_OR_ENTER_CODE:
        return <ScanOrEnterCodeStep setStep={setStep} />
      case TwoFactorStep.ENABLE_TWOFA:
        return <EnableTwoFaStep setStep={setStep} />
      default:
        return null
    }
  }, [step])

  return (
    <ModalDialog isOpen={true} title="Enable 2FA" isRightContentVisible={false} footer={<Flex />}>
      <Flex sx={{ width: ['100%', '43.5rem'], py: '3.2rem', px: '1.6rem' }}>{stepContent}</Flex>
    </ModalDialog>
  )
}
