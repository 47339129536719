import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResponseState, initialResponseState } from '../fetchingLogic'

export interface FreeDNSState extends ResponseState {
  selectedFiltersPKs?: string[]
  selectedDNSKey?: string
  selected3rdPartyFilterPK?: string
}
const initialFilterState: FreeDNSState = {
  ...initialResponseState,
}
export const freeDNSSlice = createSlice({
  name: 'freeDNS',
  initialState: initialFilterState,
  reducers: {
    setSelectedFiltersPK(state, action: PayloadAction<string>): void {
      if (state.selectedFiltersPKs?.includes(action.payload)) {
        return
      }

      state.selectedFiltersPKs = [...(state.selectedFiltersPKs || []), action.payload]
      state.selectedDNSKey = ''
      state.selected3rdPartyFilterPK = ''
    },
    setSelectedDNSKey(state, action: PayloadAction<string>): void {
      state.selectedDNSKey = action.payload
      state.selectedFiltersPKs = []
      state.selected3rdPartyFilterPK = ''
    },
    set3rdPartyFilterPK(state, action: PayloadAction<string>): void {
      state.selected3rdPartyFilterPK = action.payload
      state.selectedFiltersPKs = []
      state.selectedDNSKey = ''
    },
    removeSelectedDNSKey(state): void {
      state.selectedDNSKey = ''
    },
    remove3rdPartyFilterPK(state): void {
      state.selected3rdPartyFilterPK = ''
    },
    removeFilterPKFromSelected(state, action: PayloadAction<string>): void {
      state.selectedFiltersPKs = state.selectedFiltersPKs?.filter(pk => pk !== action.payload)
    },
    removeSelectedFiltersPK(state): void {
      state.selectedFiltersPKs = []
    },
  },
})

export const {
  setSelectedFiltersPK,
  setSelectedDNSKey,
  set3rdPartyFilterPK,
  removeFilterPKFromSelected,
  removeSelectedFiltersPK,
  removeSelectedDNSKey,
  remove3rdPartyFilterPK,
} = freeDNSSlice.actions
