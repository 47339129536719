import React, { FormEvent, ReactElement, useEffect, useState } from 'react'
import { modalFooterRef } from 'ui/NewModalDialog/ModalDialogFooter'
import { createPortal } from 'react-dom'
import { Flex } from 'theme-ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'

export default function ContinueButton({
  isButtonDisabled,
  isRequestInFlight,
  onClick,
  dataTestId,
  ariaLabel,
}: {
  isButtonDisabled?: boolean
  isRequestInFlight: boolean
  onClick?: (e: FormEvent) => void
  dataTestId?: string
  ariaLabel: string
}): ReactElement | null {
  const [shouldAppendButton, setShouldAppendButton] = useState(false)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current
    ? createPortal(
        <Flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <ButtonWithLoadingState
            type="submit"
            variant="newPrimary"
            disabled={isButtonDisabled}
            data-testid={dataTestId}
            ariaLabel={ariaLabel}
            isLoading={isRequestInFlight}
            onClick={onClick}
            sx={{ minWidth: '10rem' }}
          >
            Continue
          </ButtonWithLoadingState>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}
