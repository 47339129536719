import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import { ApiResponse } from 'store/api/http'
import { EnabledStatus, RuleType } from 'store/api/rules/rules.interface'
import { SerializedError } from '@reduxjs/toolkit'
import { profilesApi } from 'store/api/profiles'

export interface IPTypeDetails {
  ip: string
  type: string
}

export type ResponseType = {
  data: { default: DefaultRule; currentProfileId?: string | undefined }
} & { error: SerializedError }

export interface DefaultRule {
  do: RuleType
  status: EnabledStatus
  via?: string
  via_v6?: string
}

export type DefaultRuleResponse = ApiResponse<{
  default: DefaultRule
  // #1150 this field is used to pass the current selected profileid along with the api response
  currentProfileId?: string
}>

export const defaultRuleApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getDefaultRule: builder.query({
      query: ({ profileId }: { profileId: string }) =>
        getQueryArgs(`/profiles/${profileId}/default`),
      transformResponse: (response: DefaultRuleResponse) => {
        if (response.body?.default.status === 0) {
          response.body.default.status = 1
          response.body.default.do = RuleType.WHITELIST
        }

        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      providesTags: () => ['DefaultRule'],
    }),
    putDefaultRule: builder.mutation({
      query: ({ body, profileId }: { body: DefaultRule; profileId: string }) =>
        getQueryArgs(`/profiles/${profileId}/default`, 'PUT', body),
      transformResponse: (response: DefaultRuleResponse) => response.body,
      transformErrorResponse,
      invalidatesTags: () => ['DefaultRule'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              const currentProfile = draft?.profiles.find(
                p => p.PK === data.currentProfileId?.toString(),
              )

              if (!!currentProfile?.profile) {
                currentProfile.profile = {
                  ...currentProfile.profile,
                  da: data.default ?? {
                    do: RuleType.SPOOF_TAG,
                    via: 'LOCAL',
                    status: EnabledStatus.ENABLED,
                  },
                }
              }
            }),
          )
        } catch {}
      },
    }),
    checkOriginalIp: builder.query({
      query: () => getQueryArgs('/ip'),
      transformResponse: (response: ApiResponse<IPTypeDetails>) => response.body,
      transformErrorResponse,
      providesTags: () => ['DefaultRule'],
    }),
  }),
})

export const {
  endpoints,
  useGetDefaultRuleQuery,
  usePutDefaultRuleMutation,
  useCheckOriginalIpQuery,
} = defaultRuleApi
