// Issue:
// https://gitlab.int.windscribe.com/controld/frontend/-/issues/283

// Informative Stackoverflow Answer:
// https://stackoverflow.com/questions/45867761/javascript-intl-api-gives-different-results-in-chrome

export function hotfixTimezone(tz: string): string {
  if (tz === 'America/Indianapolis') {
    return 'America/Indiana/Indianapolis'
  }
  return tz
}
