import { Flex } from '@theme-ui/components'
import { Button } from 'ui'
import { setDeviceForEdit } from 'store/multiprofile'
import { DeviceDialogType } from '../DeviceModalDialog'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import React, { ReactElement, useCallback } from 'react'
import { useAppDispatch } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import { autoConfigurablePlatforms } from 'components/SetupGuide/Panes/ShowUserDns'

export default function DeviceItemActionButtons({
  isDevicePending,
  devicePk,
  deviceIcon,
}: {
  isDevicePending?: boolean
  devicePk: string
  deviceIcon?: string
}): ReactElement {
  const dispatch = useAppDispatch()
  const { qs, nav } = useQueryString()

  const handleDeviceSetupClick = useCallback((): void => {
    const setupOs = deviceIcon as SetupOs

    if (autoConfigurablePlatforms.includes(setupOs)) {
      nav({
        ...qs,
        helpPane: SetupGuideIntermediateStates.Configure,
        setupOs: deviceIcon as SetupOs,
        deviceId: devicePk,
      })
    } else {
      nav({
        ...qs,
        helpPane: SetupGuideIntermediateStates.DNS,
        setupOs: setupOs,
        deviceId: devicePk,
      })
    }
  }, [qs, deviceIcon, nav, devicePk])

  return (
    <Flex sx={{ gap: '1.2rem' }}>
      <Button
        ariaLabel="display edit device dialog"
        variant="action"
        onClick={() => {
          dispatch(setDeviceForEdit(devicePk))
          nav({ ...qs, deviceDialog: DeviceDialogType.EDIT })
        }}
      >
        Edit
      </Button>
      {isDevicePending ? (
        <Button
          ariaLabel="display configure endpoint dialog"
          data-testid="configure-device-button"
          variant="newSecondary"
          onClick={handleDeviceSetupClick}
        >
          Configure
        </Button>
      ) : (
        <Button
          ariaLabel="display endpoint resolvers"
          variant="action"
          onClick={() => {
            nav({
              ...qs,
              helpPane: SetupGuideIntermediateStates.DNS,
              setupOs: deviceIcon as SetupOs,
              deviceId: devicePk,
            })
          }}
        >
          Resolvers
        </Button>
      )}
    </Flex>
  )
}
