import React, { ReactElement, ReactNode, Suspense, useState } from 'react'
const DatePicker = React.lazy(() => import('react-datepicker'))
import { Flex, Text } from '@theme-ui/components'
import { Button, Input } from 'ui'
import { customStyles, inputStyles } from './customStyles'
import { DatePickerShortcut } from './FutureDateTimePicker'
import 'react-datepicker/dist/react-datepicker.css'
import isArray from 'lodash/isArray'
import moment from 'moment/moment'
import { ThemeUIStyleObject } from 'theme-ui'
import useGetColorMode from 'utils/useGetColorMode'

enum TimeRange {
  MINUTES = 'minutes',
  HOURS = 'hours',
}

const CustomTimeInput = ({
  isDropdown,
  date,
  onChange,
  dayValue,
  sx,
}: {
  isDropdown?: boolean
  date?: Date | null
  onChange?: (date: Date | null) => void
  dayValue?: string
  sx?: ThemeUIStyleObject
}): ReactElement => {
  const hours = date?.getHours() || 0
  const minutes = date?.getMinutes() || 0
  const { isLightMode } = useGetColorMode()

  const onChangeHandler = (value, type) => {
    if (value.length > 2) {
      return
    }

    if (type === TimeRange.HOURS && +value < 24) {
      date?.setHours(+value)
    } else if (type === TimeRange.MINUTES && +value < 60) {
      date?.setMinutes(+value)
    } else {
      return
    }

    if (date) {
      onChange?.(date)
    }
  }

  return (
    <Flex
      sx={{
        fontFamily: 'body',
        width: '100%',
        justifyContent: 'center',
        ...(isLightMode
          ? {
              backgroundColor: 'raisinBlack',
              borderRadius: '0 0 0.8rem 0.8rem',
            }
          : {}),
        ...sx,
      }}
    >
      {dayValue && (
        <Text
          variant="size12Weight700"
          sx={{
            display: 'inline-flex',
            width: '6rem',
            color: 'aliceBlue60',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {dayValue}
        </Text>
      )}
      <Flex
        sx={{
          width: 'fit-content',
          borderLeft: '1px solid',
          borderRight: '1px solid',
          borderColor: date ? 'blueYonder40' : 'transparent',
        }}
      >
        <Input
          disabled={!date}
          value={hours < 10 ? `0${hours}` : hours}
          containerStyle={{ width: 'fit-content', flexGrow: 'auto' }}
          onChange={e => {
            onChangeHandler(e.target.value.replace('0', ''), TimeRange.HOURS)
          }}
          sx={inputStyles(isDropdown)}
        />
        <Flex
          sx={{
            px: '2.4rem',
            color: 'aliceBlue',
            fontSize: '1.5rem',
            alignItems: 'center',
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderColor: date ? 'blueYonder40' : 'transparent',
          }}
        >
          :
        </Flex>
        <Input
          disabled={!date}
          value={minutes < 10 ? `0${minutes}` : minutes}
          containerStyle={{ width: 'fit-content', flexGrow: 'auto' }}
          onChange={e => {
            onChangeHandler(e.target.value.replace('0', ''), TimeRange.MINUTES)
          }}
          sx={inputStyles(isDropdown)}
        />
      </Flex>
    </Flex>
  )
}

const CustomContainer = ({ className, children, isDropdown }) => {
  return (
    <Flex
      sx={{
        fontFamily: 'body',
        mt: isDropdown ? 0 : '1.2rem',
        width: ['100%', '40.3rem'],
        padding: '16px',
        background: isDropdown ? 'raisinBlack' : 'blue',
        color: 'aliceBlue',
        border: '1px solid',
        borderRadius: isDropdown ? '1.6rem' : '0.8rem 0.8rem 0 0',
        p: 0,
        borderColor: isDropdown ? 'transparent' : 'blueYonder40',
        ...customStyles(isDropdown),
      }}
    >
      <Flex className={className} sx={{ flexDirection: 'column' }}>
        <Flex
          style={{
            position: 'relative',
            backgroundColor: isDropdown ? 'raisinBlack' : 'blue',
            flexDirection: 'column',
            borderRadius: isDropdown ? '0.8rem' : '0.8rem 0.8rem 0 0',
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}

export interface DateTimePickerProps {
  isDropdown?: boolean
  isDateRange?: boolean
  date?: Date | null
  startDate?: Date | null
  endDate?: Date | null
  onDateChange?: (date: Date) => void
  onDateRangeChange?: (date: [Date | null, Date | null] | Date[]) => void
  minDate?: Date
  maxDate?: Date
  shortcuts?: DatePickerShortcut[]
  footer?: ReactNode
}
export default function DateTimePicker({
  isDropdown,
  isDateRange,
  date,
  startDate,
  endDate,
  onDateChange,
  onDateRangeChange,
  minDate,
  maxDate,
  shortcuts,
  footer,
}: DateTimePickerProps): ReactElement {
  const [activeShortcutLabel, setActiveShortcutLabel] = useState('')
  const { isLightMode } = useGetColorMode()

  return (
    <Flex sx={{ flexDirection: 'column', mt: isDropdown ? 0 : '1.2rem' }}>
      <Flex sx={{ justifyContent: 'space-between' }}>
        {shortcuts?.map(shortcut => (
          <ShortcutButton
            key={shortcut.label}
            label={shortcut.label}
            activeLabel={activeShortcutLabel}
            onClick={() => {
              onDateChange?.(shortcut.date)
              setActiveShortcutLabel(shortcut.label)
            }}
          />
        ))}
      </Flex>
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
          ...(isLightMode && endDate
            ? {
                backgroundColor: 'black',
                borderRadius: '0.8rem',
                border: '1px solid',
                borderColor: 'blueYonder30',
              }
            : {}),
        }}
      >
        <Suspense>
          <DatePicker
            selected={startDate || date}
            startDate={startDate}
            endDate={endDate}
            onChange={date => {
              if (date) {
                if (isDateRange && isArray(date)) {
                  const [startDate, endDate] = date
                  const currentDate = moment().toDate()

                  if (startDate !== endDate) {
                    startDate?.setHours(
                      currentDate.getHours(),
                      currentDate.getMinutes(),
                      currentDate.getSeconds(),
                    )
                  }

                  onDateRangeChange?.(date)
                } else {
                  onDateChange?.(date as Date)
                }

                setActiveShortcutLabel('')
              }
            }}
            inline
            timeInputLabel=""
            minDate={minDate}
            maxDate={maxDate}
            selectsRange={isDateRange}
            calendarContainer={({ className, children }) => (
              <CustomContainer className={className} isDropdown={isDropdown}>
                {children}
              </CustomContainer>
            )}
          />
        </Suspense>
        {/* don't use a default showTimeInput, it supports only one input */}
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            ...(!isDateRange
              ? {
                  borderLeft: '1px solid',
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  borderColor: 'blueYonder40',
                  borderBottomLeftRadius: '0.8rem',
                  borderBottomRightRadius: '0.8rem',
                }
              : {}),
          }}
        >
          <CustomTimeInput
            key="start-date"
            isDropdown={isDropdown}
            date={startDate || date}
            onChange={date => {
              if (date) {
                isDateRange && endDate ? onDateRangeChange?.([date, endDate]) : onDateChange?.(date)
              }
            }}
            dayValue={
              startDate
                ? moment(startDate).format('MMM DD')
                : date
                ? moment(date).format('MMM DD')
                : undefined
            }
            sx={{
              borderTop: '1px solid',
              borderTopColor: 'blueYonder40',
              borderBottom: '1px solid',
              borderBottomColor: !endDate ? 'blueYonder40' : 'transparent',
              ...(isLightMode
                ? {
                    borderColor: endDate ? 'blueYonder40' : 'transparent',
                    borderBottomRadius: endDate ? 'none' : '0.8rem',
                  }
                : {}),
            }}
          />
          {endDate && (
            <CustomTimeInput
              key="end-date"
              isDropdown={isDropdown}
              date={endDate}
              onChange={date => {
                if (date && startDate) {
                  isDateRange ? onDateRangeChange?.([startDate, date]) : onDateChange?.(date)
                }
              }}
              dayValue={endDate && moment(endDate).format('MMM DD')}
              sx={{
                borderBottom: '1px solid',
                borderBottomColor: 'blueYonder40',
                ...(isLightMode
                  ? {
                      borderRadius: 'none',
                    }
                  : {}),
              }}
            />
          )}
          {footer}
        </Flex>
      </Flex>
    </Flex>
  )
}

function ShortcutButton({
  label,
  activeLabel,
  onClick,
}: {
  label: string
  activeLabel: string
  onClick?: () => void
}): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <Button
      ariaLabel={label}
      key={label}
      variant="simple"
      sx={{
        py: 0,
        height: '3.2rem',
        px: '1.2rem',
        border: '1px solid',
        borderColor: activeLabel === label ? 'littleBoyBlue' : 'transparent',
        borderRadius: '0.8rem',
        color: activeLabel === label ? 'aliceBlue' : 'aliceBlue60',
        '&:hover': {
          color: 'aliceBlue',
        },
        ...(isLightMode && activeLabel === label ? { backgroundColor: 'littleBoyBlue6' } : {}),
      }}
      onClick={onClick}
    >
      <Text variant="size12Weight700">{label}</Text>
    </Button>
  )
}
