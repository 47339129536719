import { configureStore, EnhancedStore, CombinedState } from '@reduxjs/toolkit'
import rootReducer, { RootState } from './rootReducer'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants'
import { baseApi } from 'store/api'

export const appStore: EnhancedStore<CombinedState<RootState>> = createStore()

if (typeof window !== 'undefined') {
  window['store'] = appStore
}

export type AppDispatch = typeof appStore.dispatch

export const persistor = persistStore(appStore)

export function createStore(): EnhancedStore<CombinedState<RootState>> {
  const store = configureStore({
    reducer: rootReducer,
    //  TODO: remove @ts-ignore and thunk when all createSlices are changed to RTK Query createApi and all api middlewares are added to concat
    // @ts-ignore
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(thunk, baseApi.middleware),
  })
  return store
}
