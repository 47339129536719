import React, { ReactElement } from 'react'
import { Box, Flex, IconButton } from 'theme-ui'
import { Svg } from 'ui'
import { CompanyLogo } from 'components/HomePage/Header/CompanyLogo'
import MobileNavMenuItem from 'components/HomePage/Header/MobileNavMenuTray/MobileNavMenuItem'
import { ButtonGroup } from 'components/HomePage/Header'
import MobileNavMenuTray, { MobileTabletNavMenuTrayProps } from 'ui/MobileNavMenuTray'
import StarsWrapper, { bigStarStyles } from 'components/Organization/HomePage/StarsWrapper'
import ClosingCrossIcon from 'images/organization/homepage/headerNavMenu/closing-cross.svg'
import { orgNavMenuContent, personalNavMenuContent } from './navMenuContent'
import { OrgAndPersonalHeaderButtons } from 'components/NewStaticPage/StaticPageHeader'
import useDetermineTypeOfHeader from 'utils/useDetermineTypeOfHeader'
import { userApi } from 'store/api/user'

export default function NavMenuTray({
  dismiss,
  isOpen,
}: MobileTabletNavMenuTrayProps): ReactElement {
  const clickableNodeTabIndex = isOpen ? 0 : -1
  const { isPersonalHeader } = useDetermineTypeOfHeader()
  const { data: userData } = userApi.endpoints.getUser.useQueryState('')
  const navMenuContent = isPersonalHeader
    ? userData?.org
      ? personalNavMenuContent.filter(content => content.title !== 'Pricing')
      : personalNavMenuContent
    : orgNavMenuContent

  return (
    <MobileNavMenuTray
      isOpen={isOpen}
      dismiss={dismiss}
      traySx={{
        background: 'blue90',
        backdropFilter: 'blur(0.5rem)',
      }}
      trayHeaderSx={{
        background: 'none',
        boxShadow: 'none',
        py: '0',
        height: ['7.2rem', '6.9rem'],
        alignItems: 'center',
      }}
      trayHeaderChildren={<OrgAndPersonalHeaderButtons />}
      trayHeaderLeftComponent={<CompanyLogo />}
      trayHeaderRightComponent={
        <IconButton
          data-testid="close-nav-menu-tray"
          onClick={dismiss}
          sx={{
            p: '0.8rem',
            width: '4rem',
            height: '4rem',
            borderRadius: '100%',
            ':hover': {
              backgroundColor: 'wakaiwaGrey20',
            },
          }}
        >
          <Svg svg={ClosingCrossIcon} sx={{ width: '1.6rem', height: '1.6rem' }} />
        </IconButton>
      }
      footer={
        <Flex
          sx={{
            height: '8rem',
            justifyContent: 'center',
            borderTop: '1px solid',
            borderTopColor: 'wakaiwaGrey50',
            backgroundColor: 'blue800',
          }}
        >
          <StarsWrapper
            sx={{ width: '100%' }}
            containerSx={{ display: 'flex' }}
            starsSx={[
              {
                left: '1.5rem',
                top: '1.5rem',
                'g > circle': {
                  fill: 'mauve',
                },
              },
              {
                right: '5.6rem',
                top: '5.6rem',
                width: '1rem',
                height: '1rem',
                'g > circle': {
                  fill: '#ABDBFF',
                },
              },
              {
                right: '0rem',
                top: '1.1rem',
                ...bigStarStyles,
                'g > circle': {
                  fill: '#9AD5FF',
                },
              },
            ]}
          >
            <ButtonGroup
              sx={{ zIndex: 'zIndex1' }}
              dropdownContainerSx={{ width: ['calc(100vw - 1rem)', '53.6rem', '28rem'] }}
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                background: 'magenta',
                filter: 'blur(75px)',
              }}
            />
          </StarsWrapper>
        </Flex>
      }
    >
      <Flex
        sx={{
          pb: '2.4rem',
          height: '100%',
          flexDirection: 'column',
          ':first-of-type': {
            borderTop: '1px solid',
            borderColor: 'wakaiwaGrey50',
          },
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            position: 'absolute',
            width: '5px',
          },

          '&::-webkit-scrollbar-thumb': {
            borderRadius: '3.5px',
            backgroundColor: 'wakaiwaGrey50',
          },
        }}
      >
        {navMenuContent.map(({ title, links, to }) => (
          <MobileNavMenuItem
            key={title}
            title={title}
            links={links}
            to={to}
            tabIndex={clickableNodeTabIndex}
            dismiss={dismiss}
          />
        ))}
      </Flex>
    </MobileNavMenuTray>
  )
}
