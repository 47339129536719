import React, { ReactElement } from 'react'
import ProfilesIcon from 'images/dashboard/navBar/profiles-icon.svg'
import DevicesIcon from 'images/dashboard/navBar/devices-icon.svg'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import { Flex } from 'theme-ui'
import { AnalyticsNavMenu } from 'components/Dashboard/NavBar/AnalyticsNavMenu'
import { useAppSelector } from 'store/hooks'
import { useGetDevicesQuery } from 'store/api/devices'
import { useGetProfilesQuery } from 'store/api/profiles'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

export enum TopNavRelativePath {
  PROFILES = 'profiles',
  ENDPOINTS = 'endpoints',
  ACTIVITY_LOG = 'activity-log',
  STATISTICS = 'statistics',
}
// Nav menu items that are common to organizations and non-organizations, like Profiles, Devices and Analytics
export function MainNavMenuItems(): ReactElement {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { isFetching: orgLoading } = useGetOrganization()

  const { data: profilesData, isFetching: profilesLoading } = useGetProfilesQuery('', {
    skip: !sessionToken,
  })
  const { data: devicesData, isFetching: endpointsLoading } = useGetDevicesQuery('', {
    skip: !sessionToken,
  })

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '0.1rem',
      }}
    >
      <NavMenuItem
        activeIcon={ProfilesIcon}
        relativePath={TopNavRelativePath.PROFILES}
        label="Profiles"
        count={profilesData?.profiles?.length}
        isLoading={profilesLoading || orgLoading}
      />
      <NavMenuItem
        activeIcon={DevicesIcon}
        relativePath={TopNavRelativePath.ENDPOINTS}
        label="Endpoints"
        count={devicesData?.devices.length}
        isLoading={endpointsLoading || orgLoading}
      />
      <AnalyticsNavMenu />
    </Flex>
  )
}
