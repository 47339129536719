import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomRuleData, EnabledStatus, RuleType } from 'store/api/rules/rules.interface'
import { Subset } from 'utils'
import { groupApi, GroupData } from 'store/api/groups'
import { ruleApi } from 'store/api/rules/rules'

export interface RuleTrayState {
  isCustomRule: boolean
  draftCustomRule: CustomRuleData
  draftGroup: GroupData
  rulesToEdit?: CustomRuleData[]
  shouldAllowGroupCreation: boolean
}

const ruleTrayInitialState: RuleTrayState = {
  isCustomRule: true,
  draftCustomRule: {
    action: {
      do: RuleType.BLOCK,
      status: EnabledStatus.ENABLED,
      via: undefined,
      via_v6: undefined,
      ttl: undefined,
    },
    PK: '',
    group: 0,
  },
  draftGroup: {
    PK: -1,
    group: '',
    action: {
      status: EnabledStatus.ENABLED,
    },
    count: 0,
  },
  shouldAllowGroupCreation: true,
}

export const ruleTraySlice = createSlice({
  name: 'ruleTray',
  initialState: { ...ruleTrayInitialState },
  reducers: {
    updateCustomRuleDraft(state, action: PayloadAction<Subset<CustomRuleData>>): void {
      const actionPayload = {
        ...action.payload.action,
      }

      // TODO: remove check on -1 when it is updated on server side
      if (`${actionPayload?.via}` === '-1') {
        actionPayload.via = ''
      }

      // TODO: remove check on -1 when it is updated on server side
      if (`${actionPayload?.via_v6}` === '-1') {
        actionPayload.via_v6 = ''
      }

      const newDraft = {
        ...state.draftCustomRule,
        ...action.payload,
        // TODO: temporary solution with number check, should be fixed on a server side
        ...(typeof action.payload?.PK === 'number' ? { PK: `${action.payload?.PK}` } : {}),
        action: { ...state.draftCustomRule.action, ...actionPayload },
      }
      state.draftCustomRule = newDraft
    },
    resetCustomRuleDraft(state): void {
      state.draftCustomRule = {
        ...ruleTrayInitialState.draftCustomRule,
        action: {
          ...state.draftCustomRule.action,
          ...ruleTrayInitialState.draftCustomRule.action,
        },
      } // retain via
    },

    updateGroupDraft(state, action: PayloadAction<Subset<GroupData>>): void {
      const actionPayload = {
        ...action.payload.action,
      }

      // TODO: remove check on -1 when it is updated on server side
      if (`${actionPayload?.via}` === '-1') {
        actionPayload.via = ''
      }

      // TODO: remove check on -1 when it is updated on server side
      if (`${actionPayload?.via_v6}` === '-1') {
        actionPayload.via_v6 = ''
      }

      const newDraft = {
        ...state.draftGroup,
        ...action.payload,
        action: { ...state.draftGroup.action, ...actionPayload },
      }
      // for groups with numeric names, the `group` field is set as a number, which makes the isCustomRule(draftGroup) return true.
      // this would prevent numeric group names from being treated as numbers.
      newDraft.group = newDraft.group.toString()
      state.draftGroup = newDraft
    },
    resetDraftGroup(state): void {
      state.draftGroup = { ...ruleTrayInitialState.draftGroup }
    },
    setRulesToEdit(state, action: PayloadAction<CustomRuleData[] | undefined>) {
      state.rulesToEdit = action.payload?.map(rule =>
        // TODO: temporary solution with number check, should be fixed on a server side
        typeof rule?.PK === 'number' ? { ...rule, PK: `${rule?.PK}` } : rule,
      )
      // if single rule edit, set the draft action from the rule
      if (action.payload?.length === 1) {
        ruleTraySlice.caseReducers.updateCustomRuleDraft(state, {
          ...action,
          payload: action.payload[0],
        })
      }
    },
    setShouldAllowGroupCreation(state, action: PayloadAction<boolean>) {
      state.shouldAllowGroupCreation = action.payload
    },
    setIsCustomRule(state, action: PayloadAction<boolean | undefined>) {
      state.isCustomRule = action.payload ?? true
    },
    resetRuleTrayState(state, action: PayloadAction<undefined>) {
      ruleTraySlice.caseReducers.resetCustomRuleDraft(state)
      ruleTraySlice.caseReducers.resetDraftGroup(state)
      ruleTraySlice.caseReducers.setRulesToEdit(state, action)
      ruleTraySlice.caseReducers.setIsCustomRule(state, action)
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(groupApi.endpoints.postGroup.matchFulfilled, state => {
        state.isCustomRule = true
      })
      .addMatcher(groupApi.endpoints.putGroup.matchFulfilled, state => {
        state.isCustomRule = true
      })
      .addMatcher(ruleApi.endpoints.putGroupRules.matchFulfilled, state => {
        state.rulesToEdit = undefined
      })
  },
})

export const {
  updateCustomRuleDraft,
  updateGroupDraft,
  resetCustomRuleDraft,
  resetDraftGroup,
  setRulesToEdit,
  setShouldAllowGroupCreation,
  setIsCustomRule,
  resetRuleTrayState,
} = ruleTraySlice.actions
