import ScheduleViewContent from './ScheduleViewContent'
import { listOfTimes } from './Schedule.helpers'
import { defaultScheduleEndTimeIndex, defaultScheduleStartTimeIndex } from './TimeRangeSelect'
import { useGetSchedulesQuery, weekdaysArrayToObjectRepresentation } from 'store/api/schedules'
import { ScheduleContextProvider } from 'components/Dashboard/Devices/DeviceListItem/ScheduleContextProvider'
import React, { ReactElement } from 'react'
import { useGetProfilesQuery } from 'store/api/profiles'
import { DeviceClient, DeviceInfo } from 'store/api/devices/devices.interface'

export default function ScheduleView({
  device,
  dismiss,
}: {
  device?: DeviceInfo<DeviceClient[]>
  dismiss: () => void
}): ReactElement {
  const { data: profilesData } = useGetProfilesQuery('')
  const { data: schedulesData } = useGetSchedulesQuery('')
  const existingSchedule = schedulesData?.schedules.find(
    schedule => schedule?.device?.PK === device?.PK,
  )
  return (
    <ScheduleContextProvider existingSchedule={existingSchedule}>
      <ScheduleViewContent
        dismiss={dismiss}
        device={device}
        profiles={profilesData?.profiles || []}
        initialDraft={{
          startTime:
            listOfTimes.find(time => {
              return time.time === existingSchedule?.time_start
            }) ?? listOfTimes[defaultScheduleStartTimeIndex],
          endTime:
            listOfTimes.find(time => time.time === existingSchedule?.time_end) ??
            listOfTimes[defaultScheduleEndTimeIndex],
          days: Array.isArray(existingSchedule?.weekdays)
            ? weekdaysArrayToObjectRepresentation(existingSchedule?.weekdays ?? [])
            : existingSchedule?.weekdays ?? {
                mon: 0,
                tue: 0,
                wed: 0,
                thu: 0,
                fri: 0,
                sat: 0,
                sun: 0,
              },
          enforcedProfilePk: existingSchedule?.profile?.PK.toString(),
          isAllDay:
            existingSchedule?.time_start === '00:00' && existingSchedule.time_end === '00:00',
        }}
      />
    </ScheduleContextProvider>
  )
}
