import { createSlice } from '@reduxjs/toolkit'
import { ActionableRule } from 'utils/uniteRules'

export interface ProxyTrayState {
  rule?: ActionableRule
}

const initialState: ProxyTrayState = {}

export const proxyTraySlice = createSlice({
  name: 'proxyTray',
  initialState,
  reducers: {
    openProxyTray(state, action): void {
      return action.payload
    },
    resetProxyTrayState() {
      return {}
    },
  },
})

export const { openProxyTray, resetProxyTrayState } = proxyTraySlice.actions
