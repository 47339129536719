import React, { ReactElement } from 'react'
import { GatsbyLinkProps, Link } from 'gatsby'
import { useAppSelector } from 'store/hooks'
import { noRedirectParam } from 'layouts'
import omit from 'lodash/omit'

export interface CustomGatsbyLink extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  testId?: string
}

export function NoRedirectLink(props: CustomGatsbyLink): ReactElement {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const to = sessionToken ? props.to : props.to.replace(noRedirectParam, '')

  return <Link data-testid={props.testId} to={to} {...omit(props, 'to', 'testId')} />
}
