import React, {
  MutableRefObject,
  ReactElement,
  ReactNode,
  RefObject,
  useRef,
  useState,
} from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import Tippy from '@tippyjs/react'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'
import customUnescape from 'utils/customUnescape'
import HiddenElementsNumber from './HiddenElementsNumber'
import { Placement } from 'tippy.js'
import useIsLongTextTruncated from '../utils/useIsLongTextTruncated'
import useGetColorMode from 'utils/useGetColorMode'

export function getClientAndScrollWidth(
  textRef?: RefObject<HTMLDivElement> | null,
  overflowCheckWithHighAccuracy?: boolean,
): { clientWidth: number; scrollWidth: number } {
  // for specific cases we need a more precise comparison, so it is important to get the clientWidth value as a float
  const clientWidth = overflowCheckWithHighAccuracy
    ? textRef?.current?.getBoundingClientRect().width ?? 0
    : textRef?.current?.clientWidth ?? 0
  const scrollWidth = textRef?.current?.scrollWidth ?? 0

  return {
    clientWidth,
    scrollWidth,
  }
}

export default function TextWithOverFlowAndTippyPopup({
  variant,
  content,
  tippyContent,
  sxText,
  sxTooltipContent,
  maxWidth,
  isEnabled = true,
  testId,
  boundaryElement,
  dropdownScrollingContainerRef,
  shouldCheckOnOverflow = true,
  theme,
  shouldShowHiddenElementsNumber,
  overflowCheckWithHighAccuracy,
  sxNumber,
  ariaLabel,
  className,
  placement,
  onTextTruncation,
  shouldResetParentSettings,
}: {
  variant?: string
  content: string | ReactNode
  tippyContent?: string
  sxText?: ThemeUIStyleObject
  sxTooltipContent?: ThemeUIStyleObject
  maxWidth?: string
  isEnabled?: boolean
  testId?: string
  boundaryElement?: Element | 'parent'
  dropdownScrollingContainerRef?: MutableRefObject<HTMLDivElement | null>
  shouldCheckOnOverflow?: boolean
  overflowCheckWithHighAccuracy?: boolean
  theme?: string
  shouldShowHiddenElementsNumber?: boolean
  sxNumber?: ThemeUIStyleObject
  ariaLabel: string
  className?: string
  placement?: Placement
  onTextTruncation?: () => void
  shouldResetParentSettings?: boolean
}): ReactElement {
  const textRef = useRef<HTMLDivElement>(null)
  const hideRef = useRef<() => void>()
  const isString = typeof content === 'string'
  const { isLightMode } = useGetColorMode()
  const [shouldBreakAnywhere, setShouldBreakAnywhere] = useState(false)

  useHideTooltipOnScroll(hideRef, dropdownScrollingContainerRef)
  useIsLongTextTruncated(textRef, onTextTruncation)

  return (
    <>
      <Tippy
        className={shouldResetParentSettings ? 'reset-parent-settings' : ''}
        onShow={(): void | false => {
          /*
           to correctly break a line we have to check if it is one word or if there are separators (space or dash),
           based on this we determine the overflowWrap value
          */
          const isOneWord = isString && !/\s|-/.test(content)
          setShouldBreakAnywhere(isOneWord)

          if (shouldCheckOnOverflow) {
            const { clientWidth, scrollWidth } = getClientAndScrollWidth(
              textRef,
              overflowCheckWithHighAccuracy,
            )

            if (
              clientWidth > scrollWidth ||
              (overflowCheckWithHighAccuracy ? false : clientWidth === scrollWidth)
            ) {
              // returning false here stops the tooltip from showing
              return false
            }
          }
        }}
        onCreate={instance => {
          hideRef.current = () => {
            instance.hide()
          }
        }}
        content={
          <Flex
            sx={{
              overflowWrap: shouldBreakAnywhere ? 'anywhere' : 'break-word',
              flexWrap: 'wrap',
              p: '0.2rem',
              ...sxTooltipContent,
            }}
          >
            {tippyContent || content}
          </Flex>
        }
        placement={placement}
        arrow={false}
        theme={theme || (isLightMode ? 'light-org-tooltip' : 'org-tooltip')}
        touch="hold"
        interactive={true}
        appendTo={boundaryElement ?? 'parent'}
        maxWidth={maxWidth ?? window.innerWidth}
        disabled={!isEnabled}
      >
        <Text
          className={className}
          variant={variant ?? 'none'}
          role="button" // it is important for accessibility, role="button" because when hovered it shows a tooltip
          data-testid={testId}
          ref={textRef}
          aria-label={`tooltip: ${ariaLabel}`}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...sxText,
          }}
          tabIndex={0}
        >
          {isString ? customUnescape(content) : content}
        </Text>
      </Tippy>
      {shouldShowHiddenElementsNumber && isString && (
        <HiddenElementsNumber textRef={textRef} content={content} sx={sxNumber} />
      )}
    </>
  )
}
