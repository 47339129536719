import { ElementType } from 'react'
import { helpLinks, solutions, orgFeatures, personalFeatures } from '../menus'
import { MobileNavMenuContentType } from 'ui/MobileNavMenuTray'
import { BLOG_CONTROL_D_DOMAIN } from 'gatsby-env-variables'

const mapCallback = (item: { header: string; icon: ElementType; to?: string; href?: string }) => ({
  title: item.header,
  leftIcon: item.icon,
  to: item.to,
  href: item.href,
})

export const orgNavMenuContent: MobileNavMenuContentType[] = [
  {
    title: 'Features',
    links: orgFeatures.map(mapCallback),
  },
  {
    title: 'Solutions',
    links: solutions.map(mapCallback),
  },
  {
    title: 'Pricing',
    to: '/pricing',
  },
  {
    title: 'Free DNS',
    to: '/free-dns',
  },
  {
    title: 'Help',
    links: helpLinks.map(mapCallback),
  },
  {
    title: 'Blog',
    to: `${BLOG_CONTROL_D_DOMAIN}`,
  },
]

export const personalNavMenuContent: MobileNavMenuContentType[] = [
  {
    title: 'Features',
    links: personalFeatures.map(mapCallback),
  },
  {
    title: 'Pricing',
    to: '/plans',
  },
  {
    title: 'Free DNS',
    to: '/free-dns',
  },
  {
    title: 'Help',
    links: helpLinks.map(mapCallback),
  },
  {
    title: 'Blog',
    to: `${BLOG_CONTROL_D_DOMAIN}`,
  },
]
