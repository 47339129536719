import React, { ReactElement } from 'react'
import { Box, Text } from 'theme-ui'
import ProfilesImage from 'images/tutorialFlows/Profiles/profiles-slide.svg'
import ProfilesImageLight from 'images/tutorialFlows/Profiles/profiles-slide-light.svg'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import { CONTROLDText } from 'ui/CONTROLD'
import useGetColorMode from 'utils/useGetColorMode'

export function ProfilesSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? ProfilesImageLight : ProfilesImage}
      description={
        <Box>
          <Text>
            A <Highlight svg={tutorialSlideIcons.profiles}>Profile</Highlight> is a set of rules
            that govern {CONTROLDText} behavior. <br /> <br /> This includes{' '}
            <Highlight svg={tutorialSlideIcons.filters}>Filters</Highlight> which{' '}
            <Highlight svg={tutorialSlideIcons.block}>Block</Highlight> categories of websites,{' '}
            <Highlight svg={tutorialSlideIcons.services}>Service</Highlight> rules that provide
            precise control over apps or web services, and highly specific{' '}
            <Highlight svg={tutorialSlideIcons.customRules}>Custom Rules</Highlight> that allow you
            to <Highlight svg={tutorialSlideIcons.block}>Block</Highlight> ,{' '}
            <Highlight svg={tutorialSlideIcons.redirect}>Redirect</Highlight> or{' '}
            <Highlight svg={tutorialSlideIcons.bypass}>Bypass</Highlight> domains and TLDs. <br />{' '}
            <br /> A <Highlight svg={tutorialSlideIcons.profiles}>Profile</Highlight> must be
            enforced by an <Highlight svg={tutorialSlideIcons.endpoints}>Endpoint</Highlight> in
            order to function.
          </Text>
        </Box>
      }
    />
  )
}
