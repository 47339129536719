import React, { ElementType, ReactElement } from 'react'
import { Button, ButtonProps, Svg } from 'ui'
import { ThemeUIStyleObject } from 'theme-ui'

export const buttonDiameter = 3.2

interface ActionIconButtonProps extends ButtonProps {
  isSwitchChecked: boolean
  icon: ElementType
  isActive: boolean
  label: string
  svgStyle?: ThemeUIStyleObject
}
export default function ActionIconButton({
  onClick,
  isSwitchChecked,
  icon,
  isActive,
  label,
  svgStyle,
  disabled = false,
  ...attrs
}: ActionIconButtonProps): ReactElement {
  return (
    <Button
      onClick={onClick}
      variant={isActive ? 'transparent' : 'simple'}
      aria-checked={isActive}
      data-testid={`${label.toLowerCase()}-button`}
      data-test-checked={isActive}
      tabIndex={isSwitchChecked ? 0 : -1}
      disabled={disabled}
      sx={{
        flexShrink: 0,
        p: 0,
        zIndex: 'zIndex50',
        borderRadius: '99rem',
        height: `${buttonDiameter}rem`,
        width: `${buttonDiameter}rem`,
        border: '1px solid',
        borderColor:
          isSwitchChecked && isActive && (label === 'Block' || label === 'Bypass')
            ? 'wakaiwaGrey20'
            : 'transparent',
        backgroundColor: isActive
          ? isSwitchChecked
            ? label === 'Block'
              ? 'errorOpaque'
              : 'greenApple'
            : 'none'
          : 'none',
        ':hover path': isSwitchChecked
          ? {}
          : {
              fill: 'white',
              opacity: 1,
            },
        ':disabled': {
          cursor: 'auto',
          backgroundColor: isActive ? 'white6' : 'transparent',
          borderColor: isActive ? 'blueYonder30' : 'none',
          '& svg,path': {
            fill: isActive ? (label === 'Block' ? 'errorOpaque' : 'greenApple') : 'aliceBlue30',
          },
        },
      }}
      {...attrs}
    >
      <Svg
        svg={icon}
        fill={isActive ? (isSwitchChecked ? 'blue800' : 'aliceBlue60') : 'aliceBlue60'}
        sx={{ width: '1.2rem', height: '1.2rem', ...svgStyle }}
      />
    </Button>
  )
}
