import React, { ReactElement } from 'react'
import InfoIcon from 'images/dashboard/info-icon.svg'
import type { onInfoIconClickType } from 'components/Dashboard/utils/useTutorialSlides'
import { ThemeUIStyleObject } from 'theme-ui'
import { CircleIconWrapperButton, Svg } from 'ui'

export default function InfoButton({
  testId,
  onInfoIconClick,
  fillSvg,
  ...props
}: {
  testId?: string
  onInfoIconClick?: onInfoIconClickType
  tabIndex?: number
  sx?: ThemeUIStyleObject
  fillSvg?: string
  hasBackgroundOnHover?: boolean
}): ReactElement {
  return (
    <CircleIconWrapperButton
      data-testid={testId || 'dashboard-info-button'}
      ariaLabel="info button"
      onClick={() => onInfoIconClick?.()}
      {...props}
    >
      <Svg svg={InfoIcon} fill={fillSvg || 'aliceBlue60'} sx={{ flexShrink: 0 }}></Svg>
    </CircleIconWrapperButton>
  )
}
