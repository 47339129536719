import { CustomRuleData, EnabledStatus, RuleType } from 'store/api/rules/rules.interface'
import { ServiceData } from 'store/api/services'
import { GroupData } from 'store/api/groups'

export interface RuleAction {
  do: RuleType
  via?: string
  via_v6?: string
  status: EnabledStatus
  ttl?: number
  /** only used by filters and services */
  ovr?: EnabledStatus
  /** only used by filters */
  lvl?: string
}

export type ActionableRule = CustomRuleData | ServiceData | GroupData

export function isServiceRule(rule?: ActionableRule): rule is ServiceData {
  return (rule as ServiceData)?.name !== undefined
}

export function isCustomRule(rule?: ActionableRule): rule is CustomRuleData {
  return typeof (rule as CustomRuleData)?.group === 'number'
}

export function isGroupRule(rule?: ActionableRule): rule is GroupData {
  return typeof (rule as GroupData)?.group === 'string'
}
