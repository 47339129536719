import React, { ReactElement } from 'react'
import StatisticsIcon from 'images/dashboard/navBar/statistics-icon.svg'
import ActivityLogIcon from 'images/dashboard/navBar/activity-log-icon.svg'
import AdminLogsIcon from 'images/dashboard/navBar/admin-logs-icon.svg'
import ReportsIcon from 'images/dashboard/navBar/reports-icon.svg'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import { Flex } from 'theme-ui'
import AnalyticsAccordion from './AnalyticsAccordion'
import useGetUserState from 'store/api/user/useGetUserState'
import { useGetUnImpersonatedUserQuery } from 'store/api/user'
import useQueryString from 'utils/useQueryString'
import { OrgPermissionLevel } from 'store/api/organization/organization.interface'

export enum AnalyticsNavRelativePath {
  STATISTICS = 'statistics',
  ACTIVITY_LOG = 'activity-log',
  ADMIN_LOGS = 'admin-logs',
  REPORTS = 'reports',
}

export function AnalyticsNavMenu(): ReactElement {
  const { qs } = useQueryString()
  const { isOrganization } = useGetUserState()
  const { data: userData } = useGetUnImpersonatedUserQuery('')
  const isSubOrg = !!userData?.org?.parent_org
  const isParentOrgUser = isOrganization && !isSubOrg && !qs.orgId
  const permissionLevel = userData?.org?.permission?.level
  const canCreateReports =
    isOrganization &&
    (permissionLevel === OrgPermissionLevel.OWNER || permissionLevel === OrgPermissionLevel.ADMIN)

  return (
    <AnalyticsAccordion>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '0.1rem',
        }}
      >
        <NavMenuItem
          isSubItem
          activeIcon={StatisticsIcon}
          relativePath={AnalyticsNavRelativePath.STATISTICS}
          label="Statistics"
        />
        <NavMenuItem
          isSubItem
          activeIcon={ActivityLogIcon}
          relativePath={AnalyticsNavRelativePath.ACTIVITY_LOG}
          label="Activity Log"
        />
        {isParentOrgUser && (
          <NavMenuItem
            isSubItem
            activeIcon={AdminLogsIcon}
            relativePath={AnalyticsNavRelativePath.ADMIN_LOGS}
            label="Admin Logs"
          />
        )}
        {canCreateReports && (
          <NavMenuItem
            isSubItem
            activeIcon={ReportsIcon}
            relativePath={AnalyticsNavRelativePath.REPORTS}
            label="Reports"
          />
        )}
      </Flex>
    </AnalyticsAccordion>
  )
}
