import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import StaticPage, {
  staticPageDesktopMaxWidth,
  staticPageMarginTop,
} from 'components/StaticPage/StaticPage'
import { Flex } from 'theme-ui'
import SecureBrowsing from 'components/FreeDNSPage/SecureBrowsing'
import NeedMoreControl from 'components/FreeDns/sections/NeedMoreControl'
import CustomConfigurations from 'components/FreeDNSPage/CustomConfigurations'
import StandardConfigurations from 'components/FreeDNSPage/StandardConfigurations'
import FreeDNSTray from 'components/FreeDNSPage/FreeDNSTray/FreeDNSTray'
import FreeDNSTrayContent from 'components/FreeDNSPage/FreeDNSTray/FreeDNSTrayContent'
import GetStartedHelpPane from '../SetupGuide/SetupGuide'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  removeSelectedFiltersPK,
  removeSelectedDNSKey,
  remove3rdPartyFilterPK,
} from 'store/freeDNS'
import { clearDevices } from 'store/multiprofile/devices'
import { Palette } from 'ui/Theme/themeObject.interface'
import useQueryString from 'utils/useQueryString'
import { TrayPresenter } from 'ui'
import useFocusLock from 'utils/useFocusLock'
import ThirdPartyFilters from 'components/FreeDns/sections/ThirdPartyFilters/ThirdPartyFilters'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useStepHelper from 'components/SetupGuide/useStepHelper'

export const freeDnsPagePath = 'free-dns'
export const commonGap = ['1.6rem', '4.8rem']

// TODO: it should be removed together with the whole FreeDNSPage folder when redesign page is ready
export default function FreeDNSPage(): ReactElement {
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()
  const [backgroundColor, setBackgroundColor] = useState<keyof Palette>('darkItemBoxShadow')
  const [isOpenTray, setIsOpenTray] = useState(false)
  const { closePane } = useStepHelper()
  const {
    selectedFiltersPKs = [],
    selectedDNSKey,
    selected3rdPartyFilterPK,
  } = useAppSelector(s => s.freeDNS)
  const isMobile = useBreakpointIndex() === 0

  useFocusLock(isOpenTray)

  // document body scroll should be hidden when tray is opened
  useEffect(() => {
    if (qs.tray || (qs.helpPane && (isOpenTray || isMobile))) {
      document.body.style.overflowY = 'hidden'
      return
    }

    document.body.style.overflowY = 'auto'
  }, [isOpenTray, isMobile, qs])

  // should close helpPane on first render
  useEffect(() => {
    closePane()
    // we only want to trigger this effect when component is first rendered, not when
    // `closePane` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // fix a bug with showing the device specific resolvers unique to the account in the setup tray after logging out.
    dispatch(clearDevices())

    // fix for the page position issue on iOS 14
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [dispatch])

  useEffect(() => {
    setIsOpenTray(
      !!(selectedFiltersPKs?.length || selectedDNSKey || (!isMobile && selected3rdPartyFilterPK)),
    )
  }, [selectedFiltersPKs.length, selectedDNSKey, selected3rdPartyFilterPK, isMobile])

  useEffect(() => {
    dispatch(removeSelectedFiltersPK())
    dispatch(removeSelectedDNSKey())
    dispatch(remove3rdPartyFilterPK())
  }, [dispatch])

  const handleScroll = useCallback(() => {
    if (document.body.scrollTop) {
      if (backgroundColor !== 'darkItemBoxShadow') {
        return
      }

      return setBackgroundColor('darkItemBoxShadow')
    }

    setBackgroundColor('darkItemBoxShadow')
  }, [backgroundColor])

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll)

    return () => {
      document.body.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <StaticPage
      hasMaxWidth={false}
      containerStyles={{
        backgroundColor: 'darkItemBoxShadow',
      }}
      headerStyles={{
        backgroundColor: backgroundColor,
        transition: 'background-color 0.3s',
      }}
    >
      <Flex
        sx={{
          display: ['block', 'block', 'none'],
          position: 'absolute',
          width: '100%',
          height: '44.9rem',
          mt: staticPageMarginTop,
          zIndex: 0,
          overflowY: 'scroll',
        }}
      />
      <Flex
        sx={{
          position: 'absolute',
          width: '100%',
          height: ['68rem', '50rem', '50rem'],
          zIndex: 0,
        }}
      />
      <Flex
        sx={{
          width: '100%',
          maxWidth: staticPageDesktopMaxWidth,
          flexDirection: 'column',
          alignSelf: 'center',
        }}
      >
        <SecureBrowsing />
        <StandardConfigurations />
        <ThirdPartyFilters />
        <CustomConfigurations />
        <NeedMoreControl />
        <GetStartedHelpPane />
        <FreeDNSTray
          isOpen={isOpenTray}
          showOverlay={false}
          entersFrom={'bottom'}
          slideDimensionLength={'auto'}
          zIndexName="zIndex9"
          sxTray={{
            height: 'fit-content',
            backgroundColor: 'blue800',
            borderTopLeftRadius: '1.2rem',
            borderTopRightRadius: '1.2rem',
          }}
        >
          <FreeDNSTrayContent setIsOpenTray={setIsOpenTray} />
        </FreeDNSTray>
        <TrayPresenter />
      </Flex>
    </StaticPage>
  )
}
