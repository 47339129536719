import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import ScheduleIcon from 'images/dashboard/devices/shedule-icon.svg'
import { Svg } from 'ui'
import moment from 'moment'
import { EnabledStatus } from 'store/api/rules'
import ScheduleToggle from 'components/Dashboard/Devices/DeviceListItem/ScheduleToggle'
import ScheduleMenu from 'components/Dashboard/Devices/DeviceListItem/ScheduleMenu'
import {
  ScheduleContextProvider,
  useScheduleState,
} from 'components/Dashboard/Devices/DeviceListItem/ScheduleContextProvider'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { useGetSchedulesQuery } from 'store/api/schedules/schedules'

const getFormattedTime = (time: moment.Moment): string => {
  return time.format('ddd, hh:mm A')
}
/**
 * The schedule is stored starting on a Monday and ending on a Sunday. Working with this type of a Mon-Sun week causes bugs since
 * Moment assumes Sunday to be the start of the week. To align with what Moment expects, this method orders the week from Sun-Sat.
 */
const getOrderedWeekdays = weekdays => {
  const weekdaysArray = Object.entries(weekdays)

  const sunday = weekdaysArray.pop()
  if (sunday) {
    weekdaysArray.unshift(sunday)
  }
  return weekdaysArray
}
const getNextTime = (time, weekDays): moment.Moment => {
  const dates: moment.Moment[] = getOrderedWeekdays(weekDays)
    .reduce((allDates: moment.Moment[], currentDay, index) => {
      if (currentDay[1] === 1) {
        // start from the first day of current week
        const start = moment().startOf('week')
        // check if todays day is after the current day under consideration. If so, add a week to the start so that
        // we get the date from next week
        if (moment().day() > index) {
          start.add(1, 'week')
        }
        // add days
        start.add(index, 'days')

        // add the time
        start.add(moment.duration(time))

        allDates.push(start)
      }

      return allDates
    }, [])
    .sort((a, b) => a.diff(b)) // sort dates descending

  // if current date time is after the top date remove it
  if (dates.length > 1 && moment().isAfter(dates[0])) {
    dates.shift()
  }

  return dates[0]
}

const getNextEndTime = (startTime, endTime, weekDays) => {
  const dates = getOrderedWeekdays(weekDays)
  if (startTime === '00:00' && endTime === '00:00') {
    if (dates.every(([, value]) => value === 1)) {
      return 'Never stops'
    }
    const timeWhenScheduleEnds = dates.reduce<moment.Moment>((end, currentDay, index, allDays) => {
      // the current day is checked & the next day is not checked
      if (currentDay[1] === 1 && (allDays[index + 1] ?? allDays[0])[1] === 0) {
        const temporalPointer = moment().startOf('week')
        /* check if todays day is after the current day under consideration.
         *  If so, add a week to the temporalPointer so that we get the date from next week
         */
        if (moment().day() > index) {
          temporalPointer.add(1, 'week')
        }
        // when endtime is seleted as 12:00AM we mean it to be next day 12:00AM, so add an extra day
        temporalPointer.add(index + 1, 'days')

        if (temporalPointer < end) {
          return temporalPointer
        }
      }

      return end
    }, moment().add(1, 'month'))

    return `Stops ${getFormattedTime(timeWhenScheduleEnds)}`
  }

  return `Stops ${getFormattedTime(getNextTime(endTime, weekDays))}`
}
export default function ScheduleStatus({ devicePk }: { devicePk?: string }): ReactElement {
  const { data: schedulesData } = useGetSchedulesQuery('')
  const existingSchedule = schedulesData?.schedules.find(
    schedule => schedule?.device?.PK === devicePk,
  )

  return (
    <ScheduleContextProvider existingSchedule={existingSchedule}>
      <Flex
        sx={{
          flex: '1 1 auto',
          flexDirection: ['column', 'column', 'row'],
          alignItems: ['left', 'left', 'center'],
          justifyContent: 'space-between',
          color: 'white50',
        }}
      >
        {existingSchedule &&
          (existingSchedule.enforcing ? <ScheduleEnforcing /> : <ScheduleNotEnforcing />)}
      </Flex>
    </ScheduleContextProvider>
  )
}

const ScheduleWrapper = ({ icon, text, children }) => (
  <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
    <Flex
      sx={{
        gap: '1.2rem',
        alignItems: 'center',
      }}
    >
      <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>{icon}</Flex>
      {text}
    </Flex>
    <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>{children}</Flex>
  </Flex>
)

const ScheduleNotEnforcing = () => {
  const { existingSchedule } = useScheduleState()
  const nextStartTime =
    existingSchedule && getNextTime(existingSchedule.time_start, existingSchedule.weekdays)

  const isEnabled = existingSchedule?.status === EnabledStatus.ENABLED
  return (
    <ScheduleWrapper
      icon={<Svg svg={ScheduleIcon} fill={isEnabled ? 'banana' : 'aliceBlue'} />}
      text={
        <Flex
          sx={{
            color: isEnabled ? 'banana' : 'aliceBlue60',
            flexDirection: 'column',
          }}
        >
          <Text variant="size12Weight400">{existingSchedule?.profile?.name}</Text>
          <TextWithOverFlowAndTippyPopup
            testId="schedule-not-enforcing"
            variant="size12Weight600"
            content={
              isEnabled && nextStartTime ? `Starts ${getFormattedTime(nextStartTime)}` : 'Disabled'
            }
            ariaLabel={
              isEnabled && nextStartTime ? `Starts ${getFormattedTime(nextStartTime)}` : 'Disabled'
            }
          />
        </Flex>
      }
    >
      <ScheduleToggle />
      <ScheduleMenu />
    </ScheduleWrapper>
  )
}

const ScheduleEnforcing = () => {
  const { existingSchedule } = useScheduleState()
  const nextEndTime =
    existingSchedule &&
    getNextEndTime(
      existingSchedule.time_start,
      existingSchedule.time_end,
      existingSchedule.weekdays,
    )

  const isEnabled = existingSchedule?.status === EnabledStatus.ENABLED
  return (
    <ScheduleWrapper
      icon={<Svg svg={ScheduleIcon} fill={isEnabled ? 'greenApple' : 'aliceBlue'} />}
      text={
        <Flex
          sx={{
            color: isEnabled ? 'greenApple' : 'aliceBlue',
            flexDirection: 'column',
          }}
        >
          <Text variant="size12Weight400">{existingSchedule?.profile?.name}</Text>
          <Text variant="size12Weight600" data-testid="schedule-enforcing">
            {isEnabled ? nextEndTime : 'Never stops'}
          </Text>
        </Flex>
      }
    >
      <ScheduleToggle />
      <ScheduleMenu />
    </ScheduleWrapper>
  )
}
