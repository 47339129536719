import React, { ElementType, ReactNode, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg } from 'ui'
import ProfilesIcon from 'images/profileManagement/profiles-icon.svg'
import FiltersIcon from 'images/subheader/FiltersIcon.svg'
import ServicesIcon from 'images/subheader/ServicesIcon.svg'
import CustomRulesIcon from 'images/subheader/CustomRulesIcon.svg'
import EndpointsIcon from 'images/dashboard/navBar/devices-icon.svg'
import ProvisionIcon from 'images/dashboard/navBar/provision.svg'
import ActivityLogIcon from 'images/dashboard/navBar/activity-log-icon.svg'
import DefaultRuleIcon from 'images/default-rule.svg'
import BlockIcon from 'images/block-icon-log.svg'
import WhitelistIcon from 'images/whitelist-icon-log.svg'
import GlobeIcon from 'images/homePage/customlocs.svg'
import InfoIcon from 'images/dashboard/info-icon.svg'
import AnalyticsIcon from 'images/dashboard/navBar/analytics-icon.svg'
import MyOrganizationIcon from 'images/organization/my-organization-icon.svg'
import SubOrganizationIcon from 'images/organization/sub-organizations-icon.svg'
import BillingIcon from 'images/dashboard/navBar/billing-icon.svg'
import AdminLogIcon from 'images/dashboard/navBar/admin-logs-icon.svg'
import ClientsIcon from 'images/dashboard/devices/clients.svg'
import StatisticsIcon from 'images/dashboard/navBar/statistics-icon.svg'

interface TutorialBaseSlideProps {
  illustration?: ElementType
  description: ReactNode
  bottomContent?: ReactNode
}

export function TutorialBaseSlide({
  illustration,
  description,
  bottomContent,
}: TutorialBaseSlideProps): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {illustration && (
        <Svg svg={illustration} sx={{ flexShrink: 0, marginBottom: '4rem', maxWidth: '100%' }} />
      )}
      <Text
        variant="size14Weight400"
        sx={{
          width: ['100%', '47.5rem'],
          textAlign: 'center',
          color: 'aliceBlue60',
        }}
      >
        {description}
        {bottomContent}
      </Text>
    </Flex>
  )
}

export function Highlight({
  children,
  svg,
}: {
  children?: ReactNode
  svg?: React.ElementType
}): ReactElement {
  return (
    <Text sx={{ display: 'inline-block' }}>
      {svg && (
        <Svg
          svg={svg}
          fill="aliceBlue"
          sx={{ width: '1.2rem', height: '1.2rem', flexShrink: 0, mr: '0.4rem' }}
        />
      )}
      <Text variant="size14Weight700" sx={{ color: 'aliceBlue' }}>
        {children}
      </Text>
    </Text>
  )
}

export const tutorialSlideIcons = {
  profiles: ProfilesIcon,
  filters: FiltersIcon,
  services: ServicesIcon,
  customRules: CustomRulesIcon,
  block: BlockIcon,
  bypass: WhitelistIcon,
  redirect: GlobeIcon,
  endpoints: EndpointsIcon,
  provision: ProvisionIcon,
  defaultRule: DefaultRuleIcon,
  activityLog: ActivityLogIcon,
  analytics: AnalyticsIcon,
  infoIcon: InfoIcon,
  org: MyOrganizationIcon,
  subOrg: SubOrganizationIcon,
  billing: BillingIcon,
  adminLog: AdminLogIcon,
  clients: ClientsIcon,
  statistics: StatisticsIcon,
}
