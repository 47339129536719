import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Filter } from 'store/api/filters/filters.interface'

export interface Filters {
  filters: Filter[]
}
export interface FiltersState {
  selectedFilter?: Filter
}

const initialState: FiltersState = {
  selectedFilter: undefined,
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSelectedFilter(state, action: PayloadAction<Filter>): void {
      state.selectedFilter = action.payload
    },
  },
})

export const { setSelectedFilter } = filtersSlice.actions
