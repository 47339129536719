import React, { ReactElement, ReactNode, useEffect } from 'react'
import { Tray } from 'ui'
import { useAppDispatch } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import TrayHeader from 'components/TrayHeader'
import { BackButton, CloseButton } from 'components/SetupGuide/Components'
import { ButtonStyles } from 'ui/ModalDialog/ModalDialogHeader'
import { ThemeUIStyleObject } from 'theme-ui'
import InfoButton from 'ui/InfoButton'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { setIsMobileTrayVisible } from 'store/dashboard/dashboard'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export const trayHeaderHeight = '7.8rem'

const MobileTray = ({
  isOpen = true,
  title,
  onCloseClick,
  dataTestId,
  sxTray,
  sxHeader,
  children,
  infoUrl,
}: {
  isOpen?: boolean
  title?: string
  dataTestId?: string
  sxTray?: ThemeUIStyleObject
  sxHeader?: ThemeUIStyleObject
  onCloseClick: () => void
  children: ReactNode
  infoUrl?: string
}): ReactElement => {
  const dispatch = useAppDispatch()
  const { data: userData } = useGetUser()
  const haptics = userData?.haptics
  const { qs } = useQueryString()

  useEffect(() => {
    dispatch(setIsMobileTrayVisible(true))
    return () => {
      dispatch(setIsMobileTrayVisible(false))
    }
  }, [dispatch])

  return (
    <Tray
      dataTestId={dataTestId}
      entersFrom="bottom"
      showOverlay={false}
      isOpen={isOpen}
      sx={{
        position: 'fixed',
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        backgroundColor: 'darkItemBG',
        zIndex: 'zIndex550',
        overflowY: 'hidden',
        ...sxTray,
      }}
      slideDimensionLength={'100%'}
      shouldVibrateOnPresent={!!haptics}
    >
      <TrayHeader
        sx={{
          width: '100%',
          p: 0,
          height: trayHeaderHeight,
          alignItems: 'center',
          fontSize: '1.8rem',
          fontWeight: 'bold',
          ...sxHeader,
        }}
        leftComponent={
          qs.setupOs || title?.includes('Other Endpoint') ? (
            <BackButton sx={ButtonStyles} />
          ) : (
            <CloseButton sx={ButtonStyles} onClick={onCloseClick} />
          )
        }
        rightComponent={
          infoUrl ? (
            <InfoButton
              onInfoIconClick={() =>
                window.open(`${DOCS_CONTROL_D_DOMAIN}/${infoUrl}`, '_blank', 'noopener, noreferrer')
              }
              sx={{ mr: '1.6rem' }}
            />
          ) : (
            <></>
          )
        }
      >
        {title}
      </TrayHeader>
      {children}
    </Tray>
  )
}

export default MobileTray
