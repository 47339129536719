import React, { ReactElement, ReactNode } from 'react'
import type { ThemeUICSSObject } from 'theme-ui'
import { Button } from 'ui'
import { useAppSelector } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'

export const sharedButtonStyles: ThemeUICSSObject = {
  height: '3.8rem',
}

interface ProfileDownloadButtonProps {
  url: string
  children: ReactNode
  disabled?: boolean
  sx?: ThemeUICSSObject
}

export default function ProfileDownloadButton({
  url,
  children,
  disabled = false,
  sx,
}: ProfileDownloadButtonProps): ReactElement {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { qs } = useQueryString()
  const selectedDevicePk = qs.deviceId ?? qs.resolverUid

  const onClick = () => {
    window.open(url)
  }

  return (
    <Button
      ariaLabel="download profile button"
      onClick={onClick}
      onKeyDown={stopPropagationOnKeyPress}
      variant="newSecondary"
      sx={{ ...sharedButtonStyles, ...sx }}
      disabled={disabled || (!selectedDevicePk && !!sessionToken && !qs.freeResolverType)}
    >
      {children}
    </Button>
  )
}
