import React, { ReactElement, ReactNode, useMemo } from 'react'
import { kebabCaseIdFromString } from 'utils'
import { TrayInfo, TrayRenderProps } from 'ui'
import TutorialTray from 'components/Dashboard/Onboarding/TutorialTray'

export interface TutorialSlideData {
  title: string
  content: ReactNode
  url?: string
}
export default function useTutorialTray({
  tutorialType,
  tutorialSlides,
  title,
  content,
  footerContent,
  handleFinish,
}: {
  tutorialType: string
  tutorialSlides: number
  title: string
  content?: ReactNode
  footerContent?: ReactElement
  handleFinish: () => void
}): TrayInfo {
  return useMemo(
    () => ({
      id: `${kebabCaseIdFromString(tutorialType)}-tutorial-tray`,
      renderTray: (props: TrayRenderProps) => (
        <TutorialTray
          title={title}
          footerContent={footerContent}
          tutorialType={tutorialType}
          handleFinish={async () => {
            handleFinish()
            props.dismiss()
          }}
          totalSlides={tutorialSlides}
          handleEarlyExit={handleFinish}
          {...props}
        >
          {content}
        </TutorialTray>
      ),
      zIndexName: 'zIndex550',
    }),
    [tutorialType, tutorialSlides, title, content, footerContent, handleFinish],
  )
}
