import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import {
  ButtonGroup,
  CompanyLogo,
  MainMenuDropdown,
  MenuItem,
} from 'components/HomePage/Header/index'
import {
  orgFeatures,
  personalFeatures,
  helpLinks,
  solutions,
} from 'components/HomePage/Header/menus'
import VerticalDivider from 'ui/VerticalDivider'
import { OrgAndPersonalHeaderButtons } from 'components/NewStaticPage/StaticPageHeader'
import useDetermineTypeOfHeader from 'utils/useDetermineTypeOfHeader'
import { useAppSelector } from 'store/hooks'
import { useLocation } from '@reach/router'
import { userApi } from 'store/api/user'
import { BLOG_CONTROL_D_DOMAIN } from 'gatsby-env-variables'

export function DesktopHeader(): ReactElement {
  const { isPersonalHeader } = useDetermineTypeOfHeader()
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const location = useLocation()
  const { data: userData, isLoading: isUserDataLoading } =
    userApi.endpoints.getUser.useQueryState('')
  const isOrgLoggedIn = userData?.org

  return (
    <>
      <Flex sx={{ gap: '2.4rem', alignItems: 'center' }}>
        <CompanyLogo
          sx={{
            '@media screen and (max-width: 1280px)': {
              display: 'none',
            },
          }}
        />
        <OrgAndPersonalHeaderButtons />
      </Flex>
      <Flex>
        <Flex sx={{ justifyContent: 'space-between' }}>
          {!isUserDataLoading && (
            <Flex
              sx={{
                alignItems: 'center',
                flexShrink: 0,
                p: 0,
                pr: sessionToken ? '0.8rem' : 0,
              }}
            >
              <MainMenuDropdown
                testId="features-dropdown"
                dropdownItems={isPersonalHeader ? personalFeatures : orgFeatures}
              >
                Features
              </MainMenuDropdown>
              {!isPersonalHeader && (
                <MainMenuDropdown testId="solutions-dropdown" dropdownItems={solutions}>
                  Solutions
                </MainMenuDropdown>
              )}
              {!isOrgLoggedIn && (
                <MenuItem text="Pricing" to={isPersonalHeader ? '/plans' : '/pricing'} />
              )}
              <MenuItem
                text="Free DNS"
                to="/free-dns"
                linkState={{ prevPath: location.pathname }}
              />
              <MainMenuDropdown testId="help-dropdown" dropdownItems={helpLinks}>
                Help
              </MainMenuDropdown>
              <MenuItem text="Blog" to={`${BLOG_CONTROL_D_DOMAIN}`} />
            </Flex>
          )}
          {!sessionToken && (
            <VerticalDivider
              sx={{
                height: '4rem',
                backgroundColor: 'borderLight',
                flexShrink: 0,
                mx: '0.8rem',
              }}
            />
          )}
        </Flex>
        <ButtonGroup />
      </Flex>
    </>
  )
}
