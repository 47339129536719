import React, { ReactElement, useCallback, useState } from 'react'
import { Flex, Text } from '@theme-ui/components'
import { MultiSelectInput, Option, Svg } from 'ui'
import SmallInfoIcon from 'images/dashboard/filters/small-filter-info-icon.svg'
import { CUSTOM_RULES_LIMIT } from 'gatsby-env-variables'
import { updateCustomRuleDraft } from 'store/customRules'
import { sanitizeUrls } from './RuleContent'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Setter } from 'utils'
import useQueryString from 'utils/useQueryString'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'

const limitErrorMessage = 'Please add 10,000 rules or less at once.'

export default function RuleDomainInput({
  validationErrorMsg,
  setValidationErrorMsg,
  rulesToEditLength,
}: {
  validationErrorMsg?: string
  setValidationErrorMsg: Setter<string>
  rulesToEditLength?: number
}): ReactElement {
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()
  const draftCustomRule = useAppSelector(s => s.ruleTray.draftCustomRule)
  const [hostnames, setHostnames] = useState<Option[] | undefined>(() => {
    const domain = qs.domain

    if (domain) {
      dispatch(
        updateCustomRuleDraft({
          PK: domain,
          action: {
            do: 1,
          },
        }),
      )

      return [{ label: domain, value: domain }]
    }

    return draftCustomRule.PK
      ? (uniq(compact(draftCustomRule.PK?.split('\n')))?.map(pk => ({
          label: pk,
          value: pk,
        })) as Option[])
      : undefined
  })

  const onInputBlur = useCallback(
    (hostnames): void => {
      const value = sanitizeUrls(
        hostnames?.filter(({ value }) => value !== '').map(({ value }) => value) || [],
      )

      setValidationErrorMsg(hostnames.length > CUSTOM_RULES_LIMIT ? limitErrorMessage : '')

      dispatch(
        updateCustomRuleDraft({
          PK: value,
        }),
      )
    },
    [dispatch, setValidationErrorMsg],
  )

  const onInputChange = useCallback(
    (newValue): void => {
      if (!newValue) {
        return
      }

      const rulesLength = hostnames?.length || 0

      setValidationErrorMsg(rulesLength > CUSTOM_RULES_LIMIT ? limitErrorMessage : '')

      dispatch(
        updateCustomRuleDraft({
          PK:
            (hostnames ? hostnames?.map(({ value }) => value)?.join('\n') : '') +
            `${newValue ? `${hostnames ? '\n' : ''}${newValue}` : ''}`,
        }),
      )
    },
    [dispatch, hostnames, setValidationErrorMsg],
  )

  return (
    <Flex sx={{ flexDirection: 'column', px: '1.6rem', mt: '1.6rem' }}>
      <Flex
        data-testid="rule-tray-input"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Text
          variant="size12Weight400"
          sx={{
            color: 'aliceBlue70',
            mb: '0.8rem',
          }}
        >
          Domain/Hostname
        </Text>
        <MultiSelectInput
          testId="rule-tray-input"
          isCustomRule
          isSmallInput
          isDisabled={!!rulesToEditLength}
          isError={!!validationErrorMsg}
          placeholder="Enter domains/hostnames"
          aria-label="enter one or more domain/hostname"
          value={hostnames}
          setValue={setHostnames}
          onInputChange={onInputChange}
          onBlurChange={onInputBlur}
          onChange={() => setValidationErrorMsg('')}
        />
      </Flex>
      <Flex
        data-testid="rule-input-error"
        sx={{
          color: 'errorOpaque',
          fontSize: '1.2rem',
          mt: validationErrorMsg ? '0.8rem' : 0,
          alignItems: 'center',
        }}
      >
        {validationErrorMsg && (
          <Svg
            svg={SmallInfoIcon}
            fill="errorOpaque"
            sx={{ mr: '0.4rem', flexShrink: 0, '& g': { opacity: 1 } }}
          />
        )}
        {validationErrorMsg}
      </Flex>
    </Flex>
  )
}
