// @jsx jsx

import { jsx } from 'theme-ui'
import React from 'react'
import ExternalLinkProps from 'ui/ExternalLink/ExternalLink.interface'

export default function ExternalLink(props: ExternalLinkProps): React.ReactElement {
  const { to, children, sx, ...rest } = props
  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      // eslint-disable-next-line react/no-unknown-property
      sx={{
        color: 'white',
        textDecoration: 'none',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </a>
  )
}
