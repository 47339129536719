import React, {
  ReactElement,
  useState,
  useCallback,
  createRef,
  createContext,
  useMemo,
  useContext,
  useEffect,
  useRef,
  FunctionComponent,
} from 'react'
import uniqueId from 'lodash/uniqueId'
import {
  AlertPresenterItemInfo,
  AlertId,
  AlertState,
  AlertInfo,
  AlertPresenterClient,
  AlertPresenterState,
  AlertContextProviderProps,
} from './AlertPresenter.interface'
import useOnClickOutside from 'utils/useOnClickOutside'

const AlertPresenterClientContext = createContext<AlertPresenterClient>({} as AlertPresenterClient)

const AlertPresenterStateContext = createContext<AlertPresenterState>({} as AlertPresenterState)

export const defaultAlertPresenterTimeout = 5000

export default function AlertContextProvider({
  children,
}: AlertContextProviderProps): ReactElement {
  const [alerts, setAlerts] = useState<AlertPresenterItemInfo[]>([])
  const cumulativeHeightRef = useRef<number>(0)
  // we use a ref here because the pending timeouts array is only ever used in a cleanup
  // effect, and we only want this to execute once (if we used `useState`, this would happen
  // every time the timeouts array changed)
  const pendingTimeoutsRef = useRef<number[]>([])

  const dismissAlert = useCallback((id: AlertId): void => {
    setAlerts(prevAlerts =>
      prevAlerts.map(alert => {
        if (alert.id === id) {
          // this starts the dismissal process, and then the `onTransitionEnd` callback for the
          // presenter item removes it from the alerts array
          return { ...alert, state: AlertState.DISMISSING }
        }

        return alert
      }),
    )
  }, [])

  const dismissAllAlerts = useCallback((): void => {
    setAlerts(prevAlerts =>
      prevAlerts.map(alert => {
        return { ...alert, state: AlertState.DISMISSING }
      }),
    )
  }, [])

  const presentAlert = useCallback(
    (alert: AlertInfo, id: AlertId = uniqueId('alert_')): AlertId => {
      const newAlertPresenterItem: AlertPresenterItemInfo = {
        alert,
        id,
        state: AlertState.PRESENTING,
        ref: createRef<HTMLDivElement>(),
      }

      setAlerts(prevAlerts => {
        if (prevAlerts.findIndex(item => item.id === id) !== -1) {
          return prevAlerts.map(item =>
            // maintaining the same ref, so that we can replace the current alert while keeping the same ui element.
            // needed for switching of alerts for force verify
            item.id === id ? { ...newAlertPresenterItem, ref: item.ref } : item,
          )
        } else {
          return [...prevAlerts, newAlertPresenterItem]
        }
      })

      if (!alert.isSticky) {
        const timeout = window.setTimeout(() => {
          dismissAlert(id)
          pendingTimeoutsRef.current = pendingTimeoutsRef.current.filter(
            prevPendingTimeout => prevPendingTimeout === timeout,
          )
        }, alert.timeout || defaultAlertPresenterTimeout)
        pendingTimeoutsRef.current.push(timeout)
      }

      return id
    },
    [dismissAlert],
  )

  const alertPresenterClient = useMemo(() => {
    return { presentAlert, dismissAlert, dismissAllAlerts }
  }, [dismissAlert, presentAlert, dismissAllAlerts])

  const alertPresenterState = useMemo(() => {
    return {
      alerts,
      setAlerts,
      cumulativeHeightRef,
    }
  }, [alerts])

  useEffect(() => {
    return (): void => {
      pendingTimeoutsRef.current.forEach(timeout => {
        clearTimeout(timeout)
      })
    }
  }, [])

  const dismissOnClickOutside = useCallback(() => {
    alerts.filter(alert => alert.alert.shouldDismissOnClickOutside).forEach(a => dismissAlert(a.id))
  }, [alerts, dismissAlert])

  const refsToDismiss = alerts.map(alert => alert.ref)

  useOnClickOutside(refsToDismiss, dismissOnClickOutside)

  return (
    <AlertPresenterStateContext.Provider value={alertPresenterState}>
      <AlertPresenterClientContext.Provider value={alertPresenterClient}>
        {children}
      </AlertPresenterClientContext.Provider>
    </AlertPresenterStateContext.Provider>
  )
}

export const useAlertPresenter = (): AlertPresenterClient => useContext(AlertPresenterClientContext)

export const useAlertPresenterState = (): AlertPresenterState =>
  useContext(AlertPresenterStateContext)

export function withAlertContextProvider<P>(
  Component: FunctionComponent<P>,
  displayName?: string,
): (props: P) => ReactElement {
  const WrappedComponent = (props): ReactElement => {
    return (
      <AlertContextProvider>
        <Component {...props} />
      </AlertContextProvider>
    )
  }
  WrappedComponent.displayName = displayName ?? Component.name
  return WrappedComponent
}
