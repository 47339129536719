import React, { ReactElement, ReactNode, useCallback, useMemo, useRef } from 'react'
import { Flex } from 'theme-ui'
import { TrayRenderProps } from 'ui'
import useOnClickOutside from 'utils/useOnClickOutside'
import { TutorialType } from 'store/tutorial/tutorial'
import useArrowNavForHelpPane from 'utils/useArrowKeyHelpPaneNavigation'
import { useHotkeys } from 'react-hotkeys-hook'
import ModalDialog from 'ui/NewModalDialog'
import useQueryString from 'utils/useQueryString'
import { modalDialogHeaderHeight } from 'ui/ModalDialog/ModalDialogHeader'
import useGetUser from 'components/Dashboard/utils/useGetUser'

interface TutorialTrayProps extends TrayRenderProps {
  title: string
  tutorialType: TutorialType | string
  children: ReactNode
  handleFinish: () => void
  totalSlides: number
  footerContent?: ReactElement
  handleEarlyExit: () => void
}

const TutorialModalDialog = ({
  title,
  tutorialType,
  children,
  dismiss,
  handleFinish,
  totalSlides,
  footerContent,
  handleEarlyExit,
}: TutorialTrayProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { data: userData } = useGetUser()
  const isHapticsEnabled = !!userData?.haptics
  const { nav, qs } = useQueryString()
  const currentSlideIndex = +(qs.slide ?? 0) || 0

  useOnClickOutside([containerRef], () => {
    handleEarlyExit()
  })

  useHotkeys('esc', handleEarlyExit)

  const onLeftArrowPress = useCallback(() => {
    if (currentSlideIndex !== 0) {
      nav({ ...qs, slide: currentSlideIndex - 1 })
    }
  }, [currentSlideIndex, nav, qs])

  const onRightArrowPress = useCallback(() => {
    if (currentSlideIndex !== totalSlides - 1) {
      nav({ ...qs, slide: currentSlideIndex + 1 })
    }
  }, [currentSlideIndex, nav, qs, totalSlides])
  const onClose = useMemo(
    () => (currentSlideIndex !== totalSlides ? handleFinish : handleEarlyExit),
    [currentSlideIndex, handleEarlyExit, handleFinish, totalSlides],
  )

  useArrowNavForHelpPane({
    onLeftArrowPress,
    onRightArrowPress,
    onClose,
    isButtonVisible: true,
  })

  return (
    <ModalDialog
      modalDialogRef={containerRef}
      dataTestId={`${tutorialType}-tutorial-dialog`}
      isOpen={!!tutorialType}
      onCloseClick={dismiss}
      dismiss={dismiss}
      sx={{}}
      shouldVibrateOnPresent={isHapticsEnabled}
      title={title}
      onHeaderButtonClick={handleFinish}
    >
      <Flex
        sx={{
          height: `calc(100% - ${modalDialogHeaderHeight})`,
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
          justifyContent: 'space-between',
          padding: '4rem',
        }}
        className="show-scrollbar"
      >
        <>
          {children}
          <Footer>{footerContent}</Footer>
        </>
      </Flex>
    </ModalDialog>
  )
}

export default TutorialModalDialog

interface FooterProps {
  children?: ReactNode
}

export function Footer({ children }: FooterProps): ReactElement {
  return (
    <Flex
      sx={{
        position: 'sticky',
        bottom: 0,
        width: '100%',
        flexDirection: 'column',
        zIndex: 'zIndex50',
      }}
    >
      <Flex
        data-testid="tutorial-footer"
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          px: '1.6rem',
          py: '0.8rem',
          '& span': {
            textDecoration: 'underline',
          },
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}
