import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  Page,
  removeChatEndsAt,
  resetMessageState,
  setChatBubbleBeenClosedOn,
  setIsBarryOpen,
  switchPage,
} from 'store/barry/barry'
import { useLocation } from '@reach/router'
import BarryIcon from 'components/Barry/BarryIcon'
import { useGetHelloBarryMessageQuery } from 'store/api/support/support'
import { differenceInMonths } from 'date-fns'
import BarryWindow from 'components/Barry/BarryWindow'
import useQueryString from 'utils/useQueryString'
import { useEndConversationMutation } from 'store/api/barry'
import omit from 'lodash/omit'

enum BarryQueryStringActions {
  WAKEUP = 'wakeup',
  START = 'start',
}

export default function BarryDialog(): ReactElement {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const pathname = location.pathname
  const isDashboard = location.pathname.includes('dashboard')
  const page = useAppSelector(x => x.barry.pageNumber)
  const isManuallyLoggedOut = useAppSelector(s => s.session.manuallyLoggedOut)
  const { isBarryOpen, chatBubbleBeenClosedOn } = useAppSelector(x => x.barry.userExperience)
  const [showBarryHelloMessage, setShowBarryHelloMessage] = useState(false)
  const { chatEndsAt, messages, conversationId } = useAppSelector(x => x.barry.message)
  const { qs, nav } = useQueryString()
  const [endConversationRequest] = useEndConversationMutation()
  const shouldFetchBubbleMessage = chatBubbleBeenClosedOn
    ? differenceInMonths(new Date(), new Date(chatBubbleBeenClosedOn)) >= 1
    : true
  const {
    data: barryHelloMessage,
    isLoading: isBarryHelloMessageLoading,
    isSuccess: isBarryHelloMessageSuccess,
  } = useGetHelloBarryMessageQuery({ on_page: pathname }, { skip: !shouldFetchBubbleMessage })

  const shouldShowBarry = !(isDashboard && !isBarryOpen)

  const shouldShowBarryPopUp =
    !!shouldShowBarry &&
    showBarryHelloMessage &&
    !!barryHelloMessage &&
    !isBarryHelloMessageLoading &&
    !isBarryOpen &&
    !chatBubbleBeenClosedOn

  const handleBarryIconToggle = useCallback(() => {
    const currentTime = Date.now()

    if (!isBarryOpen && (shouldShowBarryPopUp || showBarryHelloMessage)) {
      dispatch(setChatBubbleBeenClosedOn(currentTime))
    }

    if (qs.barry) {
      nav({ ...omit(qs, 'barry') })
    }

    setShowBarryHelloMessage(false)
    dispatch(setIsBarryOpen(!isBarryOpen))
  }, [dispatch, isBarryOpen, shouldShowBarryPopUp, showBarryHelloMessage, qs, nav])

  useEffect(() => {
    const shouldStartBarry = qs.barry === BarryQueryStringActions.START
    const shouldWakeupBarry = qs.barry === BarryQueryStringActions.WAKEUP

    if (shouldStartBarry || shouldWakeupBarry) {
      if (shouldStartBarry) {
        if (messages.length > 1) {
          dispatch(resetMessageState())
          endConversationRequest({ conversationId })
          dispatch(switchPage(Page.DESCRIBE_ISSUE_PAGE))
        } else if (!messages.length) {
          dispatch(switchPage(Page.DESCRIBE_ISSUE_PAGE))
        }
      }
      dispatch(setIsBarryOpen(true))
    }
  }, [pathname, conversationId, dispatch, endConversationRequest, messages.length, page, qs.barry])

  useEffect(() => {
    const exitConvoIfExpired = () => {
      const currentTime = new Date().getTime()
      if (chatEndsAt && currentTime > chatEndsAt) {
        if (messages.length !== 0) {
          dispatch(resetMessageState())
          endConversationRequest({ conversationId })
        }
        dispatch(removeChatEndsAt())
        dispatch(switchPage(Page.SPLASH_PAGE))
      }
    }

    const checkIfTabIsVisible = () => {
      if (document.visibilityState === 'visible' && page !== Page.SPLASH_PAGE) {
        exitConvoIfExpired()
      }
    }

    document.addEventListener('visibilitychange', checkIfTabIsVisible)

    return () => {
      document.removeEventListener('visibilitychange', checkIfTabIsVisible)
    }
  }, [chatEndsAt, dispatch, endConversationRequest, conversationId, page, messages])

  useEffect(() => {
    if (isManuallyLoggedOut) {
      dispatch(resetMessageState())
      dispatch(switchPage(Page.SPLASH_PAGE))
    }
  }, [isManuallyLoggedOut, dispatch])

  return (
    <>
      <BarryIcon
        handleBarryIconToggle={handleBarryIconToggle}
        shouldShowBarryPopUp={shouldShowBarryPopUp}
        shouldShowBarry={shouldShowBarry}
        setShowBarryHelloMessage={setShowBarryHelloMessage}
        showBarryHelloMessage={showBarryHelloMessage}
        barryHelloMessage={barryHelloMessage}
        isBarryHelloMessageSuccess={isBarryHelloMessageSuccess}
      />
      <BarryWindow handleBarryIconToggle={handleBarryIconToggle} />
    </>
  )
}
