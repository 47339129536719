export const downloadFile = ({
  content,
  fileTitle,
  fileType,
}: {
  content: object | string
  fileTitle: string
  fileType: string
}): void => {
  const a = document.createElement('a')
  const file = new Blob(
    [typeof content === 'string' ? content : JSON.stringify(content, null, 2)],
    {
      type: fileType,
    },
  )
  a.href = URL.createObjectURL(file)
  a.download = fileTitle
  a.click()
}
