import punycode from 'punycode/punycode'

export function toUnicode(input: string): string {
  try {
    return punycode.toUnicode(input)
  } catch (err) {
    return input
  }
}

export function toASCII(input: string): string {
  try {
    return punycode.toASCII(input)
  } catch (err) {
    return input
  }
}
