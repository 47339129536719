import { formatDistanceStrict } from 'date-fns'

export function getTimeSinceNow(ts?: number): string | undefined {
  if (!ts) {
    return undefined
  }

  const lastActivityTimestampMillis = ts * 1000

  return ts
    ? Date.now() - lastActivityTimestampMillis < 10000
      ? 'just now'
      : `${formatDistanceStrict(lastActivityTimestampMillis, Date.now())} ago`
    : undefined
}

export function getTimeSinceNowShortFormat(ts?: number): string | undefined {
  if (!ts) {
    return undefined
  }

  const lastActivityTimestampMillis = ts * 1000

  return ts
    ? Date.now() - lastActivityTimestampMillis < 10000
      ? 'now'
      : `${getShortFormat(formatDistanceStrict(lastActivityTimestampMillis, Date.now()))}`
    : undefined
}

function getShortFormat(str) {
  return str
    .replace(' seconds', 's')
    .replace(' second', 's')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' months', 'mo')
    .replace(' month', 'mo')
    .replace(' day', 'd')
    .replace(' year', 'y')
}
