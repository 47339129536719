import platform from 'platform'
import { useEffect, useState } from 'react'

export enum OsFamily {
  WINDOWS = 'windows',
  MAC_OS_X = 'mac',
  IOS = 'ios',
  ANDROID = 'android',
  UNKNOWN = 'unknown',
}
export enum Browser {
  CHROME = 'chrome',
  FIREFOX = 'firefox',
  SAFARI = 'safari',
  BRAVE = 'brave',
  MSEDGE = 'edge',
  OTHER = 'other',
}

export function getMajorVersion(os: { version: string; family: OsFamily }): number {
  const versionComponents = os.version?.split('.') ?? []
  return parseInt(versionComponents[0])
}

export function isBigSurMacOSVersion(): boolean {
  const { os } = platform
  const majorOSVersion = getMajorVersion(os)
  const majorBrowserVersion = getMajorVersion(platform)
  const MAC_OS_VERSION = '10.16'
  const EXCEPTIONAL_MAC_OS_VERSION = '10.15'

  /**
   * These browser versions incorrectly define MacOS Big Sur(11) version.
   * Firefox version 86 and less, detect MacOS Big Sur like 10.16 OS version.
   * But in Firefox 87, 88(beta) it detects like 10.15. The same detecting has MacOS Catalina.
   */
  const exceptionalFirefoxVersions = [87, 88]

  if (platform.name === 'Safari') {
    /**
     * Safari 14 Dev Preview shows the userAgent string as "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_16)".
     * It should say MacOS 11. More info: https://bugs.webkit.org/show_bug.cgi?id=213622.
     * The code below helps to fix this issue based on Safari version.
     */
    return (
      (majorBrowserVersion === 14 || majorBrowserVersion === 15) &&
      os?.version?.includes(EXCEPTIONAL_MAC_OS_VERSION)
    )
  }

  /**
   * This fix helps to correctly detect MacOS Big Sur(11) version for specific Firefox versions.
   * But if the user is using MacOS Catalina and installed exceptional Firefox versions, it detects like Big Sur.
   */
  if (platform.name === 'Firefox' && exceptionalFirefoxVersions.includes(majorBrowserVersion)) {
    return os?.version?.includes(EXCEPTIONAL_MAC_OS_VERSION)
  }

  if (platform.name === 'Chrome' && majorBrowserVersion >= 90) {
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1175225
    // As of Chrome 90 (I think? This was based on the discussion in the above issue),
    // Macs on Big Sur will report as 10.15.7 (the last update to Catalina) instead
    // of the actual version. Macs on the latest Chrome but running Catalina will
    // possibly be falsely detected as Big Sur.
    return os?.version === '10.15.7'
  }

  // Older browser versions define Big Sur like 10.16, newer browsers like 11+.
  return majorOSVersion >= 11 || os?.version?.includes(MAC_OS_VERSION)
}

export function getOsFamily(): OsFamily {
  const os: { family: string } = platform.os

  if (!os) {
    return OsFamily.UNKNOWN
  }

  if (os.family.startsWith('Windows')) {
    return OsFamily.WINDOWS
  }

  if (os.family === 'OS X') {
    // HACK: since iOS 13, iPads report themselves as Intel Macs running OS X, so this
    // appears to be the best way to distinguish between the two, although it might
    // not be safe forever (for example if a touchscreen Mac were released)
    //
    // more info: https://developer.apple.com/forums/thread/119186)
    if (window.navigator.maxTouchPoints > 0) {
      return OsFamily.IOS
    } else {
      return OsFamily.MAC_OS_X
    }
  }

  if (os.family === 'iOS') {
    return OsFamily.IOS
  }

  if (os.family === 'Android') {
    return OsFamily.ANDROID
  }

  return OsFamily.UNKNOWN
}

export function getBrowser(): Browser {
  const { name, ua } = platform

  if (name === 'Chrome') {
    // Since Brave does not have a unique UA, this prevents users on Brave from seeing 'Chrome' highlighted in the setup guide

    if (navigator['brave']) {
      return Browser.OTHER
    }
    if (ua.includes('Edg')) {
      return Browser.MSEDGE
    } else {
      return Browser.CHROME
    }
  } else if (name === 'Microsoft Edge') {
    return Browser.MSEDGE
  } else if (name === 'Firefox') {
    return Browser.FIREFOX
  } else if (name === 'Safari') {
    return Browser.SAFARI
  } else if (name === 'Brave') {
    return Browser.BRAVE
  } else {
    return Browser.OTHER
  }
}

export function supportsDnsSetupViaProfile(): boolean {
  const { os } = platform
  const majorVersion = getMajorVersion(os)

  return (
    ((getOsFamily() === OsFamily.MAC_OS_X && majorVersion >= 11) ||
      (getOsFamily() === OsFamily.IOS && majorVersion >= 10)) &&
    getBrowser() === Browser.SAFARI
  )
}
/**
 * This hook is used to get the os family from the platform and cause a rerender.
 * This is needed for mobile devices where the initial platform load does not
 * update the selected OS and in turn the svg which represents the OS.
 * If we use the getOsFamily method directly, in some cases, the initial render
 * happens before the getOsFamily call gets completed and then the value it returned
 * does not update the svg. Refer to #571 & #580.
 */
export function useOsFamily(): { initialOs: OsFamily } {
  const [initialOs, setInitialOs] = useState(OsFamily.UNKNOWN)
  const osFamily = getOsFamily()
  useEffect(() => {
    if (osFamily !== OsFamily.UNKNOWN) {
      setInitialOs(osFamily)
    }
  }, [osFamily])

  return { initialOs }
}

export function useBrowser(): Browser {
  const [browser, setBrowser] = useState(Browser.OTHER)
  useEffect(() => {
    setBrowser(getBrowser())
  }, [])

  return browser
}
