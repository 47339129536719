import React, { ReactElement } from 'react'
import { setIsBarryOpen } from 'store/barry/barry'
import { useAppDispatch } from 'store/hooks'
import { ThemeUIStyleObject } from 'theme-ui'
import { ButtonVariant } from 'ui'
import ExternalLink from 'ui/ExternalLink/ExternalLink.base'
import { SmoothAnchorLink } from 'ui/SmoothAnchorLink'

export function InternalOrExternalLink({
  to,
  href = '',
  children,
  dismiss,
  sx,
  tabIndex,
  buttonVariant,
}: {
  to?: string
  href?: string
  sx?: ThemeUIStyleObject
  tabIndex?: number
  children: ReactElement
  dismiss?: () => void
  buttonVariant?: ButtonVariant
}): ReactElement {
  const dispatch = useAppDispatch()

  return to ? (
    <ExternalLink
      to={to}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        if (to === 'barry') {
          event?.preventDefault()
          dispatch(setIsBarryOpen(true))
        } else if (dismiss) {
          dismiss()
        }
      }}
      {...{ sx, tabIndex }}
    >
      {children}
    </ExternalLink>
  ) : (
    <SmoothAnchorLink
      ariaLabel={`scroll to ${href}`}
      testId={href}
      to={href}
      onClick={dismiss}
      sx={{ width: '100%', textDecoration: 'none', ...sx }}
      tabIndex={tabIndex}
      buttonVariant={buttonVariant}
    >
      {children}
    </SmoothAnchorLink>
  )
}
