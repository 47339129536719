import { useCallback } from 'react'
import useCopyToClipboard from 'utils/copyToClipboard'
import CheckIcon from 'images/freeDNS/check.svg'
import { useAlertPresenter } from 'ui'
import { useGetDevicesQuery } from 'store/api/devices'
import { useAppSelector } from 'store/hooks'

// this hook is used to copy the setup link of a device to the clipboard from the Device Menu & from the Setup guide ShowDNS page
export default function useCopySetupLink(selectedDevicePk?: string): {
  onCopySetupLinkClick: () => void
} {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  const selectedDevice = devicesData?.devices.find(device => device.PK === selectedDevicePk)
  const { copyToClipboard, resetCopiedState } = useCopyToClipboard(
    `${location.origin}/provision?resolverUid=${selectedDevice?.resolvers?.uid}`,
  )

  const { presentAlert, dismissAlert } = useAlertPresenter()
  const onCopySetupLinkClick = useCallback(() => {
    copyToClipboard()
    presentAlert(
      {
        icon: CheckIcon,
        message: 'Setup link has been generated and copied to your clipboard',
        buttons: [
          {
            onClick: (): void => {
              dismissAlert('setup-link')
              resetCopiedState()
            },
            text: 'Okay',
          },
        ],
      },
      'setup-link',
    )
  }, [copyToClipboard, dismissAlert, presentAlert, resetCopiedState])

  return { onCopySetupLinkClick }
}
