import React, { ReactElement } from 'react'
import noop from 'lodash/noop'
import { ThemeUIStyleObject } from 'theme-ui'
import { Button, ExternalLink } from 'ui'

export default function ExternalLinkOrButton({
  isButton,
  commonSx,
  onClick = noop,
  dataTestId,
  href = '',
  children,
  sxButton,
}: {
  isButton: boolean
  commonSx: ThemeUIStyleObject
  onClick?: () => void
  dataTestId?: string
  href?: string
  children: ReactElement | ReactElement[]
  sxButton?: ThemeUIStyleObject
}): ReactElement {
  if (isButton) {
    return (
      <Button
        ariaLabel={dataTestId || ''}
        variant="transparent"
        data-testid={dataTestId}
        sx={{
          width: isButton ? '22rem' : 'none',
          backgroundColor: 'transparent',
          height: '3.8rem',
          borderRadius: '20px',
          border: '1px solid',
          borderColor: 'blueYonder30',
          ...sxButton,
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    )
  }

  return (
    <ExternalLink
      data-testid={dataTestId}
      to={href}
      sx={{
        display: 'flex',
        ...commonSx,
        ':hover': {
          color: 'white',
          backgroundColor: 'secondaryHoverBG',
          transform: 'scale(1.025)',
        },
      }}
    >
      {children}
    </ExternalLink>
  )
}
