import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionTrigger, ActivityLog, HistoricalLogsMetaData } from 'store/api/activityLog'
import { sessionLogout } from 'store/session'
import omit from 'lodash/omit'
import uniqueId from 'lodash/uniqueId'
import { PresetTimeRange } from 'ui/Analytics/PresetTimeRange'
import { initialResponseState } from '../fetchingLogic'
import { ACTIVITY_LOG_SESSION_TIMEOUT } from 'gatsby-env-variables'
import { RootState } from 'store/rootReducer'
import getDateByPresetTimeRange from 'utils/getDateByPresetTimeRange'
import { clearStateForImpersonation } from 'store/organization'
import { activityLogApi } from 'store/api/activityLog/activityLog'
import { devicesApi } from 'store/api/devices/devices'
import { toASCII } from 'utils/punycode'
import { QueriesFilterMenuType } from 'components/Dashboard/Analytics/ActivityLog/FiltersContainer'

export const getQueryStringFromFilters = (
  queryFilters: QueryFilters,
  presetTimeRange: PresetTimeRange,
): string => {
  const partialQueryFilters = omit(queryFilters, ['filters', 'services', 'locations'])

  if (partialQueryFilters.search) {
    partialQueryFilters.search = toASCII(partialQueryFilters.search)
  }

  if (presetTimeRange !== PresetTimeRange.CUSTOM) {
    partialQueryFilters.endTs = new Date().getTime()
  }

  partialQueryFilters.startTs = getDateByPresetTimeRange(presetTimeRange)?.getTime()

  const queryFiltersArray = Object.entries(partialQueryFilters)

  let queryString = queryFiltersArray
    .map(([key, value]) => {
      if (value && value !== 'all') {
        return `${key}=${value}`
      }
      return ''
    })
    .filter(queryString => queryString !== '')
    .join('&')
  if (queryFilters.filters) {
    queryString = `${queryString}&actionTrigger=${ActionTrigger.FILTER}&actionTriggerValue=${queryFilters.filters}`
  } else if (queryFilters.services) {
    queryString = `${queryString}&actionTrigger=${ActionTrigger.SERVICE}&actionTriggerValue=${queryFilters.services}`
  }

  if (queryFilters.locations) {
    queryString = `${queryString}&actionSpoofTarget=${queryFilters.locations}`
  }
  return queryString
}

export const startSessionTimer = createAsyncThunk(
  'activityLog/startSessionTimer',
  async (_, { dispatch, getState }) => {
    await new Promise(resolve => setTimeout(resolve, ACTIVITY_LOG_SESSION_TIMEOUT)) //30 minutes timeout
    const state = getState() as RootState
    const { region = '' } = state.session
    const presetTimeRange = state.activityLog.selectedPresetTimeRange

    const queryFilters = (getState() as RootState)?.activityLog.queryFilters
    dispatch(endActivityLogSession())
    dispatch(
      activityLogApi.endpoints.getHistoricalLogs.initiate({
        region,
        queryString: getQueryStringFromFilters(queryFilters, presetTimeRange),
      }),
    )
  },
)

export type QueryFilters = {
  [key in QueriesFilterMenuType]?: string
} & {
  deviceId?: string
  clientId?: string
  search?: string
  endTs: number
  startTs: number
  page?: number
  pageSize?: number
}

type ActivityLogState = {
  isEventSourceOpen: boolean
  meta?: HistoricalLogsMetaData
  logs: ActivityLog[]
  queryFilters: QueryFilters
  selectedPresetTimeRange: PresetTimeRange
  resetEventSource?: () => void //need to store the event source so that we can close it when the user stops realtime logging
}
const activityLogState: ActivityLogState = {
  isEventSourceOpen: false,
  logs: [],
  queryFilters: {
    [QueriesFilterMenuType.ENDPOINT]: '',
    [QueriesFilterMenuType.CLIENT]: '',
    [QueriesFilterMenuType.RTYPE]: '',
    [QueriesFilterMenuType.RCODE]: '',
    [QueriesFilterMenuType.FILTERS]: '',
    [QueriesFilterMenuType.SERVICES]: '',
    [QueriesFilterMenuType.LOCATIONS]: '',
    [QueriesFilterMenuType.ACTION]: 'all',
    [QueriesFilterMenuType.PROTOCOL]: 'all',
    endTs: Date.now(),
    startTs: Date.now() - 1000 * 60 * 60 * 24,
  },
  selectedPresetTimeRange: PresetTimeRange.LAST_DAY,
}

const initialState = {
  ...activityLogState,
  ...initialResponseState,
}
export const activityLogSlice = createSlice({
  name: 'activityLog',
  initialState: {
    ...initialState,
    ...initialResponseState,
  },
  reducers: {
    addQuery: (state, action) => {
      state.logs.unshift({ id: uniqueId(), ...action.payload })
    },
    startActivityLogSession: state => {
      state.meta = undefined
      state.logs = []
      state.isEventSourceOpen = true
    },
    endActivityLogSession: state => {
      state.isEventSourceOpen = false
      state.resetEventSource?.()
      state.resetEventSource = undefined
      state.logs = []
    },
    deleteLogs: state => {
      state.logs = []
    },
    setQueryFilters(state, { payload }: PayloadAction<Partial<QueryFilters>>): void {
      state.queryFilters = { ...state.queryFilters, ...payload, page: payload.page || 0 }
    },
    resetFilterParameters(state): void {
      // Preserve deviceId and time range when clearing filters
      const { endTs, startTs } = state.queryFilters
      state.queryFilters = { ...initialState.queryFilters, endTs, startTs }
    },
    updateEventSourceHandler(state, { payload }: PayloadAction<() => void | undefined>): void {
      state.resetEventSource = payload
    },
    setSelectedPresetTimeRange(state, { payload }: PayloadAction<PresetTimeRange>): void {
      state.selectedPresetTimeRange = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, () => {
        // clear data when logging out
        return initialState
      })
      .addCase(clearStateForImpersonation.fulfilled, state => {
        state.queryFilters.deviceId = undefined
        state.queryFilters.clientId = undefined
      })
      .addMatcher(devicesApi.endpoints.deleteDeviceClient.matchFulfilled, (state, action) => {
        if (state.queryFilters.clientId === action.meta.arg.originalArgs.clientId) {
          state.queryFilters.clientId = undefined
        }
      })
      .addMatcher(activityLogApi.endpoints.getHistoricalLogs.matchFulfilled, (state, action) => {
        state.logs = action.payload.queries.map(query => ({ ...query, id: uniqueId() }))
        state.meta = action.payload.meta
      })
  },
})

export const {
  addQuery,
  deleteLogs,
  startActivityLogSession,
  endActivityLogSession,
  setQueryFilters,
  resetFilterParameters,
  setSelectedPresetTimeRange,
  updateEventSourceHandler,
} = activityLogSlice.actions
