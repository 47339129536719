import flatMap from 'lodash/flatMap'
import range from 'lodash/range'
import { WeekdayListItem } from 'store/api/schedules/schedules'

interface TimeDropdownItemData {
  text: string
  time: string
}

export const convertToParsed12HrClock = (hours: number, minutes: string): string => {
  const suffix = hours >= 12 ? 'pm' : 'am'
  const correctedHour = ((hours + 11) % 12) + 1
  const formattedHour = `${correctedHour.toString().padStart(2, '0')}:${minutes}${suffix}`
  return formattedHour
}

export const listOfDays: Array<WeekdayListItem> = [
  { name: 'Monday', shortName: 'mon' },
  { name: 'Tuesday', shortName: 'tue' },
  { name: 'Wednesday', shortName: 'wed' },
  { name: 'Thursday', shortName: 'thu' },
  { name: 'Friday', shortName: 'fri' },
  { name: 'Saturday', shortName: 'sat' },
  { name: 'Sunday', shortName: 'sun' },
]

// for each hour of the day we should return four times: :00, :15, :30, :45

export const listOfTimes: Array<TimeDropdownItemData> = flatMap(range(0, 24), hour => [
  {
    text: convertToParsed12HrClock(hour, '00'),
    time: `${hour.toString().padStart(2, '0')}:00`,
  },
  {
    text: convertToParsed12HrClock(hour, '15'),
    time: `${hour.toString().padStart(2, '0')}:15`,
  },
  {
    text: convertToParsed12HrClock(hour, '30'),
    time: `${hour.toString().padStart(2, '0')}:30`,
  },
  {
    text: convertToParsed12HrClock(hour, '45'),
    time: `${hour.toString().padStart(2, '0')}:45`,
  },
])

export const getSelectedDropdownTime = (time: string | undefined): TimeDropdownItemData => {
  return listOfTimes.filter(t => t.time === time)[0] || listOfTimes[0]
}
