import React, { ReactElement, forwardRef } from 'react'
import { Input, InputProps } from 'ui'

function hostnameMask(value: string): string {
  return value.replace(/\s/g, '')
}

interface RuleInputProps extends InputProps {
  isUrl?: boolean
  value: string
}
const RuleInput = forwardRef<HTMLInputElement, RuleInputProps>(function RuleInput(
  { isUrl = false, value, ...props }: RuleInputProps,
  ref,
): ReactElement {
  return (
    <Input
      isCompact
      ref={ref}
      variant="newPrimary"
      value={isUrl ? hostnameMask(value) : value}
      autoCapitalize={isUrl ? 'none' : ''}
      containerStyle={{ flexBasis: ['auto', 0] }}
      {...props}
    />
  )
})

export default RuleInput
