import React, { ReactElement } from 'react'
import { Text, Flex, Alert as ThemeUiAlert } from 'theme-ui'
import { AlertInfo } from 'ui/AlertPresenter/AlertPresenter.interface'
import { Svg, Button } from 'ui'
import useGetColorMode from 'utils/useGetColorMode'

export default function Alert({
  message,
  icon,
  buttons,
  variant = 'primary',
}: AlertInfo): ReactElement {
  const { isLightMode } = useGetColorMode()

  if (variant !== 'primary' && (icon || buttons)) {
    console.warn('`icon` and `buttons` props only supported in `Alert`s with `variant`="primary"')
  }

  return (
    <>
      {variant === 'primary' ? (
        <ThemeUiAlert
          variant={variant}
          sx={{
            rowGap: '1.2rem',
            columnGap: '2.4rem',
            boxShadow: isLightMode ? 'none' : '0px 5px 45px -5px rgba(0, 0, 0, 0.45)',
            background: ({ colors }) =>
              isLightMode
                ? `linear-gradient(0deg, rgba(235, 236, 243, 0.00) 0%, rgba(187, 190, 210, 0.20) 100%), ${colors?.plumpPurple}`
                : `linear-gradient(0deg, rgba(1, 8, 24, 0.00) 0%, rgba(1, 8, 24, 0.50) 100%), ${colors?.plumpPurple}`,
          }}
        >
          <Flex
            sx={{
              flexDirection: 'row',
              flex: 'auto',
              alignItems: 'center',
              minHeight: 'initial',
              gap: '1.2rem',
            }}
          >
            {icon && (
              <Svg
                data-testid="alert-icon"
                svg={icon}
                fill="commonWhite"
                sx={{
                  width: '1.6rem',
                  height: '1.6rem',
                  flexShrink: 0,
                }}
              />
            )}
            <Text
              variant="size14Weight400Line138Letter056"
              data-testid="alert-message"
              sx={{ m: 0, fontFamily: 'body', wordWrap: 'break-word', color: 'commonWhite' }}
            >
              {message}
            </Text>
          </Flex>
          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: 'auto',
              height: '100%',
              gap: '2.4rem',
            }}
          >
            {buttons?.map((button, index) => (
              <Button
                variant="simple"
                data-testid="alert-button"
                ariaLabel={`${button.text} button`}
                sx={{
                  p: 0,
                  border: 'none',
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  color: 'commonWhite',
                  '@media (hover: hover)': {
                    '&:hover': {
                      ':not([disabled])': {
                        color: 'commonWhite',
                        span: {
                          color: 'commonWhite',
                        },
                      },
                    },
                  },
                }}
                key={index}
                onClick={button.onClick}
                onKeyDown={button.onKeyDown}
              >
                {button.text}
              </Button>
            ))}
          </Flex>
        </ThemeUiAlert>
      ) : (
        <ThemeUiAlert
          variant={variant}
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          {icon && (
            <Svg
              data-testid="alert-icon"
              svg={icon}
              sx={{
                width: '1.6rem',
                height: '1.6rem',
                flexShrink: 0,
                mr: '1.2rem',
              }}
            />
          )}
          <Text data-testid="alert-message" sx={{ fontWeight: 'normal', fontFamily: 'body' }}>
            {message}
          </Text>
        </ThemeUiAlert>
      )}
    </>
  )
}
