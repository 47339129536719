import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialResponseState, ResponseState } from 'store/fetchingLogic'
import { groupApi, GroupData, GroupsResponseData } from 'store/api/groups'
import { ruleApi } from 'store/api/rules/rules'

interface GroupInitialState extends ResponseState<GroupsResponseData> {
  groupForDeletion?: number
  currentGroup?: GroupData
  filteredGroups?: GroupData[]
}
const initialGroupsState: GroupInitialState = {
  ...initialResponseState,
}
export const groupsSlice = createSlice({
  name: 'groups',
  initialState: { ...initialGroupsState },
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    setGroupForDeletion(state, action: PayloadAction<number | undefined>): void {
      state.groupForDeletion = action.payload
    },
    setFilteredGroups(state, action: PayloadAction<GroupData[]>): void {
      state.filteredGroups = action.payload
    },
    setCurrentGroup(state, action: PayloadAction<GroupData | undefined>): void {
      state.currentGroup = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(groupApi.endpoints.deleteGroup.matchFulfilled, state => {
        state.groupForDeletion = undefined
      })
      .addMatcher(ruleApi.endpoints.getRules.matchFulfilled, state => {
        state.groupForDeletion = undefined
      })
      .addMatcher(ruleApi.endpoints.postRules.matchFulfilled, (state, action) => {
        if (
          state.currentGroup &&
          action.meta.arg.originalArgs.body.group === +state.currentGroup.PK
        ) {
          state.currentGroup.count =
            (state.currentGroup.count ?? 0) + action.meta.arg.originalArgs.body.hostnames.length
        }
      })
      .addMatcher(ruleApi.endpoints.deleteGroupRules.matchFulfilled, (state, action) => {
        if (state.currentGroup?.count) {
          state.currentGroup.count -= action.meta.arg.originalArgs.body.hostnames.length
        }
      })
      .addMatcher(ruleApi.endpoints.moveRulesToGroup.matchFulfilled, (state, action) => {
        if (state.currentGroup?.count) {
          state.currentGroup.count -= action.meta.arg.originalArgs.body.hostnames.length
        }
      })
  },
})

export const { setGroupForDeletion, setFilteredGroups, setCurrentGroup } = groupsSlice.actions
