import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { ApiResponse } from 'store/api/http'
import { Filter } from 'store/api/filters/filters.interface'

export type GetFiltersResponse = ApiResponse<{
  filters: Filter[]
  ip_filters: Filter[]
  counter_filters: Filter[]
  external_filters: Filter[]
}>

export type GetDomainsResponse = ApiResponse<{
  domains: string[]
}>

export const freeDnsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getFreeDNSFilters: builder.query({
      query: () => getQueryArgs('/filters'),
      transformResponse: (response: GetFiltersResponse) => response.body,
      transformErrorResponse,
      providesTags: () => ['FreeDns'],
    }),
    getUncensoredDomains: builder.query({
      query: () => getQueryArgs('/filters/uncensored'),
      transformResponse: (response: GetDomainsResponse) => response.body,
      transformErrorResponse,
      providesTags: () => ['FreeDns'],
    }),
  }),
})

export const { endpoints, useGetFreeDNSFiltersQuery, useGetUncensoredDomainsQuery } = freeDnsApi
