import React, { ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import { QueriesFilterMenuType } from '../FiltersContainer'
import { useGetDeviceClientsQuery, useGetDevicesQuery } from 'store/api/devices'

export default function ClientsDropdown(): ReactElement {
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const { data: devicesData } = useGetDevicesQuery('')

  const selectedEndpoint = devicesData?.devices?.find(
    device => filterParameters[QueriesFilterMenuType.ENDPOINT] === device.PK,
  )
  const { data: clientsData } = useGetDeviceClientsQuery(selectedEndpoint?.PK, {
    skip: !selectedEndpoint?.PK,
  })

  const selectedClient = clientsData?.clients?.find(
    client => filterParameters[QueriesFilterMenuType.CLIENT] === client.id,
  )

  return (
    <QueryFilterDropdown
      isDisabled={!selectedEndpoint?.PK || !clientsData?.clients?.length}
      selectedValue={selectedClient?.host || 'Clients'}
      type={QueriesFilterMenuType.CLIENT}
    >
      <QueryFilterDropdownContent
        type={QueriesFilterMenuType.CLIENT}
        clients={clientsData?.clients || []}
        maxHeight="29.5rem"
      />
    </QueryFilterDropdown>
  )
}
