import React, { ReactElement } from 'react'
import DateTimePicker, { DateTimePickerProps } from 'ui/DateTimePicker/DateTimePicker'
import moment from 'moment'

export interface DatePickerShortcut {
  date: Date
  includeTime: boolean
  label: string
}

export default function FutureDateTimePicker(props: DateTimePickerProps): ReactElement {
  const shortcuts: DatePickerShortcut[] = [
    {
      date: moment().add(1, 'hour').toDate(),
      includeTime: true,
      label: 'After 1 Hour',
    },
    {
      date: moment().add(1, 'day').toDate(),
      includeTime: true,
      label: 'After 1 Day',
    },
    {
      date: moment().add(1, 'week').toDate(),
      includeTime: true,
      label: 'After 1 Week',
    },
    {
      date: moment().add(1, 'month').toDate(),
      includeTime: true,
      label: 'After 1 Month',
    },
  ]

  return <DateTimePicker {...{ ...props, shortcuts }} />
}
