import React, { ReactElement, useMemo, useRef } from 'react'
import { SimpleActionMenuItemProps, SimpleDropdown, Svg } from 'ui'
import { listOfTimes } from './Schedule.helpers'
import { Flex } from 'theme-ui'
import CheckIcon from 'images/analytics/check.svg'
import { TimeDropdownItemData } from './Schedule.interface'
import { Setter } from 'utils'

export default function TimeDropdown({
  label,
  testId,
  selectedTime,
  setTime,
  isDisabled,
}: {
  label?: string
  testId?: string
  selectedTime?: TimeDropdownItemData
  setTime: Setter<TimeDropdownItemData>
  isDisabled?: boolean
}): ReactElement {
  const hideRef = useRef<() => void>()
  const options: SimpleActionMenuItemProps[] = useMemo(
    () =>
      listOfTimes.map(time => ({
        ariaLabel: `${time.text} option`,
        isSelected: time === selectedTime,
        isBackgroundHighlighted: true,
        buttonContent: time.text,
        searchBy: time.text,
        children: (
          <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Flex
              sx={{
                height: '100%',
                alignItems: 'center',
                fontSize: '1.6rem',
                fontWeight: 'bold',
              }}
            >
              {time.text}
            </Flex>
            {time === selectedTime && (
              <Svg svg={CheckIcon} fill="aliceBlue60" sx={{ flexShrink: 0 }} />
            )}
          </Flex>
        ),
        onClick: () => {
          setTime(time)
          hideRef.current?.()
        },
      })) || [],
    [selectedTime, setTime],
  )

  return (
    <SimpleDropdown
      testId={testId}
      ariaLabel="time dropdown"
      items={options}
      hideRef={hideRef}
      label={label}
      disabled={isDisabled}
      arrowFill={isDisabled ? 'aliceBlue10' : undefined}
    />
  )
}
