import React, { ReactElement } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export default function Column({
  children,
  sx,
  dataTestId,
}: {
  children: ReactElement | ReactElement[]
  sx?: ThemeUIStyleObject
  dataTestId?: string
}): ReactElement {
  return (
    <Flex data-testid={dataTestId} sx={{ flexDirection: 'column', ...sx }}>
      {children}
    </Flex>
  )
}
