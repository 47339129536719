import { useLocation } from '@reach/router'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { useEffect, useMemo } from 'react'
import useQueryString from 'utils/useQueryString'
import { resetAnalyticsMaintenanceMode } from 'store/errors'

export default function useMaintenanceMode(): void {
  const isInMaintenanceMode = useAppSelector(s => s.errors.isInMaintenanceMode)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { qs, relocate } = useQueryString()

  const redirectPath = useMemo((): string => {
    if (qs.redirectTo) {
      return `/maintenance-mode${
        qs.redirectTo.includes('http') ? '' : `?redirectTo=${qs.redirectTo}`
      }`
    }
    return `/maintenance-mode?redirectTo=${location.pathname + location.search}`
  }, [location.pathname, location.search, qs.redirectTo])

  useEffect(() => {
    dispatch(resetAnalyticsMaintenanceMode())
  }, [dispatch, location])

  useEffect(() => {
    if (isInMaintenanceMode) {
      relocate(redirectPath)
    }
  }, [isInMaintenanceMode, location.pathname, location.search, redirectPath, relocate])
}
