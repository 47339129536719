import { ReactNode, ElementType, MutableRefObject, RefObject } from 'react'
import { Setter } from 'utils'
import AlertVariants from 'ui/Theme/customComponents/alerts'

export enum AlertState {
  PRESENTING = 'PRESENTING',
  PRESENTED = 'PRESENTED',
  DISMISSING = 'DISMISSING',
}

export interface AlertButton {
  text: string
  onClick: () => void
  onKeyDown?: () => void
}

export type AlertVariant = keyof typeof AlertVariants
export interface AlertInfo {
  message: string | ReactNode

  // timeout in milliseconds (ignored when `isSticky` is true) - defaults to 5000
  timeout?: number

  // if true, the alert will persist until `dismissAlert` is explicitly called
  isSticky?: boolean

  // an array of simple text buttons, ordered left-to-right at the right side of the alert
  buttons?: AlertButton[]

  icon?: ElementType

  shouldDismissOnClickOutside?: boolean

  // defaults to "primary"
  // NOTE: `buttons` and `icon` are ignored if `variant` is not "primary"
  variant?: AlertVariant
}

export interface AlertPresenterItemInfo {
  alert: AlertInfo
  state: AlertState
  id: AlertId

  // used to get height from DOM element for manual height calculations inside alert presenter
  ref: RefObject<HTMLDivElement>
}

export type AlertId = string

export type PresentAlert = (alert: AlertInfo, id?: AlertId) => AlertId

export interface AlertPresenterClient {
  /**
   * Presents an alert with the given info. If `id` is specified, the presented alert will have
   * this value as its ID. This can be used to implement deduplication, for example in the case
   * of the upgrade alert in Control D.
   */
  presentAlert: PresentAlert
  dismissAlert(id: AlertId): void
  dismissAllAlerts(): void
}

export interface AlertPresenterState {
  alerts: AlertPresenterItemInfo[]
  setAlerts: Setter<AlertPresenterItemInfo[]>
  cumulativeHeightRef: MutableRefObject<number>
}

export interface AlertPresenterProps {
  containerRef: RefObject<HTMLElement>
  position?: 'absolute' | 'fixed'
}

export interface AlertContextProviderProps {
  children?: ReactNode
}
