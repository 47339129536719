import React, { ReactElement } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { TimeDropdownItemData } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/Scheduling/Schedule.interface'
import { Setter } from 'utils'
import { listOfTimes } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/Scheduling/Schedule.helpers'
import TimeDropdown from './TimeDropdown'

export const defaultScheduleStartTimeIndex = 36
export const defaultScheduleEndTimeIndex = 68

export default function TimeRangeSelect({
  startTime = listOfTimes[defaultScheduleStartTimeIndex],
  endTime = listOfTimes[defaultScheduleEndTimeIndex],
  setStartTime,
  setEndTime,
  sx,
  isAllDaySelected = false,
}: {
  startTime?: TimeDropdownItemData
  endTime?: TimeDropdownItemData
  setStartTime: Setter<TimeDropdownItemData>
  setEndTime: Setter<TimeDropdownItemData>
  sx?: ThemeUIStyleObject
  isAllDaySelected?: boolean
}): ReactElement {
  return (
    <Flex
      sx={{
        mt: '1.2rem',
        gap: '1.2rem',
        ...sx,
        '& > *': {
          flex: 1,
          flexDirection: 'column',
        },
      }}
    >
      <TimeDropdown
        label="Start time"
        testId="select-start-time"
        setTime={setStartTime}
        selectedTime={startTime || listOfTimes[defaultScheduleStartTimeIndex]}
        isDisabled={isAllDaySelected}
      />
      <TimeDropdown
        label="End time"
        testId="select-end-time"
        setTime={setEndTime}
        selectedTime={endTime || listOfTimes[defaultScheduleEndTimeIndex]}
        isDisabled={isAllDaySelected}
      />
    </Flex>
  )
}
