import React, { PropsWithChildren, ReactElement } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export default function PreferencesWrapper({
  children,
  sx,
}: PropsWithChildren<{ sx?: ThemeUIStyleObject }>): ReactElement {
  return (
    <Flex
      sx={{
        flexWrap: 'unset',
        flexDirection: 'column',
        flexGrow: 1,
        gap: [0, '1.2rem'],
        pl: 0,
        pb: '2.4rem',
        ...sx,
      }}
    >
      {children}
    </Flex>
  )
}
