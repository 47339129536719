import React, { ReactElement, useEffect } from 'react'
import { Flex, Text } from 'theme-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import NotificationIcon from 'images/profileManagement/notification-icon-white.svg'
import { setIsNotificationsDialogOpen } from 'store/dashboard/dashboard'
import ActionIconButton from 'ui/ActionIconButton'

export default function NavBarBottomContent({ isFixed }: { isFixed?: boolean }): ReactElement {
  const dispatch = useAppDispatch()
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const isTablet = useBreakpointIndex() === 1
  const newNotificationKeys = useAppSelector(s => s.notifications.newNotificationKeys)

  useEffect(() => {
    if (newNotificationKeys?.length) {
      dispatch(setIsNotificationsDialogOpen(true))
    }
  }, [dispatch, newNotificationKeys?.length])

  return (
    <>
      <Flex
        sx={{
          position: !isNavBarExpanded || isFixed ? 'fixed' : 'static',
          bottom: '1.6rem',
          flexDirection: ['row', 'column', isNavBarExpanded ? 'row' : 'column'],
          gap: ['1.6rem', 0, '1.6rem'],
          zIndex: 'zIndex300',
        }}
      >
        {isNavBarExpanded && !isTablet && (
          <NotificationButton showDialog={() => dispatch(setIsNotificationsDialogOpen(true))} />
        )}
      </Flex>
    </>
  )
}

function NotificationButton({ showDialog }: { showDialog?: () => void }) {
  const newNotificationKeys = useAppSelector(s => s.notifications.newNotificationKeys)

  return (
    <Flex sx={{ position: 'relative' }}>
      <ActionIconButton
        ariaLabel="notification button"
        dataTestId="notification-button"
        tooltipText="Notifications"
        svgIcon={NotificationIcon}
        onClick={() => showDialog?.()}
        sx={{
          width: ['3.2rem', '2.4rem'],
          height: ['3.2rem', '2.4rem'],
        }}
      />
      {newNotificationKeys && newNotificationKeys.length > 0 && (
        <Flex
          data-testid="notifications-count"
          sx={{
            position: 'absolute',
            top: ['-0.8rem', '-0.5rem'],
            left: ['2rem', '2.8rem'],
            width: '2rem',
            height: '2rem',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'greenApple15',
            borderRadius: '2.6rem',
          }}
        >
          <Text
            variant="size14Weight600"
            sx={{
              color: 'greenApple',
            }}
          >
            {newNotificationKeys.length > 99 ? '99+' : newNotificationKeys.length}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
