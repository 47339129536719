import { useAppSelector } from '../store/hooks'
import { Flex, Text } from '@theme-ui/components'
import { Link } from 'gatsby'
import React, { ReactElement } from 'react'

export default function SelectRegionWarningMessage(): ReactElement {
  const isOrganization = useAppSelector(s => s.session.isOrganization)

  return (
    <Flex
      data-testid="select-region-warning"
      sx={{
        p: '1.2rem',
        justifyContent: 'center',
        textAlign: 'center',
        background:
          'linear-gradient(180deg, rgba(235, 236, 243, 0.00) 0%, rgba(235, 236, 243, 0.30) 100%), #E93349',
      }}
    >
      <Text variant="size12Weight700" sx={{ color: 'white' }}>
        Please choose an Analytics storage region first.{' '}
        <Link
          data-testid="select-region-link"
          to={`/dashboard/${isOrganization ? 'my-org' : 'account'}`}
          style={{
            color: 'inherit',
          }}
        >
          Select Region
        </Link>
      </Text>
    </Flex>
  )
}
