import React, { ReactElement, useCallback } from 'react'
import { Button, Svg } from 'ui'
import RedirectArrow from 'images/very-small-chevron-right.svg'
import { RuleType } from 'store/api/rules'
import { openProxyTray } from 'store/proxyTray'
import { Flex } from 'theme-ui'
import { buttonDiameter } from 'components/Dashboard/Profiles/RuleActions/ActionIconButton'
import { useAppDispatch } from 'store/hooks'
import { ServiceData } from 'store/api/services'
import { ProxyLocation } from 'store/api/proxies'
import ExternalSvgIcon, {
  colorFilters,
  globeIcon,
  IconType,
} from 'components/Dashboard/ExternalSvgIcon'

interface RedirectButtonProps {
  serviceRule: ServiceData
  isSwitchChecked: boolean
  setRedirectButtonHover?: (x: boolean) => void
  showProxyTray?: () => void
  // this method is passed the service item handleclick which is only used if the service is not active
  onFirstClick: () => void
  redirectLocation?: ProxyLocation
}
// This component will only be used by services. Maybe move it into Services folder?
export default function RedirectButton({
  serviceRule,
  isSwitchChecked,
  setRedirectButtonHover = (): void => {},
  onFirstClick,
  redirectLocation,
}: RedirectButtonProps): ReactElement {
  const dispatch = useAppDispatch()

  const openProxyList = useCallback(() => {
    dispatch(
      openProxyTray({
        rule: serviceRule,
      }),
    )
  }, [dispatch, serviceRule])

  const proxyIcon = redirectLocation?.country || ''

  const isSpoofed = serviceRule?.action?.do === RuleType.SPOOF_IP
  const isRedirected = serviceRule?.action?.do === RuleType.SPOOF_TAG

  const isActive = isRedirected || isSpoofed

  const handleClick = useCallback(() => {
    if (!isActive || !isSwitchChecked) {
      onFirstClick()
    } else {
      openProxyList()
    }
  }, [isActive, isSwitchChecked, onFirstClick, openProxyList])

  return (
    <Button
      onClick={handleClick}
      onMouseEnter={(): void => {
        if (isActive) {
          setRedirectButtonHover(true)
        }
      }}
      onMouseLeave={(): void => {
        setRedirectButtonHover(false)
      }}
      onTouchStart={(): void => {
        if (isActive) {
          setRedirectButtonHover(true)
        }
      }}
      onTouchEnd={(): void => setRedirectButtonHover(false)}
      variant="transparent"
      data-testid={`proxy-list-button-${
        redirectLocation?.isSingle
          ? redirectLocation?.country_name
          : redirectLocation?.city ?? (isSpoofed ? 'spoof' : 'redirect')
      }`}
      ariaLabel={`Open Proxy List ${
        redirectLocation
          ? ` - ${
              redirectLocation.isSingle ? redirectLocation.country_name : redirectLocation.city
            } is selected`
          : isSpoofed
          ? ` - Redirected to ${serviceRule?.action?.via}`
          : ''
      }`}
      tabIndex={isSwitchChecked ? 0 : -1}
      sx={{
        flexShrink: 0,
        p: 0,
        position: 'relative',
        zIndex: 'zIndex50',
        borderRadius: '99rem',
        height: `${buttonDiameter}rem`,
        width: `${buttonDiameter}rem`,
        backgroundColor: isActive ? (isSwitchChecked ? 'greenApple' : 'aliceBlue60') : 'none',
      }}
    >
      <ExternalSvgIcon
        className="proxyIcon_svg"
        icon={isSpoofed ? globeIcon : proxyIcon || globeIcon}
        type={IconType.LOCATIONS}
        fill={isSpoofed || !proxyIcon ? 'white50' : undefined}
        sx={{
          width: '1.2rem',
          height: '1.2rem',
          '&:hover': {
            filter: !isActive ? colorFilters.white : undefined,
          },
        }}
      />
      {isActive && isSwitchChecked && <Arrow />}
    </Button>
  )
}

const Arrow = (): ReactElement => {
  return (
    <Flex
      sx={{
        transition: 'transform 0.2s ease',
      }}
    >
      <Svg svg={RedirectArrow} />
    </Flex>
  )
}
