import React, { Dispatch, ElementType, useCallback, useMemo, useState } from 'react'
import { DNS_V4, DOCS_CONTROL_D_DOMAIN, FREE_DNS_DOMAIN } from 'gatsby-env-variables'
import MoreIcon from 'images/more-button.svg'
import { ExternalLink, Svg } from 'ui'
import { Link, Text } from 'theme-ui'
import { SetupGuideMode, SetupOs, StepInfo } from './SetupGuide.interface'
import {
  configDownloadPathsByResolverType,
  DnsProtocol,
  DnsServers,
  dnsServersByProtocol,
} from 'constants/dnsServers'
import { Browser, useBrowser } from 'utils/platform'
import AmazonHomeMenuIcon from 'images/helpPaneSteps/fireTV/amazonhomemenu.svg'
import CautionIcon from 'images/setup-guide/caution-icon.svg'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import values from 'lodash/values'
import DevicesIcon from 'images/profileManagement/devices-icon.svg'
import { CONTROLDText } from 'ui/CONTROLD'
import useQueryString from 'utils/useQueryString'
import AutomaticProfileDownload from 'components/SetupGuide/Components/Automatic/AutomaticProfileDownload'
import ConfigurationCode from 'components/SetupGuide/Components/ConfigurationCode'
import WindowsInterfaceConfiguration from 'components/SetupGuide/Components/WindowsInterfaceConfiguration'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useOsImageQuery from './useOsImageQuery'
import AppleTv14Resolver from 'components/SetupGuide/ResolverTabs/AppleTv14Resolver'
import { ResolverKey } from 'components/FreeDNSPage/constants/freeDNS'
import ProfileDownload from 'components/SetupGuide/Components/ProfileDownload'
import OnlyLegacyDNS from 'components/SetupGuide/Components/OnlyLegacyDNS'
import { getDeviceType } from 'components/SetupGuide/ResolverTabs/Components/useResolverTabHighlight'
import { useGetFreeDNSFiltersQuery } from 'store/api/freeDns'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import { useGetDevicesQuery, useGetDevicesTypesQuery } from 'store/api/devices'
import DividerWithText from 'ui/DividerWithText'
import useGetProvisioningState from 'store/provisioning/useGetProvisioningState'
import customUnescape from 'utils/customUnescape'
import { DropdownItemData } from 'components/Dashboard/Profiles/CustomRules/MoveRulesMenu'

export interface PlatformSteps {
  title: string
  icon?: ElementType
  steps: (StepInfo | undefined)[]
}

const compatibilityScreen = {
  text: (
    <Text variant="size14Weight700Line138" sx={{ color: 'banana' }}>
      Compatibility Warning
    </Text>
  ),
  titleIcon: CautionIcon,
  content: (
    <Text
      variant="size14Weight400Line138"
      sx={{
        maxWidth: ['100%', '38rem'],
        textAlign: 'center',
        color: 'aliceBlue60',
      }}
    >
      If you have any other DNS profiles or VPN adapters installed, they may conflict with this
      configuration. If you see that {CONTROLDText} is not working, please remove all other profiles
      and VPN adapters.
    </Text>
  ),
  shouldPresentTroubleshootingAlert: true,
}

export const usePlatformSteps = ({
  setupOs,
  selectedDevicePk,
}: {
  setupOs: SetupOs
  selectedDevicePk?: string
}): PlatformSteps | undefined => {
  const dispatch = useAppDispatch()
  const browser = useBrowser()
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  const selectedDevice = devicesData?.devices.find(device => device.PK === selectedDevicePk)
  const { data: typesData } = useGetDevicesTypesQuery('')
  const deviceTypeSettings = typesData?.types?.map(t => t.icons).flat()

  const legacyNeededScreen = useMemo(
    () => ({
      text: (
        <Text variant="size14Weight700Line138" sx={{ color: 'banana' }}>
          Compatibility Warning
        </Text>
      ),
      titleIcon: CautionIcon,
      content: <OnlyLegacyDNS selectedDevicePk={selectedDevicePk} />,
      shouldNotShowFooter: true,
    }),
    [selectedDevicePk],
  )
  const { qs } = useQueryString()
  const freeResolverType = qs.freeResolverType as keyof DnsServers | undefined
  const { data: freeDnsData } = useGetFreeDNSFiltersQuery('')

  const isProvisioning = !!qs.resolverUid
  const { resolver: provisioningResolver, uid: provisioningUid } = useGetProvisioningState()
  const isMobile = useBreakpointIndex() === 0
  const images = useOsImageQuery()
  const [isQrCode, setIsQrCode] = useState<boolean>(false)

  // we need the free dns resolvers to figure out whether it supports legacy dns or not
  const freeDNSResolver = useMemo(() => {
    if (!freeResolverType || qs.freeResolverType === 'custom') {
      return
    }

    const filterResolver = values(freeDnsData)
      .flat(1)
      .find(filter => filter.PK === qs.freeResolverType)

    if (filterResolver) {
      return filterResolver.resolvers
    }

    return {
      v4: [
        dnsServersByProtocol[DnsProtocol.LEGACY_DNS_V4][freeResolverType],
        dnsServersByProtocol[DnsProtocol.LEGACY_DNS_V4_ALT][freeResolverType],
      ],
    }
  }, [freeDnsData, freeResolverType, qs.freeResolverType])

  const standardResolver = selectedDevice?.resolvers
  const resolver: { v4?: string[] } | undefined = isProvisioning
    ? provisioningResolver
    : freeResolverType
    ? freeDNSResolver
    : standardResolver
  const selectedDNSKey = useAppSelector(s => s.freeDNS.selectedDNSKey)
  const selectedFiltersPK = useAppSelector(s => s.freeDNS.selectedFiltersPKs)
  const selected3rdPartyFilterPK = useAppSelector(s => s.freeDNS.selected3rdPartyFilterPK)

  const standardResolverUid = standardResolver?.uid
    ? `${standardResolver.uid}`
    : qs.freeResolverType === 'custom'
    ? `no-${selectedFiltersPK?.join('-')}`
    : selected3rdPartyFilterPK
    ? selected3rdPartyFilterPK
    : configDownloadPathsByResolverType[selectedDNSKey || ResolverKey.UNFILTERED]

  const resolverUid = isProvisioning ? provisioningUid : standardResolverUid

  const automaticSteps = useMemo(
    () => [
      {
        content: <AutomaticProfileDownload resolverUid={resolverUid} />,
      },
    ],
    [resolverUid],
  )
  const getResolverStep = useCallback(
    (shouldPresentTroubleshootingAlert = false) => {
      const deviceType = getDeviceType(setupOs)
      return {
        shouldRemoveXPadding: true,
        text: deviceTypeSettings?.find(s => s.type === deviceType)?.highlight?.length
          ? 'Use the highlighted DNS resolver below.'
          : '',
        shouldPresentTroubleshootingAlert,
      }
    },
    [deviceTypeSettings, setupOs],
  )
  const getFirefoxSteps = useCallback(() => {
    return [
      {
        text: 'Go to "Settings" in the top-right menu and search for "DNS."',
        image: images.firefox1?.childImageSharp?.gatsbyImageData,
      },
      {
        text: 'Under the "Enable DNS over HTTPS" section, choose "Max Protection" and then select "Custom" from the "Choose provider" dropdown.',
        image: images.firefox2?.childImageSharp?.gatsbyImageData,
      },
      getResolverStep(true),
    ]
  }, [images, getResolverStep])

  const isManual = qs.mode === SetupGuideMode.MANUAL
  const macOSTitle = `Mac OS - ${isMobile && qs.mode === SetupGuideMode.AUTO ? 'Auto' : qs.mode}`
  const windowsTitle = `Windows - ${isMobile && qs.mode === SetupGuideMode.AUTO ? 'Auto' : qs.mode}`
  const routerWindowsTitle = `Router Windows - ${
    isMobile && qs.mode === SetupGuideMode.AUTO ? 'Auto' : qs.mode
  }`
  const linuxTitle = `Linux - ${isMobile && qs.mode === SetupGuideMode.AUTO ? 'Auto' : qs.mode}`
  const routerTitle = `Router${
    isMobile && qs.mode === SetupGuideMode.AUTO ? ' - Auto' : `${qs.mode ? ` - ${qs.mode}` : ''}`
  }`

  const androidTitle = `Android - ${
    isMobile && qs.mode === SetupGuideMode.AUTO ? 'Auto' : qs.mode
  } `
  const iosTitle = `IOS - ${isMobile && qs.mode === SetupGuideMode.AUTO ? 'Auto' : qs.mode} `

  const getWindows11ManualSteps = useCallback(
    (currentDevice?: DeviceInfo) => {
      const ip = resolver?.v4?.[0] ?? (freeResolverType ? `${DNS_V4}.11` : `${DNS_V4}.22`) // hard coded to this ip for devices that do not have a v4 resolver

      const dohtemplate = `https://${FREE_DNS_DOMAIN}/${standardResolverUid}`
      return [
        {
          text: 'Open Administrative command line and enter the following command to create a template',
          content: (
            <ConfigurationCode
              shouldWrap={true}
              text={`netsh dns add encryption server=${ip} dohtemplate=${
                freeResolverType
                  ? dohtemplate
                  : isProvisioning
                  ? provisioningResolver?.doh
                  : currentDevice?.resolvers.doh
              }`}
            />
          ),
        },
        {
          content: <WindowsInterfaceConfiguration ip={ip} />,
        },
        {
          text: 'Go to Start, search for "Network & Internet”, and click the name of the network you are on. Scroll down to DNS server assignment and click Edit.',
          image: images.winDnsSettings?.childImageSharp?.gatsbyImageData,
          imageWidth: ['100%', '33.7rem'],
        },
        {
          text: 'Change “Preferred DNS Encryption” to Encrypted Only and press Save.',
          image: images.winDnsEncryption?.childImageSharp?.gatsbyImageData,
          imageWidth: ['100%', '33.7rem'],
        },
      ]
    },
    [
      freeResolverType,
      images.winDnsEncryption?.childImageSharp?.gatsbyImageData,
      images.winDnsSettings?.childImageSharp?.gatsbyImageData,
      isProvisioning,
      provisioningResolver?.doh,
      resolver?.v4,
      standardResolverUid,
    ],
  )

  const getBigSurSteps = useCallback(
    (
      freeResolverType: keyof DnsServers | undefined,
      isProvisoning: boolean,
      images: GatsbyTypes.ImageQueryQuery,
      deviceData: DropdownItemData[],
      currentDevice: DropdownItemData,
      dispatch: Dispatch<{ payload: string; type: string }>,
      browser: Browser,
    ): PlatformSteps => {
      const bigSurProfileDownloadStep: StepInfo[] = [
        {
          shouldRemoveTopPadding: true,
          content: (
            <ProfileDownload
              isQrCode={isQrCode}
              setIsQrCode={setIsQrCode}
              text={
                isQrCode
                  ? 'Scan this QR code using a mobile device to download the DNS Profile.'
                  : `First, download the ${CONTROLDText} DNS Profile.`
              }
            />
          ),
        },
      ]

      const macOsPreferencesSteps: StepInfo[] = [
        {
          text: 'Go to “System Settings”',
          image: images.bigSurPreferences?.childImageSharp?.gatsbyImageData,
        },
        {
          text: 'Click “Privacy and Security” in the sidebar, then click “Profiles” on the right',
          image: images.bigSurProfiles?.childImageSharp?.gatsbyImageData,
        },
        {
          text: 'Double-click the profile. Review the profile contents then click “Install”',
          image: images.bigSurInstall?.childImageSharp?.gatsbyImageData,
        },
        compatibilityScreen,
      ]

      const safariBigSurSteps: StepInfo[] = [...bigSurProfileDownloadStep, ...macOsPreferencesSteps]

      switch (browser) {
        case Browser.CHROME:
          return {
            title: macOSTitle,
            steps: isManual
              ? [
                  ...bigSurProfileDownloadStep,
                  {
                    text: 'Open the downloaded profile at the bottom of the screen.',
                    image: images.bigSurChromeOpenProfile?.childImageSharp?.gatsbyImageData,
                  },
                  ...macOsPreferencesSteps,
                ]
              : automaticSteps,
          }
        case Browser.FIREFOX:
          return {
            title: macOSTitle,
            steps: isManual
              ? [
                  ...bigSurProfileDownloadStep,
                  {
                    text: "Select 'Open with Firefox' and then 'Open with ProfileHelper'",
                    image: images.bigSurFirefoxOpenProfile?.childImageSharp?.gatsbyImageData,
                  },
                  ...macOsPreferencesSteps,
                ]
              : automaticSteps,
          }
        default:
          return {
            title: macOSTitle,
            steps: isManual ? safariBigSurSteps : automaticSteps,
          }
      }
    },
    [isManual, macOSTitle, automaticSteps, isQrCode, setIsQrCode],
  )

  const platformSteps = useMemo(() => {
    const isSafari = browser === Browser.SAFARI
    const currentDevice = devicesData?.devices?.find(f => f.PK.toString() === selectedDevicePk)
    const deviceData = (devicesData?.devices ?? []).map(x => ({
      ...x,
      text: customUnescape(x.name) || 'No device',
    }))
    const currentDeviceData: DropdownItemData = {
      Icon: DevicesIcon,
      text: `${customUnescape(currentDevice?.name)}`,
    }

    switch (setupOs) {
      case SetupOs.MAC_OS_X:
        return {
          title: macOSTitle,
          steps: resolver?.v4
            ? [
                {
                  text: 'Go to "System Preferences"',
                  image: images.macPref?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Click on "Network"',
                  image: images.macNetwork?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Click on "Advanced"',
                  image: images.macAdvanced?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Go to the “DNS” tab and double click on the address below to edit. If none is present, click on the “+” button to add one.',
                  image: images.macDns?.childImageSharp?.gatsbyImageData,
                },
                getResolverStep(true),
              ]
            : [legacyNeededScreen],
        }
      case SetupOs.MAC_OS_BIG_SUR:
        return getBigSurSteps(
          freeResolverType,
          isProvisioning,
          images,
          deviceData,
          currentDeviceData,
          dispatch,
          browser,
        )
      case SetupOs.MAC_OS:
        return {
          title: macOSTitle,
          steps: automaticSteps,
        }
      case SetupOs.WINDOWS_10_OR_OLDER:
        return {
          title: windowsTitle,
          steps: resolver?.v4
            ? isManual
              ? [
                  {
                    text: 'Open the Start Menu, type in "Control Panel" and click "Control Panel"',
                    image: images.winStartMenu?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: 'Click on “Network and Internet”',
                    image: images.winNetwork?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: 'Click on “Network and Sharing Center”',
                    image: images.winSharing?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: 'Click on “Change adapter settings”',
                    image: images.winSettings?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: 'Select the network connection you want to change the DNS servers for',
                    image: images.winDns?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: 'Select “Properties”',
                    image: images.winProperties?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: 'Double-click on “Internet Protocol Version 4…”',
                    image: images.winProtocol?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: 'Select “Use the following DNS server addresses”',
                    image: images.winServer?.childImageSharp?.gatsbyImageData,
                  },
                  {
                    text: '',
                    shouldPresentTroubleshootingAlert: true,
                  },
                ]
              : automaticSteps
            : [legacyNeededScreen],
        }
      case SetupOs.WINDOWS_11:
        return {
          title: windowsTitle,
          steps: isManual ? getWindows11ManualSteps(currentDevice) : automaticSteps,
        }
      case SetupOs.WINDOWS_OS:
      case SetupOs.WINDOWS_SERVER:
        const isWindowsOs = setupOs === SetupOs.WINDOWS_OS

        return {
          title: isWindowsOs ? windowsTitle : routerWindowsTitle,
          steps: [
            {
              content: (
                <AutomaticProfileDownload resolverUid={resolverUid}>
                  <DividerWithText text="OR" />
                  <ExternalLink
                    data-testid={isWindowsOs ? 'yoga-dns-link' : 'setup-docs'}
                    to={`${DOCS_CONTROL_D_DOMAIN}/docs/${
                      isWindowsOs ? 'platform-windows#automatic-setup-yogadns' : 'windows-server'
                    }`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      minWidth: '6rem',
                      minHeight: '3.2rem',
                      px: '1.6rem',
                      py: 0,
                      borderRadius: '2rem',
                      border: '1px solid',
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      outline: 'none',
                      boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.30)',
                      backgroundColor: 'plumpPurple',
                      borderColor: 'white10',
                      color: 'text',
                      span: {
                        color: 'text',
                      },
                      ':hover': {
                        ':not([disabled])': {
                          background:
                            'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #593EA5',
                        },
                      },
                    }}
                  >
                    {isWindowsOs ? 'Setup via YogaDNS' : 'Read Setup Docs'}
                  </ExternalLink>
                </AutomaticProfileDownload>
              ),
            },
          ],
        }
      case SetupOs.LINUX_OS:
        return {
          title: linuxTitle,
          steps: automaticSteps,
        }
      case SetupOs.ROUTER:
        return {
          title: routerTitle,
          steps: automaticSteps,
        }
      case SetupOs.PF_SENSE:
        return {
          title: routerTitle,
          steps: automaticSteps,
        }
      case SetupOs.OPN_SENSE:
        return {
          title: routerTitle,
          steps: automaticSteps,
        }

      case SetupOs.ANDROID:
        return {
          title: androidTitle,
          steps: [
            {
              text: 'Go to your phone’s “Settings”',
              image: images.droidSettings?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Search “Private DNS”',
              image: images.droidSearch?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Tap on “Private DNS”',
              image: images.droidTap?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Choose “Private DNS provider hostname”',
              image: images.droidChoose?.childImageSharp?.gatsbyImageData,
            },
            getResolverStep(true),
          ],
        }
      case SetupOs.ANDROID_OS:
        return {
          title: androidTitle,
          steps: automaticSteps,
        }
      case SetupOs.CHROME:
        return {
          title: 'Chrome',
          steps: [
            {
              text: (
                <>
                  Go to “Settings” in the top right
                  <Svg svg={MoreIcon} fill="white50" sx={{ mb: '-0.3rem', mx: 2 }} />
                  button.
                </>
              ),
              image: images.chromeSettings?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Click on “Privacy and Security”',
              image: images.chromePrivacy?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Click on “Security”',
              image: images.chromeSecurity?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Under “Advanced”, enable “Encrypt the names of sites you visit” . Select “Add custom DNS service provider” from the Select DNS Provider dropdown.',
              image: images.chromeCustom?.childImageSharp?.gatsbyImageData,
            },
            getResolverStep(true),
          ],
        }
      case SetupOs.FIREFOX:
        return {
          title: 'Firefox',
          steps: getFirefoxSteps(),
        }
      case SetupOs.EDGE:
        return {
          title: 'MS Edge',
          steps: [
            {
              text: 'Go to "Settings" in the top right ... menu',
              image: images.edgeSettings?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Search for "DNS"',
              image: images.edgeDns?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Select "Choose a Service Provider"',
              image: images.edgeServiceProvider?.childImageSharp?.gatsbyImageData,
            },
            getResolverStep(true),
          ],
        }
      case SetupOs.IOS_OS:
        return {
          title: iosTitle,
          steps: automaticSteps,
        }
      case SetupOs.IOS:
        return {
          title: iosTitle,
          steps: [
            {
              shouldRemoveTopPadding: true,
              content: (
                <ProfileDownload
                  isQrCode={isQrCode}
                  setIsQrCode={setIsQrCode}
                  text={
                    isQrCode
                      ? 'Scan this QR code using a mobile device to download the DNS Profile.'
                      : isSafari
                      ? `Download the ${CONTROLDText} profile using Safari.`
                      : 'Please use Safari to complete this step.'
                  }
                />
              ),
            },
            {
              text: 'In your "Settings", go to "Profile downloaded".',
              image: images.iosSettings?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Tap on "Install" in the top right corner.',
              image: images.iosInstallProfile?.childImageSharp?.gatsbyImageData,
            },
            compatibilityScreen,
          ],
        }
      case SetupOs.APPLE_TV_OS_13:
        return {
          title: 'Apple TV',
          steps: resolver?.v4
            ? [
                {
                  text: 'Select and press on the "Settings" button.',
                  image: images.appleTv1?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Go down and press on "Network".',
                  image: images.appleTv2?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Press on your network connection.',
                  image: images.appleTv3?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Press on your network once again.',
                  image: images.appleTv4?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Go down and press on "DNS".',
                  image: images.appleTv5?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Enter the DNS address in the next screen in the boxes below.',
                  image: images.appleTv6?.childImageSharp?.gatsbyImageData,
                },
                getResolverStep(),
              ]
            : [legacyNeededScreen],
        }
      case SetupOs.APPLE_TV_OS_14:
        return {
          title: 'Apple TV',
          steps: [
            {
              text: 'Select and press on the "Settings" button.',
              image: images.appleTv1?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Press "General" and go to "Privacy".',
              image: images.appleTv14_2?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Select the "Share Apple TV Analytics" menu option, but don\'t press ENTER. Instead, press the PLAY button on the remote.',
              image: images.appleTv14_3?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Click on "Add Profile". You will be presented with a URL entry form.',
              image: images.appleTv14_4?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Enter the URL in the next step in the entry form below.',
              image: images.appleTv14_5?.childImageSharp?.gatsbyImageData,
            },
            {
              text: 'Input this exact URL into the box.',
              content: <AppleTv14Resolver />,
            },
          ],
        }
      case SetupOs.FIRE_TV:
        return {
          title: 'Fire TV',
          steps: resolver?.v4
            ? [
                {
                  text: 'Navigate to the Settings Button to the right of the screen.',
                  image: images.fireTv1?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select "My Fire TV".',
                  image: images.fireTv2?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select "About".',
                  image: images.fireTv3?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select "Network" and write down or take a picture of the "IP Address", "Gateway" and "Subnet Mask" fields, you will need these later.',
                  image: images.fireTv4?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Now go back to "Settings" and open "Network".',
                  image: images.fireTv5?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: (
                    <Text>
                      Select the network you&apos;re connected to and press the{' '}
                      <Svg
                        svg={AmazonHomeMenuIcon}
                        sx={{
                          // to nicely line up the icon with the text instruction that it is part of
                          position: 'relative',
                          top: '0.4rem',
                        }}
                      />{' '}
                      button to forget it followed by the center select button on the remote to
                      confirm.
                    </Text>
                  ),
                  image: images.fireTv6?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select your WIFI network again.',
                  image: images.fireTv7?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Enter your WIFI password and select "Advanced" when you\'re done.',
                  image: images.fireTv8?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Enter the IP Address you wrote down from "Step 4" and press next.',
                  image: images.fireTv9?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Enter the "Gateway" from "Step 4" and press next.',
                  image: images.fireTv10?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: (
                    <Text>
                      Your &quot;Network Prefix Length&quot; will depend on what you wrote down in
                      &quot;Step 4&quot;. <br /> You should see 255.255.255.0 as your Subnet Mask
                      which is a Prefix Length of 24. If not, click{' '}
                      <Link
                        target="_blank"
                        href={'https://i.imgur.com/shyHnCl.png'}
                        sx={{
                          color: 'white',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        here
                      </Link>{' '}
                      to find out.
                    </Text>
                  ),
                  image: images.fireTv11?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Enter the "Legacy DNS" Address in the next screen for DNS 1 and press "Next". Enter it again for DNS 2 and press "Connect".',
                  image: images.fireTv12?.childImageSharp?.gatsbyImageData,
                },
                getResolverStep(),
              ]
            : [legacyNeededScreen],
        }
      case SetupOs.SAMSUNG_TV:
        return {
          title: 'Samsung TV',
          steps: resolver?.v4
            ? [
                {
                  text: `Go to the TV's Menu using the remote and select "Network".`,
                  image: images.samsungTv1?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select "Network Status".',
                  image: images.samsungTv2?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select "IP Settings".',
                  image: images.samsungTv3?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select "DNS setting".',
                  image: images.samsungTv4?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Select "Enter manually".',
                  image: images.samsungTv5?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Enter the DNS address in the next step in the boxes below.',
                  image: images.samsungTv6?.childImageSharp?.gatsbyImageData,
                },
                getResolverStep(),
              ]
            : [legacyNeededScreen],
        }
      case SetupOs.ANDROID_TV:
        return {
          title: 'Android TV',
          steps: resolver?.v4
            ? [
                {
                  text: 'On your Android TV, open “Settings”',
                  content: <></>,
                },
                {
                  text: 'Head to “Network and internet” and write down the IP address. Then, select “IP settings”',
                  image: images.androidTv1?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'In “IP settings,” switch to “Static.”',
                  image: images.androidTv2?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Enter the IP address from step 2.',
                  image: images.androidTv3?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Find your Gateway address and enter it in the appropriate field.',
                  image: images.androidTv4?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: 'Make sure the “Network prefix length” is 24 — change it if necessary.',
                  image: images.androidTv5?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: `Enter the first DNS address: ${resolver?.v4?.[0]}`,
                  image: images.androidTv6?.childImageSharp?.gatsbyImageData,
                },
                {
                  text: `Enter the second DNS address: ${resolver?.v4?.[1]}`,
                  image: images.androidTv7?.childImageSharp?.gatsbyImageData,
                },
              ]
            : [legacyNeededScreen],
        }
      case SetupOs.TV_OTHER:
        return {
          title: 'Configure Other TV',
          steps: resolver?.v4 ? [getResolverStep()] : [legacyNeededScreen],
        }
      default:
        return {
          title: 'Configure Endpoint',
          steps: [],
        }
    }
  }, [
    browser,
    devicesData?.devices,
    setupOs,
    selectedDevicePk,
    macOSTitle,
    resolver?.v4,
    images,
    getResolverStep,
    legacyNeededScreen,
    getBigSurSteps,
    freeResolverType,
    isProvisioning,
    isQrCode,
    setIsQrCode,
    dispatch,
    automaticSteps,
    windowsTitle,
    isManual,
    getWindows11ManualSteps,
    resolverUid,
    linuxTitle,
    routerTitle,
    androidTitle,
    getFirefoxSteps,
    iosTitle,
    routerWindowsTitle,
  ])

  return platformSteps
}
