import { Box, ThemeUIStyleObject } from 'theme-ui'
import React, { ReactElement } from 'react'

const VerticalDivider = ({ sx }: { sx?: ThemeUIStyleObject }): ReactElement => (
  <Box
    sx={{
      backgroundColor: 'white15',
      width: '1px',
      ...sx,
    }}
  />
)

export default VerticalDivider
