import React, { createRef, PropsWithChildren } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export const modalFooterRef = createRef<HTMLDivElement>()

export interface ModalDialogFooterProps {
  sx?: ThemeUIStyleObject
  shouldHideFooter?: boolean
}
export default function ModalDialogFooter({
  sx,
  children,
  shouldHideFooter,
}: PropsWithChildren<ModalDialogFooterProps>): React.ReactElement {
  return (
    <Flex
      ref={modalFooterRef}
      data-testid="modal-dialog-footer"
      sx={{
        display: shouldHideFooter ? 'none' : 'flex',
        height: 'fit-content',
        alignItems: 'center',
        flexShrink: 0,
        backgroundColor: 'blue800',
        px: '1.6rem',
        py: '1.2rem',
        borderBottomRightRadius: [0, '1.2rem'],
        borderBottomLeftRadius: [0, '1.2rem'],
        borderTop: '1px solid',
        borderColor: 'blueYonder30',
        ...sx,
      }}
    >
      {children}
    </Flex>
  )
}
