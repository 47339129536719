import React, { forwardRef } from 'react'
import { Card as _Card, Box } from 'theme-ui'
import { CardProps } from '.'

export default forwardRef<HTMLDivElement, CardProps>(function Card(
  props: CardProps,
  ref,
): React.ReactElement {
  const { children, header = null, footer = null, variant = 'primary', sx = {}, ...rest } = props

  return (
    <_Card sx={sx} variant={variant} ref={ref} {...rest}>
      {header && (
        <Box className="card-header" sx={{ marginBottom: 6 }}>
          {header}
        </Box>
      )}
      <Box className="card-children" sx={{ width: '100%' }}>
        {children}
      </Box>
      {footer && (
        <Box
          sx={{
            marginTop: 6,
            flexShrink: 0,
          }}
        >
          {footer}
        </Box>
      )}
    </_Card>
  )
})
