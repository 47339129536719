import React, { ElementType, ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import { ProtocolType } from 'store/analytics'
import { ResolverType } from 'components/SetupGuide/SetupGuide.interface'
import UnencryptedIcon from 'images/dnsProtocols/unencrypted-icon.svg'
import DOHIcon from 'images/dnsProtocols/DOH.svg'
import DOTIcon from 'images/dnsProtocols/DOT.svg'
import DOQIcon from 'images/dnsProtocols/DOQ.svg'
import DOH3Icon from 'images/dnsProtocols/DOH3.svg'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import protocolIcons from 'components/Dashboard/IconMap/ProtocolIcons'
import { QueriesFilterMenuType } from '../FiltersContainer'

export const protocolTypeFilters: ProtocolType[] = [
  'all',
  ResolverType.LEGACY,
  ResolverType.DOH,
  ResolverType.DOT,
  ResolverType.DOQ,
  ResolverType.DOH3,
]

export const protocolData: Record<
  Exclude<ProtocolType, 'all' | ResolverType.SECURE>,
  { label: string; icon: ElementType }
> = {
  [ResolverType.LEGACY]: { label: 'Legacy', icon: UnencryptedIcon },
  [ResolverType.DOH]: { label: 'DNS-over-HTTPS', icon: DOHIcon },
  [ResolverType.DOT]: {
    label: 'DNS-over-TLS',
    icon: DOTIcon,
  },
  [ResolverType.DOQ]: {
    label: 'DNS-over-QUIC',
    icon: DOQIcon,
  },
  [ResolverType.DOH3]: {
    label: 'DNS-over-HTTPS/3',
    icon: DOH3Icon,
  },
}

export const protocolToDropdownItemData: Record<
  Exclude<ProtocolType, ResolverType.SECURE>,
  { label: string; icon?: ElementType }
> = {
  all: { label: 'All Protocols' },
  ...protocolData,
}

export default function ProtocolsDropdown(): ReactElement {
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const selectedFilterName =
    protocolToDropdownItemData[filterParameters[QueriesFilterMenuType.PROTOCOL] || 'all']?.label ||
    ''

  const icon = protocolIcons[filterParameters[QueriesFilterMenuType.PROTOCOL] || ''] || undefined

  return (
    <QueryFilterDropdown
      selectedValue={selectedFilterName}
      icon={icon}
      type={QueriesFilterMenuType.PROTOCOL}
    >
      <QueryFilterDropdownContent type={QueriesFilterMenuType.PROTOCOL} />
    </QueryFilterDropdown>
  )
}
