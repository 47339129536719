import React, { ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import { useGetProxiesQuery } from 'store/api/proxies'
import { QueriesFilterMenuType } from '../FiltersContainer'
import { IconType } from 'components/Dashboard/ExternalSvgIcon'

export default function LocationsDropdown(): ReactElement {
  const { data: proxiesData } = useGetProxiesQuery('')
  const locations = proxiesData?.proxies
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)

  const selectedLocation = locations?.find(
    location => filterParameters[QueriesFilterMenuType.LOCATIONS] === location.PK,
  )
  const selectedFilterName = selectedLocation?.city || 'Locations'

  const externalIcon = { icon: selectedLocation?.country || '', type: IconType.LOCATIONS }

  return (
    <QueryFilterDropdown
      selectedValue={selectedFilterName}
      externalIcon={externalIcon}
      type={QueriesFilterMenuType.LOCATIONS}
    >
      <QueryFilterDropdownContent type={QueriesFilterMenuType.LOCATIONS} locations={locations} />
    </QueryFilterDropdown>
  )
}
