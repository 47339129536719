import UnfilteredIcon from 'images/freeDNS/no-blocking.svg'
import MalwareIcon from 'images/freeDNS/malware-white.svg'
import AdsTrackingIcon from 'images/freeDNS/ads-tracking-white.svg'
import SocialIcon from 'images/freeDNS/social-white.svg'
import FamilyFriendlyIcon from 'images/freeDNS/family-friendly-white.svg'
import UnfilteredBlueIcon from 'images/freeDNS/unfiltered-white.svg'
import IconBlock1 from 'images/freeDNS/group-118.svg'
import IconBlock2 from 'images/freeDNS/group-119.svg'
import IconBlock3 from 'images/freeDNS/union.svg'
import SCIUnfiltered from 'images/scicons/unfil.svg'
import SCIMalware from 'images/scicons/mal.svg'
import SCIAds from 'images/scicons/ads.svg'
import SCISocial from 'images/scicons/social.svg'
import SCIFamily from 'images/scicons/family.svg'
import SCIUncensored from 'images/scicons/uncensored.svg'

export enum ResolverKey {
  UNFILTERED = 'unfiltered',
  MALWARE = 'blockMalware',
  ADS_TRACKING = 'blockMalwareAndAds',
  SOCIAL = 'blockMalwareAdsAndSocial',
  FAMILY_FRIENDLY = 'family',
  UNCENSORED = 'uncensored',
  CUSTOM = 'custom',
}

export const standardConfigurationsData = [
  {
    key: ResolverKey.UNFILTERED,
    icon: UnfilteredIcon,
    flatIcon: SCIUnfiltered,
    title: 'Unfiltered',
    firstTab: {
      title: 'Blocks',
      content: ['No DNS record blocking or manipulation.'],
    },
    secondTab: {
      title: 'Use Cases',
      content: ['DNS Query Privacy & Security'],
    },
  },
  {
    key: ResolverKey.MALWARE,
    icon: MalwareIcon,
    flatIcon: SCIMalware,
    title: 'Malware',
    firstTab: {
      title: 'Blocks',
      content: ['Malware Domains'],
    },
    secondTab: {
      title: 'Use Cases',
      content: ['Network Security', 'Phishing Attacks', 'Hardware Compromise'],
    },
  },
  {
    key: ResolverKey.ADS_TRACKING,
    icon: AdsTrackingIcon,
    flatIcon: SCIAds,
    title: 'Ads & Tracking',
    isRecommended: true,
    firstTab: {
      title: 'Blocks',
      content: ['Malware', 'Ads & Tracking'],
    },
    secondTab: {
      title: 'Use Cases',
      content: ['Privacy Protection', 'Faster Internet', 'Improved Usability'],
    },
  },
  {
    key: ResolverKey.SOCIAL,
    icon: SocialIcon,
    flatIcon: SCISocial,
    title: 'Social',
    firstTab: {
      title: 'Blocks',
      content: ['Malware', 'Ads & Tracking', 'Social Networks'],
    },
    secondTab: {
      title: 'Use Cases',
      content: ['Network Security', 'Privacy Protection', 'Improved Productivity'],
    },
  },
  {
    key: ResolverKey.FAMILY_FRIENDLY,
    icon: FamilyFriendlyIcon,
    flatIcon: SCIFamily,
    title: 'Family Friendly',
    firstTab: {
      title: 'Blocks',
      content: ['Malware', 'Ads & Tracking', 'Adult Content + Drugs'],
    },
    secondTab: {
      title: 'Use Cases',
      content: ['Harmful Content Protection'],
    },
  },
  {
    key: ResolverKey.UNCENSORED,
    icon: UnfilteredBlueIcon,
    flatIcon: SCIUncensored,
    title: 'Uncensored',

    firstTab: {
      title: 'Unblocks',
      content: ['Censored Domains from various countries'],
      links: ['See Domains', 'Limitations'],
    },
    secondTab: {
      title: 'Use Cases',
      content: ['Network Security', 'Faster Internet', 'Privacy Protection', 'No Geo-Blocking'],
    },
  },
]

export const secureBrowsingData = [
  {
    key: 'block-1',
    icon: IconBlock1,
    title: 'No Logging',
    text: 'We don’t log or store your DNS queries.',
    color: 'berryBlue',
  },
  {
    key: 'block-2',
    icon: IconBlock2,
    title: 'Anycast Network',
    text: 'Optimized for low latency and performance.',
    color: 'banana',
  },
  {
    key: 'block-3',
    icon: IconBlock3,
    title: 'Secure Protocols',
    text: 'DNS-over-HTTPS/3 and DNS-over-TLS/DoQ support.',
    color: 'greenApple',
  },
]
