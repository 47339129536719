import React, { PropsWithChildren, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import { resolverText } from 'components/SetupGuide/ResolverTabs/Components/ResolverTabTitle'
import { ResolverType } from 'components/SetupGuide/SetupGuide.interface'
import { Accordion } from 'ui'

const bootstrapIps = {
  [ResolverType.DOH]: ['76.76.2.22', '2606:1a40::22'],
  [ResolverType.DOT]: ['76.76.2.22', '2606:1a40::22'],
}

export default function SecureResolver({
  isResolverTypeHighlighted,
  canCopy,
  address,
  shouldShowFullAddress,
  type,
}: {
  isResolverTypeHighlighted: boolean
  address: string
  canCopy: boolean
  shouldShowFullAddress?: boolean
  type?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minWidth: '100%',
      }}
    >
      <Flex
        sx={{
          px: '2.4rem',
          py: '1.2rem',
          minWidth: '100%',
          flexShrink: 0,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor: 'blueYonder15',
          flexDirection: 'column',
        }}
      >
        {type && (
          <Text
            variant="size12Weight700"
            sx={{
              whiteSpace: 'nowrap',
              flexShrink: 0,
              mr: '0.8rem',
              color: isResolverTypeHighlighted ? 'greenApple' : 'aliceBlue60',
            }}
          >
            {resolverText[type]}
          </Text>
        )}
        <Address
          shouldShowFullAddress={shouldShowFullAddress}
          address={address}
          canCopy={canCopy}
          isResolverTypeHighlighted={isResolverTypeHighlighted}
        />
        {bootstrapIps[type as ResolverType] && (
          <BootstrapIp>
            {bootstrapIps[type as ResolverType].map(address => (
              <Address
                key={address}
                address={address}
                canCopy={canCopy}
                isResolverTypeHighlighted={isResolverTypeHighlighted}
              />
            ))}
          </BootstrapIp>
        )}
      </Flex>
    </Flex>
  )
}

function BootstrapIp({
  type,
  children,
}: PropsWithChildren<{ type?: string; shouldAddBottomMargin?: boolean }>) {
  return (
    <Accordion
      data-testid="Bootstrap IPs"
      id={type || ''}
      title="Bootstrap IPs"
      containerStyle={{
        minWidth: '100%',
        mt: '1.2rem',
      }}
      buttonStyle={{
        justifyContent: 'space-between',
        px: 0,
        py: 0,
        width: '100%',
      }}
      contentContainerStyle={{
        mt: '0.4rem',
      }}
      titleStyle={{ minWidth: '100%', fontSize: '1.2rem', fontWeight: 700 }}
      openTitleStyle={{ minWidth: '100%', fontSize: '1.2rem', fontWeight: 700 }}
    >
      <Flex sx={{ width: '100%', flexDirection: 'column' }}>{children}</Flex>
    </Accordion>
  )
}

function Address({
  shouldShowFullAddress,
  address,
  canCopy,
  isResolverTypeHighlighted,
}: {
  shouldShowFullAddress?: boolean
  address: string
  canCopy: boolean
  isResolverTypeHighlighted: boolean
}) {
  return (
    <Flex
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid',
        borderColor: 'blueYonder40',
        px: '1.2rem',
        mt: '0.8rem',
        height: '3.8rem',
        backgroundColor: 'blue',
        borderRadius: '0.8rem',
      }}
    >
      <Text
        variant="size12Weight400"
        sx={{
          color: isResolverTypeHighlighted ? 'greenApple' : 'aliceBlue',
          mr: '2rem',
          overflow: 'hidden',
          textAlign: 'left',
          ...(shouldShowFullAddress
            ? { wordWrap: 'break-word' }
            : {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%', // to enable the textoverflow
              }),
          minWidth: '20rem',
        }}
        tabIndex={0}
      >
        {address}
      </Text>
      {canCopy && (
        <CopyToClipboard
          data={address}
          smallIcon
          ariaLabelText="copy resolver to clipboard"
          fillSvg={isResolverTypeHighlighted ? 'greenApple' : 'aliceBlue60'}
          sx={{ p: 0 }}
        />
      )}
    </Flex>
  )
}
