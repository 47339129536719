import React, { ElementType, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg } from 'ui'
import { InternalOrExternalLink } from 'components/HomePage/Header/index'

export function DropdownMenuItem({
  header,
  icon,
  description,
  to,
  href,
  dismiss,
}: {
  header: string
  description?: string
  icon?: ElementType
  to?: string
  href?: string
  dismiss?: () => void
  testId?: string
}): ReactElement {
  return (
    <>
      <InternalOrExternalLink to={to} href={href} dismiss={dismiss}>
        <Flex sx={{ alignItems: 'center', height: '100%', gap: '0.8rem' }}>
          {icon && (
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '4.8rem',
                height: '4.8rem',
                flexShrink: 0,
              }}
            >
              <Svg fill={'aliceBlue'} svg={icon} />
            </Flex>
          )}
          <Flex sx={{ flexDirection: 'column' }}>
            <Text
              sx={{
                color: 'aliceBlue',
                fontSize: '1.6rem',
                fontWeight: 500,
                textAlign: 'left',
              }}
            >
              {header}
            </Text>
            {description && (
              <Text
                sx={{
                  color: 'aliceBlue70',
                  fontSize: '1.3rem',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                {description}
              </Text>
            )}
          </Flex>
        </Flex>
      </InternalOrExternalLink>
    </>
  )
}
