import React, { ReactElement, useState } from 'react'
import { Accordion, Svg } from 'ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { markAllRead } from 'store/notifications'
import { Flex, Text, Link, Image } from 'theme-ui'
import { Notification } from 'store/api/notifications'
import SmallArrowUpIcon from 'images/profileManagement/context-menu-arrow-up.svg'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import { CONTROLDText } from 'ui/CONTROLD'
import { useGetNotificationsQuery } from 'store/api/notifications/notifications'
import ExternalLinkIcon from 'images/dashboard/external-link-icon.svg'
import VerticalDivider from 'ui/VerticalDivider'
import ModalDialog from 'ui/NewModalDialog'
import { setIsNotificationsDialogOpen } from 'store/dashboard/dashboard'

export default function NotificationsDialog(): ReactElement {
  const dispatch = useAppDispatch()
  const newNotificationKeys = useAppSelector(s => s.notifications.newNotificationKeys)
  const isOpen = useAppSelector(s => s.dashboard.isNotificationsDialogOpen)
  const userPk = useAppSelector(s => s.session.userPk)
  const { data: notificationsData } = useGetNotificationsQuery('', { skip: !userPk })

  const dismiss = () => dispatch(setIsNotificationsDialogOpen(false))

  const closeDialog = () => {
    dispatch(
      markAllRead({
        userPk,
        incomingNotificationKeys: notificationsData?.notifications.map(n => n.PK) || [],
      }),
    )

    dismiss()
  }

  return (
    <ModalDialog
      dataTestId="notifications-dialog"
      isOpen={isOpen}
      title="Notifications"
      dismiss={() => closeDialog()}
      onCloseClick={() => closeDialog()}
    >
      <Flex
        sx={{
          width: ['100%', '45.2rem'],
          overflowY: 'auto',
          maxHeight: [`calc(100% - 1rem)`, `calc(100vh - 12rem)`],
        }}
        className="hide-scrollbar"
      >
        <Flex
          sx={{
            flexDirection: 'column',
            pt: '1.2rem',
            width: '100%',
          }}
        >
          <Flex
            as="ul"
            sx={{
              pl: 0,
              ml: 0,
              flexDirection: 'column',
            }}
          >
            {notificationsData?.notifications.map(notification => (
              <NotificationItem
                notification={notification}
                shouldBeOpen={!!newNotificationKeys?.includes(notification.PK)}
                key={notification.PK}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </ModalDialog>
  )
}

interface NotificationItemProps {
  notification: Notification
  shouldBeOpen: boolean
}

function NotificationItem({ notification, shouldBeOpen }: NotificationItemProps): ReactElement {
  const [isOpen, setIsOpen] = useState(shouldBeOpen)
  const linkData = Array.from(notification.links ?? [])
  const notificationMessage = notification.message.replace(/ControlD/g, CONTROLDText)

  return (
    <Flex
      as="li"
      key={notification.PK}
      data-testid={`notification-${notification.PK}`}
      sx={{
        listStyle: 'none',
        m: 0,
        px: '1.2rem',
        mb: '1.2rem',
      }}
    >
      <Accordion
        data-testid="notifications-accordion"
        id={notification.PK}
        title={
          <NotificationItemTitle
            text={notification.title}
            timestamp={notification.date}
            isOpen={isOpen}
          />
        }
        icon={SmallArrowUpIcon}
        titleStyle={{
          minWidth: '100%',
          color: isOpen ? 'aliceBlue' : 'aliceBlue60',
          textAlign: 'left',
        }}
        containerStyle={{
          minWidth: '100%',
          py: '0.8rem',
          backgroundColor: 'white6',
          borderRadius: '0.8rem',
        }}
        buttonStyle={{
          justifyContent: 'space-between',
          py: '0.8rem',
          px: '1.2rem',
          '&:hover > div span:first-of-type': { color: 'aliceBlue' },
          '&:hover svg > path': { fill: 'aliceBlue ' },
        }}
        svgStyle={{
          p: 0,
          svg: {
            width: '2.2rem',
            height: '2.2rem',
          },
        }}
        isOpenControlled={isOpen}
        setIsOpenControlled={setIsOpen}
      >
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          {notification.header_img && (
            <Flex sx={{ mb: '0.8rem' }}>
              <Image data-testid="notofication-image" src={notification.header_img} />
            </Flex>
          )}
          <Text
            variant="size12Weight400Line138Letter048"
            sx={{
              display: 'block',
              // to preserve white space characters (handle lists)
              whiteSpace: 'pre-wrap',
              px: '1.2rem',
              color: 'aliceBlue60',
            }}
          >
            {notificationMessage}
          </Text>
          <Flex sx={{ gap: '0.8rem', alignItems: 'center', px: '1.2rem', mt: '0.8rem' }}>
            {linkData &&
              linkData.map((link, index) => (
                <Flex
                  key={index}
                  sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: '0.8rem' }}
                >
                  {index !== 0 && (
                    <VerticalDivider sx={{ width: '0.1rem', height: '1.6rem', flexShrink: 0 }} />
                  )}
                  <Link
                    data-testid={`notifications-link-${notification.PK}-${index}`}
                    target="_blank"
                    href={link.url}
                    sx={{
                      color: 'aliceBlue60',
                      lineHeight: '138%',
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                      ':hover': {
                        color: 'aliceBlue',
                      },
                    }}
                  >
                    {link.title}
                  </Link>
                  <Svg
                    svg={ExternalLinkIcon}
                    fill="aliceBlue60"
                    sx={{ flexShrink: 0, width: '1.6rem', height: '1.6rem' }}
                  />
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Accordion>
    </Flex>
  )
}

interface NotificationItemTitleProps {
  text: string
  timestamp: number
  isOpen: boolean
}
function NotificationItemTitle({
  text,
  timestamp,
  isOpen,
}: NotificationItemTitleProps): ReactElement {
  const now = new Date()
  const timeAndDateOfNotification = new Date(timestamp * 1000)
  const distanceFromNotificationTimeToNow = formatDistanceStrict(timeAndDateOfNotification, now)

  return (
    <Flex
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        pr: '1.2rem',
        color: 'aliceBlue60',
      }}
    >
      <Text
        variant="size14Weight600Line138Letter056"
        sx={{ color: isOpen ? 'aliceBlue' : 'aliceBlue60' }}
      >
        {text}
      </Text>
      <Text
        variant="size12Weight400Line138Letter048"
        sx={{
          color: 'aliceBlue60',
        }}
      >
        {distanceFromNotificationTimeToNow} ago
      </Text>
    </Flex>
  )
}
