import React, { ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import { QueriesFilterMenuType } from '../FiltersContainer'

export enum RTYPE {
  A = 'A',
  AAAA = 'AAAA',
  CNAME = 'CNAME',
  HTTPS = 'HTTPS',
  MX = 'MX',
  PTR = 'PTR',
  SOA = 'SOA',
  SRV = 'SRV',
}

const rTypes = [
  RTYPE.A,
  RTYPE.AAAA,
  RTYPE.CNAME,
  RTYPE.HTTPS,
  RTYPE.MX,
  RTYPE.PTR,
  RTYPE.SOA,
  RTYPE.SRV,
]

export default function TypesDropdown(): ReactElement {
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)

  const selectedType =
    rTypes?.find(type => filterParameters[QueriesFilterMenuType.RTYPE] === type) || 'RTYPEs'

  return (
    <QueryFilterDropdown selectedValue={selectedType} type={QueriesFilterMenuType.RTYPE}>
      <QueryFilterDropdownContent
        type={QueriesFilterMenuType.RTYPE}
        types={rTypes || []}
        isSearchVisible={false}
        maxHeight="29rem"
      />
    </QueryFilterDropdown>
  )
}
