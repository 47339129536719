import noop from 'lodash/noop'
import React, { ReactElement, ReactNode, forwardRef } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

type CircleIconWrapperProps = {
  children: ReactNode
  sx?: ThemeUIStyleObject
  isActive?: boolean
  onClick?: () => void
  tabIndex?: number
  onKeyPress?: () => void
}

export default forwardRef<HTMLElement, CircleIconWrapperProps>(function CircleIconWrapper(
  { children, sx, onClick = noop, isActive, ...rest },
  ref,
): ReactElement {
  return (
    <Flex
      ref={ref}
      onClick={onClick}
      sx={{
        width: ['fit-content', '4.8rem'],
        height: ['fit-content', '4.8rem'],
        borderRadius: '100%',
        backgroundColor: ['transparent', isActive ? 'white25' : 'white10'],
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        '&:hover': {
          backgroundColor: ['transparent', 'white25'],
          color: 'white',
          '& > button > svg > path': {
            fill: 'white',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Flex>
  )
})
