import {
  ActionReducerMapBuilder,
  AsyncThunk,
  Draft,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { ApiResponse } from 'store/api/http'
export enum LoadingState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export interface ResponseState<T = null> {
  loading: LoadingState
  data: T | null
  error?: SerializedError
  message?: string
}

// https://gitlab.int.windscribe.com/controld/core/-/wikis/API-Spec#response-conventions
export const initialResponseState: ResponseState = {
  loading: LoadingState.IDLE,
  data: null,
  message: '', // optional
}

// the "states" below are immer drafts
export function fetchPending<T>(state: ResponseState<T>): void {
  state.loading = LoadingState.PENDING
}
export function fetchFulfilled<T>(
  state: ResponseState<T>,
  { payload }: PayloadAction<ApiResponse<T>>,
): void {
  state.loading = LoadingState.FULFILLED
  state.data = payload.body
  state.error = payload.error as SerializedError
  state.message = payload.message
}

export function fetchRejected<T>(state: ResponseState<T>, action: unknown): void {
  state.error = (action as { error: SerializedError }).error
  state.loading = LoadingState.REJECTED
}

export function getDataFetchingReducers<
  StateDataType,
  SliceStateType,
  ThunkArgType,
  AsyncThunkConfig,
>({
  builder,
  stateField,
  thunk,
  additionalFulFilledHandling,
  additionalRejectedHandling,
}: {
  builder: ActionReducerMapBuilder<SliceStateType>
  stateField: keyof SliceStateType
  /** This type error is most probably because of changes in types provided by RTK in the newer version of the library
   * Since we are plannning to move away from this construct and use RTKQuery instead, maybe better to just upgrade to
   * get rid of this error?
   */
  // @ts-ignore
  thunk: AsyncThunk<ApiResponse<StateDataType>, ThunkArgType, AsyncThunkConfig>
  additionalFulFilledHandling?: (
    state: Draft<SliceStateType>,
    action: PayloadAction<ApiResponse<StateDataType>>,
  ) => void
  additionalRejectedHandling?: (state: Draft<SliceStateType>, action) => void
}): void {
  const stateFieldString = stateField as string
  builder
    .addCase(thunk.pending, state => fetchPending(state[stateFieldString]))
    .addCase(thunk.fulfilled, (state, action) => {
      fetchFulfilled(state[stateFieldString], action)
      additionalFulFilledHandling?.(state, action)
    })
    .addCase(thunk.rejected, (state, action) => {
      fetchRejected(state[stateFieldString], action)
      additionalRejectedHandling?.(state, action)
    })
}

export function getFulfilledBody<T>({ payload }: PayloadAction<ApiResponse<T> | unknown>): T {
  return (payload as ApiResponse<T>).body
}
