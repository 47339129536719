import React, { createRef, ReactElement, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { Flex } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import DashboardAlerts from 'components/Dashboard/DashboardAlerts'
import NavMenuMobile from 'components/Dashboard/NavBar/NavMenu.mobile'
import NavBarContent from 'components/Dashboard/NavBar/NavBarContent'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { AlertContextProvider, TrayPresenter, TrayRenderProps, useTrayPresenter } from 'ui'
import useTutorialSlides from 'components/Dashboard/utils/useTutorialSlides'
import useQueryString from 'utils/useQueryString'
import { useLocation, useMatch } from '@reach/router'
import useMaintenanceMode from 'components/Dashboard/utils/useMaintenanceMode'
import useRedirects from 'utils/useRedirects'
import { useGetNotificationsQuery } from 'store/api/notifications/notifications'
import ConfirmEmailScreen from 'components/Organization/ConfirmEmailScreen'
import TwoFaModalDialog from 'components/Organization/TwoFa/TwoFaModalDialog'
import OrganizationNavBarContent, {
  OrganizationNavRelativePath,
} from 'components/Organization/OrganizationNavBar/OrganizationNavBarContent'
import { useTraysVisibility } from 'ui/Tray/TrayContextProvider'
import { organizationApi } from 'store/api/organization'
import { provisionApi } from 'store/api/provision'
import { devicesApi } from 'store/api/devices'
import { userApi } from 'store/api/user'
import useGetUserState from 'store/api/user/useGetUserState'
import { profilesApi, useGetProfilesQuery } from 'store/api/profiles'
import { useSuccessfulRegistrationTracking } from 'utils/campaignTracking'
import DashboardTutorial from 'components/Dashboard/Tutorial/DashboardTutorial'
import NavBar from 'components/Dashboard/NavBar'
import { useGetStatEndpointsQuery } from 'store/api/analytics'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { reportsApi } from 'store/api/reports'
import { useDetectConfiguredDevice } from 'components/Dashboard/utils/useDetectConfiguredDevice'
import NotificationsDialog from 'components/Dashboard/NavBar/NotificationsDialog'
import SetupGuide from 'components/SetupGuide/SetupGuide'
import { useIsTwoFaVisible } from 'utils/useIsTwoFaVisible'
import BarryDialog from 'components/Barry/BarryDialog'
import useGetColorMode from 'utils/useGetColorMode'
const DashboardPages = React.lazy(() => import('components/Dashboard/DashboardPages'))

const paddingRight = ['3.2rem', '2.4rem', '2.4rem']
export const scrollingContainerRef = createRef<HTMLDivElement>()
export const trayRef = createRef<HTMLDivElement>()

export default function Dashboard(): ReactElement {
  const dispatch = useAppDispatch()
  const userPk = useAppSelector(s => s.session.userPk)
  const { qs } = useQueryString()
  const resizeableAlertContainerRef = useRef<HTMLDivElement>(null)
  const breakpoints = useBreakpointIndex()
  const isMobile = breakpoints === 0
  const location = useLocation()
  const { currentTutorial } = useTutorialSlides()
  const isSetupGuideVisible = useAppSelector(s => !!s.persistData.sessionToken) && qs.deviceId
  const { data: userData } = useGetUser({ refetchOnMountOrArgChange: true })
  const { isOrganization } = useGetUserState()
  const isConfirmEmailVisible = !!userData?.org?.PK && !userData?.email_status
  const isInMaintenanceMode = useMatch('maintenance-mode')
  const is404Page = useMatch('404')
  const { isLightMode } = useGetColorMode()

  useSuccessfulRegistrationTracking()

  const isTwoFaVisible = useIsTwoFaVisible()
  const { isAnyTrayOpen } = useTraysVisibility()
  const isMobileNavMenuOpen = useAppSelector(s => s.dashboard.isMobileNavMenuOpen)
  const isModalDialogVisible = useAppSelector(s => s.dashboard.isModalDialogVisible)
  const isMobileTrayVisible = useAppSelector(s => s.dashboard.isMobileTrayVisible)

  const shouldHideDashboardAlert =
    isSetupGuideVisible ||
    qs.profileDialog ||
    qs.deviceDialog ||
    qs.ctrldDeviceId ||
    qs.subOrgDialog ||
    qs.provisionDialog ||
    qs.billingDialog ||
    qs.reportsDialog ||
    isModalDialogVisible ||
    isMobileTrayVisible ||
    qs.memberModal

  //handle redirects from old paths
  useRedirects()

  useMaintenanceMode()

  useGetNotificationsQuery('', { skip: !userPk })
  const { startPollingUser } = useGetUserState()
  useGetProfilesQuery('')
  useGetStatEndpointsQuery('', {
    skip: !userPk,
    refetchOnMountOrArgChange: true,
  })

  useDetectConfiguredDevice()

  /** This effect invalidates the org data in the store and forces a refetch
   * when the orgId in the query string changes due to a sub-org being impersonated.
   * When a sub-org ends impersonation, the whole api state is reset in the
   * Exit impersonation button.
   * Invalidation of org tags should be done before rendering to get rid of cached
   * org data in child components.
   */
  useLayoutEffect(() => {
    if (isOrganization) {
      dispatch(organizationApi.util.invalidateTags(['Organization', 'Member']))
      dispatch(provisionApi.util.invalidateTags(['Provision']))
      dispatch(devicesApi.util.invalidateTags(['Devices']))
      dispatch(profilesApi.util.invalidateTags(['Profiles', 'ProfileOptions']))
      dispatch(reportsApi.util.invalidateTags(['Reports']))
      dispatch(userApi.endpoints.getUser.initiate('', { forceRefetch: true }))
    }
  }, [dispatch, qs.orgId, isOrganization])

  useEffect(() => {
    if (isConfirmEmailVisible && userData && !isTwoFaVisible) {
      startPollingUser(true)
    }

    return () => {
      startPollingUser(false)
    }
  }, [userData, isConfirmEmailVisible, startPollingUser, isTwoFaVisible])

  useEffect(() => {
    dispatch(userApi.endpoints.learnIpv4.initiate(''))
  }, [dispatch])

  const navMobileTray = useMemo(
    () => ({
      id: 'NavMobileTray',
      renderTray: (props: TrayRenderProps) => (
        <NavMenuMobile {...props}>
          {isOrganization ? <OrganizationNavBarContent /> : <NavBarContent />}
        </NavMenuMobile>
      ),
      zIndexName: 'zIndex550',
    }),
    [isOrganization],
  )
  const { showTray: showMobileTray, hideTray: hideMobileTray } = useTrayPresenter(navMobileTray)

  useEffect(() => {
    isMobileNavMenuOpen ? showMobileTray() : hideMobileTray()
  }, [hideMobileTray, isMobileNavMenuOpen, showMobileTray])

  return (
    <Flex
      ref={scrollingContainerRef}
      data-testid="dashboard-scrolling-container"
      sx={{
        position: 'fixed',
        width: '100vw',
        height: '100%',
        backgroundColor: 'blue800',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        overflowX: 'hidden',
        fontFamily: 'body',
        // scrolling container should not affect the tray
        overflowY: isAnyTrayOpen || isMobileNavMenuOpen ? 'hidden' : 'scroll',
      }}
      className={`${isLightMode ? 'light' : 'dark'} show-scrollbar`}
    >
      {isConfirmEmailVisible &&
        !location.pathname.includes(OrganizationNavRelativePath.ACCOUNT) && <ConfirmEmailScreen />}
      {isTwoFaVisible && <TwoFaModalDialog />}
      <Flex
        sx={{
          maxWidth: '1512px',
          width: '100%',
          height: '100%',
          opacity: breakpoints === -1 ? 0 : 1,
        }}
      >
        {!isMobile && !is404Page && !isInMaintenanceMode && <NavBar />}
        <Flex
          sx={{
            position: 'relative',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DashboardPages />
          {!shouldHideDashboardAlert && (
            <Flex
              ref={resizeableAlertContainerRef}
              sx={{
                position: 'absolute',
                left: [`calc(${paddingRight[breakpoints]} / 2)`, 0],
                width: `calc(100% - ${paddingRight[breakpoints]})`,
                height: '100%',
                pointerEvents: 'none',
              }}
            >
              <DashboardAlerts resizeableAlertContainerRef={resizeableAlertContainerRef} />
            </Flex>
          )}
        </Flex>
      </Flex>

      {isSetupGuideVisible && (
        <AlertContextProvider>
          <SetupGuide />
        </AlertContextProvider>
      )}

      <TrayPresenter ref={trayRef} />
      {!isConfirmEmailVisible && !isTwoFaVisible && (
        <DashboardTutorial tutorialType={currentTutorial} />
      )}
      <NotificationsDialog />

      <BarryDialog />
    </Flex>
  )
}
