import React, { ReactElement } from 'react'
import range from 'lodash/range'
import { Box, Flex } from 'theme-ui'

export default function Dots(): ReactElement {
  return (
    <Flex>
      {range(9).map(i => (
        <Box
          key={i}
          sx={{
            borderRadius: '50%',
            backgroundColor: 'aliceBlue60',
            width: '0.6rem',
            height: '0.6rem',
            mr: '0.4rem',
            transition: 'width 0.3s',
            flexShrink: 0,
          }}
        />
      ))}
    </Flex>
  )
}
