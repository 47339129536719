import { useEffect } from 'react'
import useQueryString from 'utils/useQueryString'

export default function useCaptureCtrlOrCmdFClick(handler: () => void): void {
  const { qs } = useQueryString()

  useEffect(() => {
    const handleCtrlOrCmdFClick = e => {
      // Don't want to mess with users ctrl+f when these overlays/trays are open
      const isOverlayOrTutorialOpen = !!qs.helpPane || !!qs.tutorial || !!qs.tray
      if (
        !isOverlayOrTutorialOpen &&
        ((e.metaKey && e.key === 'f') || (e.ctrlKey && e.key === 'f'))
      ) {
        e.preventDefault()
        handler()
      }
    }

    window.addEventListener('keydown', handleCtrlOrCmdFClick)

    return () => {
      window.removeEventListener('keydown', handleCtrlOrCmdFClick)
    }
  }, [qs.helpPane, qs.tutorial, qs.tray, handler])
}
