import React, { ReactElement, useEffect, useState } from 'react'
import FocusLock from 'react-focus-lock'
import { TrayProps, EntryDirection } from './Tray.interface'
import { Box } from 'theme-ui'
import './Tray.scss'
import useQueryString from 'utils/useQueryString'
import { useTraysVisibility } from './TrayContextProvider'
import { useHotkeys } from 'react-hotkeys-hook'
import omit from 'lodash/omit'
import { trayMargin } from 'ui/Tray/TrayPresenter'
import useOnClickOutside from 'utils/useOnClickOutside'

const propsForSlideTransition = (
  isOpen: boolean,
  slideDimensionLength: string | number | Array<string> | Array<number>,
  entersFrom: EntryDirection,
): Record<string, unknown> => {
  const heightDifference = `${trayMargin * 2}px`

  switch (entersFrom) {
    case 'right':
      return {
        width: slideDimensionLength,
        height: ['100%', `calc(100% - ${heightDifference})`],
        right: '0px',
        top: '0px',
        transform: isOpen ? null : 'translate3d(100%, 0, 0)',
      }
    case 'left':
      return {
        width: slideDimensionLength,
        height: ['100%', `calc(100% - ${heightDifference})`],
        left: '0px',
        top: '0px',
        transform: isOpen ? null : 'translate3d(-100%, 0, 0)',
      }
    case 'top':
      return {
        width: '100%',
        height: ['100%', `calc(${slideDimensionLength}-${heightDifference})`],
        left: '0px',
        top: '0px',
        transform: isOpen ? null : 'translate3d(0, -100%, 0)',
      }
    case 'bottom':
      return {
        width: '100%',
        height: ['100%', `calc(${slideDimensionLength}-${heightDifference})`],
        left: '0px',
        bottom: '0px',
        transform: isOpen ? null : 'translate3d(0, 100%, 0)',
      }
  }
}

export default function Tray({
  entersFrom = 'right',
  dismiss = (): void => {},
  backgroundOverlayWhenOpen = true,
  slideDimensionLength = ['100%', '46rem'],
  isOpen,
  children,
  sx,
  zIndexName,
  dataTestId = 'tray-body',
  shouldVibrateOnPresent = false,
  showOverlay = true,
  overlayContent,
  trayRef,
  disableFocusLock,
}: TrayProps): ReactElement {
  const { qs, nav } = useQueryString()
  const { isAnyTrayOpen } = useTraysVisibility()
  const [isFocusLockDisabled, setIsDisabledFocusLock] = useState(true)

  useOnClickOutside([], () => setIsDisabledFocusLock(true))

  useHotkeys(
    'Tab',
    () => {
      setIsDisabledFocusLock(false)
    },
    [],
  )

  useHotkeys(
    'Escape',
    () => {
      const queryKeys = Object.keys(qs)

      !isAnyTrayOpen && queryKeys.length === 1 && queryKeys[0] === 'tray' && dismiss()

      if (!isAnyTrayOpen) {
        nav({ ...omit(qs, ['overlay', 'option']) })
      }
    },
    [qs],
  )

  useEffect(() => {
    if (shouldVibrateOnPresent && isOpen && window.navigator.vibrate) {
      try {
        window.navigator.vibrate(10)
      } catch (e) {}
    }
    // we only want to trigger this effect when `isOpen` changes, not when
    // `shouldVibrateOnPresent` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      {showOverlay && (
        <Box
          onClick={(): void => dismiss()}
          sx={{
            // @ts-ignore
            display: sx?.display || 'inherit',
            visibility: isOpen ? 'inherit' : 'hidden',
            transition: 'opacity 0.2s ease',
            backgroundColor: 'black',
            opacity: isOpen ? '0.9' : '0',
            width: backgroundOverlayWhenOpen ? '100%' : 0,
            height: backgroundOverlayWhenOpen ? '100%' : 0,
            position: 'fixed',
            // @ts-ignore
            left: sx?.left || '0px',
            top: '0px',
            zIndex: backgroundOverlayWhenOpen ? zIndexName ?? 'zIndex249' : -1000,
          }}
        >
          {overlayContent}
        </Box>
      )}
      <FocusLock
        returnFocus={true}
        autoFocus={false}
        disabled={disableFocusLock || isFocusLockDisabled || !isOpen}
      >
        <Box
          tabIndex={-1}
          ref={trayRef}
          className={`controld-tray ${isOpen ? 'transition' : ''}`}
          data-testid={dataTestId}
          aria-live="polite"
          aria-hidden={!isOpen}
          sx={{
            visibility: isOpen ? 'visible' : 'hidden',
            willChange: 'transform',
            overflowX: 'hidden',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: 'dialogBodyBg',
            position: 'fixed',
            zIndex: zIndexName ?? 'zIndex275',
            overflowY: ['hidden', 'auto'],
            ...propsForSlideTransition(isOpen, slideDimensionLength, entersFrom),
            ...sx,
          }}
        >
          {children}
        </Box>
      </FocusLock>
    </>
  )
}
