import React, { ReactElement, useMemo, useRef } from 'react'
import { SimpleActionMenuItemProps, SimpleDropdown, Svg } from 'ui'
import useQueryString from 'utils/useQueryString'
import { Flex } from '@theme-ui/components'
import { useAppDispatch } from 'store/hooks'
import { standardConfigurationsData } from 'components/FreeDNSPage/constants/freeDNS'
import { removeSelectedFiltersPK, setSelectedDNSKey } from 'store/freeDNS'
import CheckIcon from 'images/analytics/check.svg'

const StandardConfigurationsDropdown = (): ReactElement => {
  const { qs, nav } = useQueryString()
  const dispatch = useAppDispatch()

  const selectedConfig = standardConfigurationsData.find(x => x.key === qs.freeResolverType)

  const hideRef = useRef<() => void>()

  const options: SimpleActionMenuItemProps[] = useMemo(() => {
    return standardConfigurationsData.map(config => ({
      isSelected: selectedConfig?.key === config.key,
      isBackgroundHighlighted: true,
      searchBy: config.title,
      buttonContent: config.title,
      ariaLabel: `${config.title} option`,
      children: (
        <Flex
          data-testid={`standard-config-menu-item-${config.key}`}
          sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Flex
            sx={{
              height: '100%',
              alignItems: 'center',
              fontSize: '1.6rem',
              fontWeight: 'bold',
            }}
          >
            {config.title}
          </Flex>
          {selectedConfig?.key === config.key && (
            <Svg svg={CheckIcon} sx={{ flexShrink: 0, fill: 'aliceBlue', opacity: 0.5 }} />
          )}
        </Flex>
      ),
      onClick: () => {
        dispatch(setSelectedDNSKey(config.key))
        dispatch(removeSelectedFiltersPK())

        nav({
          ...qs,
          freeResolverType: config.key,
        })
        hideRef.current?.()
      },
    }))
  }, [selectedConfig?.key, dispatch, nav, qs])

  return (
    <SimpleDropdown
      testId="standard-config-select-dropdown"
      ariaLabel="standard config select dropdown"
      items={options}
      hideRef={hideRef}
    />
  )
}

export default StandardConfigurationsDropdown
