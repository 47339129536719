import React, { ReactElement, useState } from 'react'
import { Flex, Image, Spinner, Text } from '@theme-ui/components'
import ErrorBox from 'components/LoginSignUpForm/ErrorBox'
import { Button } from 'ui'
import type { Setter } from 'utils'

interface QrCodeProps {
  url: string
  isQrCodeLoading: boolean
  setIsQrCode: Setter<boolean>
  setIsQrCodeLoading: Setter<boolean>
  text?: string
}

export default function QrCode({
  url,
  isQrCodeLoading,
  setIsQrCode,
  setIsQrCodeLoading,
  text,
}: QrCodeProps): ReactElement {
  const [error, setError] = useState<string>('')

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        px: '4rem',
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      {text && (
        <Text variant="size14Weight400Line138" sx={{ color: 'aliceBlue', my: '4rem' }}>
          {text}
        </Text>
      )}
      {error ? (
        <ErrorBox errorMessage={error} sx={{ mb: ['2.4rem', '4.8rem'] }} />
      ) : (
        <Flex
          sx={{
            width: ['17rem', '26rem'],
            height: ['17rem', '26rem'],
            mb: ['2.4rem', '4.8rem'],
            mt: ['-1.8rem', '-3.2rem'],
            alignItems: 'center',
            justifyContent: 'center',
            bg: 'white',
            borderRadius: '2.4rem',
          }}
        >
          {isQrCodeLoading && <Spinner />}
          <Image
            data-testid="qr-code"
            sx={{
              width: ['15.4rem', '24.4rem'],
              height: ['15.4rem', '24.4rem'],
              flexShrink: 0,
              display: isQrCodeLoading ? 'none' : 'block',
            }}
            src={url}
            onLoad={() => {
              setError('')
              setIsQrCodeLoading(false)
            }}
            onError={() => {
              setIsQrCodeLoading(false)
              setError('Failed to generate QR code. Please try again.')
            }}
          />
        </Flex>
      )}
      <Button
        ariaLabel="back button"
        variant="newSecondary"
        onClick={() => {
          setIsQrCode(false)
          setError('')
        }}
        sx={{
          width: 'fit-content',
        }}
      >
        Back
      </Button>
    </Flex>
  )
}
