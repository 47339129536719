import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit'
import { fetchRejected, initialResponseState, LoadingState, ResponseState } from '../fetchingLogic'
import { twoFaApi, TwofaData } from 'store/api/twofa/twofa'
import { Setter } from 'utils'
import { userApi } from 'store/api/user'

export enum TwoFactorStep {
  PASSWORD_CHECK,
  SCAN_OR_ENTER_CODE,
  ENABLE_TWOFA,
}

export enum TwoFactorStatus {
  PENDING_VERIFICATION = -1,
  DISABLED = 0,
  ENABLED = 1,
}

interface ToggleTwofaFormArgs {
  password: string
  closeEditing: () => void
  setTwofaStep: (step: TwoFactorStep) => void | Setter<TwoFactorStep>
  sessionTwofa?: TwoFactorStatus
}

export const toggleTwoFa = createAsyncThunk(
  'twofa/toggle',
  async (
    { password, setTwofaStep, closeEditing, sessionTwofa }: ToggleTwofaFormArgs,
    { dispatch },
  ) => {
    if (sessionTwofa === TwoFactorStatus.ENABLED) {
      const response = await dispatch(
        twoFaApi.endpoints.deleteTwofa.initiate({
          current_password: password,
        }),
      )
      await dispatch(userApi.endpoints.getUser.initiate(''))
      closeEditing()
      return response
    } else {
      const response = (await dispatch(
        twoFaApi.endpoints.postTwofa.initiate({
          current_password: password,
        }),
      )) as { data: TwofaData; error: SerializedError }
      if (!response.error) {
        setTwofaStep(TwoFactorStep.SCAN_OR_ENTER_CODE)
      }
      return response
    }
  },
)

interface AddTwofaCodeArgs {
  code: string
  closeEditing: () => void
  setTwofaStep: (step: TwoFactorStep) => void
}
export const addCode = createAsyncThunk(
  'twofa/addCode',
  async ({ code, closeEditing, setTwofaStep }: AddTwofaCodeArgs, { dispatch }) => {
    const response = await dispatch(twoFaApi.endpoints.putTwofa.initiate({ code }))
    await dispatch(userApi.endpoints.getUser.initiate('', { forceRefetch: true }))
    closeEditing()
    setTwofaStep(TwoFactorStep.PASSWORD_CHECK)
    return response
  },
)

const initialState: ResponseState<TwofaData> = {
  ...initialResponseState,
  data: { twofa: { secret: '', qr: '' } },
}
export const twofaSlice = createSlice({
  name: 'twofa',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(toggleTwoFa.pending, state => {
        state.loading = LoadingState.PENDING
      })
      .addCase(toggleTwoFa.fulfilled, (state, action) => {
        state.loading = LoadingState.FULFILLED

        state.data = (action.payload as { data: TwofaData }).data
      })
      .addCase(toggleTwoFa.rejected, fetchRejected)
      .addCase(addCode.pending, state => {
        state.loading = LoadingState.PENDING
      })
      .addCase(addCode.fulfilled, state => {
        state.loading = LoadingState.FULFILLED
      })
      .addCase(addCode.rejected, fetchRejected)
  },
})
