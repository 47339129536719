import { MutableRefObject, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

const useDropdownKeyboardControl = (
  itemsLength: number,
  isSearchDropdownVisible: boolean,
  hideRef?: MutableRefObject<(() => void) | undefined>,
  isOpen?: boolean,
): number => {
  const [defaultHoverIndex, setDefaultHoverIndex] = useState(-1)

  useEffect(() => {
    const handleKeydown = () => setDefaultHoverIndex(-1)

    window.addEventListener('mousemove', handleKeydown)

    return (): void => {
      window.removeEventListener('mousemove', handleKeydown)
    }
  }, [])

  useHotkeys(
    'up',
    e => {
      if (defaultHoverIndex) {
        e.preventDefault()
        setDefaultHoverIndex(defaultHoverIndex - 1)
      }
    },
    { ignoreEventWhen: () => isSearchDropdownVisible || !isOpen },
    [isSearchDropdownVisible, defaultHoverIndex],
  )

  useHotkeys(
    ['down', 'Tab'],
    e => {
      if (e.key !== 'Tab') {
        e.preventDefault()
      }

      if (itemsLength - 1 > defaultHoverIndex) {
        setDefaultHoverIndex(defaultHoverIndex + 1)
      }
    },
    { ignoreEventWhen: () => isSearchDropdownVisible || !isOpen },
    [defaultHoverIndex, isSearchDropdownVisible],
  )

  useHotkeys(
    'Escape',
    e => {
      e.preventDefault()
      hideRef?.current?.()
    },
    [defaultHoverIndex],
  )

  return defaultHoverIndex
}

export default useDropdownKeyboardControl
