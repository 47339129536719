import { ApiResponse } from 'store/api/http'
import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { GetTokensResponse, GetTokenTypesResponse, TokenBody, Token } from './token.interface'

export const tokenApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTokens: builder.query({
      query: () => getQueryArgs('/token'),
      transformResponse: (response: GetTokensResponse) => response.body,
      transformErrorResponse,
      providesTags: () => ['Token'],
    }),
    getTokenTypes: builder.query({
      query: () => getQueryArgs('/token/types'),
      transformResponse: (response: GetTokenTypesResponse) => response.body,
      transformErrorResponse,
    }),
    createToken: builder.mutation({
      query: (body: TokenBody) => getQueryArgs('/token', 'POST', body),
      transformResponse: (response: ApiResponse<{ tokens: Token }>) => response.body,
      transformErrorResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            tokenApi.util.updateQueryData('getTokens', '', draft => {
              draft.tokens = [...draft.tokens, data.tokens]
            }),
          )
        } catch {}
      },
    }),
    removeToken: builder.mutation({
      query: (tokenId: string) => getQueryArgs(`/token/${tokenId}`, 'DELETE'),
      transformErrorResponse,
      async onQueryStarted(tokenId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            tokenApi.util.updateQueryData('getTokens', '', draft => {
              draft.tokens = draft.tokens.filter(token => token.PK !== tokenId)
            }),
          )
        } catch {}
      },
    }),
  }),
})

export const { useGetTokensQuery, useGetTokenTypesQuery, useCreateTokenMutation } = tokenApi
