import { MutableRefObject, useEffect } from 'react'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

/*
  Transitionend event does not always work correctly on some mobile devices.
  Small delay helps with keyboard opening on mobile devices issue.
 */
export default function useMobileInputFocus(
  inputRef: MutableRefObject<HTMLInputElement | null>,
  focusConditions = true,
): void {
  const isMobile = useBreakpointIndex() < 1

  useEffect(() => {
    let timerId

    if (!inputRef.current) {
      return
    }

    if (!isMobile && focusConditions) {
      return inputRef.current?.focus()
    }

    if (focusConditions) {
      timerId = setTimeout(() => {
        inputRef.current?.focus()
      }, 500)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [focusConditions, inputRef, isMobile])
}
