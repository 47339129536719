import React, { ReactElement, ReactNode } from 'react'
import type { ThemeUICSSObject } from 'theme-ui'
import { Button } from 'ui/Buttons'
import { useAppSelector } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import { sharedButtonStyles } from 'components/SetupGuide/Components/ProfileDownloadButton'

interface GenerateQrCodeButtonProps {
  children: ReactNode
  disabled?: boolean
  sx?: ThemeUICSSObject
  onClick: () => void
}

export default function GenerateQrCodeButton({
  onClick,
  children,
  disabled = false,
  sx,
}: GenerateQrCodeButtonProps): ReactElement {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { qs } = useQueryString()
  const selectedDevicePk = qs.deviceId ?? qs.resolverUid

  return (
    <Button
      ariaLabel="generate QR code button"
      onClick={onClick}
      onKeyDown={stopPropagationOnKeyPress}
      variant="newSecondary"
      sx={{ ...sharedButtonStyles, ...sx }}
      disabled={disabled || (!selectedDevicePk && !!sessionToken && !qs.freeResolverType)}
    >
      {children}
    </Button>
  )
}
