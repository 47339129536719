import { useState, useCallback } from 'react'

interface UseCopyToClipboardReturn {
  isCopied: boolean
  copyToClipboard: () => void
  resetCopiedState: () => void
}

export default function useCopyToClipboard(text?: string): UseCopyToClipboardReturn {
  const [isCopied, setIsCopied] = useState(false)
  const resetCopiedState = useCallback(() => {
    setIsCopied(false)
  }, [setIsCopied])

  const copyToClipboard = useCallback((): void => {
    if (!text) {
      return
    }
    if (typeof window !== 'undefined') {
      if (!navigator.userAgent.includes('BingSapphire')) {
        window.navigator.clipboard?.writeText(text).then(() => setIsCopied(true))
      } else {
        // Workaround for Bing app that doesn't support regular copy to clipboard functionality
        const textarea = document.createElement('textarea')
        textarea.textContent = text
        const container =
          document.querySelector('[data-focus-lock-disabled="false"]') || document.body
        container.appendChild(textarea)
        textarea.focus()
        textarea.select()
        const isCopied = document.execCommand('copy')
        container.removeChild(textarea)
        if (isCopied) {
          setIsCopied(true)
        }
      }
    }
  }, [text])

  return { isCopied, copyToClipboard, resetCopiedState }
}
