import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import { Text } from '@theme-ui/components'
import CopyToClipboard from 'components/CopyToClipboard'
import { BASE_URL, DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { useAppSelector } from 'store/hooks'
import { useGetProvisionQuery } from 'store/api/provision'
import { Button, ExternalLink } from 'ui'
import { downloadFile } from 'utils/downloadFile'
import DividerWithText from 'ui/DividerWithText'
import { NumberBadge } from 'components/SetupGuide/Components/Automatic/AutomaticProfileDownload'

export function InstructionsView({
  resolverId,
  deviceType,
}: {
  resolverId?: string
  deviceType?: string
}): ReactElement {
  const selectProvisionPk = useAppSelector(s => s.organization.selectProvisionPk || '')
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: provisionData } = useGetProvisionQuery('', {
    skip: !!(resolverId && deviceType) || !sessionToken,
  })
  const provision = provisionData?.provisions.find(provision => provision.PK === selectProvisionPk)
  const isWindows = provision?.icon?.includes('windows') || deviceType?.includes('windows')

  const cmd =
    provision?.ctrld.cmd ||
    (isWindows
      ? `powershell -Command "(Invoke-WebRequest -Uri '${BASE_URL}/dl' -UseBasicParsing).Content | Set-Content '%TEMP%\\ctrld_install.bat'" && %TEMP%\\ctrld_install.bat ${resolverId} forced`
      : `sh -c 'sh -c "$(curl -sSL ${BASE_URL}/dl)" -s ${resolverId} forced'`)
  const startServiceCurl = `${isWindows ? 'ctrld.exe' : 'ctrld'} start ${
    resolverId ? '--cd=' : '--cd-org '
  }${resolverId}`

  return (
    <Flex
      sx={{
        width: ['100%', '48rem'],
        p: '4rem',
        flexDirection: 'column',
        gap: '3.2rem',
        alignItems: 'center',
        textAlign: 'center',
        maxHeight: [`calc(100% - ${trayHeaderHeight})`, '100%', '100%'],
        overflowY: 'auto',
      }}
      className="show-scrollbar"
    >
      <Text variant="size14Weight400Line138" sx={{ color: 'aliceBlue', maxWidth: '29.3rem' }}>
        Execute the following installer command in your favorite RMM.
      </Text>

      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2.4rem',
          px: ['1.6rem', 0],
        }}
      >
        {!provision?.ctrld.cmd && (
          <Text variant="size14Weight400Line138" sx={{ color: 'aliceBlue' }}>
            {isWindows ? 'Windows' : 'MacOS/ Linux Endpoints'}
          </Text>
        )}

        <Flex
          data-testid="terminal-container"
          sx={{
            width: ['100%', '39rem'],
            backgroundColor: 'white6',
            p: '1.6rem',
            borderRadius: '1.2rem',
            textAlign: 'left',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Text variant="size12Weight500" sx={{ color: 'aliceBlue' }}>
            {cmd}
          </Text>
          <CopyToClipboard smallIcon data={cmd} fillSvg="aliceBlue60" />
        </Flex>
      </Flex>

      {provision?.ctrld.cmd && (
        <>
          <DividerWithText text="OR" />

          <Text
            variant="size14Weight400Line138"
            sx={{
              maxWidth: '29.3rem',
              color: 'aliceBlue',
            }}
          >
            Or download the self-contained install script and deploy it.
          </Text>

          <Button
            data-testid="download-install"
            variant="newSecondary"
            ariaLabel="download script"
            sx={{
              width: 'fit-content',
              height: '3.8rem',
              border: 'none',
              px: '2.4rem',
            }}
            onClick={() => {
              if (provision?.ctrld) {
                downloadFile({
                  content: decodeURIComponent(provision.ctrld.script),
                  fileTitle: provision.ctrld.filename,
                  fileType: 'text/plain',
                })
              }
            }}
          >
            Download Install Script
          </Button>
        </>
      )}

      <DividerWithText text="OR" />

      <Text
        variant="size14Weight400Line138"
        sx={{
          maxWidth: '29.3rem',
          color: 'aliceBlue',
        }}
      >
        {!provision?.ctrld.cmd
          ? 'Alternatively, you can avoid using the installer, download and run the binaries manually.'
          : 'Alternatively, download and deploy the binary manually.'}
      </Text>

      {!provision?.ctrld.cmd && (
        <>
          <Text
            variant="size14Weight400Line138"
            sx={{
              display: 'inline-flex',
              maxWidth: '29.3rem',
              color: 'aliceBlue',
              alignItems: 'center',
              textAlign: 'left',
            }}
          >
            <NumberBadge value={1} />
            <Text sx={{ width: 'fit-content', ml: '1.2rem' }}>
              Download the appropriate binary for target architecture{' '}
              <ExternalLink
                data-testid="download-manually"
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/ctrld#download-manually`}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                here
              </ExternalLink>
            </Text>
          </Text>

          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              gap: '2.4rem',
              alignItems: 'center',
              px: ['1.6rem', 0],
            }}
          >
            <Text
              variant="size14Weight400Line138"
              sx={{
                maxWidth: '49.8rem',
                display: 'inline-flex',
                color: 'aliceBlue',
                gap: '1.2rem',
                alignItems: 'center',
              }}
            >
              <NumberBadge value={2} /> Start the service
            </Text>

            <Flex
              data-testid="terminal-container"
              sx={{
                width: ['100%', '39rem'],
                backgroundColor: 'white6',
                p: '1.6rem',
                borderRadius: '1.2rem',
                textAlign: 'left',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text variant="size12Weight500" sx={{ color: 'aliceBlue' }}>
                {startServiceCurl}
              </Text>
              <CopyToClipboard smallIcon data={startServiceCurl} fillSvg="aliceBlue60" />
            </Flex>
          </Flex>
        </>
      )}
      <Button
        variant="newSecondary"
        ariaLabel="open provisioning doc"
        sx={{
          width: 'fit-content',
          border: 'none',
          height: '3.8rem',
          px: '2.4rem',
        }}
        onClick={() => {
          window.open(
            `${DOCS_CONTROL_D_DOMAIN}/docs/provisioning`,
            '_blank',
            'noopener, noreferrer',
          )
        }}
      >
        View Documentation
      </Button>
    </Flex>
  )
}
