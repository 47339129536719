import React, { forwardRef, ReactElement, useEffect, useState } from 'react'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { useTrayPresenterState, Tray, TrayInfo } from 'ui'
import { Box } from 'theme-ui'
import { useForClosingTray } from 'utils/useForClosingTray'

export const trayMargin = 24

interface TrayPresenterProps {
  shouldVibrate?: boolean
}
export default forwardRef<HTMLDivElement, TrayPresenterProps>(function TrayPresenter(
  props: TrayPresenterProps,
  ref,
): ReactElement {
  const { trays, hideTray } = useTrayPresenterState()

  return (
    <Box ref={ref} data-testid="tray-presenter">
      {trays
        ?.filter(tray => tray.isVisible) // only render a tray if it is set to be shown
        ?.map(tray => (
          <TrayItem key={tray.id} tray={tray} hideTray={hideTray} {...props} />
        ))}
    </Box>
  )
})

interface TrayItemProps extends TrayPresenterProps {
  tray: TrayInfo
  hideTray: (id: string) => void
}
const TrayItem = ({ tray, hideTray, shouldVibrate }: TrayItemProps): ReactElement => {
  const breakpoint = useBreakpointIndex()
  const [isTrayVisible, setTrayVisible] = useState(false)
  useForClosingTray({
    closeTray: () => hideTray(tray.id),
  })

  /**
   * This effect would trigger the timer which would make the tray visible along with associated animation
   */
  useEffect(() => {
    const timer = setTimeout(() => setTrayVisible(!!tray.isVisible), 50)
    return () => clearTimeout(timer)
  }, [tray.isVisible])

  return (
    <Tray
      dataTestId={tray.id}
      entersFrom={breakpoint === 0 ? 'bottom' : 'right'}
      isOpen={isTrayVisible}
      backgroundOverlayWhenOpen={true}
      dismiss={() => {
        hideTray(tray.id)
      }}
      shouldVibrateOnPresent={!!shouldVibrate}
      zIndexName={tray.zIndexName}
      sx={{
        borderRadius: [0, '32px'],
        border: [undefined, '1px solid'],
        borderColor: [undefined, 'white10'],
        m: [0, `${trayMargin}px`],
      }}
    >
      {tray.renderTray({
        isVisible: tray.isVisible,
        dismiss: () => hideTray(tray.id),
      })}
    </Tray>
  )
}
