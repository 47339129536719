import React, { ReactElement, ReactNode } from 'react'
import { Currency } from 'store/api/billing'
import { Flex, Text } from 'theme-ui'
import getFormattedPrice from 'utils/getFormattedPrice'
import getPriceDetailsForCurrency from 'utils/getPriceDetailsForCurrency'
import { CommonPriceInterval, PricePoint } from 'store/api/billing/payments.interface'

interface StrikeThroughPriceProps {
  children: ReactNode
}
function StrikeThroughPrice({ children }: StrikeThroughPriceProps): ReactElement {
  return (
    <Text
      variant="size21Weight600Line138"
      sx={{
        color: 'aliceBlue60',
        textDecoration: 'line-through',
        mr: '0.8rem',
      }}
    >
      {children}
    </Text>
  )
}

interface PriceElementProps {
  pricePoints: PricePoint[]
  currency?: Currency
}

function PriceElement({ pricePoints, currency }: PriceElementProps): ReactElement {
  const monthlyPricePoint = pricePoints.find(x => x.duration === CommonPriceInterval.MONTHLY)
  const monthlyPrice =
    monthlyPricePoint && getPriceDetailsForCurrency(monthlyPricePoint, currency?.code)
  const yearlyPricePoint = pricePoints.find(
    x => x.duration > CommonPriceInterval.MONTHLY && x.duration % CommonPriceInterval.YEARLY === 0,
  )
  const yearlyPrice =
    yearlyPricePoint && getPriceDetailsForCurrency(yearlyPricePoint, currency?.code)
  const monthsPricePoint = pricePoints.find(
    x => x.duration > CommonPriceInterval.MONTHLY && x.duration % CommonPriceInterval.YEARLY !== 0,
  )
  const monthsPrice =
    monthsPricePoint && getPriceDetailsForCurrency(monthsPricePoint, currency?.code)
  const doesMonthAndYearPriceExist = monthlyPrice && yearlyPrice
  const isAlreadyBilled = pricePoints[0].already_billed === 1
  const yearsNumber = yearlyPrice ? yearlyPrice.duration / CommonPriceInterval.YEARLY : 0

  const isOriginalPrice = monthlyPrice?.original_price || yearlyPrice?.original_price

  return (
    <Flex
      sx={{
        flexDirection: isOriginalPrice ? 'row' : 'column',
        alignItems: isOriginalPrice ? 'flex-end' : 'flex-start',
      }}
    >
      {monthlyPrice && (
        <>
          <Flex
            data-testid="monthly-price"
            sx={{
              alignItems: 'flex-end',
            }}
          >
            {monthlyPrice.original_price && (
              <StrikeThroughPrice>
                {getFormattedPrice(currency, monthlyPrice.original_price)}
              </StrikeThroughPrice>
            )}
            <Text variant="size28Weight600">
              {getFormattedPrice(currency, isAlreadyBilled ? 0 : monthlyPrice.price)}
              {doesMonthAndYearPriceExist && (
                <Text variant="size16Weight500Line138" sx={{ color: 'aliceBlue70', pl: '0.4rem' }}>
                  per month or{' '}
                </Text>
              )}
            </Text>
          </Flex>
          {!doesMonthAndYearPriceExist && (
            <Text variant="size16Weight500Line138" sx={{ color: 'aliceBlue70' }}>
              {isAlreadyBilled ? 'for 1' : 'per'} month.
            </Text>
          )}
        </>
      )}
      {yearlyPrice && (
        <>
          <Flex
            data-testid="yearly-price"
            sx={{
              alignItems: 'flex-end',
            }}
          >
            {yearlyPrice.original_price && (
              <StrikeThroughPrice>
                {getFormattedPrice(currency, yearlyPrice.original_price)}
              </StrikeThroughPrice>
            )}
            <Text
              variant="size28Weight600"
              sx={{
                pr: '0.4rem',
              }}
            >
              {getFormattedPrice(currency, isAlreadyBilled ? 0 : yearlyPrice.price)}
            </Text>
            <Text variant="size16Weight500Line138" sx={{ color: 'aliceBlue70' }}>
              {doesMonthAndYearPriceExist && 'per year.'}
            </Text>
          </Flex>
          {!doesMonthAndYearPriceExist && (
            <Text
              variant="size16Weight500Line138"
              sx={{ fontWeight: 'normal', color: 'aliceBlue70' }}
            >{`${isAlreadyBilled ? 'for' : 'per'} ${
              yearsNumber === CommonPriceInterval.MONTHLY && yearlyPrice.price
                ? isAlreadyBilled
                  ? '1'
                  : ''
                : yearsNumber
            } year${yearsNumber > CommonPriceInterval.MONTHLY ? 's' : ''}.`}</Text>
          )}
        </>
      )}
      {monthsPrice && (
        <>
          <Flex data-testid="half-yearly-price">
            <Text
              variant="size28Weight600"
              sx={{
                pr: '0.4rem',
              }}
            >
              {monthsPrice.original_price && (
                <StrikeThroughPrice>
                  {getFormattedPrice(currency, monthsPrice.original_price)}
                </StrikeThroughPrice>
              )}
              <Text
                variant="size28Weight600"
                sx={{
                  pr: '0.4rem',
                }}
              >
                {getFormattedPrice(currency, isAlreadyBilled ? 0 : monthsPrice.price)}
              </Text>
              <Text variant="size16Weight500Line138" sx={{ color: 'aliceBlue70' }}>
                {monthsPrice.original_price && `for ${monthsPrice.duration} months.`}
              </Text>
            </Text>
          </Flex>
          {!monthsPrice.original_price && (
            <Text
              variant="size16Weight500Line138"
              sx={{ color: 'aliceBlue70' }}
            >{`for ${monthsPrice.duration} months.`}</Text>
          )}
        </>
      )}
    </Flex>
  )
}
export default PriceElement
