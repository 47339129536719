import { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { userApi } from './user'
import { proxiesApi } from '../proxies'
import {
  LoginRequestArgs,
  NukeAnalyticsType,
  PreAuthData,
  UserData,
  UsersRegisterArgs,
} from './user.interface'
import { ResponseType } from '../index'
import { setCaptcha, clearCaptcha, setManuallyLoggedOut, setSessionUpdate } from 'store/session'
import useGetUser from 'components/Dashboard/utils/useGetUser'

const userPollingIntervalMs = 2_000
let userPollingResponse

const useGetUserState = (): {
  registerUser: (requestData: UsersRegisterArgs) => void
  register: (body: UsersRegisterArgs) => Promise<void | ResponseType<PreAuthData>>
  resolveCaptcha: (body: UsersRegisterArgs) => Promise<void | ResponseType<PreAuthData>>
  sessionLogin: (body: LoginRequestArgs) => Promise<void>
  sessionAutologin: () => Promise<void>
  isLoginLoading: boolean
  startPollingUser: (shouldSubscribe: boolean) => Promise<void>
  nuke_analytics: NukeAnalyticsType
  region?: string
  isOrganization: boolean
  /**
   * If the user is in a suborg and does not have access to the parent org or
   * if the user is from a parent org and is not impersonating a suborg,
   * this will be true
   */
  noAccessToParentOrg: boolean
} => {
  const dispatch = useAppDispatch()
  const cid = useAppSelector(s => s.tracking.cid)
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const { data: userData } = useGetUser()
  const nuke_analytics = userData?.org ? NukeAnalyticsType.ORG : NukeAnalyticsType.USER
  const isOrganization = useAppSelector(s => s.session.isOrganization)
  const region = useAppSelector(s => s.session.region)
  const noAccessToParentOrg = userData?.org_id !== userData?.org?.parent_org?.PK
  const registerUser = useCallback(
    async (requestData: UsersRegisterArgs) => {
      const response = (await dispatch(
        userApi.endpoints.userRegister.initiate(requestData),
      )) as ResponseType<UserData>

      if (response.data) {
        dispatch(proxiesApi.endpoints.getProxies.initiate(''))
        dispatch(setSessionUpdate(response.data))
      }
    },
    [dispatch],
  )

  /* Register is called to try and register the user. If no abuse is suspected, this
   * call will return the users data and sessiontoken. If abuse is suspected, then
   * the preauth request returns a captcha and this thunk will return the preauth
   * response, which is saved in the session slice to facilitate showing the captcha
   * challenge to the user. When the user completes the captcha, the solution and trail
   * are posted using the resolveCaptcha thunk, which, on success, returns the registered
   * user data and session token.
   */
  const register = useCallback(
    async (body: UsersRegisterArgs) => {
      const preauthResponse = (await dispatch(
        userApi.endpoints.preAuthUserRegister.initiate(body),
      )) as ResponseType<PreAuthData>

      if (preauthResponse.error) {
        return
      }

      if (preauthResponse.data?.captcha) {
        //return captcha response
        dispatch(setCaptcha(preauthResponse.data))
        return preauthResponse
      } else {
        const requestData = {
          ...body,
          ...preauthResponse.data,
          ...(cid ? { cid } : {}),
        }

        return await registerUser(requestData)
      }
    },
    [cid, dispatch, registerUser],
  )

  const resolveCaptcha = useCallback(
    async (body: UsersRegisterArgs) => {
      const requestData = {
        ...body,
        ...(cid ? { cid } : {}),
      }

      await registerUser(requestData)
      dispatch(clearCaptcha())
    },
    [cid, dispatch, registerUser],
  )

  const sessionLogin = useCallback(
    async (body: LoginRequestArgs) => {
      setIsLoginLoading(true)
      const preAuthResp = (await dispatch(
        userApi.endpoints.preAuthUserLogin.initiate(body),
      )) as ResponseType<PreAuthData>

      if (preAuthResp.error) {
        setIsLoginLoading(false)
        return
      }

      const response = (await dispatch(
        userApi.endpoints.userLogin.initiate({ ...body, ...preAuthResp.data }),
      )) as ResponseType<UserData>

      setIsLoginLoading(false)

      if (response.data) {
        // public and protected api should have consistency.
        // on login fetching proxies from public api, when the user is logged in - from protected api.
        dispatch(proxiesApi.endpoints.getProxies.initiate(''))
        // on login fetch all devices so that we can check if any of them is configured
        dispatch(setSessionUpdate(response.data))
        dispatch(setManuallyLoggedOut(false))
      }
    },
    [dispatch],
  )

  const sessionAutologin = useCallback(async () => {
    const response = (await dispatch(
      userApi.endpoints.getUser.initiate(''),
    )) as ResponseType<UserData>

    await dispatch(proxiesApi.endpoints.getProxies.initiate(''))

    if (response.data) {
      dispatch(setSessionUpdate(response.data))
    }
  }, [dispatch])

  const startPollingUser = useCallback(
    async shouldSubscribe => {
      if (!shouldSubscribe) {
        userPollingResponse?.unsubscribe()
      } else {
        userPollingResponse = dispatch(
          userApi.endpoints.getUser.initiate('', {
            subscribe: true,
            subscriptionOptions: {
              pollingInterval: userPollingIntervalMs,
            },
          }),
        )
      }
    },
    [dispatch],
  )

  return {
    registerUser,
    register,
    resolveCaptcha,
    sessionLogin,
    sessionAutologin,
    isLoginLoading,
    startPollingUser,
    nuke_analytics,
    region,
    isOrganization,
    noAccessToParentOrg,
  }
}

export default useGetUserState
