import React, { ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import { QueriesFilterMenuType } from '../FiltersContainer'

export enum RCODE {
  NOERROR = 'NOERROR',
  SERVFAIL = 'SERVFAIL',
  NXDOMAIN = 'NXDOMAIN',
  REFUSED = 'REFUSED',
}

export const RCODEMap = {
  [RCODE.NOERROR]: 0,
  [RCODE.SERVFAIL]: 2,
  [RCODE.NXDOMAIN]: 3,
  [RCODE.REFUSED]: 5,
}
const rCodes = [RCODE.NOERROR, RCODE.SERVFAIL, RCODE.NXDOMAIN, RCODE.REFUSED]

export default function CodesDropdown(): ReactElement {
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)

  const selectedCode =
    Object.entries(RCODEMap).find(
      ([, value]) =>
        value.toString() === (filterParameters[QueriesFilterMenuType.RCODE] ?? '').toString(),
    )?.[0] ?? 'RCODEs'

  return (
    <QueryFilterDropdown selectedValue={selectedCode} type={QueriesFilterMenuType.RCODE}>
      <QueryFilterDropdownContent
        type={QueriesFilterMenuType.RCODE}
        codes={rCodes || []}
        isSearchVisible={false}
      />
    </QueryFilterDropdown>
  )
}
