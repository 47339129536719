import React, { ReactElement } from 'react'
import { Box, Text } from 'theme-ui'
import ActivityLogImage from 'images/tutorialFlows/Analytics/activity-log-slide.svg'
import ActivityLogImageLight from 'images/tutorialFlows/Analytics/activity-log-slide-light.svg'
import StatisticsImage from 'images/tutorialFlows/Analytics/statistics-slide.svg'
import StatisticsImageLight from 'images/tutorialFlows/Analytics/statistics-slide-light.svg'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import useGetColorMode from 'utils/useGetColorMode'

export function ActivityLogSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? ActivityLogImageLight : ActivityLogImage}
      description={
        <Box>
          <Text>
            With Full <Highlight svg={tutorialSlideIcons.analytics}>Analytics</Highlight> enabled,
            you&apos;ll be able to see some detailed information about your recent browsing. Use the
            filter dropdown menus to narrow down the logs to exactly what you need, and click the{' '}
            <Highlight svg={tutorialSlideIcons.infoIcon} />
            icon on any entry to show more.
          </Text>
        </Box>
      }
    />
  )
}

export function StatisticsSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? StatisticsImageLight : StatisticsImage}
      description={
        <Box>
          <Text>
            In the line chart, you can choose which data you want to see by clicking the Queries
            Filter dropdown, and you can choose how far back you want to view the data by choosing
            from the dropdown. <br /> <br />
            The Most Active section and Top Domains sections expand on this data.
          </Text>
        </Box>
      }
    />
  )
}
