import React, { ReactElement, useMemo } from 'react'
import { API_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import SecureResolver from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/SecureResolver'
import { Box } from 'theme-ui'
import usePathSuffix from 'components/SetupGuide/usePathSuffix'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function AppleTv14Resolver(): ReactElement {
  const pathSuffix = usePathSuffix()
  const isMobile = useBreakpointIndex() === 0
  const configAddress = useMemo(() => {
    return `${API_CONTROL_D_DOMAIN}/mobileconfig/${pathSuffix}`
  }, [pathSuffix])
  return (
    <>
      <Box sx={{ mx: ['1rem', '8rem'], width: ['calc(100% - 2rem)', '100%'] }}>
        <SecureResolver
          isResolverTypeHighlighted={true}
          canCopy={true}
          address={configAddress}
          shouldShowFullAddress={isMobile}
        />
      </Box>
    </>
  )
}
