import { configDownloadPathsByResolverType, DnsServers } from 'constants/dnsServers'
import { useMemo } from 'react'
import { useAppSelector } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import useGetProvisioningState from 'store/provisioning/useGetProvisioningState'
import { useGetDevicesQuery } from 'store/api/devices'

export default function usePathSuffix(): string | undefined {
  const { qs } = useQueryString()

  const freeResolverType = qs.freeResolverType as keyof DnsServers | undefined
  const isProvisioning = !!qs.resolverUid
  const selectedDevicePk = qs.deviceId
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  const selectedDevice = devicesData?.devices.find(device => device.PK === selectedDevicePk)
  const { uid: provisoningResolverUid } = useGetProvisioningState()
  const standDardResolverUid = selectedDevice?.resolvers.uid
  const resolverUid = isProvisioning ? provisoningResolverUid : standDardResolverUid ?? ''
  const selectedFiltersPK = useAppSelector(s => s.freeDNS.selectedFiltersPKs ?? [])
  const selected3rdPartyFilterPK = useAppSelector(s => s.freeDNS.selected3rdPartyFilterPK)
  const pathSuffix = useMemo(() => {
    if (!!selectedFiltersPK.length) {
      return `no-${selectedFiltersPK.join('-')}?type=free`
    }

    if (!!selected3rdPartyFilterPK) {
      return `${selected3rdPartyFilterPK}?type=free`
    }

    if (resolverUid === '') {
      return `${configDownloadPathsByResolverType[freeResolverType || 'unfiltered']}?type=free`
    }
    return resolverUid
  }, [freeResolverType, resolverUid, selectedFiltersPK, selected3rdPartyFilterPK])

  return pathSuffix
}
