import React, { ReactElement } from 'react'
import { Button, BackIcon } from 'ui'

const BackButton = ({ onClick }: { onClick?: () => void }): ReactElement => {
  return (
    <Button
      variant="transparent"
      ariaLabel="Back button"
      data-testid="dialog-back-button"
      onClick={onClick}
      sx={{
        width: 'fit-content',
        border: 'none',
        p: 0,
        flexShrink: 0,
        '&:hover svg path': {
          fill: 'aliceBlue',
        },
      }}
    >
      <BackIcon
        fill="aliceBlue60"
        sx={{
          width: '2rem',
          height: '2rem',
        }}
      />
    </Button>
  )
}

export default BackButton
