import React, { ReactElement, useCallback } from 'react'
import { Button, Svg } from 'ui'
import ForwardIcon from 'images/forward-icon.svg'
import { Flex, Text } from 'theme-ui'
import { ActionableRule } from 'utils/uniteRules'
import { RuleType } from 'store/api/rules/rules.interface'
import { openProxyTray } from 'store/proxyTray'
import { useAppDispatch } from 'store/hooks'
import { autoLocation, randomLocation } from 'components/ProxyList/ProxyList'
import { useGetProxiesQuery } from 'store/api/proxies'
import ExternalSvgIcon, { IconType } from 'components/Dashboard/ExternalSvgIcon'

const getText = ({ via, viaV6 }) => {
  let text = ''

  if (via) {
    text += via
  }

  if (viaV6) {
    text += (via ? ', ' : '') + viaV6
  }

  return text
}

interface ChooseDestinationButtonProps {
  rule?: ActionableRule
  forceRuleType?: RuleType
}
export default function ChooseDestinationButton({
  rule,
  forceRuleType,
}: ChooseDestinationButtonProps): ReactElement | null {
  const dispatch = useAppDispatch()
  const { data: proxiesData } = useGetProxiesQuery('')
  const redirectLocationFromProxyList = proxiesData?.proxies?.find(p => p.PK === rule?.action?.via)
  const isRandomLocation = rule?.action?.via === '?'
  const isAutoLocation = rule?.action?.via === 'LOCAL'
  const text = getText({ via: rule?.action?.via, viaV6: rule?.action?.via_v6 })

  const redirectLocation = isRandomLocation
    ? randomLocation
    : isAutoLocation
    ? autoLocation
    : redirectLocationFromProxyList

  const handleClick = useCallback(() => {
    dispatch(
      openProxyTray({
        rule,
      }),
    )
  }, [dispatch, rule])

  if (rule?.action?.do !== RuleType.SPOOF_IP && rule?.action?.do !== RuleType.SPOOF_TAG) {
    return null
  }
  return (
    <Button
      variant="transparent"
      onClick={handleClick}
      data-testid="choose-destination-button"
      ariaLabel="choose destination"
      sx={{
        border: '1px solid',
        borderColor: 'blueYonder40',
        borderRadius: '0.8rem',
        mt: '1.2rem',
        py: '1.2rem',
        backgroundColor: 'transparent',
        color: 'aliceBlue',
        justifyContent: 'space-between',
        ':hover:not([disabled]) svg:last-child path': {
          fill: 'aliceBlue',
        },
      }}
      disabled={forceRuleType !== undefined}
    >
      <Flex sx={{ fontSize: '1.4rem', color: 'aliceBlue60', alignItems: 'center' }}>
        {redirectLocation ? ( // Spoof tag
          <>
            <ExternalSvgIcon
              icon={redirectLocation?.country || '?'}
              type={IconType.LOCATIONS}
              fill={redirectLocation?.country ? undefined : 'white'}
              sx={{
                width: '1.6rem',
                height: '1.6rem',
              }}
            />
            <Text sx={{ pl: '1.2rem', textAlign: 'left' }}>
              {isRandomLocation
                ? 'Random Location'
                : isAutoLocation
                ? 'Auto Location'
                : `${`${redirectLocation?.city}, ${redirectLocation?.country}`}`}
            </Text>
          </>
        ) : (
          <Text sx={{ textAlign: 'left' }}>{text || 'Choose Destination'}</Text> // Spoof ip
        )}
      </Flex>
      <Svg svg={ForwardIcon} fill="aliceBlue60" />
    </Button>
  )
}
