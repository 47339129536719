import React, { ReactElement, ReactNode } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export default function Cell({
  children,
  sx,
}: {
  children: ReactNode
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex data-testid="feature-grid-cell" sx={sx}>
      {children}
    </Flex>
  )
}
