import React, { ReactElement } from 'react'
import NonOrganizationNavBarContent from 'components/Dashboard/NavBar/NonOrganizationNavBarContent'
import OrganizationNavBarContent from 'components/Organization/OrganizationNavBar/OrganizationNavBarContent'
import useGetUserState from 'store/api/user/useGetUserState'

export default function NavBarContent(): ReactElement {
  const { isOrganization } = useGetUserState()

  return isOrganization ? <OrganizationNavBarContent /> : <NonOrganizationNavBarContent />
}
