import React, { ReactElement, RefObject } from 'react'
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui'
import CloseButton from 'ui/NewModalDialog/CloseButton'
import BackButton from './BackButton'

export const modalDialogHeaderHeight = '5.4rem'

export interface ModalDialogHeaderProps {
  headerRef?: RefObject<HTMLDivElement>
  title?: string
  sx?: ThemeUIStyleObject
  rightContent?: ReactElement
  leftContent?: ReactElement
  isLeftContentVisible?: boolean
  isRightContentVisible?: boolean
  onCloseClick?: () => void
  onBackClick?: () => void
}
export default function ModalDialogHeader({
  headerRef,
  sx,
  title,
  rightContent,
  leftContent,
  isLeftContentVisible,
  isRightContentVisible = true,
  onCloseClick,
  onBackClick,
}: ModalDialogHeaderProps): React.ReactElement {
  return (
    <Flex
      data-testid="modal-dialog-header"
      ref={headerRef}
      sx={{
        position: 'sticky',
        top: 0,
        height: modalDialogHeaderHeight,
        alignItems: 'center',
        flexShrink: 0,
        backgroundColor: 'blue800',
        px: '1.6rem',
        zIndex: 'zIndex240',
        borderTopRightRadius: [0, '1.2rem'],
        borderTopLeftRadius: [0, '1.2rem'],
        borderBottom: '1px solid',
        borderColor: 'blueYonder30',
        ...sx,
      }}
    >
      <Flex sx={{ flex: '1 0 0' }}>
        {isLeftContentVisible ? leftContent || <BackButton onClick={onBackClick} /> : null}
      </Flex>
      <Text
        variant="size17Weight600Line138"
        className="modal-dialog-children-container"
        sx={{
          flex: '0 0 auto',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'aliceBlue',
        }}
        tabIndex={0}
      >
        {title}
      </Text>
      <Flex sx={{ flex: '1 0 0', flexDirection: 'row-reverse' }}>
        {isRightContentVisible ? rightContent || <CloseButton onClick={onCloseClick} /> : null}
      </Flex>
    </Flex>
  )
}
