import React, { RefObject } from 'react'
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui'
import { BackButton, CloseButton } from 'components/SetupGuide/Components'
import InfoButton from 'ui/InfoButton'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'

export const modalDialogHeaderHeight = ['auto', '8rem', '8rem']
export const modalDialogHeaderMinHeight = '6.4rem'

export enum ButtonType {
  CLOSE = 'close',
  BACK = 'back',
}

export const ButtonStyles = {
  width: ['4rem', '4.8rem'],
  height: ['4rem', '4.8rem'],
  borderRadius: '50%',
  backgroundColor: 'white10',
}

export interface ModalDialogHeaderProps {
  headerRef?: RefObject<HTMLDivElement>
  title?: string
  sx?: ThemeUIStyleObject
  buttonType?: ButtonType
  onButtonClick?: () => void
  infoUrl?: string
}
export default function ModalDialogHeader({
  headerRef,
  sx,
  title,
  buttonType = ButtonType.CLOSE,
  onButtonClick,
  infoUrl,
}: ModalDialogHeaderProps): React.ReactElement {
  return (
    <Flex
      data-testid="modal-dialog-header"
      ref={headerRef}
      sx={{
        height: modalDialogHeaderHeight,
        minHeight: modalDialogHeaderMinHeight,
        alignItems: 'center',
        flexShrink: 0,
        backgroundColor: 'dialogElementBg',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 0 rgba(255, 255, 255, 0.1)',
        ...sx,
      }}
    >
      <Flex sx={{ flex: '1 0 0' }}>
        {buttonType === ButtonType.CLOSE && (
          <CloseButton sx={ButtonStyles} onClick={onButtonClick} />
        )}
        {buttonType === ButtonType.BACK && <BackButton sx={ButtonStyles} />}
      </Flex>
      <Text
        variant="size15Weight700"
        className="modal-dialog-children-container"
        sx={{
          flex: '0 0 auto',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'labelColor',
        }}
        tabIndex={0}
      >
        {title}
      </Text>
      <Flex sx={{ flex: '1 0 0', flexDirection: 'row-reverse' }}>
        {!!infoUrl && (
          <InfoButton
            onInfoIconClick={() =>
              window.open(`${DOCS_CONTROL_D_DOMAIN}/${infoUrl}`, '_blank', 'noopener, noreferrer')
            }
            sx={{ mr: '1.6rem' }}
          />
        )}
      </Flex>
    </Flex>
  )
}
