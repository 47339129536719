import { useEffect } from 'react'
import { useAppSelector } from 'store/hooks'
import { useStartCampaignTrackingMutation } from 'store/api/campaignTracking'

/**
 * This hook is called from the entry page where the user lands with a cid
 */
export function useEntryTracking(): void {
  const cid = useAppSelector(s => s.tracking.cid)
  const entryPage = useAppSelector(s => s.tracking.entryPage)
  const isEntryPixelRequestExecuted = useAppSelector(s => s.tracking.isEntryPixelRequestExecuted)
  const [startCampaignTracking] = useStartCampaignTrackingMutation()

  useEffect(() => {
    if (!isEntryPixelRequestExecuted && cid && entryPage !== undefined) {
      startCampaignTracking({
        cid,
        entryPage,
      })
    }
  }, [isEntryPixelRequestExecuted, cid, entryPage, startCampaignTracking])
}

/**
 * This hook is called from the Plans page to track landing on the Plans page
 */
export function usePlansTracking(): void {
  const cid = useAppSelector(s => s.tracking.cid)
  const entryPage = useAppSelector(s => s.tracking.entryPage)
  const isPlansPixelRequestExecuted = useAppSelector(s => s.tracking.isPlansPixelRequestExecuted)
  const [startCampaignTracking] = useStartCampaignTrackingMutation()

  useEffect(() => {
    if (!isPlansPixelRequestExecuted && cid && entryPage !== undefined) {
      startCampaignTracking({
        path: 'plan',
        cid,
        entryPage,
      })
    }
  }, [isPlansPixelRequestExecuted, cid, entryPage, startCampaignTracking])
}

/**
 * This hook is called from the create account view page only when the register succeeds and a valid
 * session token has been received
 */
export function useSuccessfulRegistrationTracking(): void {
  const cid = useAppSelector(s => s.tracking.cid)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const entryPage = useAppSelector(s => s.tracking.entryPage)
  const isSignupPixelRequestExecuted = useAppSelector(s => s.tracking.isSignupPixelRequestExecuted)
  const shouldTriggerSignupPixelRequest = useAppSelector(
    s => s.tracking.shouldTriggerSignupPixelRequest,
  )
  const [startCampaignTracking] = useStartCampaignTrackingMutation()

  useEffect(() => {
    if (
      shouldTriggerSignupPixelRequest &&
      !isSignupPixelRequestExecuted &&
      cid &&
      entryPage !== undefined &&
      sessionToken
    ) {
      startCampaignTracking({
        path: 'signup',
        cid,
        entryPage,
      })
    }
  }, [
    isSignupPixelRequestExecuted,
    cid,
    entryPage,
    sessionToken,
    startCampaignTracking,
    shouldTriggerSignupPixelRequest,
  ])
}
