import { EnabledStatus, RuleType } from 'store/api/rules'
import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import {
  Filter,
  FilterOptionType,
  GetFiltersResponse,
  PutFiltersResponse,
} from './filters.interface'
import { ApiResponse } from 'store/api/http'

export const filtersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAllFilters: builder.query({
      query: () => getQueryArgs('/filters'),
      transformResponse: (
        response: ApiResponse<{
          filters: Filter[]
          external_filters: Filter[]
        }>,
      ) => response.body,
      transformErrorResponse,
    }),
    getFilters: builder.query({
      query: ({ profileId }: { profileId: string | number | undefined }) =>
        getQueryArgs(`/profiles/${profileId}/filters`),
      transformResponse: (response: GetFiltersResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Filters'],
    }),
    getGlobalProfileNativeFilters: builder.query({
      query: ({
        globalProfileId,
      }: {
        globalProfileId: string | number | undefined
        profileId: string | number | undefined
      }) => ({
        url: `/profiles/${globalProfileId}/filters`,
      }),
      transformResponse: (response: GetFiltersResponse) => {
        return response.body
      },
      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          /**
           * Get all filters which have a block action enabled or have a level
           * with a block action enabled from the global profile
           */
          const enabledGlobalFilters = data.filters.filter(
            filter =>
              filter.levels?.some(level => level.status === EnabledStatus.ENABLED) ||
              (filter.action?.status === EnabledStatus.ENABLED &&
                filter.action?.do === RuleType.BLOCK),
          )
          dispatch(
            filtersApi.util.updateQueryData('getFilters', { profileId }, draft => {
              enabledGlobalFilters.forEach(enabledFilter => {
                const filter = draft.filters.find(f => f.PK === enabledFilter.PK)
                if (filter) {
                  filter.global = enabledFilter
                }
              })
            }),
          )
        } catch {}
      },
      providesTags: ['Filters'],
    }),
    getExternalFilters: builder.query({
      query: ({ profileId }: { profileId: string | number | undefined }) =>
        getQueryArgs(`/profiles/${profileId}/filters/external`),
      transformResponse: (response: GetFiltersResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Filters'],
    }),

    getGlobalProfile3rdPartyFilters: builder.query({
      query: ({
        globalProfileId,
      }: {
        globalProfileId: string | number | undefined
        profileId: string | number | undefined
      }) => ({
        url: `/profiles/${globalProfileId}/filters/external`,
      }),
      transformResponse: (response: GetFiltersResponse) => {
        return response.body
      },
      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          /**
           * Get all filters which have a block action enabled or have a level
           * with a block action enabled from the global profile
           */
          const enabledGlobalFilters = data.filters.filter(
            filter =>
              filter.levels?.some(level => level.status === EnabledStatus.ENABLED) ||
              (filter.action?.status === EnabledStatus.ENABLED &&
                filter.action?.do === RuleType.BLOCK),
          )
          dispatch(
            filtersApi.util.updateQueryData('getExternalFilters', { profileId }, draft => {
              enabledGlobalFilters.forEach(enabledFilter => {
                const filter = draft.filters.find(f => f.PK === enabledFilter.PK)
                if (filter) {
                  filter.global = enabledFilter
                }
              })
            }),
          )
        } catch {}
      },
      providesTags: ['Filters'],
    }),
    putFilter: builder.mutation({
      query: ({
        pk,
        profileId,
        status,
        doAction,
        ovr,
        lvl,
      }: {
        pk: string
        profileId: string | undefined
        doAction: RuleType | undefined
        status: EnabledStatus
        ovr: EnabledStatus | undefined
        lvl?: string // filter level
      }) =>
        getQueryArgs(`/profiles/${profileId}/filters/filter/${pk}`, 'PUT', {
          status,
          do: doAction,
          ovr,
          lvl,
        }),
      transformResponse: (response: PutFiltersResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted({ profileId, pk }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const action = data.filters[pk]

          const filtersQueryEndpoint = pk.startsWith('x-') ? 'getExternalFilters' : 'getFilters'

          dispatch(
            filtersApi.util.updateQueryData(
              filtersQueryEndpoint,
              { profileId: profileId },
              draft => {
                const filter = draft.filters.find(f => f.PK === pk)

                if (filter) {
                  filter.action = action
                  // if action has a level, set it to enabled & disable others
                  filter.levels?.map(level => {
                    if (level.name === action?.lvl) {
                      level.status = EnabledStatus.ENABLED
                    } else {
                      level.status = EnabledStatus.DISABLED
                    }
                  })
                  // if action does not have a lvl, enable the first level
                  if (action && !action.lvl && filter.levels?.[0]) {
                    filter.levels[0].status = EnabledStatus.ENABLED
                  }
                }
              },
            ),
          )
        } catch {}
      },
    }),
    /**
     * This mutation is only used for malware filter levels
     */
    putFilterOption: builder.mutation({
      query: ({
        name,
        type,
        profileId,
        status,
        ovr,
        doAction,
      }: {
        name: string
        filterPK: string
        type: FilterOptionType
        profileId: string | undefined
        status: EnabledStatus
        ovr?: EnabledStatus
        doAction?: RuleType
      }) =>
        getQueryArgs(`/profiles/${profileId}/filters/${type}/${name}`, 'PUT', {
          status,
          ovr,
          do: doAction,
        }),
      transformResponse: (response: PutFiltersResponse) => response.body,
      transformErrorResponse,

      async onQueryStarted({ profileId, name, filterPK, status }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const action = data.filters[filterPK]

          dispatch(
            filtersApi.util.updateQueryData('getFilters', { profileId }, draft => {
              const filter = draft.filters.find(f => f.PK === filterPK)
              if (filter) {
                filter.action = action
                const level = filter.levels?.find(level => level.name === name)
                if (level) {
                  if (filter.PK !== 'malware' && filter.levels) {
                    filter.levels.forEach(l => {
                      l.status = EnabledStatus.DISABLED
                    })
                  }
                  level.status = status
                }
              }
            }),
          )
        } catch {}
      },
    }),
  }),
})

export const {
  endpoints,
  useGetAllFiltersQuery,
  useGetFiltersQuery,
  useGetExternalFiltersQuery,
  usePutFilterMutation,
  usePutFilterOptionMutation,
  useGetGlobalProfileNativeFiltersQuery,
  useGetGlobalProfile3rdPartyFiltersQuery,
} = filtersApi
