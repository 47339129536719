export const getStarted = [
  {
    header: 'Organization',
    description: 'Make an account for your team',
    href: '/onboard',
  },
  {
    header: 'Personal',
    description: 'Make an account for yourself',
    href: '/plans?step=account&fullControl=true',
  },
]
