import React, { ElementType, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'

import type { Setter } from 'utils'
import { Svg } from 'ui'
import { InternalOrExternalLink } from 'components/HomePage/Header/index'

const MobileNavMenuLinkItem = ({
  title,
  to,
  href,
  leftIcon,
  dataTestId,
  tabIndex,
  dismiss,
}: {
  title: string
  dataTestId: string
  to?: string
  href?: string
  leftIcon?: ElementType
  setIsOpen?: Setter<boolean>
  tabIndex?: number
  dismiss?: () => void
}): ReactElement => {
  return (
    <InternalOrExternalLink
      data-testid={`nav-menu-link-${dataTestId}`}
      to={to}
      href={href}
      tabIndex={tabIndex}
      dismiss={dismiss}
      sx={{
        width: '100%',
        fontSize: '1.8rem',
        cursor: 'pointer',
        textDecoration: 'none',
        border: 'none',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          borderRadius: '10rem',
          px: '1.6rem',
          py: '1.2rem',
          ':hover': {
            backgroundColor: 'wakaiwaGrey20',
          },
        }}
      >
        <Flex
          sx={{
            flexShrink: 0,
            alignItems: 'center',
          }}
        >
          {leftIcon && (
            <Svg
              svg={leftIcon}
              sx={{
                mr: '0.8rem',
                width: '2.4rem',
                height: '2.4rem',
                opacity: 1,
                '& path': {
                  strokeOpacity: 1,
                  fillOpacity: 1,
                },
              }}
            />
          )}
          <Text
            sx={{
              color: 'lightSteelBlue',
              fontSize: '1.6rem',
              lineHeight: '138%',
              fontWeight: 500,
            }}
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    </InternalOrExternalLink>
  )
}

export default MobileNavMenuLinkItem
