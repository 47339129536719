import SecureIcon from 'images/dnsProtocols/secure-icon.svg'
import UnencryptedIcon from 'images/dnsProtocols/unencrypted-icon.svg'
import DOHIcon from 'images/dnsProtocols/DOH.svg'
import DOTIcon from 'images/dnsProtocols/DOT.svg'
import DOQIcon from 'images/dnsProtocols/DOQ.svg'
import DOH3Icon from 'images/dnsProtocols/DOH3.svg'

const protocolIcons = {
  legacy: UnencryptedIcon,
  doh: DOHIcon,
  dot: DOTIcon,
  doq: DOQIcon,
  doh3: DOH3Icon,
  secure: SecureIcon,
}

export default protocolIcons
