import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MessageResponse } from 'store/api/barry/barry.interface'
import { v4 as uuidv4 } from 'uuid'
import { getCurrentTime } from 'utils/getCurrentTime'
import { barryApi } from 'store/api/barry'
import { RootState } from 'store/rootReducer'
import { supportApi } from 'store/api/support/support'
import * as errorCodes from 'store/api/errorCodes'

enum ErrorTypes {
  ConversationConcluded = 'ConversationConcluded',
}
export interface BarryChatErrorResponse {
  reason: ErrorTypes
  message: string
}

export enum Page {
  SPLASH_PAGE = 0,
  DESCRIBE_ISSUE_PAGE = 1,
  CHAT_PAGE = 3,
  SUBMIT_TICKET = 4,
  TICKET_SENT = 5,
  SOMETHING_WENT_WRONG = 8,
}

export interface MessageState {
  messages: Message[]
  conversationId: string
  confidenceRatings: number[]
  ticketSubmitted: boolean
  timestamp?: number
  needsHuman: boolean
  isBarryTyping: boolean
  chatEndsAt?: number
}

export interface Message {
  content: string
  fromAgent: boolean
  fromBarry: boolean
  sender: string
  timestamp: number
  needsHuman?: boolean
  hasErrored?: boolean
  errorMessage?: string
  isOffence?: boolean
  time?: string
  forceEndConversation?: boolean
}
export interface UserExperienceState {
  chatBubbleBeenClosedOn?: number
  isBarryOpen?: boolean
  forceEndConversation?: boolean
}

export interface BarryState {
  message: MessageState
  page: Page
  userExperience: UserExperienceState
}

const getMessageInitialState = (): MessageState => ({
  messages: [],
  conversationId: uuidv4(),
  confidenceRatings: [],
  ticketSubmitted: false,
  timestamp: undefined,
  needsHuman: false,
  isBarryTyping: false,
})

const userExperienceInitialState: UserExperienceState = {
  chatBubbleBeenClosedOn: undefined,
  isBarryOpen: false,
  forceEndConversation: false,
}

const pageInitialState = Page.SPLASH_PAGE

const processMessage = (payload: MessageResponse, ...regexes: RegExp[]) => {
  const barryResponseMessage = payload.output[0]

  for (const regex of regexes) {
    if (barryResponseMessage.match(regex)) {
      return barryResponseMessage.replace(regex, '')
    }
  }
  return barryResponseMessage
}

export const sendMessage = createAsyncThunk(
  'message/sendMessage',
  async (
    { messageToSend, sessionToken }: { messageToSend: string; sessionToken: string | undefined },
    { dispatch, getState },
  ) => {
    const cdSessionAuth = sessionToken
    const message = (getState() as RootState).barry.message

    dispatch(
      barryApi.endpoints.sendMessage.initiate(
        {
          conversationId: message.conversationId,
          message: messageToSend,
          ...(cdSessionAuth && { sessionAuthHash: cdSessionAuth }),
        },
        //This cache key allows us to read if the request is flying anywhere in the code, provided we use the same key
        { fixedCacheKey: 'sendMessage' },
      ),
    )
  },
)

export const barrySlice = createSlice({
  name: 'barry',
  initialState: {
    message: getMessageInitialState(),
    pageNumber: pageInitialState,
    userExperience: userExperienceInitialState,
  },
  reducers: {
    addMessage(state, { payload }: PayloadAction<Message>) {
      state.message.messages.push(payload)
    },
    resetMessageState: state => {
      state.message = getMessageInitialState()
    },
    setTyping(state, { payload }: PayloadAction<boolean>) {
      state.message.isBarryTyping = payload
    },
    setMessageError(
      state,
      { payload }: PayloadAction<{ timestamp: number; errorMessage: string }>,
    ) {
      state.message.messages = state.message.messages.map(message =>
        message.timestamp === payload.timestamp
          ? { ...message, hasErrored: true, errorMessage: payload.errorMessage }
          : message,
      )
    },
    removeMessage(state, { payload }: PayloadAction<number>) {
      state.message.messages = state.message.messages.filter(
        message => message.timestamp !== payload,
      )
    },
    setForceEndConversation(state, action: PayloadAction<boolean>) {
      state.userExperience.forceEndConversation = action.payload
    },
    switchPage(state, action: PayloadAction<Page>) {
      state.pageNumber = action.payload
    },
    clearPageState: state => {
      state.pageNumber = Page.SPLASH_PAGE
    },
    removeChatEndsAt: state => {
      state.message.chatEndsAt = undefined
    },
    setChatBubbleBeenClosedOn(state, action: PayloadAction<number>) {
      state.userExperience.chatBubbleBeenClosedOn = action.payload
    },
    clearUserExperienceState: state => {
      state.userExperience = userExperienceInitialState
    },
    setIsBarryOpen(state, action: PayloadAction<boolean>) {
      state.userExperience.isBarryOpen = action.payload
    },
    setChatEndsAt(state, action: PayloadAction<number>) {
      state.message.chatEndsAt = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(barryApi.endpoints.sendMessage.matchFulfilled, (state, action) => {
      const chatEndsAt = new Date().getTime() + 120 * 60 * 1000

      state.userExperience.forceEndConversation = false
      state.message.chatEndsAt = chatEndsAt

      const data = action.payload
      if (action.payload.output[0]) {
        const { conversationId, output, intents } = action.payload

        if (state.message.conversationId !== conversationId) {
          return
        }

        state.message.timestamp = Math.floor(Date.now() / 1000)
        state.message.confidenceRatings.push(...intents.map(intent => intent.confidence))

        const needsHumanRegexNewFormat = /{{action-human}}/g
        const needsHuman = !!output[0].match(needsHumanRegexNewFormat)
        state.message.needsHuman = needsHuman

        const endConversationRegex = /{{action-end-conversation}}/g
        const endConversation = !!output[0].match(endConversationRegex)

        if (endConversation) {
          state.userExperience.forceEndConversation = true
        }

        const messageContent = processMessage(data, needsHumanRegexNewFormat, endConversationRegex)

        const message: Message = {
          content: messageContent,
          fromAgent: false,
          fromBarry: true,
          sender: 'barry',
          timestamp: Math.floor(Date.now() / 1000),
          needsHuman,
          time: getCurrentTime(),
          forceEndConversation: endConversation,
        }

        state.message.messages.push(message)
      }
    }),
      builder.addMatcher(barryApi.endpoints.sendMessage.matchRejected, (state, action) => {
        const error = action.payload?.data as BarryChatErrorResponse
        const errorStatus = action.payload?.status.toString()

        if (
          errorStatus === errorCodes.BARRY_SEND_MESSAGE &&
          error.reason === ErrorTypes.ConversationConcluded
        ) {
          state.message = getMessageInitialState()
          state.pageNumber = Page.SPLASH_PAGE
        }

        if (
          errorStatus === errorCodes.BARRY_RATE_LIMIT_EXCEEDED ||
          errorStatus === errorCodes.BARRY_INTERNAL_SERVER_ERROR
        ) {
          const messages = state.message.messages
          const timestamp = messages[messages.length - 1].timestamp
          state.message.messages = state.message.messages.map(message =>
            message.timestamp === timestamp
              ? {
                  ...message,
                  hasErrored: true,
                  errorMessage:
                    action.payload?.status.toString() === errorCodes.BARRY_RATE_LIMIT_EXCEEDED
                      ? 'Rate limit exceeded. Wait a moment and tap to retry'
                      : '!Failed - Tap to resend',
                }
              : message,
          )
        }
      })
    builder.addMatcher(supportApi.endpoints.sendSupportTicket.matchFulfilled, state => {
      state.pageNumber = Page.TICKET_SENT
    })
  },
})

export const {
  setIsBarryOpen,
  clearUserExperienceState,
  setChatBubbleBeenClosedOn,
  clearPageState,
  switchPage,
  setForceEndConversation,
  removeMessage,
  setMessageError,
  setTyping,
  removeChatEndsAt,
  resetMessageState,
  addMessage,
  setChatEndsAt,
} = barrySlice.actions
