import React, { ReactElement } from 'react'
import MobileHeaderClose from 'images/barry/mobile-header-close.svg'
import ControlDLogo from 'images/barry/controld-logo-small.svg'
import SplashScreen from 'components/Barry/SplashScreen'
import SubmitTicketView from 'components/Barry/SubmitTicketView'
import TicketSent from 'components/Barry/TicketSent'
import { useAppSelector } from 'store/hooks'
import { Page } from 'store/barry/barry'
import { Flex, IconButton } from 'theme-ui'
import BarryChatScreen from 'components/Barry/BarryChatScreen'
import DescribeYourIssue from 'components/Barry/DescribeYourIssue'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { Svg } from 'ui'
import useGetColorMode from 'utils/useGetColorMode'

export default function BarryWindow({
  handleBarryIconToggle,
}: {
  handleBarryIconToggle: () => void
}): ReactElement {
  const page = useAppSelector(x => x.barry.pageNumber)
  const isMobile = useBreakpointIndex() === 0
  const { isBarryOpen } = useAppSelector(x => x.barry.userExperience)
  const { isLightMode } = useGetColorMode()
  const isChatPage = page === Page.CHAT_PAGE

  return (
    <Flex
      id="barry-window"
      data-testid="barry-window"
      sx={{
        zIndex: 'zIndex625',
        margin: 0,
        fontFamily: 'body',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        overflow: 'clip',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: 'blueYonder30',
        boxShadow: '0px 5px 45px -5px rgba(0, 0, 0, 45%)',
        borderRadius: [0, '1.6rem'],
        transitionProperty: 'opacity',
        transitionDuration: '125ms',
        opacity: isBarryOpen ? 1 : 0,
        pointerEvents: isBarryOpen ? 'auto' : 'none',
        position: 'fixed',
        height: ['100%', 'calc(100% - 96px)'],
        maxHeight: ['none', '80rem'],
        width: ['100%', '36rem', '36rem'],
        bottom: [0, `8.4rem`],
        right: [0, '2.4rem'],
        backgroundColor: 'blue',
        justifyContent: 'center',
        '@media screen and (max-height: 450px)': {
          overflow: !isChatPage ? 'scroll' : ' none',
        },
      }}
    >
      <Flex
        sx={{
          height: '7.2rem',
          width: '100%',
          alignItems: 'center',
          zIndex: 'zIndex630',
          justifyContent: ['space-between', 'center'],
          borderBottom: '1px solid',
          borderColor: 'borderFaint',
          flexShrink: 0,
          p: '1.6rem',
          '@media screen and (max-height: 450px)': {
            backgroundColor: 'smokyBlack',
            position: !isChatPage ? 'fixed' : 'static',
            top: 0,
          },
        }}
      >
        <Svg svg={ControlDLogo} fill="aliceBlue" />
        {isMobile && (
          <IconButton
            aria-label="barry mobile close button"
            data-testid="barry-mobile-close-button"
            sx={{
              height: '4.8rem',
              width: '4.8rem',
              mr: '-1.5rem',
            }}
            onClick={handleBarryIconToggle}
          >
            <Svg svg={MobileHeaderClose} fill="aliceBlue" />
          </IconButton>
        )}
      </Flex>
      <Flex
        data-testid="barry-window-container"
        sx={{
          flex: 1,
          alignItems: 'center',
          backgroundColor: isLightMode && 'isabelline',
        }}
      >
        {page === Page.SPLASH_PAGE && <SplashScreen />}
        {page === Page.DESCRIBE_ISSUE_PAGE && <DescribeYourIssue />}
        {page === Page.CHAT_PAGE && (
          <BarryChatScreen handleBarryIconToggle={handleBarryIconToggle} />
        )}
        {page === Page.SUBMIT_TICKET && <SubmitTicketView />}
        {page === Page.TICKET_SENT && <TicketSent handleBarryIconToggle={handleBarryIconToggle} />}
      </Flex>
    </Flex>
  )
}
