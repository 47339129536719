import DeviceSecurityIcon from 'images/organization/homepage/headerNavMenu/solutionsIcons/device-security-icon.svg'
import NetworkProtectionIcon from 'images/organization/homepage/headerNavMenu/solutionsIcons/network-protection-icon.svg'
import ContentFilteringIcon from 'images/organization/homepage/headerNavMenu/solutionsIcons/content-filter-icon.svg'
import DataInsightsIcon from 'images/organization/homepage/headerNavMenu/solutionsIcons/data-insight-icon.svg'
import PrivacyShield from 'images/organization/homepage/headerNavMenu/solutionsIcons/privacy-shield-icon.svg'

export enum SolutionType {
  DeviceSecurity = 'device-security',
  NetworkProtection = 'network-protection',
  ContentFiltering = 'content-filtering',
  DataInsights = 'data-insights',
  PrivacyShield = 'privacy-shield',
}

export const solutions = [
  {
    header: 'Device Security',
    icon: DeviceSecurityIcon,
    description:
      'Protect staff from malicious attacks, phishing and many types of harmful online content with just a few clicks.',
    href: `/#${SolutionType.DeviceSecurity}`,
  },
  {
    header: 'Network Protection',
    icon: NetworkProtectionIcon,
    description:
      'Safeguard against threats before a connection is even made. Block malicious or undesirable domains across networks.',
    href: `/#${SolutionType.NetworkProtection}`,
  },
  {
    header: 'Content Filtering',
    icon: ContentFilteringIcon,
    description:
      'Block categories of websites, individual web services, apps and specific domain names with a single click.',
    href: `/#${SolutionType.ContentFiltering}`,
  },
  {
    header: 'Data Insights',
    icon: DataInsightsIcon,
    description:
      'Gain actionable insights on web activity inside your network, or on individual devices. Store your data anywhere.',
    href: `/#${SolutionType.DataInsights}`,
  },
  {
    header: 'Privacy Shield',
    icon: PrivacyShield,
    description:
      'Go beyond basic blocking and improve the online privacy of your staff to reduce the chance of a targeted attack.',
    href: `/#${SolutionType.PrivacyShield}`,
  },
]
