import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import { Input } from 'ui'

const SupportTicketInput = ({
  label,
  inputTestId,
  onChange,
  value,
  name,
  required,
  type,
}: {
  label: string
  inputTestId: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  name: string
  required?: boolean
  type: string
}): ReactElement => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '0.8rem',
      }}
    >
      <Flex>
        <Input
          label={label}
          data-testid={inputTestId}
          name={name}
          value={value}
          labelColor="aliceBlue70"
          color="white"
          required={required}
          onChange={onChange}
          type={type}
          sx={{
            borderRadius: '1.2rem',
          }}
        />
      </Flex>
    </Flex>
  )
}

export default SupportTicketInput
