import { ApiResponse } from '../http'
import { RuleAction } from 'utils/uniteRules'
import { EnabledStatus } from 'store/api/rules'

export enum FilterOptionType {
  SERVICE = 'service',
  COUNTERFILTER = 'counterfilter',
  IPFILTER = 'ipfilter',
}
export enum FilterLevel {
  RELAXED = 'Relaxed',
  BALANCED = 'Balanced',
  STRICT = 'Strict',
}
export interface FiltersLevel {
  title: FilterLevel
  type: FilterOptionType
  name: string
  opt?: { PK: string; value: number | string }[]
  status: EnabledStatus
}
export interface FiltersOption extends FiltersLevel {
  description: string
}

export interface Resolver {
  v4: string[]
  v6: string[]
}

export interface Filter {
  PK: string
  description: string
  additional?: string
  name: string
  sources: string[]
  action?: RuleAction
  levels?: FiltersLevel[]
  resolvers?: Resolver
  /**
   * this is the name of the icon to use for the filter level. For filter levels,
   * the PK is the name of the level e.g. "ads_small" but the iconName is "ads",
   * This field is populated in getFilterTextForFiltersWithLevels method
   */
  iconName?: string // icons for levels
  global?: Filter //global Filter
}

export type GetFiltersResponse = ApiResponse<{
  filters: Filter[]
}>

export type PutFiltersResponse = ApiResponse<{ filters: Record<string, RuleAction> }>
