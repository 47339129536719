import React, { ReactElement } from 'react'
import { Button, Svg } from 'ui'
import CloseIcon from 'images/menu-close.svg'
import { ThemeUIStyleObject } from 'theme-ui'

export default function CloseButton({
  sx,
  onClick,
  dataTestId,
}: {
  sx?: ThemeUIStyleObject
  dataTestId?: string
  onClick?: () => void
}): ReactElement {
  return (
    <Button
      variant="simple"
      ariaLabel="close dialog"
      sx={{
        width: 'fit-content',
        p: 0,
        border: 0,
        textAlign: 'center',
        justifyContent: 'center',
        '&:hover > svg': {
          opacity: 1,
        },
        flexShrink: 0,
        ...sx,
      }}
      onClick={onClick}
      data-testid={dataTestId ?? 'close-button'}
    >
      <Svg
        svg={CloseIcon}
        fill="aliceBlue"
        sx={{ width: '2.4rem', height: '2.4rem', opacity: 0.6 }}
      />
    </Button>
  )
}
