import { Setter } from 'utils'
import { addCode, TwoFactorStep } from 'store/twofa'
import React, { FormEvent, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Flex, Text } from 'theme-ui'
import { Input } from 'ui'
import { LoadingState } from 'store/fetchingLogic'
import { userApi } from 'store/api/user'
import ContinueButton from '../ContinueButton'

export default function EnableTwoFaStep({
  setStep,
}: {
  setStep: Setter<TwoFactorStep>
}): React.ReactElement {
  const dispatch = useAppDispatch()
  const twofa = useAppSelector(s => s.twofa)
  const [code, setCode] = useState('')

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await dispatch(
      addCode({
        code,
        setTwofaStep: setStep,
        closeEditing: () => {},
      }),
    )
    await dispatch(userApi.endpoints.getUser.initiate(''))
  }

  return (
    <Flex
      as="form"
      onSubmit={onSubmit}
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: '1.2rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
      }}
    >
      <Text
        variant="size15Weight400Line138"
        sx={{ maxWidth: ['100%', '30.1rem'], color: 'aliceBlue60' }}
      >
        Now enter the code generated by the app.
      </Text>
      <Input
        isCompact
        data-testid="enable-two-factor-code"
        label="Code"
        aria-label="current password"
        autoComplete="current-password"
        type="password"
        id="confirm-two-factor-password"
        name="password"
        value={code}
        onChange={e => setCode(e.target.value)}
        containerStyle={{
          width: ['100%', '100%', 'auto'],
        }}
      />

      <ContinueButton
        isRequestInFlight={twofa.loading === LoadingState.PENDING}
        ariaLabel="enable twofa"
        dataTestId="enable-twofa"
        onClick={onSubmit}
      />
    </Flex>
  )
}
