import React, { ReactElement, ReactNode } from 'react'
import { Tray } from 'ui'
import { EntryDirection } from 'ui/Tray/Tray.interface'
import { ThemeUIStyleObject } from 'theme-ui'
import useGetUser from 'components/Dashboard/utils/useGetUser'

interface FreeDNSTrayProps {
  isOpen: boolean
  dismiss?: () => void
  showOverlay?: boolean
  entersFrom?: EntryDirection
  slideDimensionLength?: string
  sxTray?: ThemeUIStyleObject
  zIndexName?: string
  children: ReactNode
}
const FreeDNSTray = ({
  isOpen,
  dismiss,
  showOverlay,
  entersFrom = 'right',
  slideDimensionLength,
  children,
  sxTray,
  zIndexName,
}: FreeDNSTrayProps): ReactElement => {
  const { data: userData } = useGetUser()
  const isHapticFeedbackEnabled = userData?.haptics

  return (
    <Tray
      isOpen={isOpen}
      dismiss={dismiss}
      entersFrom={entersFrom}
      slideDimensionLength={slideDimensionLength}
      showOverlay={showOverlay}
      shouldVibrateOnPresent={!!isHapticFeedbackEnabled}
      zIndexName={zIndexName || 'zIndex450'}
      sx={{
        display: isOpen ? 'block' : 'none',
        flexDirection: 'column',
        backgroundColor: 'darkItemBG',
        borderTopLeftRadius: entersFrom === 'bottom' && '24px',
        borderTopRightRadius: entersFrom === 'bottom' && '24px',
        ...sxTray,
      }}
    >
      {children}
    </Tray>
  )
}

export default FreeDNSTray
