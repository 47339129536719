import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface OptimizationState {
  /** the selected suborg for editing or deleting */
  selectedSubOrgPk?: string
  /** the selected provision for sending email code */
  selectProvisionPk?: string
  /** the selected report for editing or deleting */
  selectedReportPk?: string
}

const initialState: OptimizationState = {
  selectedSubOrgPk: '',
}

/**
 * This async thunk is used to trigger impersonation. All slices that need to
 * clean their data can subscribe to this action and clear their data.
 */
export const clearStateForImpersonation = createAsyncThunk(
  'organization/clearStateForImpersonation',
  async (_, { fulfillWithValue }) => fulfillWithValue(''),
)

export const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setSelectedSubOrgPk(state, action: PayloadAction<string>): void {
      state.selectedSubOrgPk = action.payload
    },
    setSelectProvisionPk(state, action: PayloadAction<string | undefined>): void {
      state.selectProvisionPk = action.payload
    },
    setSelectedReportPk(state, action: PayloadAction<string | undefined>): void {
      state.selectedReportPk = action.payload
    },
  },
})

export const { setSelectedSubOrgPk, setSelectProvisionPk, setSelectedReportPk } =
  organizationSlice.actions
