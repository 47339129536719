import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import { ApiResponse, getFile } from 'store/api/http'
import {
  CheckInviteCodeResponse,
  GetMemberResponse,
  GetOrganizationResponse,
  GetSubOrganizationsResponse,
  Member,
  ModifyAdminRequest,
  Organization,
  Permission,
  SubOrgRequestBody,
} from 'store/api/organization/organization.interface'
import { userApi } from 'store/api/user'
import { setRegion } from 'store/session'

export const getSubOrgPricingCSV = (): Promise<Response> => {
  return getFile(`/organizations/sub-organizations/csv`)
}

export const organizationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    checkInviteCode: builder.query({
      query: ({ hash }: { hash: string }) =>
        getQueryArgs(`/organizations/invite?org_invite_hash=${hash}`),
      transformErrorResponse,
      transformResponse: (response: CheckInviteCodeResponse) => response.body,
    }),
    getOrganization: builder.query({
      query: () => getQueryArgs('/organizations/organization'),
      transformResponse: (response: GetOrganizationResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Organization'],
    }),
    getMembers: builder.query({
      query: () => getQueryArgs('/organizations/members'),
      transformResponse: (response: GetMemberResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Member'],
    }),
    getSubOrganizations: builder.query({
      query: () => getQueryArgs('/organizations/sub_organizations'),
      transformResponse: (response: GetSubOrganizationsResponse) => response.body,
      transformErrorResponse,
      providesTags: ['SubOrg'],
    }),
    editOrganization: builder.mutation({
      query: (organization: Partial<Organization>) =>
        getQueryArgs('/organizations', 'PUT', organization),
      transformErrorResponse,
      async onQueryStarted(organization, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            organizationApi.util.updateQueryData('getOrganization', '', draft => {
              draft.organization = data.body.organization
            }),
          )

          dispatch(
            userApi.util.updateQueryData('getUser', '', draft => {
              draft.org = { ...draft.org, ...data.body.organization }
            }),
          )

          dispatch(setRegion(data.body.organization.stats_endpoint))
        } catch {}
      },
    }),
    getAdminPermissionLevels: builder.query({
      query: () => getQueryArgs('/organizations/permission_levels'),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<{ permission_levels: Map<number, string> }>) =>
        Object.entries(response.body.permission_levels).map(([level, printable]) => ({
          level: parseInt(level),
          printable: printable.toString(),
        })),
    }),
    inviteAdmin: builder.mutation({
      query: ({ email, permission }: { email: string; permission: Permission }) =>
        getQueryArgs('/organizations/invite', 'POST', {
          email,
          permission: permission.level,
        }),
      transformErrorResponse,
      invalidatesTags: ['Member'],
    }),
    modifyAdmin: builder.mutation({
      query: ({ adminPk, permission }: ModifyAdminRequest) =>
        getQueryArgs('/organizations/member', 'PUT', {
          user: adminPk,
          permission_level: permission.level,
        }),
      async onQueryStarted(
        { adminPk, permission }: ModifyAdminRequest,
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled
          dispatch(
            organizationApi.util.updateQueryData('getMembers', '', draft => {
              const admin = draft.members.find(m => m.PK === adminPk)
              if (admin) {
                admin.permission = permission
              }
            }),
          )
        } catch {}
      },
      transformErrorResponse,
    }),
    deleteAdmin: builder.mutation({
      query: ({ adminPk }: { adminPk: string }) =>
        getQueryArgs(`/organizations/member/${adminPk}`, 'DELETE'),
      async onQueryStarted({ adminPk }: { adminPk: string }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            organizationApi.util.updateQueryData('getMembers', '', draft => {
              draft.members = draft.members.filter(m => m.PK !== adminPk)
            }),
          )
        } catch {}
      },
      transformErrorResponse,
    }),

    createSubOrganization: builder.mutation({
      query: (body: SubOrgRequestBody) => getQueryArgs(`/organizations/suborg`, 'POST', body),
      transformResponse: (response: ApiResponse<{ organization: Organization }>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(body: SubOrgRequestBody, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            organizationApi.util.updateQueryData('getSubOrganizations', '', draft => {
              draft.sub_organizations = [data.organization, ...draft.sub_organizations]
            }),
          )
        } catch {}
      },
      transformErrorResponse,
    }),
    updateSubOrganization: builder.mutation({
      query: ({ pk, body }: { pk: string; body: SubOrgRequestBody }) =>
        getQueryArgs(`/organizations/suborg/${pk}`, 'PUT', body),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<{ organization: Organization }>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(
        { pk }: { pk: string; body: SubOrgRequestBody },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            // should be '', not {} in the second arg, otherwise data is not updated
            organizationApi.util.updateQueryData('getSubOrganizations', '', draft => {
              draft.sub_organizations = draft.sub_organizations.map(subOrg => {
                if (subOrg.PK === pk) {
                  return data.organization
                }

                return subOrg
              })
            }),
          )
        } catch {}
      },
    }),
    deleteSubOrganization: builder.mutation({
      query: ({ pk, body }: { pk: string; body: { password: string } }) =>
        getQueryArgs(`/organizations/suborg/${pk}`, 'DELETE', body),
      transformErrorResponse,
      transformResponse: (response: GetSubOrganizationsResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(
        { pk }: { pk: string; body: { password: string } },
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled
          dispatch(
            // should be '', not {} in the second arg, otherwise data is not updated
            organizationApi.util.updateQueryData('getSubOrganizations', '', draft => {
              draft.sub_organizations = draft.sub_organizations?.filter(m => m.PK !== pk)
            }),
          )
        } catch {}
      },
    }),
    getAllOrgMembers: builder.query({
      query: () => getQueryArgs(`/organizations/members/all-sub-orgs`),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<{ members: Member[] }>) => response.body.members,
    }),
    getOrgTypes: builder.query({
      query: () => getQueryArgs(`/organizations/types`),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<{ types: Record<string, string> }>) =>
        response.body,
    }),
  }),
})

export const {
  endpoints,
  useGetOrganizationQuery,
  useGetMembersQuery,
  useGetSubOrganizationsQuery,
  useEditOrganizationMutation,
  useGetAdminPermissionLevelsQuery,
  useInviteAdminMutation,
  useDeleteAdminMutation,
  useModifyAdminMutation,
  useCreateSubOrganizationMutation,
  useUpdateSubOrganizationMutation,
  useDeleteSubOrganizationMutation,
  useGetAllOrgMembersQuery,
  useGetOrgTypesQuery,
} = organizationApi
