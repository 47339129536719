import React, { ReactElement } from 'react'
import { Box, Text } from 'theme-ui'
import MyOrgImage from 'images/tutorialFlows/MyOrg/my-org-slide.svg'
import MyOrgImageLight from 'images/tutorialFlows/MyOrg/my-org-slide-light.svg'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import useGetColorMode from 'utils/useGetColorMode'

export function MyOrgSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? MyOrgImageLight : MyOrgImage}
      description={
        <Box>
          <Text>
            Use your <Highlight svg={tutorialSlideIcons.org}>Organization</Highlight> account to
            invite team members, manage large numbers of{' '}
            <Highlight svg={tutorialSlideIcons.endpoints}>Endpoint</Highlight> DNS resolvers, and
            easily provision more{' '}
            <Highlight svg={tutorialSlideIcons.endpoints}>Endpoints</Highlight>. <br /> <br /> You
            can also keep track of{' '}
            <Highlight svg={tutorialSlideIcons.analytics}>Analytics</Highlight> across your whole
            Organization, enforce settings like 2FA and create{' '}
            <Highlight svg={tutorialSlideIcons.subOrg}>Sub-Organizations</Highlight> for your
            customers or departments, who will be able to manage their own assets separately.
          </Text>
        </Box>
      }
    />
  )
}
