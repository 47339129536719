import cards from './cards'
import forms from './forms'
import buttons from './buttons'
import tables from './tables'
import alerts from './alerts'
import links from './links'
import text from './text'

export default {
  cards,
  forms,
  buttons,
  tables,
  alerts,
  links,
  text,
}
