import { useState, useEffect, useRef, useCallback } from 'react'
import useQueryString from 'utils/useQueryString'
import { useTraysVisibility } from '../ui/Tray/TrayContextProvider'

export default function useFocusLock(isCustomTrayOpened?: boolean): void {
  const focusRef = useRef<HTMLDivElement | null>(null)
  const { isAnyTrayOpen } = useTraysVisibility()
  const { qs } = useQueryString()
  const [eventTarget, setEventTarget] = useState(null)
  const isTrayOrModalDialogOpened =
    isAnyTrayOpen || isCustomTrayOpened || qs.helpPane || qs.tray || qs.tutorial

  useEffect(() => {
    if (isTrayOrModalDialogOpened && eventTarget && !focusRef.current) {
      focusRef.current = eventTarget
    }

    if (!isTrayOrModalDialogOpened && focusRef.current) {
      focusRef.current?.focus()
      focusRef.current = null
    }
  }, [eventTarget, isTrayOrModalDialogOpened])

  const lockFocus = useCallback(event => {
    if (event.key === 'Enter' || event.code === 'Space') {
      setEventTarget(event.target)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', lockFocus)
    return () => {
      document.removeEventListener('keydown', lockFocus)
    }
  }, [lockFocus])
}
