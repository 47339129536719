import React from 'react'
import { Button as _Button } from 'theme-ui'
import { ButtonProps } from './index'

export default React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  props: ButtonProps,
  ref,
): React.ReactElement {
  const {
    ariaLabel,
    children,
    disabled = false,
    sx = {},
    onClick = (): void => {},
    ...attrs
  } = props

  return (
    <_Button
      variant="primary"
      ref={ref}
      onClick={onClick}
      sx={{
        ...sx,
      }}
      disabled={disabled}
      role="button"
      aria-label={ariaLabel}
      tabIndex={0}
      // default value is submit. we change it to button to prevent form submission
      type="button"
      {...attrs}
    >
      {children}
    </_Button>
  )
})
