import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { ApiResponse } from 'store/api/http'
import { Resolvers } from 'store/api/devices/devices.interface'
import { SerializedError } from '@reduxjs/toolkit'

export enum StatusType {
  INVALIDATED = -1,
  LIMIT_REACHED = 0,
  ACTIVE = 1,
}

export interface Provision {
  org: string
  profile_id: string
  ts: number
  ts_exp: number
  code: string
  status: StatusType
  icon: string
  stats: StatLevel
  max: number
  count: number
  name_prefix: string
  PK: string
  profile: {
    PK: string
    updated: number
    name: string
  }
  ctrld: {
    cmd: string
    script: string
    filename: string
  }
  expired: number
  deactivation_pin?: number
}

export type GetProvisionResponse = ApiResponse<{
  provisions: Provision[]
}>

export interface CreateProvisionRequestBody {
  profile_id: string
  ts_exp: number
  max: number
  icon: string
  stats: StatLevel
  name_prefix?: string
}

export interface ProvisionResponseData {
  provision: Provision
}

export type PostProvisionResponse = ApiResponse<ProvisionResponseData>

export interface ConsumeProvision {
  devices: {
    resolvers: Resolvers
    icon: string
  }
}

export interface ValidateDeviceUid {
  type: string
  resolver: Resolvers
  uid: string
  icon: string
}

export type ValidateDeviceResponse = { data: ValidateDeviceUid } & { error: SerializedError }

export type ConsumeProvisionResponseBody = ApiResponse<ConsumeProvision>

export const provisionApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProvision: builder.query({
      query: () => getQueryArgs('/provision'),
      transformResponse: (response: GetProvisionResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Provision'],
    }),
    createProvision: builder.mutation({
      query: ({ body }: { body: CreateProvisionRequestBody }) =>
        getQueryArgs('/provision', 'POST', body),
      transformResponse: (response: PostProvisionResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            provisionApi.util.updateQueryData('getProvision', '', draft => {
              draft.provisions = [data.provision, ...draft.provisions]
            }),
          )
        } catch {}
      },
    }),
    putProvisionEmail: builder.mutation({
      query: ({ pk, body }: { pk: string; body: { email: string } }) =>
        getQueryArgs(`/provision/${pk}/email`, 'PUT', body),
      transformErrorResponse,
      transformResponse: (response: ApiResponse) => response.body,
    }),
    putProvisionInvalidate: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/provision/${pk}/invalidate`, 'PUT'),
      transformResponse: (response: ApiResponse) => response.body,
      transformErrorResponse,
      invalidatesTags: ['Provision'],
    }),
    deleteProvision: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/provision/${pk}`, 'DELETE'),
      transformResponse: (response: ApiResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted({ pk }: { pk: string }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            provisionApi.util.updateQueryData('getProvision', '', draft => {
              draft.provisions = draft.provisions.filter(m => m.PK !== pk)
            }),
          )
        } catch {}
      },
    }),
    consumeProvision: builder.mutation({
      query: ({ body }: { body: { code: string; device_name: string } }) =>
        getQueryArgs('/provision/consume', 'POST', body),
      transformResponse: (response: ConsumeProvisionResponseBody) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
    }),
    validateDeviceUID: builder.mutation({
      query: ({ uid }: { uid: string }) =>
        getQueryArgs('/utility', 'POST', { uid, hide_unusable_v4: 1 }),
      transformResponse: (response: ApiResponse<ValidateDeviceUid>, _, arg) => {
        throwErrorFromResponseData(response)

        return {
          ...response.body,
          uid: arg.uid,
        }
      },
      transformErrorResponse,
    }),
  }),
})

export const {
  useGetProvisionQuery,
  useCreateProvisionMutation,
  usePutProvisionEmailMutation,
  usePutProvisionInvalidateMutation,
  useDeleteProvisionMutation,
  useConsumeProvisionMutation,
  useValidateDeviceUIDMutation,
} = provisionApi
