import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { initialResponseState, ResponseState } from '../fetchingLogic'
import { DefaultRule, defaultRuleApi, IPTypeDetails } from 'store/api/defaultRule'
import { RootState } from 'store/rootReducer'
import { ApiResponse, http, httpWithAbortByTimeout } from 'store/api/http'
import { V4_DOMAIN, V6_DOMAIN } from 'gatsby-env-variables'
import { sessionLogout } from 'store/session'
import { clearStateForImpersonation } from 'store/organization'

export const checkCurrentIp = createAsyncThunk(
  'defaultRule/getCurrentIp',
  async (_, { dispatch }) => {
    const randomSubDomain = Math.random().toString(36).substr(2, 5)
    let ipv4: IPTypeDetails | undefined = undefined
    let ipv6: IPTypeDetails | undefined = undefined

    const ipv4Call = http(`https://${randomSubDomain}.${V4_DOMAIN}/ip`, {
      method: 'GET',
    })
    const ipV6Call = httpWithAbortByTimeout(`https://${randomSubDomain}.${V6_DOMAIN}/ip`, {
      method: 'GET',
    })

    const [ipV4Response, ipV6Response] = await Promise.allSettled([ipv4Call, ipV6Call])

    if (ipV4Response.status === 'fulfilled') {
      const { ip, type } = (ipV4Response as PromiseFulfilledResult<ApiResponse<unknown>>).value
        .body as IPTypeDetails
      ipv4 = { ip, type }
    }

    if (ipV6Response.status === 'fulfilled') {
      const { ip, type } = (ipV6Response as PromiseFulfilledResult<ApiResponse<unknown>>).value
        .body as IPTypeDetails
      ipv6 = { ip, type }
    }

    dispatch(setIsCheckCurrentIpTimeoutActive(false))

    return { ipv4, ipv6 }
  },
)

export const awaitDnsSettingsUpdateAndCheckIp = createAsyncThunk(
  'defaultRule/awaitDnsSettingsUpdateAndCheckIp',
  async (_, { getState, dispatch }) => {
    if ((getState() as RootState).defaultRule.isCheckCurrentIpTimeoutActive) {
      return
    }
    dispatch(clearCurrentIPTypeDetails())
    dispatch(setIsCheckCurrentIpTimeoutActive(true))
    // need to wait for 500ms for the new dns settings to take effect
    setTimeout(async () => {
      await dispatch(checkCurrentIp())
    }, 500)
  },
)

export enum RedirectMode {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export interface CurrentIPTypeDetails {
  isFulfilled?: boolean
  ipv4?: IPTypeDetails
  ipv6?: IPTypeDetails
}
export interface DefaultRedirectInitialState extends ResponseState<{ default: DefaultRule }> {
  currentIPTypeDetails: CurrentIPTypeDetails
  originalIPTypeDetails?: IPTypeDetails
  redirectMode: RedirectMode.AUTO | RedirectMode.MANUAL
  lastUsedRedirectLocationPK: string
  isCheckCurrentIpTimeoutActive: boolean
}

const initialState: DefaultRedirectInitialState = {
  ...initialResponseState,
  redirectMode: RedirectMode.AUTO,
  lastUsedRedirectLocationPK: '',
  currentIPTypeDetails: {},
  isCheckCurrentIpTimeoutActive: false,
}

export const defaultRuleSlice = createSlice({
  name: 'defaultRule',
  initialState,
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    clearCurrentIPTypeDetails(state): void {
      state.currentIPTypeDetails = {}
    },
    setRedirectMode(state, action: PayloadAction<RedirectMode.AUTO | RedirectMode.MANUAL>): void {
      state.redirectMode = action.payload
    },
    setIsCheckCurrentIpTimeoutActive(state, action: PayloadAction<boolean>): void {
      state.isCheckCurrentIpTimeoutActive = action.payload
    },
    setLastUsedRedirectLocationPK(state, action: PayloadAction<string>): void {
      state.lastUsedRedirectLocationPK = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, () => initialState)
      .addCase(checkCurrentIp.pending, state => {
        state.currentIPTypeDetails.isFulfilled = false
      })
      .addCase(checkCurrentIp.fulfilled, (state, action) => {
        state.currentIPTypeDetails = { ...action.payload }
        !state.isCheckCurrentIpTimeoutActive && (state.currentIPTypeDetails.isFulfilled = true)
      })
      .addCase(checkCurrentIp.rejected, state => {
        state.currentIPTypeDetails.isFulfilled = false
      })
      .addCase(clearStateForImpersonation.fulfilled, () => initialState)
      .addMatcher(defaultRuleApi.endpoints.checkOriginalIp.matchFulfilled, (state, action) => {
        state.originalIPTypeDetails = action.payload as IPTypeDetails
      })
  },
})

export const {
  clearCurrentIPTypeDetails,
  setRedirectMode,
  setLastUsedRedirectLocationPK,
  setIsCheckCurrentIpTimeoutActive,
} = defaultRuleSlice.actions
