import palette from './palette'
import { ColorRoleToPaletteMap, ThemeMode } from './themeObject.interface'

// TODO: light theme colors should be updated
const colorMap: ColorRoleToPaletteMap = {
  // Test colors (colors should be used only from palette)
  primary: 'limeGreen',
  primaryShadow: 'darkItemBoxShadow',
  secondary: 'darkItemBG50',
  text: 'white',
  background: 'darkItemBG50',
  cardBackground: 'white',
  cardShadow: 'white',
  modalBackground: 'darkItemBG50',
  accent: 'white15',
  muted: 'white50',
  error: 'pomegranate',
  alertError: 'pomegranate15',
  active: 'white25',
  successBackground: 'greenApple15',
  scrollbarThumb: 'darkItemBG50',
  staticPageBackground: 'white',
  barryLink: 'white50',

  dialogElementBg: 'white5',
  dialogBodyBg: 'darkItemBG',
  subTextColor: 'white50',
  textColor: 'white50',
  labelColor: 'white',
  greenApple: 'limeGreen',
  blue800: 'isabelline',
  white8: 'culturedPearl',
  white10: 'black10',
  white6: 'black6',
  cardBg: 'white',
  white60: 'black60',
  white50: 'black50',
  white25: 'black25',
  plumpPurple: 'plumpPurple',
  aliceBlue: 'black',
  aliceBlue30: 'black30',
  aliceBlue60: 'black90',
  aliceBlue60Black60: 'black60',
  aliceBlue70: 'black70',
  blueYonder80: 'black60',
  blueYonder40: 'black30',
  blueYonder30: 'black15',
  blueYonder20: 'black5',
  blueYonder15: 'black10',
  white: 'black',
  white4: 'black4',
  darkJungleGreen: 'blueYonder10',
  black: 'white',
  blue: 'white',
  cyan800: 'littleBoyBlue',
  darkBodyBG: 'white',
  grayLogoBG: 'silverSpoon',
  darkItemBG: 'white',
  raisinBlack: 'white',
  darkItemBG50: 'white50',
  lightSteelBlue: 'black60',
  smokyBlack: 'white',
  aliceBlue40: 'black30',
  blackKnight: 'white',
  white15: 'black15',

  commonWhite: 'white',
  commonBlack: 'black',
  commonWhite60: 'white60',
  commonCyan800: 'cyan800',
  confirmAlertBG: 'aloeMist90',
}

const colors = Object.entries(colorMap).reduce((acc, [k, v]) => {
  acc[k] = palette[v]
  return acc
}, {})

const lightModeObj: ThemeMode = {
  light: {
    ...colors,
  },
}

export default lightModeObj
