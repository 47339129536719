import React, { forwardRef, ReactElement } from 'react'
import InputProps from './Input.interface'
import Input from './Input.base'
import { Flex } from 'theme-ui'
import SearchIcon from 'images/ui/search-icon-bold.svg'
import { Button, Svg } from 'ui'
import CloseIcon from 'images/menu-close.svg'
import omit from 'lodash/omit'

export default forwardRef<HTMLInputElement, InputProps>(function InputSearch(
  props: InputProps,
  ref,
): ReactElement {
  const {
    sx = {},
    containerStyle = {},
    svgContainerStyle = {},
    closeContainerStyle = {},
    clearInput,
    ...attrs
  } = props

  return (
    <Flex
      sx={{
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: 'blueYonder30',
        position: 'relative',
        ...containerStyle,
      }}
    >
      <Flex
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '2.4rem',
          backgroundColor: 'transparent',
          ...svgContainerStyle,
        }}
      >
        <Svg
          svg={SearchIcon}
          fill="aliceBlue60Black60"
          sx={{
            margin: '0 auto',
            alignSelf: 'center',
            flexShrink: 0,
          }}
        />
      </Flex>

      <Input
        ref={ref}
        variant="newSearch"
        sx={{ pl: '3.8rem', pr: '3.2rem', ...sx }}
        {...omit(attrs, 'hasFocus')}
      />

      {clearInput && props.value && (
        <Button
          ariaLabel="clear-search"
          variant="simple"
          sx={{
            p: 0,
            width: 'fit-content',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '1.2rem',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            ...closeContainerStyle,
          }}
          onClick={clearInput}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              clearInput()
            }
          }}
          tabIndex={0}
        >
          <Svg
            data-testid="clear-search"
            svg={CloseIcon}
            fill="aliceBlue60Black60"
            sx={{
              width: '2rem',
              height: '2rem',
              margin: '0 auto',
              flexShrink: 0,
            }}
          />
        </Button>
      )}
    </Flex>
  )
})
