import React, { ReactElement, RefObject, useCallback, useEffect, useState } from 'react'
import { Text, ThemeUIStyleObject } from 'theme-ui'
import debounce from 'lodash/debounce'
import { getClientAndScrollWidth } from './TextWithOverFlowAndTippyPopup'

function getHiddenElementsNumber(
  clientWidth?: number,
  scrollWidth?: number,
  elements?: string[],
  charWidth?: number,
) {
  if (
    !clientWidth ||
    !scrollWidth ||
    clientWidth > scrollWidth ||
    clientWidth === scrollWidth ||
    !elements?.length ||
    !charWidth
  ) {
    return 0
  } else {
    const lastElementLength = elements[elements.length - 1].length
    const lastElementWidth = lastElementLength * charWidth

    return (
      1 +
      getHiddenElementsNumber(
        clientWidth,
        Math.round(scrollWidth - lastElementWidth),
        elements.slice(0, -1),
        charWidth,
      )
    )
  }
}

export default function HiddenElementsNumber({
  textRef,
  content,
  sx,
}: {
  textRef?: RefObject<HTMLDivElement> | null
  content: string
  sx?: ThemeUIStyleObject
}): ReactElement | null {
  const [numberOfHiddenElements, setNumberOfHiddenElements] = useState(0)

  const debounceCallback = useCallback(() => {
    const { clientWidth, scrollWidth } = getClientAndScrollWidth(textRef)

    if (scrollWidth < clientWidth) {
      return
    }

    const charWidth = Math.round((scrollWidth || 0) / content.length)
    const elements = content?.split(',')

    setNumberOfHiddenElements(
      getHiddenElementsNumber(clientWidth, scrollWidth, elements, charWidth),
    )
  }, [content, textRef])

  useEffect(() => {
    if (!numberOfHiddenElements) {
      debounceCallback()
    }
  }, [debounceCallback, numberOfHiddenElements])

  useEffect(() => {
    const debouncedOptionChange = debounce(debounceCallback, 300)

    window.addEventListener('resize', debouncedOptionChange)

    return () => window.removeEventListener('resize', debouncedOptionChange)
  }, [debounceCallback])

  return numberOfHiddenElements ? (
    <Text sx={{ display: 'inline-flex', flexShrink: 0, ...sx }}>+{numberOfHiddenElements}</Text>
  ) : null
}
