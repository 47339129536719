import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'

interface LegacyIPDataRowProps {
  title: string
  primary?: string
  secondary?: string
  isHighlighted?: boolean
}

export default function LegacyIPDataRow({
  title,
  primary,
  secondary,
  isHighlighted,
}: LegacyIPDataRowProps): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'smokyBlack60',
        border: '1px solid',
        borderColor: 'blueYonder20',
        borderRadius: '0.8rem',
        px: '1.2rem',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
          my: '1.2rem',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Text
            variant="size12Weight700"
            sx={{
              mr: 0,
              color: isHighlighted ? 'greenApple' : 'aliceBlue60',
            }}
            tabIndex={0}
            aria-label={`${title} is ${secondary} ${isHighlighted ? 'and is highlighted' : ''}`}
          >
            {title}
          </Text>
          <Flex sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Text
              variant="size12Weight400"
              sx={{
                color: isHighlighted ? 'greenApple' : 'aliceBlue60',
                mr: ['0.8rem', '0.8rem', '1.6rem'],
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100%',
              }}
            >
              {primary}
            </Text>

            {primary && (
              <CopyToClipboard
                data={primary}
                smallIcon
                ariaLabelText="copy ip address to clipboard?"
                fillSvg={isHighlighted ? 'greenApple' : 'aliceBlue60'}
                sx={{ p: 0 }}
              />
            )}
          </Flex>
          <Flex sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Text
              variant="size12Weight500"
              sx={{
                color: isHighlighted ? 'greenApple' : 'aliceBlue60',
                mr: ['0.8rem', '0.8rem', '1.6rem'],
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100%',
              }}
            >
              {secondary}
            </Text>

            {secondary && (
              <CopyToClipboard
                data={secondary}
                smallIcon
                ariaLabelText="copy ip address to clipboard?"
                fillSvg={isHighlighted ? 'greenApple' : 'aliceBlue60'}
                sx={{ p: 0 }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
