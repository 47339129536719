import React, { ReactElement } from 'react'
import { Text, Flex } from 'theme-ui'

export default function TypingDotsAnimated(): ReactElement {
  return (
    <Flex
      sx={{
        '@keyframes blink': {
          '0%': { opacity: 0.2 },
          '20%': { opacity: 1 },
          '100%': { opacity: 0.2 },
        },
        '.saving span': {
          animationName: 'blink',
          animationDuration: '1.4s',
          animationIterationCount: 'infinite',
          animationFillMode: 'both',
          color: 'color50',
          fontSize: '1.2rem',
        },
        '.saving span:nth-of-type(2)': {
          animationDelay: '0.2s',
        },
        '.saving span:nth-of-type(3)': {
          animationDelay: '0.4s',
        },
      }}
    >
      <Flex className="saving" sx={{ flexDirection: 'row' }}>
        <Text>.</Text>
        <Text>.</Text>
        <Text>.</Text>
      </Flex>
    </Flex>
  )
}
