import React, { ReactElement } from 'react'
import { Text, ThemeUIStyleObject } from 'theme-ui'

export default function PreferencesSectionTitle({
  title,
  sx,
}: {
  title: string
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Text
      variant="size12Weight700Uppercase"
      sx={{ mb: '1.2rem', mt: ['1.2rem', 0], color: 'aliceBlue60', ml: ['1.2rem', 0], ...sx }}
    >
      {title}
    </Text>
  )
}
