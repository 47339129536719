export default {
  nav: {
    //default
    cursor: 'pointer',
    color: ({ colors }): string => `${colors.muted} !important`,
  },
  primary: {
    cursor: 'pointer',
    color: ({ colors }): string => `${colors.muted} !important`,
  },
  secondary: {
    cursor: 'pointer',
    color: ({ colors }): string => `${colors.background} !important`,
  },
}
