import React, { ReactElement, useMemo } from 'react'
import { CONTROLDText } from 'ui/CONTROLD'
import { Text } from 'theme-ui'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

const ControlDVersionAndDateInfo = (): ReactElement => {
  const lastUpdate = useMemo(() => {
    const date = new Date(process.env.TIME_STAMP || '')

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }, [])

  return (
    <>
      <TextWithOverFlowAndTippyPopup
        variant="size12Weight400"
        testId="header-title"
        content={`${CONTROLDText} ${process.env.TAG_NAME || process.env.REF_NAME?.split('-')[0]}`}
        ariaLabel={`${CONTROLDText} ${process.env.TAG_NAME || process.env.REF_NAME?.split('-')[0]}`}
        sxText={{
          maxWidth: ['28rem', '20rem'],
          color: 'aliceBlue60',
        }}
      />
      <Text variant="size12Weight400" sx={{ color: 'aliceBlue30' }}>{`Updated ${lastUpdate}`}</Text>
    </>
  )
}

export default ControlDVersionAndDateInfo
