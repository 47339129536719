import React, { ReactNode, ReactElement, useState, useEffect } from 'react'
import { Flex, Box, ThemeUIStyleObject, useThemeUI } from 'theme-ui'
import { Global } from '@emotion/react'
import { navigate } from 'gatsby'
import { customDesktopScrollbarStyle } from 'ui'
import Footer from 'components/StaticPage/StaticPageFooter'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useEntryTracking } from 'utils/campaignTracking'
import { resetTrackingData } from 'store/tracking'
import { StaticPageHeader as NewStaticPageHeader } from 'components/HomePage/Header'
import NavMenuTray from 'components/HomePage/Header/MobileNavMenuTray/MobileNavMenuTray'

export const staticPageHeaderHeight = ['8rem', '9.6rem', '10rem']

// used in free dns page. moved here to prevent a circular reference error
export const staticPageMarginTop = staticPageHeaderHeight.map(headerHeight => `-${headerHeight}`)

export const staticPageDesktopMaxWidth = '108rem'

export interface LinkInfo {
  text: string
  href?: string
  to?: string
  ariaLabel?: string
}

export interface StaticPageProps {
  shouldShowFooter?: boolean
  children?: ReactNode
  headerChildren?: ReactNode
  hasMaxWidth?: boolean
  shouldShowOnlyLogo?: boolean
  containerStyles?: ThemeUIStyleObject
  headerStyles?: ThemeUIStyleObject
}

export default function StaticPage({
  children,
  headerChildren,
  containerStyles,
  hasMaxWidth = true,
  shouldShowOnlyLogo = false,
  shouldShowFooter = !shouldShowOnlyLogo,
}: StaticPageProps): ReactElement {
  const dispatch = useAppDispatch()
  const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false)
  const trackingRedirectURL = useAppSelector(s => s.tracking.trackingRedirectURL)
  const { theme } = useThemeUI()

  useEntryTracking()

  useEffect(() => {
    trackingRedirectURL && navigate(trackingRedirectURL)
    dispatch(resetTrackingData())
  }, [trackingRedirectURL, dispatch])

  return (
    <>
      <Global styles={customDesktopScrollbarStyle(theme)} />

      <Flex
        sx={{
          // 100vw (rather than 100%) so that content doesn't jump when custom
          // scrollbar appears/ disappears on desktop
          width: '100vw',
          position: 'relative',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'darkBodyBG',
          ...containerStyles,
        }}
      >
        <NewStaticPageHeader
          shouldUseMainHeader={!shouldShowOnlyLogo}
          setIsMobileNavMenuOpen={setIsMobileNavMenuOpen}
        >
          {headerChildren}
        </NewStaticPageHeader>
        <Flex
          sx={{
            flex: '1',
            maxWidth: hasMaxWidth ? staticPageDesktopMaxWidth : undefined,
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Flex sx={{ flex: 1, justifyContent: 'center' }}>{children}</Flex>
        </Flex>
        {shouldShowFooter && (
          <Box
            sx={{
              px: ['2rem', undefined, 0],
              mt: 6,
              maxWidth: staticPageDesktopMaxWidth,
              width: '100%',
            }}
          >
            <Footer />
          </Box>
        )}
      </Flex>
      <NavMenuTray dismiss={() => setIsMobileNavMenuOpen(false)} isOpen={isMobileNavMenuOpen} />
    </>
  )
}
