// Trims protocol, trailing slash, and spaces, and makes lowercase

export function sanitizeUrl(url: string, shouldRemoveWWW = false): string {
  let sanitizedUrl = url
    .replace('https://', '') // remove leading https://
    .replace('http://', '') // remove leading http://
    .toLowerCase() // hostnames always need to be lowercase. prevents auto-capitalization on mobile
    .trim()

  if (shouldRemoveWWW) {
    sanitizedUrl = sanitizedUrl.replace('www.', '')
  }

  if (sanitizedUrl.indexOf('/') !== -1) {
    return sanitizedUrl.slice(0, sanitizedUrl.indexOf('/'))
  }

  if (sanitizedUrl.indexOf('?') !== -1) {
    return sanitizedUrl.slice(0, sanitizedUrl.indexOf('?'))
  }

  return sanitizedUrl
}
