import React, { ReactElement, useMemo, ReactNode } from 'react'
import ColumnContainer from './components/ColumnContainer'
import DataColumn from './DataColumn'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import chunk from 'lodash/chunk'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel'
import { Flex, Box } from 'theme-ui'

export default function FlexTable({
  data,
  shouldTransposeData = false,
}: {
  data: (ReactNode | boolean)[][]
  shouldTransposeData?: boolean
}): ReactElement {
  const isTablet = useBreakpointIndex() === 1
  const tableData = useMemo(() => {
    const transposedData = shouldTransposeData
      ? data[0].map((_, colIndex) => data.map(row => row[colIndex]))
      : data

    const firstColumn = transposedData[0]
    const partitions = isTablet ? chunk(transposedData.slice(1), 3) : [transposedData.slice(1)]
    return { firstColumn, partitions }
  }, [shouldTransposeData, data, isTablet])
  return (
    <Box sx={{ display: ['none', 'block'], width: ['auto', 'auto', '102rem'] }}>
      <ColumnContainer>
        <DataColumn
          key={`column-1st`}
          columnData={tableData.firstColumn}
          columnIndex={0}
          totalColumns={tableData.partitions.length + 1}
          isFirstColumn
        />
        <TabletGrid partitions={tableData.partitions} />
        <DeskTopGrid tableData={tableData.partitions[0]} />
      </ColumnContainer>
    </Box>
  )
}

const DeskTopGrid = ({ tableData }: { tableData: (ReactNode | boolean)[][] }) => (
  <ColumnContainer dataTestId="desktop-grid" sx={{ display: ['none', 'none', 'flex'] }}>
    {tableData.map((col, columnIndex) => (
      <DataColumn
        key={`column-${columnIndex}`}
        columnData={col}
        columnIndex={columnIndex}
        totalColumns={tableData.length}
        isControlDColumn={columnIndex === 0}
      />
    ))}
  </ColumnContainer>
)

const TabletGrid = ({ partitions }: { partitions: (ReactNode | boolean)[][][] }) => (
  <Box
    data-testid="tablet-grid-container"
    sx={{
      display: ['none', 'block', 'none'],
      width: '75%',
    }}
  >
    <CarouselProvider
      data-testid="tablet-grid"
      naturalSlideWidth={10}
      naturalSlideHeight={22}
      totalSlides={partitions.length}
    >
      <Slider>
        {partitions.map((tableData, tableIndex) => (
          <Slide key={tableIndex} index={tableIndex}>
            <ColumnContainer>
              {tableData.map((col, columnIndex) => (
                <DataColumn
                  key={`column-${col[0]}`}
                  columnData={col}
                  columnIndex={columnIndex}
                  totalColumns={tableData.length}
                  isControlDColumn={tableIndex === 0 && columnIndex === 0}
                />
              ))}
            </ColumnContainer>
          </Slide>
        ))}
      </Slider>
      <Flex sx={{ justifyContent: 'center', mt: '-1.8rem', mb: '5.2rem' }}>
        {partitions.map((_, index) => {
          return <Dot slide={index} key={`dot-${index}`} />
        })}
      </Flex>
    </CarouselProvider>
  </Box>
)
