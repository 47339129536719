import CheckboxProps from './Checkbox.interface'
import DefaultCheckedIcon from 'images/ui/checkbox-checked.svg'
import DefaultUncheckedIcon from 'images/ui/checkbox-default.svg'
import React, { ReactElement } from 'react'
import { Label, Box, Text, Flex } from 'theme-ui'
import { Svg } from 'ui'

const CheckboxIcon = ({
  checkedIcon = <Svg svg={DefaultCheckedIcon} />,
  uncheckedIcon = <Svg svg={DefaultUncheckedIcon} />,
}: CheckboxProps): ReactElement => {
  return (
    <>
      <Box
        sx={{
          display: 'none',
          'input:checked + * &': {
            display: 'block',
          },
          svg: {
            fill: 'greenApple',
          },
          'input:focus-visible + * &': {
            outline: '-webkit-focus-ring-color auto 1px',
          },
        }}
      >
        <Flex>{checkedIcon}</Flex>
      </Box>
      <Box
        className="checkedIconBox"
        sx={{
          display: 'block',
          'input:checked + * &': {
            display: 'none',
          },
          'input:focus-visible + * &': {
            outline: '-webkit-focus-ring-color auto 1px',
          },
        }}
      >
        <Flex>{uncheckedIcon}</Flex>
      </Box>
    </>
  )
}

export default React.forwardRef<HTMLDivElement, CheckboxProps>(function Checkbox(
  {
    sx,
    containerSx,
    variant = 'primary',
    text,
    onChange = (): void => {},
    isChecked,
    defaultChecked,
    name,
    value,
    textVariant,
    ...props
  }: CheckboxProps,
  ref,
): ReactElement {
  // if isChecked, then give control to consumer, else keep it here
  const controlProp =
    isChecked === undefined && defaultChecked ? { defaultChecked } : { checked: isChecked }
  return (
    // The label is necessary, even if there is no text. The checkbox will not
    // toggle on click without it.
    // If wrapper around this component have onClick and user click on checkbox directly,
    // label wrapper from theme ui will call onClick from its parent twice(propagation and additionally)
    // decision: create additional wrapper without onClick and next wrapper with click handler
    <Label
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        '&:hover': {
          '& > span': {
            color: 'aliceBlue',
          },
          '& .checkedIconBox > div > svg > rect': {
            stroke: 'blueYonder80',
          },
        },
        ...containerSx,
      }}
    >
      <Box
        data-testid="checkbox-input"
        ref={ref}
        as="input"
        // @ts-ignore
        name={name}
        value={value}
        type="checkbox"
        role="checkbox"
        {...props}
        {...controlProp}
        onChange={(event): void => {
          // @ts-ignore
          onChange(event.currentTarget.checked)
        }}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: 'zIndex-100',
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      />
      <Box
        aria-hidden="true"
        // @ts-ignore
        __themeKey="forms"
        variant={variant}
        sx={{
          ...sx,
          mr: text ? 2 : 0,
          borderRadius: 0,
          color: 'darkItemBG',
          'input:checked ~ &': {
            color: 'greenApple',
          },
          'input:focus ~ &': {
            color: 'greenApple',
          },
        }}
      >
        <CheckboxIcon checkedIcon={props.checkedIcon} uncheckedIcon={props.uncheckedIcon} />
      </Box>
      <Text
        variant={textVariant || 'size15Weight400'}
        sx={{ color: isChecked ? 'aliceBlue' : 'aliceBlue60' }}
      >
        {text}
      </Text>
    </Label>
  )
})
