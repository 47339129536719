import { RuleType } from 'store/api/rules/rules.interface'
import BlockedQueriesIcon from 'images/menus/analytics/blocked-queries-icon.svg'
import BypassedQueriesIcon from 'images/menus/analytics/bypassed-queries-icon.svg'
import GlobeIcon from 'images/small-globe-icon.svg'
import OtherDNSErrorIcon from 'images/error.svg'

const actionIcons = {
  [RuleType.BLOCK]: BlockedQueriesIcon,
  [RuleType.WHITELIST]: BypassedQueriesIcon,
  [RuleType.SPOOF_TAG]: GlobeIcon,
  [RuleType.RESET]: OtherDNSErrorIcon,
}

export default actionIcons
