import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import { resolverText } from 'components/SetupGuide/ResolverTabs/Components/ResolverTabTitle'

export interface SecureResolverData {
  resolverId?: string
  doh?: string
  dot?: string
}
export default function SecureResolver({
  isResolverTypeHighlighted,
  canCopy,
  address,
  shouldShowFullAddress,
  type,
}: {
  isResolverTypeHighlighted: boolean
  address: string
  canCopy: boolean
  shouldShowFullAddress?: boolean
  type?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minWidth: '100%',
        px: '1.2rem',
        backgroundColor: 'smokyBlack60',
        border: '1px solid',
        borderColor: 'blueYonder20',
        borderRadius: '0.8rem',
      }}
    >
      <Flex
        sx={{
          py: '1.2rem',
          minWidth: '100%',
          flexShrink: 0,
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {type && (
          <Text
            variant="size12Weight700"
            sx={{
              whiteSpace: 'nowrap',
              flexShrink: 0,
              mr: '0.8rem',
              color: isResolverTypeHighlighted ? 'greenApple' : 'aliceBlue60',
            }}
          >
            {resolverText[type]}
          </Text>
        )}
        <Flex sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
          <Text
            variant="size12Weight500"
            sx={{
              color: isResolverTypeHighlighted ? 'greenApple' : 'aliceBlue60',
              mr: '2rem',
              overflow: 'hidden',
              textAlign: 'left',
              ...(shouldShowFullAddress
                ? { wordWrap: 'break-word' }
                : {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%', // to enable the textoverflow
                  }),
              minWidth: '20rem',
            }}
            tabIndex={0}
          >
            {address}
          </Text>
          {canCopy && (
            <CopyToClipboard
              data={address}
              smallIcon
              ariaLabelText="copy resolver to clipboard"
              fillSvg={isResolverTypeHighlighted ? 'greenApple' : 'aliceBlue60'}
              sx={{ p: 0 }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
