import { RefObject, useEffect } from 'react'
import debounce from 'lodash/debounce'
import { getClientAndScrollWidth } from 'components/TextWithOverFlowAndTippyPopup'

export default function useIsLongTextTruncated(
  textRef?: RefObject<HTMLDivElement> | null,
  onTextTruncation?: () => void,
): void {
  useEffect(() => {
    if (!onTextTruncation || !textRef?.current) {
      return
    }
    const debouncedOptionChange = debounce(() => {
      const { clientWidth, scrollWidth } = getClientAndScrollWidth(textRef)

      if (scrollWidth > clientWidth) {
        onTextTruncation()
      }
    }, 100)

    debouncedOptionChange()

    window.addEventListener('resize', debouncedOptionChange)

    return () => window.removeEventListener('resize', debouncedOptionChange)
  }, [onTextTruncation, textRef])
}
