import { useLayoutEffect } from 'react'

// body-loader is added in the gatsby-ssr.tsx
// it is important to use this hook in a specific place to hide the loader in time
export default function useHideBodyLoader(shouldHideLoader = true): void {
  useLayoutEffect(() => {
    if (shouldHideLoader) {
      const loader = document.getElementById('body-loader')

      if (loader) {
        loader.style.display = 'none'
      }
    }
  }, [shouldHideLoader])
}
