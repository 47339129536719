import {
  SetupGuideIntermediateStates,
  SetupGuideMode,
  SetupOs,
} from 'components/SetupGuide/SetupGuide.interface'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import useQueryString from 'utils/useQueryString'

type SetupFlows = {
  auto: () => void
  manual: () => void
}

export default function useStartSetupFlow(): SetupFlows {
  const { setupGuideNav, qs } = useQueryString()

  const getSetupGuideNav = (os, mode, isSetupOS = false) => {
    const navObj = {
      ...qs,
      helpPane: os,
      mode: mode,
    }

    if (isSetupOS) {
      navObj.setupOs = os
    }

    return setupGuideNav(navObj)
  }

  switch (qs.setupOs) {
    case SetupOs.WINDOWS:
    case SetupOs.WINDOWS_SERVER:
      const isWindowsOs = qs.setupOs === SetupOs.WINDOWS

      return {
        auto: () =>
          getSetupGuideNav(
            isWindowsOs ? SetupOs.WINDOWS_OS : SetupOs.WINDOWS_SERVER,
            SetupGuideMode.AUTO,
            true,
          ),
        manual: () =>
          getSetupGuideNav(SetupGuideIntermediateStates.WindowsVersion, SetupGuideMode.MANUAL),
      }
    case SetupOs.MAC:
      return {
        auto: () => getSetupGuideNav(SetupOs.MAC_OS, SetupGuideMode.AUTO, true),
        manual: () =>
          getSetupGuideNav(SetupGuideIntermediateStates.MacVersion, SetupGuideMode.MANUAL),
      }
    case SetupOs.LINUX:
      return {
        auto: () => getSetupGuideNav(SetupOs.LINUX_OS, SetupGuideMode.AUTO, true),
        manual: () =>
          window.open(
            `${DOCS_CONTROL_D_DOMAIN}/docs/linux-platform`,
            '_blank',
            'noopener, noreferrer',
          ),
      }
    case SetupOs.ASUS:
    case SetupOs.DD_WRT:
    case SetupOs.OPEN_WRT:
    case SetupOs.UBIQUITI:
    case SetupOs.FRESH_TOMATO:
    case SetupOs.GL_INET:
    case SetupOs.GENERIC_LINUX:
    case SetupOs.FIREWALLA:
    case SetupOs.SYNOLOGY:
      return {
        auto: () => getSetupGuideNav(SetupOs.ROUTER, SetupGuideMode.AUTO, true),
        manual: () =>
          window.open(
            `${DOCS_CONTROL_D_DOMAIN}/docs/routers-platform`,
            '_blank',
            'noopener, noreferrer',
          ),
      }

    case SetupOs.PF_SENSE:
      return {
        auto: () => getSetupGuideNav(SetupOs.PF_SENSE, SetupGuideMode.AUTO, true),
        manual: () =>
          window.open(
            `${DOCS_CONTROL_D_DOMAIN}/docs/routers-platform`,
            '_blank',
            'noopener, noreferrer',
          ),
      }
    case SetupOs.OPN_SENSE:
      return {
        auto: () => getSetupGuideNav(SetupOs.OPN_SENSE, SetupGuideMode.AUTO, true),
        manual: () =>
          window.open(
            `${DOCS_CONTROL_D_DOMAIN}/docs/routers-platform`,
            '_blank',
            'noopener, noreferrer',
          ),
      }
    case SetupOs.ANDROID:
      return {
        auto: () => getSetupGuideNav(SetupOs.ANDROID_OS, SetupGuideMode.AUTO, true),
        manual: () => getSetupGuideNav(SetupOs.ANDROID, SetupGuideMode.MANUAL, true),
      }
    case SetupOs.IOS:
      return {
        auto: () => getSetupGuideNav(SetupOs.IOS_OS, SetupGuideMode.AUTO, true),
        manual: () => getSetupGuideNav(SetupOs.IOS, SetupGuideMode.MANUAL, true),
      }
    default: {
      return {
        auto: () => setupGuideNav({ ...qs, helpPane: qs.setupOs, setupOs: qs.setupOs, step: '0' }),
        manual: () => setupGuideNav({ ...qs, helpPane: SetupGuideIntermediateStates.Platform }),
      }
    }
  }
}
