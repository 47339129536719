import { DNS_V4, DNS_V4_ALT, DNS_V6, DNS_V6_ALT, FREE_DNS_DOMAIN } from 'gatsby-env-variables'

export enum DnsProtocol {
  LEGACY_DNS_V4,
  LEGACY_DNS_V4_ALT,
  LEGACY_DNS_V6,
  LEGACY_DNS_V6_ALT,
  DNS_OVER_HTTPS,
  DNS_OVER_TLS,
}

export enum DnsProtocolCollection {
  ALL_LEGACY,
  ALL_SECURE,
}

export interface DnsServers {
  unfiltered: string
  blockMalware: string
  blockMalwareAndAds: string
  blockMalwareAdsAndSocial: string
  family: string
  uncensored: string
}

export const dnsServersByProtocol: Record<DnsProtocol, DnsServers> = {
  [DnsProtocol.LEGACY_DNS_V4]: {
    unfiltered: `${DNS_V4}.0`,
    blockMalware: `${DNS_V4}.1`,
    blockMalwareAndAds: `${DNS_V4}.2`,
    blockMalwareAdsAndSocial: `${DNS_V4}.3`,
    family: `${DNS_V4}.4`,
    uncensored: `${DNS_V4}.5`,
  },
  [DnsProtocol.LEGACY_DNS_V4_ALT]: {
    unfiltered: `${DNS_V4_ALT}.0`,
    blockMalware: `${DNS_V4_ALT}.1`,
    blockMalwareAndAds: `${DNS_V4_ALT}.2`,
    blockMalwareAdsAndSocial: `${DNS_V4_ALT}.3`,
    family: `${DNS_V4_ALT}.4`,
    uncensored: `${DNS_V4_ALT}.5`,
  },
  [DnsProtocol.LEGACY_DNS_V6]: {
    unfiltered: `${DNS_V6}:`,
    blockMalware: `${DNS_V6}:1`,
    blockMalwareAndAds: `${DNS_V6}:2`,
    blockMalwareAdsAndSocial: `${DNS_V6}:3`,
    family: `${DNS_V6}:4`,
    uncensored: `${DNS_V6}:5`,
  },
  [DnsProtocol.LEGACY_DNS_V6_ALT]: {
    unfiltered: `${DNS_V6_ALT}:`,
    blockMalware: `${DNS_V6_ALT}:1`,
    blockMalwareAndAds: `${DNS_V6_ALT}:2`,
    blockMalwareAdsAndSocial: `${DNS_V6_ALT}:3`,
    family: `${DNS_V6_ALT}:4`,
    uncensored: `${DNS_V6_ALT}:5`,
  },
  [DnsProtocol.DNS_OVER_HTTPS]: {
    unfiltered: `https://${FREE_DNS_DOMAIN}/p0`,
    blockMalware: `https://${FREE_DNS_DOMAIN}/p1`,
    blockMalwareAndAds: `https://${FREE_DNS_DOMAIN}/p2`,
    blockMalwareAdsAndSocial: `https://${FREE_DNS_DOMAIN}/p3`,
    family: `https://${FREE_DNS_DOMAIN}/family`,
    uncensored: `https://${FREE_DNS_DOMAIN}/uncensored`,
  },
  [DnsProtocol.DNS_OVER_TLS]: {
    unfiltered: `p0.${FREE_DNS_DOMAIN}`,
    blockMalware: `p1.${FREE_DNS_DOMAIN}`,
    blockMalwareAndAds: `p2.${FREE_DNS_DOMAIN}`,
    blockMalwareAdsAndSocial: `p3.${FREE_DNS_DOMAIN}`,
    family: `family.${FREE_DNS_DOMAIN}`,
    uncensored: `uncensored.${FREE_DNS_DOMAIN}`,
  },
}

export const configDownloadPathsByResolverType: Record<keyof DnsServers, string> = {
  unfiltered: 'p0',
  blockMalware: 'p1',
  blockMalwareAndAds: 'p2',
  blockMalwareAdsAndSocial: 'p3',
  family: 'family',
  uncensored: 'uncensored',
}
