import React, { ReactElement, useEffect, useRef } from 'react'
import { Button, CircleIconWrapper, Input, Svg } from 'ui'
import SearchIcon from 'images/searchicon.svg'
import CloseIcon from 'images/menu-close.svg'
import useCaptureCtrlOrCmdFClick from 'utils/useCaptureCtrlOrCmdFClick'
import { ThemeUIStyleObject } from 'theme-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setIsSearchExpanded } from 'store/dashboard/dashboard'

interface SearchBar {
  name: string
  searchText: string
  onChange: (
    event: React.ChangeEvent<HTMLInputElement> & React.SyntheticEvent<HTMLInputElement, InputEvent>,
  ) => void
  onPaste?: React.ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  clearSearch: () => void
  sx?: ThemeUIStyleObject
  tabIndex?: number
}

export default function SearchBar({
  name,
  searchText,
  onChange,
  onPaste,
  clearSearch,
  sx,
  tabIndex,
}: SearchBar): ReactElement {
  const dispatch = useAppDispatch()
  const isSearchExpanded = useAppSelector(s => s.dashboard.isSearchExpanded)
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    return () => {
      dispatch(setIsSearchExpanded?.(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (isSearchExpanded) {
      searchInputRef.current?.focus()
    } else {
      clearSearch()
    }
  }, [isSearchExpanded, clearSearch])

  useCaptureCtrlOrCmdFClick(() => {
    if (isSearchExpanded) {
      searchInputRef.current?.focus()
    } else {
      dispatch(setIsSearchExpanded?.(true))
    }
  })

  return (
    <CircleIconWrapper
      sx={{
        position: 'relative',
        height: '4rem',
        width: isSearchExpanded ? '23.8rem' : '4rem',
        borderRadius: isSearchExpanded ? '0.8rem' : '50%',
        justifyContent: isSearchExpanded && 'left',
        color: 'aliceBlue',
        backgroundColor: 'transparent',
        '&:hover ': {
          backgroundColor: 'transparent',
        },
        transition: `width ${isSearchExpanded ? '0.3s' : '0s'}, border-radius ${
          isSearchExpanded ? '0.1s' : '0s'
        }, background-color 0.3s`,
        ...sx,
      }}
    >
      {isSearchExpanded && (
        <Input
          isCompact
          variant="newSearch"
          ref={searchInputRef}
          data-testid={`search-${name}`}
          id={`search-${name}`}
          name={`search-${name}`}
          placeholder="Search All"
          aria-label="search all"
          value={searchText}
          onChange={onChange}
          onPaste={onPaste}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              dispatch(setIsSearchExpanded?.(false))
            }
          }}
          autoComplete="off"
          onBlur={() => {
            if (!searchText) {
              dispatch(setIsSearchExpanded?.(!isSearchExpanded))
              clearSearch()
            }
          }}
        />
      )}
      <Button
        data-testid={isSearchExpanded ? 'clear-search' : 'search-bar'}
        ariaLabel="search bar"
        variant="simple"
        tabIndex={tabIndex}
        onClick={() => {
          dispatch(setIsSearchExpanded?.(!isSearchExpanded))
          clearSearch()
        }}
        sx={{
          width: '3.8rem',
          height: '3.8rem',
          position: isSearchExpanded ? 'absolute' : 'static',
          right: 0,
          p: '0.8rem',
          flexShrink: 0,
          borderRadius: '100%',
          backgroundColor: isSearchExpanded ? 'transparent' : 'white6',
          svg: {
            opacity: 0.6,
          },
          '&:hover ': {
            svg: {
              opacity: 1,
            },
          },
        }}
      >
        <Svg svg={isSearchExpanded ? CloseIcon : SearchIcon} fill="aliceBlue" />
      </Button>
    </CircleIconWrapper>
  )
}
