import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sessionLogout } from 'store/session'
import omit from 'lodash/omit'
import { RuleType } from 'store/api/rules/rules.interface'
import { RedirectMode } from 'store/defaultRule'

type RuleTooltipSettings = {
  selectedRuleType: RuleType | RedirectMode
  hasSeenBlockTooltip?: boolean
  hasSeenBypassTooltip?: boolean
  hasSeenRedirectTooltip?: boolean
  hasSeenDefaultRuleRedirectAutoTooltip?: boolean
  hasSeenDefaultRuleRedirectManualTooltip?: boolean
}

interface SetRuleTooltipSettingsPayload {
  selectedRuleType?: RuleType | RedirectMode
  hasSeenBlockTooltip?: boolean
  hasSeenBypassTooltip?: boolean
  hasSeenRedirectTooltip?: boolean
  hasSeenDefaultRuleRedirectAutoTooltip?: boolean
  hasSeenDefaultRuleRedirectManualTooltip?: boolean
  userPk: string
}

type TutorialDeniedType = {
  wasTutorialDenied?: boolean
}

export enum TutorialType {
  PROFILES = 'profiles',
  ENDPOINTS = 'endpoints',
  PROVISION = 'provision',
  MY_ORG = 'my-org',
  SUB_ORGS = 'sub-orgs',
  ORG_BILLING = 'org-billing',
  CUSTOM_RULES = 'custom-rules',
  FILTERS = 'filters',
  SERVICES = 'services',
  PROFILE_OPTIONS = 'profile-options',
  SETUP_GUIDE = 'setup-guide',
  STATISTICS = 'statistics',
  ACTIVITY_LOG = 'activity-log',
  UPCOMING_FEATURES = 'upcoming-features',
  ADMIN_LOGS = 'admin-logs',
  REPORTS = 'reports',
  CLIENTS = 'clients',
}

export type TutorialViewedState = Record<
  TutorialType,
  {
    hasBeenViewed: boolean
  }
> &
  TutorialDeniedType &
  RuleTooltipSettings

export interface TutorialState {
  viewedStateByUserPk: Record<string, TutorialViewedState>
}

interface SetHasTutorialBeenViewedPayload {
  tutorialType: TutorialType | string
  hasBeenViewed?: boolean
  userPk: string
  wasTutorialDenied?: boolean
}

const defaultViewedTutorialState: TutorialViewedState = {
  [TutorialType.CUSTOM_RULES]: {
    hasBeenViewed: false,
  },
  [TutorialType.FILTERS]: {
    hasBeenViewed: false,
  },
  [TutorialType.SERVICES]: {
    hasBeenViewed: false,
  },
  [TutorialType.PROFILE_OPTIONS]: {
    hasBeenViewed: false,
  },
  [TutorialType.ENDPOINTS]: {
    hasBeenViewed: false,
  },
  [TutorialType.PROVISION]: {
    hasBeenViewed: false,
  },
  [TutorialType.MY_ORG]: {
    hasBeenViewed: false,
  },
  [TutorialType.SUB_ORGS]: {
    hasBeenViewed: false,
  },
  [TutorialType.ORG_BILLING]: {
    hasBeenViewed: false,
  },
  [TutorialType.PROFILES]: {
    hasBeenViewed: false,
  },
  [TutorialType.SETUP_GUIDE]: {
    hasBeenViewed: false,
  },
  [TutorialType.STATISTICS]: {
    hasBeenViewed: false,
  },
  [TutorialType.ACTIVITY_LOG]: {
    hasBeenViewed: false,
  },
  [TutorialType.ADMIN_LOGS]: {
    hasBeenViewed: false,
  },
  [TutorialType.UPCOMING_FEATURES]: {
    hasBeenViewed: false,
  },
  [TutorialType.REPORTS]: {
    hasBeenViewed: false,
  },
  [TutorialType.CLIENTS]: {
    hasBeenViewed: false,
  },
  wasTutorialDenied: false,
  selectedRuleType: RuleType.RESET,
  hasSeenBlockTooltip: false,
  hasSeenBypassTooltip: false,
  hasSeenRedirectTooltip: false,
  hasSeenDefaultRuleRedirectAutoTooltip: false,
  hasSeenDefaultRuleRedirectManualTooltip: false,
}

const defaultTutorialState: TutorialState = {
  viewedStateByUserPk: {},
}

export const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState: defaultTutorialState,
  reducers: {
    setHasTutorialBeenViewed(state, action: PayloadAction<SetHasTutorialBeenViewedPayload>): void {
      const tutorialViewedState = state.viewedStateByUserPk[action.payload.userPk] ?? {
        ...defaultViewedTutorialState,
      }

      const prevViewedStateForTutorialOfType = tutorialViewedState[action.payload.tutorialType]

      state.viewedStateByUserPk[action.payload.userPk] = {
        ...tutorialViewedState,
        wasTutorialDenied: !!action.payload.wasTutorialDenied,
        [action.payload.tutorialType]: {
          ...prevViewedStateForTutorialOfType,
          hasBeenViewed: action.payload.hasBeenViewed,
        },
      }
    },
    setRuleTooltipSettings(state, action: PayloadAction<SetRuleTooltipSettingsPayload>): void {
      const tutorialViewedState = state.viewedStateByUserPk[action.payload.userPk] ?? {
        ...defaultViewedTutorialState,
      }

      state.viewedStateByUserPk[action.payload.userPk] = {
        ...tutorialViewedState,
        selectedRuleType: action.payload.selectedRuleType ?? tutorialViewedState.selectedRuleType,
        hasSeenBlockTooltip:
          action.payload.hasSeenBlockTooltip ?? tutorialViewedState.hasSeenBlockTooltip,
        hasSeenBypassTooltip:
          action.payload.hasSeenBypassTooltip ?? tutorialViewedState.hasSeenBypassTooltip,
        hasSeenRedirectTooltip:
          action.payload.hasSeenRedirectTooltip ?? tutorialViewedState.hasSeenRedirectTooltip,
        hasSeenDefaultRuleRedirectAutoTooltip:
          action.payload.hasSeenDefaultRuleRedirectAutoTooltip ??
          tutorialViewedState.hasSeenDefaultRuleRedirectAutoTooltip,
        hasSeenDefaultRuleRedirectManualTooltip:
          action.payload.hasSeenDefaultRuleRedirectManualTooltip ??
          tutorialViewedState.hasSeenDefaultRuleRedirectManualTooltip,
      }
    },
    resetTutorialStateForUserPk(state, action: PayloadAction<string>): void {
      state.viewedStateByUserPk[action.payload] = defaultViewedTutorialState
    },
  },
  extraReducers: builder => {
    builder.addCase(sessionLogout.fulfilled, (state, action) => {
      const PK = action.payload.PK ?? ''

      if (state.viewedStateByUserPk.wasTutorialDenied || !state.viewedStateByUserPk[PK]) {
        return
      }

      const propList = Object.keys(
        omit(defaultViewedTutorialState, [
          'wasTutorialDenied',
          'selectedRuleType',
          'hasSeenBlockTooltip',
          'hasSeenBypassTooltip',
          'hasSeenRedirectTooltip',
          'hasSeenDefaultRuleRedirectAutoTooltip',
          'hasSeenDefaultRuleRedirectManualTooltip',
        ]),
      )
      propList.forEach(item => {
        if (item) {
          const isCompleted = state.viewedStateByUserPk[PK]?.[item]?.hasBeenViewed
          if (!isCompleted) {
            // check if the item exists and add it if it does not. This is to prevent a logout error
            // for users who do not have the profile-management key in their tutorial state that was
            // persisted in local-storage before the profile-management tutorial was released
            if (state.viewedStateByUserPk[PK][item]) {
              state.viewedStateByUserPk[PK][item].hasBeenViewed = false
            } else {
              state.viewedStateByUserPk[PK] = {
                ...state.viewedStateByUserPk[PK],
                [item]: { hasBeenViewed: false },
              }
            }
          }
        }
      })
    })
  },
})

export const { setHasTutorialBeenViewed, resetTutorialStateForUserPk, setRuleTooltipSettings } =
  tutorialSlice.actions
