import { ActionType, DeviceActionType, InitialStateType } from './SettingsState'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import React, { Dispatch, ReactElement, useLayoutEffect, useMemo, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import ProfileSelectionDropDown, {
  ProfileDropDownItem,
} from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import { Button, ExternalLink, Svg } from 'ui'
import AddIcon from 'images/add-icon.svg'
import Tippy from '@tippyjs/react'
import { EnforcingStatus, useGetSchedulesQuery } from 'store/api/schedules'
import { useGetProfilesQuery } from 'store/api/profiles'
import DeleteIcon from 'images/delete-icon.svg'
import ScheduleIcon from 'images/dashboard/devices/shedule-icon.svg'
import VerticalDivider from 'ui/VerticalDivider'
import useQueryString from 'utils/useQueryString'
import { DeviceDialogType } from '../index'
import useGetColorMode from 'utils/useGetColorMode'

const MultipleProfiles = ({
  settingsState,
  settingsDispatch,
  devicePk,
  globalProfilePk,
}: {
  settingsState: InitialStateType
  settingsDispatch: Dispatch<ActionType>
  devicePk?: string
  globalProfilePk?: string
}): ReactElement => {
  const { qs, nav } = useQueryString()
  const [isSecondProfileVisible, setIsSecondProfileVisible] = useState(false)
  const { data: profilesData } = useGetProfilesQuery('')
  const { data: schedulesData } = useGetSchedulesQuery('')
  const { isLightMode } = useGetColorMode()

  const existingSchedule = useMemo(() => {
    return schedulesData?.schedules?.find(schedule => devicePk && devicePk === schedule?.device?.PK)
  }, [devicePk, schedulesData?.schedules])

  useLayoutEffect(() => {
    setIsSecondProfileVisible(!!settingsState.profile2)
  }, [settingsState.profile2])

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: '1.6rem',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          gap: '1.2rem',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <ProfileSelectionDropDown
          label="Enforced Profile"
          boundaryElementTestId="device-dialog"
          disabledProfilePk={settingsState.profile2?.PK.toString()}
          isDropdownDisabled={existingSchedule?.enforcing === EnforcingStatus.TRUE}
          globalProfilePk={globalProfilePk}
          selectedProfile={
            settingsState.profile
              ? { ...settingsState.profile, text: settingsState.profile?.name || '' }
              : undefined
          }
          setSelectedProfile={profile =>
            settingsDispatch({
              type: DeviceActionType.SELECTED_PROFILE,
              payload: profile as ProfileDropDownItem | undefined,
            })
          }
          description=""
          tooltipText={
            existingSchedule?.enforcing === EnforcingStatus.TRUE
              ? "Please disable or delete the Schedule before changing this Endpoint's Enforced Profile."
              : 'The Profile whose rules you want to enforce on this Endpoint'
          }
          sx={{
            flex: 1,
            flexShrink: isSecondProfileVisible && 0,
            p: 0,
          }}
        />
        <Flex sx={{ alignItems: 'center', gap: '1.2rem' }}>
          {!isSecondProfileVisible && (
            <Tippy
              theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
              arrow={false}
              content={
                <Box sx={{ fontSize: '1.2rem', textAlign: 'center' }}>
                  {!!existingSchedule
                    ? 'Second profile not allowed as a schedule exists on this device'
                    : 'Add second profile'}
                </Box>
              }
              maxWidth={200}
            >
              <Flex>
                <Button
                  variant="simple"
                  data-testid="add-remove-second-profile"
                  ariaLabel="add second profile"
                  disabled={
                    (profilesData?.profiles?.length || 0) +
                      (profilesData?.shared_profiles?.length || 0) <
                      2 || !!(devicePk && existingSchedule)
                  }
                  sx={{
                    width: '3.8rem',
                    height: '3.8rem',
                    alignItems: 'center',
                    p: 0,
                    ':disabled': {
                      backgroundColor: 'transparent',
                      'svg path': {
                        fill: 'aliceBlue60',
                      },
                    },
                  }}
                  hasBackgroundOnHover
                  onClick={() => {
                    setIsSecondProfileVisible(!isSecondProfileVisible)
                    settingsDispatch({
                      type: DeviceActionType.SELECTED_PROFILE_2,
                      payload: undefined,
                    })
                  }}
                >
                  <Svg
                    svg={AddIcon}
                    fill="aliceBlue60"
                    sx={{
                      width: '2.4rem',
                      height: '2.4rem',
                    }}
                  />
                </Button>
              </Flex>
            </Tippy>
          )}
          {devicePk && !existingSchedule && (
            <Flex
              sx={{
                alignItems: 'center',
                gap: '1.2rem',
              }}
            >
              {!isSecondProfileVisible && (
                <VerticalDivider sx={{ color: 'blueYonder15', height: '2.4rem' }} />
              )}
              <Tippy
                theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
                arrow={false}
                content={
                  <Box sx={{ fontSize: '1.2rem', textAlign: 'center' }}>
                    {!!devicePk && isSecondProfileVisible
                      ? 'Scheduling not available with multiple profiles'
                      : 'Create schedule'}
                  </Box>
                }
                maxWidth={200}
              >
                <Flex>
                  <Button
                    disabled={!!devicePk && isSecondProfileVisible}
                    variant="simple"
                    data-testid="create-schedule-button"
                    ariaLabel="create schedule button"
                    sx={{
                      p: 0,
                      ':disabled': {
                        backgroundColor: 'transparent',
                        'svg path': {
                          fill: 'aliceBlue60',
                        },
                      },
                      mb: isSecondProfileVisible ? '0.5rem' : 0,
                    }}
                    hasBackgroundOnHover
                    onClick={() => {
                      nav({ ...qs, deviceDialog: DeviceDialogType.SCHEDULE })
                    }}
                  >
                    <Svg
                      svg={ScheduleIcon}
                      fill="aliceBlue60"
                      sx={{
                        width: '2.4rem',
                        height: '2.4rem',
                      }}
                    />
                  </Button>
                </Flex>
              </Tippy>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isSecondProfileVisible && (
        <Flex
          sx={{
            width: isSecondProfileVisible ? '100%' : 'fit-content',
            gap: '1.6rem',
            alignItems: 'flex-end',
            flexShrink: !isSecondProfileVisible && 0,
            flexDirection: 'column',
          }}
        >
          <Flex sx={{ width: '100%', gap: '1.2rem', alignItems: 'flex-end' }}>
            <ProfileSelectionDropDown
              disabledProfilePk={settingsState.profile?.PK.toString()}
              globalProfilePk={globalProfilePk}
              label="Enforced Profile 2"
              testId="2nd-select-profile-menu"
              boundaryElementTestId="device-dialog"
              selectedProfile={
                settingsState.profile2
                  ? { ...settingsState.profile2, text: settingsState.profile2?.name || '' }
                  : undefined
              }
              setSelectedProfile={profile2 => {
                settingsDispatch({
                  type: DeviceActionType.SELECTED_PROFILE_2,
                  payload: profile2 as ProfileDropDownItem | undefined,
                })
              }}
              description=""
              tooltipText="The Profile whose rules you want to enforce on this Endpoint"
              sx={{
                width: isSecondProfileVisible ? ['100%', '24.2rem'] : '100%',
                flexShrink: isSecondProfileVisible && 0,
                p: 0,
                flex: 1,
              }}
            />
            <Tippy
              theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
              arrow={false}
              content={
                <Box sx={{ fontSize: '1.2rem', textAlign: 'center' }}>Remove second profile</Box>
              }
              maxWidth={200}
            >
              <Button
                ariaLabel="delete second profile"
                variant="simple"
                sx={{ p: 0 }}
                onClick={() => {
                  setIsSecondProfileVisible(!isSecondProfileVisible)
                  settingsDispatch({
                    type: DeviceActionType.SELECTED_PROFILE_2,
                    payload: undefined,
                  })
                }}
              >
                <Svg svg={DeleteIcon} fill="aliceBlue60" sx={{ mb: '0.8rem' }} />
              </Button>
            </Tippy>
          </Flex>
          <Text
            variant="size12Weight400"
            data-testid="multiple-linked-profile-message"
            sx={{ mr: '0.8rem', color: 'aliceBlue60' }}
          >
            {devicePk && existingSchedule
              ? ' Second profile not allowed as a schedule exists on this Endpoint.'
              : `Multiple linked profiles allow you to enforce rules from two profiles simultaneously when using this Endpoint.`}
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/multiple-enforced-profiles`}
              sx={{
                color: 'aliceBlue60',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default MultipleProfiles
