import { EnabledStatus } from 'store/api/rules'
import { userApi } from 'store/api/user'
import useQueryString from './useQueryString'

export function useIsTwoFaVisible(): boolean {
  const { qs } = useQueryString()
  const { data: userData, isFetching: isUserDataLoading } =
    userApi.endpoints.getUser.useQueryState('')

  return !!(
    userData?.org?.twofa_req &&
    userData?.twofa !== EnabledStatus.ENABLED &&
    userData?.email_status &&
    // do not show the 2fa popup when the user is impersonating a sub-org
    !qs.orgId &&
    !isUserDataLoading
  )
}
