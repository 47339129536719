// https://gitlab.int.windscribe.com/controld/backend/api/-/blob/master/master/Application.php#L16

//400s
export const ERROR_INVALID_SESSION = '40001'
export const ERROR_MISSING_REQUIRED = '40002' // configure resolver
export const ERROR_DUPLICATE_RESOURCE = '40003'
export const ERROR_INVALID_PARAMETER = '40003'
export const ERROR_ACCOUNT_EXISTS = '40004'
export const ERROR_DDNS_EXISTS = '40005'
export const BARRY_SEND_MESSAGE = '400'
export const BARRY_RATE_LIMIT_EXCEEDED = '429'

//402s
export const ERROR_PAY_ME = '40201' // needs subscription

//403s
export const ERROR_UNAUTHORIZED = '40301'

//404s
export const ERROR_NOT_FOUND = '40401'

//500s
export const BARRY_INTERNAL_SERVER_ERROR = '500'
export const ERROR_DATABASE = '50001'
export const ERROR_UNKNOWN = '50002'
export const ERROR_READ_ONLY = '50003'
export const ERROR_HARD_MAINTENANCE = '50302'
export const ANALYTICS_ERROR_HARD_MAINTENANCE = '50303'

export const ERROR_SERVICE_UNAVAILABLE = '50301'
