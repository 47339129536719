import { getCurrentTime } from 'utils/getCurrentTime'
import { MessageResponse, MessageRequest, EndChatRequest } from 'store/api/barry/barry.interface'
import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { BARRY_API_URL } from 'gatsby-env-variables'
import { addMessage } from 'store/barry/barry'

export const barryApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    sendMessage: builder.mutation({
      query: (body: MessageRequest) => getQueryArgs(`${BARRY_API_URL}/message`, 'POST', body),
      async onQueryStarted(arg, { dispatch }) {
        const timestamp = Math.floor(Date.now() / 1000)
        dispatch(
          addMessage({
            content: arg.message,
            fromAgent: false,
            fromBarry: false,
            sender: 'you',
            timestamp,
            time: getCurrentTime(),
          }),
        )
      },
      transformResponse: (response: MessageResponse) => response,
    }),

    endConversation: builder.mutation({
      query: (body: EndChatRequest) =>
        getQueryArgs(`${BARRY_API_URL}/end-conversation`, 'POST', body),
      transformErrorResponse,
    }),
  }),
})

export const { useSendMessageMutation, useEndConversationMutation } = barryApi
