import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import { HighlightProtocolInSetupGuide } from 'store/api/devices/devices.interface'
import { useGetDevicesTypesQuery } from 'store/api/devices'
export const getDeviceType = (setupOs?: string): string | undefined => {
  switch (setupOs) {
    case SetupOs.MAC_OS:
    case SetupOs.MAC_OS_BIG_SUR:
    case SetupOs.MAC_OS_X:
      return SetupOs.MAC
    case SetupOs.WINDOWS_10_OR_OLDER:
    case SetupOs.WINDOWS_11:
    case SetupOs.WINDOWS_OS:
      return SetupOs.WINDOWS
    case SetupOs.APPLE_TV_OS_13:
    case SetupOs.APPLE_TV_OS_14:
      return SetupOs.APPLE_TV
    default:
      return setupOs
  }
}
export default function useResolverTabHighlight(deviceType?: string): {
  highlight?: HighlightProtocolInSetupGuide[]
} {
  const type = getDeviceType(deviceType)
  const { data: typesData } = useGetDevicesTypesQuery('')
  const deviceSettings = typesData?.types?.map(t => t.icons).flat()
  const currentDeviceSetting = deviceSettings?.find(d => d.type === type)
  const highlight = currentDeviceSetting?.highlight

  return { highlight }
}
