import { useLocation } from '@reach/router'
import { useCallback, useLayoutEffect, useState } from 'react'
import { TutorialType } from 'store/tutorial/tutorial'
import useQueryString from 'utils/useQueryString'
import { useAppSelector } from 'store/hooks'
import useGetUser from 'components/Dashboard/utils/useGetUser'

// add all excluded types when shouldn't show a tutorial alert
const excludedTypes = ['account', 'helpPane']

export const isExcludedTypeExist = (pathname: string): boolean =>
  excludedTypes.some(type => pathname.includes(type))

const getTutorialType = (pathname: string): TutorialType | string => {
  for (const key in TutorialType) {
    const type = TutorialType[key]

    if (type === TutorialType.PROFILES && !pathname.endsWith(TutorialType.PROFILES)) {
      continue
    }

    if (pathname.includes(type) && !isExcludedTypeExist(pathname)) {
      if (type === TutorialType.ENDPOINTS && pathname.includes(TutorialType.CLIENTS)) {
        return TutorialType.CLIENTS
      }
      return type
    }
  }

  return ''
}

export type onInfoIconClickType = (slide?: number, customTutorial?: TutorialType) => void

// this hook encapsulates all the logic related to the showing of tutorials from the dashboard
export default function useTutorialSlides(): {
  onInfoIconClick: onInfoIconClickType
  currentTutorial?: TutorialType
  wasTutorialViewed?: boolean
} {
  const { nav } = useQueryString()
  const location = useLocation()
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''
  const tutorialType = getTutorialType(location.pathname)
  const wasTutorialViewed = useAppSelector(
    s => s.tutorial.viewedStateByUserPk[userPk ?? '']?.[tutorialType]?.hasBeenViewed,
  )

  const [currentTutorial, setCurrentTutorial] = useState<TutorialType | undefined>()
  // sets the tutorial that needs to be displayed on info icon click
  // we need useLayoutEffect to prevent blinking custom tutorial
  useLayoutEffect(() => {
    const tutorialType = getTutorialType(location.pathname) as TutorialType
    setCurrentTutorial(tutorialType)
  }, [location])

  const onInfoIconClick = useCallback(
    (slide = 0, customTutorial?: TutorialType) => {
      const tutorialType = customTutorial || currentTutorial

      if (tutorialType) {
        const trayPropObj = customTutorial ? {} : { tray: undefined }
        nav(
          { slide: slide, tutorial: tutorialType, ...trayPropObj },
          { shouldLeaveQueryString: true },
        )
      }
    },
    [currentTutorial, nav],
  )

  return { onInfoIconClick, currentTutorial, wasTutorialViewed }
}
