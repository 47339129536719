import { StatLevel } from 'store/api/analytics/analytics.interface'
import { ProfileDropDownItem } from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import { expiresDurations, KeyValuePair } from './ExpiresDropdown'
import { NukeAnalyticsType, PutUserArgs } from 'store/api/user/user.interface'
import { EnabledStatus } from 'store/api/rules'

export enum ProvisionActionType {
  DEVICE_TYPE = 'icon',
  PROFILE_ID = 'profile_id',
  LIMIT = 'max',
  SELECTED_PROFILE = 'selectedProfile',
  STATS = 'stats',
  EXPIRED = 'expired',
  NAME_PREFIX = 'name_prefix',
  REGION_SETTINGS = 'regionSettings',
  PREVENT_DEACTIVATION_STATUS = 'deactivationStatus',
  PREVENT_DEACTIVATION_PIN = 'prevent_deactivation_pin',
}

type RegionSettings = Partial<Pick<PutUserArgs, 'stats_endpoint' | NukeAnalyticsType.USER>> // this deletes analytics data on region change

export type ActionType = {
  type: ProvisionActionType
  payload:
    | StatLevel
    | string
    | ProfileDropDownItem
    | KeyValuePair
    | number
    | RegionSettings
    | undefined
}

export interface InitialStateType {
  selectedProfile?: ProfileDropDownItem
  profile_id?: string
  icon?: string
  max?: number
  stats: StatLevel
  expired?: KeyValuePair
  name_prefix?: string
  regionSettings?: RegionSettings
  deactivationStatus?: EnabledStatus
  deactivation_pin?: number
}

export const initialState: InitialStateType = {
  stats: StatLevel.FULL,
  expired: expiresDurations[1],
}

export const provisionReducer = (state: InitialStateType, action: ActionType): InitialStateType => {
  switch (action.type) {
    case ProvisionActionType.DEVICE_TYPE:
      return {
        ...state,
        icon: action.payload as string,
      }
    case ProvisionActionType.LIMIT:
      return {
        ...state,
        max: action.payload as number,
      }
    case ProvisionActionType.SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.payload as ProfileDropDownItem,
        profile_id: (action.payload as ProfileDropDownItem)?.PK as string,
      }
    case ProvisionActionType.STATS:
      return {
        ...state,
        ...(action.payload === StatLevel.NO ? { regionSettings: undefined } : {}),
        stats: action.payload as StatLevel,
      }
    case ProvisionActionType.REGION_SETTINGS:
      return {
        ...state,
        regionSettings: action.payload as RegionSettings,
      }
    case ProvisionActionType.EXPIRED:
      return {
        ...state,
        expired: action.payload as KeyValuePair,
      }
    case ProvisionActionType.NAME_PREFIX:
      return {
        ...state,
        ...(action.payload === ''
          ? { name_prefix: undefined }
          : { name_prefix: action.payload as string }),
      }
    case ProvisionActionType.PREVENT_DEACTIVATION_STATUS:
      return {
        ...state,
        deactivationStatus: action.payload as EnabledStatus,
      }
    case ProvisionActionType.PREVENT_DEACTIVATION_PIN:
      return {
        ...state,
        deactivation_pin: action.payload as number,
      }
    default:
      throw new Error()
  }
}
