import React, { ReactElement, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { SimpleActionMenuItemProps, SimpleDropdown, Svg } from 'ui'
import CheckIcon from 'images/analytics/check.svg'

export interface KeyValuePair {
  key: string
  value: string
}

export enum ExpiresType {
  HOUR = '1 hour',
  DAY = '1 day',
  WEEK = '1 week',
  MONTH = '1 month',
  YEAR = '1 year',
  NEVER = 'No Expiry',
}

export const expiresDurations: KeyValuePair[] = [
  { key: 'hour', value: '1 hour' },
  { key: 'day', value: '1 day' },
  { key: 'week', value: '1 week' },
  { key: 'month', value: '1 month' },
  { key: 'year', value: '1 year' },
  { key: 'never', value: 'No Expiry' },
]
export default function ExpiresDropdown({
  selectedDuration,
  setSelectedDuration,
  tooltipText,
}: {
  selectedDuration?: KeyValuePair
  setSelectedDuration: (KeyValuePairs) => void
  tooltipText: string
}): ReactElement {
  const hideRef = useRef<() => void>()

  const durations: SimpleActionMenuItemProps[] = expiresDurations.map(duration => ({
    isSelected: selectedDuration?.key === duration.key,
    isBackgroundHighlighted: true,
    ariaLabel: `${duration} option`,
    onClick: () => {
      setSelectedDuration(duration)
      hideRef.current?.()
    },
    buttonContent: <DurationItem duration={duration} />,
    children: (
      <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <DurationItem duration={duration} />
        {selectedDuration?.key === duration.key && (
          <Svg svg={CheckIcon} fill="aliceBlue60" sx={{ flexShrink: 0 }} />
        )}
      </Flex>
    ),
    tabIndex: 0,
  }))

  return (
    <SimpleDropdown
      label="Expires After"
      testId="expires-provision-dropdown"
      ariaLabel="expires provision dropdown"
      items={durations}
      hideRef={hideRef}
      tooltipText={tooltipText}
      mobileContentWidth="100%"
      sx={{
        width: '100%',
        flex: 1,
      }}
    />
  )
}

export const DurationItem = ({ duration }: { duration?: KeyValuePair }): ReactElement => {
  return (
    <Text
      sx={{
        fontSize: '1.6rem',
        textAlign: 'left',
        pr: '2.4rem',
        fontWeight: 'normal',
      }}
    >
      {duration?.value}
    </Text>
  )
}
