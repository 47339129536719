import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Profile } from 'store/api/profiles/profiles.interface'
import { useGetProfilesQuery } from 'store/api/profiles'
import { useParams } from '@reach/router'
import { setCurrentProfileId } from 'store/persist'

export default function useGetSelectedProfile(): Profile | undefined {
  const dispatch = useAppDispatch()
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  let currentProfileId = useAppSelector(s => s.persistData.currentProfileId)
  // if the current profile id exists in the URL, set it as the current profile id
  const params = useParams()
  if (params && params.profileId !== currentProfileId) {
    currentProfileId = params.profileId
    dispatch(setCurrentProfileId(params.profileId))
  }

  const { data: profilesData } = useGetProfilesQuery('', { skip: !sessionToken })

  const selectedProfile = profilesData?.profiles.find(p => p.PK === currentProfileId)
  return selectedProfile
}
