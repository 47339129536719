import { useEffect, useState } from 'react'

/*
	Hydration issue fix. Don't use window === undefined instead,
	because React needs HTML coming from SSR and from the first initial render on the client to match exactly.
	Rendering elements only on client side.
*/
export default function useShouldRenderOnClient(): boolean {
  const [shouldRenderOnClient, setShouldRenderOnClient] = useState(false)

  useEffect(() => {
    setShouldRenderOnClient(true)
  }, [])

  return shouldRenderOnClient
}
