import React, { ReactElement, ReactNode } from 'react'
import PreferencesIcon from 'images/dashboard/navBar/preferences-icon.svg'
import { PreferencesNavRelativePath } from './PreferencesNavMenu'
import NavBarAccordion, { NavBarAccordionType } from './NavBarAccordion'

export default function PreferencesAccordion({ children }: { children: ReactNode }): ReactElement {
  return (
    <NavBarAccordion
      type={NavBarAccordionType.PREFERENCES}
      title="Preferences"
      titleIcon={PreferencesIcon}
      relativePath={PreferencesNavRelativePath}
    >
      {children}
    </NavBarAccordion>
  )
}
