import React, { ReactElement } from 'react'
import { Text } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function ErrorMessage({ promoCodeError }: { promoCodeError: string }): ReactElement {
  const isMobile = useBreakpointIndex() === 0

  return (
    <Text
      variant="size12Weight400"
      data-testid="promo-input-error"
      sx={{
        display: 'inline-flex',
        maxWidth: isMobile ? '100%' : '50.7rem',
        color: 'error',
      }}
    >
      {promoCodeError}
    </Text>
  )
}
