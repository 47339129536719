import React, { ReactElement } from 'react'
import { Flex, Spinner } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function ConfigurationCode({
  text,
  shouldWrap,
}: {
  text: string
  shouldWrap?: boolean
}): ReactElement {
  const { isLoading } = useGetUser()
  const splitText = text.split('\n')

  return (
    <Flex
      sx={{
        width: ['100%', '37.7rem'],
        p: '1.6rem',
        backgroundColor: 'white6',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'space-between',
        borderRadius: '16px',
        px: '1.6rem',
      }}
    >
      {isLoading ? (
        <Spinner color="aliceBlue" />
      ) : (
        <Flex>
          {splitText.map(text => (
            <TextWithOverFlowAndTippyPopup
              variant="size12Weight500"
              key={text}
              content={text}
              ariaLabel={text}
              sxText={{
                color: 'aliceBlue',
                whiteSpace: shouldWrap ? 'normal' : 'nowrap',
              }}
            />
          ))}
        </Flex>
      )}
      <CopyToClipboard smallIcon data={text.trim()} fillSvg="aliceBlue60" />
    </Flex>
  )
}
