import { useGetBillingPaymentsQuery, useGetBillingSubscriptionsQuery } from 'store/api/billing'
import sortBy from 'lodash/sortBy'
import {
  OrganizationInvoice,
  PaymentsData,
  Subscription,
  SubscriptionsData,
} from 'store/api/billing/payments.interface'

export default function useGetBillingAndSubscriptions(skip?: boolean): {
  billingPayment?: PaymentsData
  isBillingPaymentLoading?: boolean
  billingSubscriptions?: SubscriptionsData
  billingSubscription?: Subscription
  areSubscriptionsLoading?: boolean
  lastPayment?: OrganizationInvoice
  isSubscriptionActive: boolean
} {
  const { data: billingPayment, isLoading: isBillingPaymentLoading } = useGetBillingPaymentsQuery(
    '',
    { refetchOnMountOrArgChange: true, skip },
  )

  const lastPayment = sortBy(billingPayment?.payments ?? [], [
    'ts',
  ]).reverse()[0] as OrganizationInvoice

  const { data: billingSubscriptions, isLoading: areSubscriptionsLoading } =
    useGetBillingSubscriptionsQuery('', {
      skip: isBillingPaymentLoading || skip,
    })

  // return the active subscription if it exists, otherwise return the most recent subscription
  let subscription = billingSubscriptions?.subscriptions?.find(sub => sub.state === 'active')
  if (!subscription) {
    subscription = sortBy(billingSubscriptions?.subscriptions ?? [], ['started']).reverse()[0]
  }
  return {
    billingPayment,
    isBillingPaymentLoading,
    billingSubscriptions,
    billingSubscription: subscription,
    areSubscriptionsLoading,
    lastPayment,
    isSubscriptionActive: ['active', 'failed'].includes(subscription?.state ?? ''),
  }
}
