import React, { ReactElement } from 'react'
import { Box } from 'theme-ui'
import { LoadingIndicatorProps } from './LoadingIndicator.interface'

const LoadingIndicator = ({ sx = {} }: LoadingIndicatorProps): ReactElement => {
  return (
    <Box data-testid="loading-indicator" sx={{ ...sx, overflow: 'hidden', height: '3px' }}>
      <Box
        sx={{
          '@keyframes backAndForth': {
            '0%, 30%': {
              left: '-20%',
            },
            '50%': {
              left: '100%',
            },

            '70%, 100%': {
              left: '-20%',
            },
          },
          backgroundColor: 'greenApple',
          width: '20%',
          height: '3px',
          position: 'absolute',

          // offset the initial 30% delay from the keyframes when first shown
          animationDelay: ['-300ms', '-300ms', '-450ms'],
          animationDuration: ['1000ms', '1000ms', '1500ms'],
          animationName: 'backAndForth',
          animationTimingFunction: 'ease-in-out',
          animationIterationCount: 'infinite',
        }}
      />
    </Box>
  )
}

export default LoadingIndicator
