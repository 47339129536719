import React, { ReactElement } from 'react'
import {
  Highlight,
  TutorialBaseSlide,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import { Box, Text } from 'theme-ui'
import ClientsImage from 'images/tutorialFlows/Devices/clients-slide.svg'
import ClientsImageLight from 'images/tutorialFlows/Devices/clients-slide-light.svg'
import { CONTROLDText } from 'ui/CONTROLD'
import useGetColorMode from 'utils/useGetColorMode'

export function ClientsSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? ClientsImageLight : ClientsImage}
      description={
        <Box>
          <Text>
            A <Highlight svg={tutorialSlideIcons.clients}>Client</Highlight> is a physical device
            (identified by its hostname) that is configured using a {CONTROLDText} GUI setup utility
            or automatically created by sending a DNS query to a server{' '}
            <Highlight svg={tutorialSlideIcons.endpoints}>Endpoint</Highlight> running ctrld daemon.
            You can think of them as mini
            <Highlight svg={tutorialSlideIcons.endpoints}>Endpoints</Highlight>. <br /> <br />
            You can get granular information about each Client on this page - like its MAC address
            and LAN IP address - and view its Analytics by clicking the{' '}
            <Highlight svg={tutorialSlideIcons.statistics}>Statistics</Highlight> icon next to each{' '}
            <Highlight svg={tutorialSlideIcons.clients}>Client</Highlight>. You can also link any{' '}
            <Highlight svg={tutorialSlideIcons.clients}>Client</Highlight> here to any of your{' '}
            <Highlight svg={tutorialSlideIcons.profiles}>Profiles</Highlight> to have it enforce a
            different set of rules.
          </Text>
        </Box>
      }
    />
  )
}
