import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useCallback } from 'react'

export enum TrackingEventCategory {
  Nav = 'nav',
  Payments = 'payments',
}
export enum TrackingEventAction {
  Filters = 'filters',
  Services = 'services',
  CustomRules = 'custom',
  Account = 'account',
  Debug = 'debug',
  DefaultRedirect = 'gproxy',
  Schedules = 'schedules',
  Tutorial = 'tutorial',
  FullControl = 'full',
  SomeControl = 'some',
  Trial = 'trial',
  Method = 'method',
  Success = 'is_success',
  Failure = 'is_failure',
  CameBackFromCoingate = 'came_back_from_coingate',
}

export enum TrackingEventName {
  Add = 'add',
  Modify = 'modify',
  Remove = 'remove',
  Delete = 'delete',
  AddTo = 'add_to',
  RemoveFrom = 'remove_from',
  View = 'view',
  Summary = 'summary',
  Start = 'start',
  Stop = 'stop',
  Logout = 'logout',
  Confident = 'confident',
  Windows = 'windows',
  Mac = 'mac',
  IOS = 'ios',
  Chrome = 'chrome',
  Firefox = 'firefox',
  AppleTV = 'apple_tv',
  Year = 'year',
  Month = 'month',
  CreditCard = 'credit_card',
  Crypto = 'crypto',
  GooglePay = 'google_pay',
  AmazonPay = 'amazon_pay',
  ApplePay = 'apple_pay',
  XPay = 'xpay',
  Code = 'code',
}
interface UseTrackingHelperReturn {
  trackEvent: (eventInfo: TrackEventInfo) => void
}

interface TrackEventInfo {
  category: TrackingEventCategory
  action: TrackingEventAction
  name?: TrackingEventName
}

export function useTrackingHelper(): UseTrackingHelperReturn {
  const { trackEvent: track } = useMatomo()

  const trackEvent = useCallback(
    (eventInfo: TrackEventInfo): void => {
      track(eventInfo)
    },
    [track],
  )

  return { trackEvent }
}
