import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Text } from 'theme-ui'
import { Accordion } from 'ui'
import { AnalyticsNavRelativePath } from './AnalyticsNavMenu'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { PreferencesNavRelativePath } from './PreferencesNavMenu'
import { OrganizationNavRelativePath } from 'components/Organization/OrganizationNavBar/OrganizationNavBarContent'
import { useLocation } from '@reach/router'

export enum NavBarAccordionType {
  ORGANIZATION = 'organization',
  DEVICES = 'devices',
  ANALYTICS = 'analytics',
  PREFERENCES = 'preferences',
}

export default function NavBarAccordion({
  title,
  titleIcon,
  relativePath,
  type,
  children,
}: {
  title: string | ReactNode
  titleIcon: ReactElement
  type: NavBarAccordionType
  relativePath:
    | typeof AnalyticsNavRelativePath
    | typeof PreferencesNavRelativePath
    | typeof OrganizationNavRelativePath
  children: ReactNode
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isFirstRender = useRef(true)

  useEffect(() => {
    const shouldOpen = Object.values(relativePath).some(path => location.pathname.includes(path))

    if (isFirstRender.current) {
      setIsOpen(shouldOpen)
    }

    isFirstRender.current = false
  }, [dispatch, location, relativePath, type])

  return (
    <Accordion
      buttonVariant="transparent"
      id={`${type}-accordion`}
      testId={`${type}-accordion`}
      tabIndex={0}
      title={
        typeof title === 'string' ? (
          <Text
            sx={{
              display: ['flex', 'none', isNavBarExpanded ? 'flex' : 'none'],
            }}
            variant="size15Weight700"
          >
            {title}
          </Text>
        ) : (
          title
        )
      }
      titleIcon={titleIcon}
      sxTitleIcon={{
        width: '2.4rem',
        height: '2.4rem',
        flexShrink: 0,
        m: 0,
      }}
      sxTitleContainer={{
        minWidth: 'none',
        pr: 0,
      }}
      titleStyle={{
        ml: ['1.2rem', 0, isNavBarExpanded ? '1.2rem' : 0],
      }}
      openTitleStyle={{
        ml: ['1.2rem', 0, isNavBarExpanded ? '1.2rem' : 0],
      }}
      containerStyle={{ width: '100%' }}
      buttonStyle={{
        boxSizing: 'content-box',
        height: '3.8rem',
        gap: [0, '0.8rem', '0.8rem'],
        justifyContent: 'space-between',
        fontSize: '1.5rem',
        px: '0.8rem',
        py: 0,
        ...(isOpen
          ? {
              '& > div > div > span': {
                color: 'aliceBlue',
              },
            }
          : {}),
        ':hover': {
          backgroundColor: 'white6',
          borderRadius: '1.2rem',
          '& > div > span': {
            color: 'aliceBlue',
          },
          '& > div > div > span': {
            color: 'aliceBlue',
          },
          '& > div svg:not(.crown-icon) path': {
            fill: 'aliceBlue',
          },
        },
      }}
      svgStyle={{
        p: 0,
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={setIsOpen}
    >
      {children}
    </Accordion>
  )
}
