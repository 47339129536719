import { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { sessionLogout } from 'store/session'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import * as errorCodes from 'store/api/errorCodes'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function useRequireAuthentication(autoLogin = true): void {
  const isManuallyLoggedOut = useAppSelector(s => s.session.manuallyLoggedOut)
  const errors = useAppSelector(s => s.errors.errors)
  const dispatch = useAppDispatch()
  const loggingOut = useRef(false)
  const location = useLocation()
  useGetUser()

  useEffect(() => {
    if (errors.some(e => `${e.code}` === errorCodes.ERROR_INVALID_SESSION) && !loggingOut.current) {
      loggingOut.current = true
      dispatch(sessionLogout({ manuallyLoggedOut: false }))
      navigate('/login', { state: location })
    }
  }, [dispatch, location, errors, isManuallyLoggedOut, autoLogin])
}
