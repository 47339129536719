import React, { ElementType, ReactElement } from 'react'
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui'
import AutoAuthIpIcon from 'images/profileManagement/settings/auto-auth-ip.svg'
import ExposeIpIcon from 'images/profileManagement/settings/expose-ip.svg'
import LegacyResolverIcon from 'images/profileManagement/settings/legacy.svg'
import ctrldIcon from 'images/profileManagement/settings/ctrld.svg'
import RestrictedDeviceIcon from 'images/profileManagement/settings/restricted-device.svg'
import useQueryString from 'utils/useQueryString'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import VerticalDivider from 'ui/VerticalDivider'
import { setDeviceForEdit } from 'store/multiprofile'
import { DeviceDialogType } from '../DeviceModalDialog'
import { useAppDispatch } from 'store/hooks'

interface DeviceStatusIconProps {
  icon: ElementType
  testId: string
  title: string
  shouldShow: boolean
  sx?: ThemeUIStyleObject
  onClick?: () => void
}

function DeviceStatusIcon({
  icon,
  shouldShow,
  testId,
  title = '',
  sx,
  onClick,
}: DeviceStatusIconProps): ReactElement | null {
  if (!shouldShow) {
    return null
  }

  return (
    <Flex
      sx={{
        alignItems: 'center',
        // Square containers for desktop, more narrow for mobile/tablet
        width: '2rem',
        height: '2rem',
        '& svg': {
          opacity: 0.6,
        },
        '&:hover svg': {
          opacity: 1,
        },
        ...sx,
      }}
    >
      <IconWithTippyPopup
        placement="top"
        testId={testId}
        content={title}
        ariaLabel={title}
        svg={icon}
        fill="aliceBlue"
        onClick={onClick}
        sx={{ width: '2rem', height: '2rem', flexShrink: 0 }}
      />
    </Flex>
  )
}

export function DeviceStatusIcons({
  device,
  isCompressed,
}: {
  device: DeviceInfo
  isCompressed?: boolean
}): ReactElement | null {
  const dispatch = useAppDispatch()
  const isAutoAuthIpOn = !!device.learn_ip
  const isExposeIpOn = !!device.ddns?.status
  const isLegacyResolverOn = !!device.legacy_ipv4?.status
  const isCtrldOn = !!device.ctrld?.status
  const isRestrictedResolver = !!device.restricted
  const { nav, qs } = useQueryString()

  if (
    !isExposeIpOn &&
    !isAutoAuthIpOn &&
    !isLegacyResolverOn &&
    !isCtrldOn &&
    !isRestrictedResolver
  ) {
    return null
  }

  const totalIcons =
    +isExposeIpOn + +isAutoAuthIpOn + +isLegacyResolverOn + +isCtrldOn + +isRestrictedResolver - 1

  return (
    <Flex sx={{ display: ['none', 'flex'], alignItems: 'center' }}>
      <Flex
        data-testid={`status-icons`}
        sx={{
          height: 'fit-content',
          alignItems: 'center',
          gap: isCompressed ? '0.6rem' : '1.6rem',
          cursor: isCompressed ? 'pointer' : 'auto',
          '&:hover': isCompressed && {
            svg: {
              opacity: 1,
            },
            span: {
              color: 'aliceBlue',
            },
          },
        }}
      >
        <DeviceStatusIcon
          icon={RestrictedDeviceIcon}
          testId="restricted-device"
          title="Restricted Resolver"
          shouldShow={isRestrictedResolver}
        />
        <DeviceStatusIcon
          icon={ctrldIcon}
          testId={`ctrld-icon`}
          title="ctrld status"
          shouldShow={isCtrldOn}
          sx={{
            display: isRestrictedResolver && isCompressed && 'none',
          }}
          onClick={() => nav({ ...qs, ctrldDeviceId: device.PK })}
        />
        <DeviceStatusIcon
          icon={ExposeIpIcon}
          testId={`expose-ip-icon`}
          title="Expose IP via DNS"
          shouldShow={isExposeIpOn}
          sx={{
            display: (isRestrictedResolver || isCtrldOn) && isCompressed && 'none',
          }}
        />
        <DeviceStatusIcon
          icon={AutoAuthIpIcon}
          testId={`auto-auth-ip-icon`}
          title="Auto Authorize IP"
          shouldShow={isAutoAuthIpOn}
          sx={{
            display: (isRestrictedResolver || isCtrldOn || isExposeIpOn) && isCompressed && 'none',
          }}
        />
        <DeviceStatusIcon
          icon={LegacyResolverIcon}
          testId={`legacy-resolver-icon`}
          title="Legacy Resolver"
          shouldShow={isLegacyResolverOn}
          sx={{
            display:
              (isRestrictedResolver || isCtrldOn || isExposeIpOn || isAutoAuthIpOn) &&
              isCompressed &&
              'none',
          }}
        />
        {isCompressed && totalIcons > 0 && (
          <Text
            variant="size12Weight700Letter018"
            sx={{ color: 'aliceBlue60' }}
            onClick={() => {
              dispatch(setDeviceForEdit(device.PK))
              nav({ ...qs, deviceDialog: DeviceDialogType.EDIT })
            }}
          >{`+${totalIcons}`}</Text>
        )}
      </Flex>
      <VerticalDivider
        sx={{
          mr: '0.8rem',
          ml: '1.2rem',
          flexShrink: 0,
          height: '2.4rem',
          color: 'blueYonder15',
          backgroundColor: 'blueYonder15',
        }}
      />
    </Flex>
  )
}
