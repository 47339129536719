import React, { ReactElement, useMemo, useRef } from 'react'
import { SimpleActionMenuItemProps } from 'ui'
import DeviceSearchDropdown from 'components/Dashboard/DeviceDropdown/DeviceSearchDropdown'
import { useLocation } from '@reach/router'
import DeviceDropdownItem from './DeviceDropdownItem'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import { ThemeUIStyleObject } from 'theme-ui'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'
import { scrollingFiltersContainerRef } from 'components/Dashboard/Analytics/ActivityLog/FiltersContainer'

const allDevices = 'All Endpoints'

export interface DevicesDropdownProps {
  deviceId?: string
  dropdownDevices: DeviceInfo[]
  isAllDevicesVisible?: boolean
  shouldShowClients?: boolean
  onClick?: (deviceId: string) => void
  boundaryElementTestId?: string
  maxWidth?: string
  isLoading?: boolean
  shouldShowSmallView?: boolean
  sxButtonContent?: ThemeUIStyleObject
  sx?: ThemeUIStyleObject
  shouldHighlightSelectedEndpoint?: boolean
}

export default function DeviceDropdown({
  isAllDevicesVisible = true,
  deviceId,
  shouldShowClients = true,
  dropdownDevices,
  onClick,
  boundaryElementTestId,
  maxWidth,
  isLoading,
  shouldShowSmallView,
  sxButtonContent,
  sx,
  shouldHighlightSelectedEndpoint,
}: DevicesDropdownProps): ReactElement | null {
  const location = useLocation()
  const isProfiles = location.pathname.includes('profiles')

  const hideRef = useRef<() => void>()

  useHideTooltipOnScroll(hideRef, scrollingFiltersContainerRef)

  const deviceName =
    dropdownDevices.find(
      device => device.device_id === deviceId || device.resolvers.uid === deviceId,
    )?.name || (isAllDevicesVisible ? allDevices : undefined)

  const dropdownButtonText =
    deviceId !== undefined || isAllDevicesVisible
      ? deviceName
      : `${dropdownDevices.length} Endpoint${dropdownDevices.length > 1 ? 's' : ''}`

  const options: SimpleActionMenuItemProps[] = useMemo(() => {
    const devicesOptions = dropdownDevices.map(device => ({
      isSelected: deviceId === device.PK,
      // device.stats can be undefined. It should be disabled when device.stats is StatLevel.NO or undefined
      isDisabled: isProfiles && !device.stats,
      ariaLabel: `${device.name} option`,
      children: (
        <DeviceDropdownItem
          isSelected={deviceId === device.PK}
          isGraphIconVisible={isProfiles && !!device.stats}
          icon={device.icon}
          text={device.name}
          stats={device.stats}
          status={device.status}
          pk={device.PK}
          lastActivity={device.last_activity}
          parentDeviceId={device.parent_device?.device_id}
          clientsCount={shouldShowClients ? device.clients?.length : undefined}
          testId={`${device.name}-device-dropdown-item`}
        />
      ),
      onClick: () => {
        onClick?.(device.device_id)
        hideRef.current?.()
      },
    }))

    return isAllDevicesVisible
      ? [
          {
            ariaLabel: `all devices option`,
            isSelected: !deviceId && isAllDevicesVisible,
            children: (
              <DeviceDropdownItem text={allDevices} isSelected={!deviceId && isAllDevicesVisible} />
            ),
            onClick: () => {
              onClick?.('')
              hideRef.current?.()
            },
          },
          ...devicesOptions,
        ]
      : devicesOptions
  }, [dropdownDevices, isAllDevicesVisible, deviceId, isProfiles, shouldShowClients, onClick])

  if (isLoading) {
    return null
  }

  return (
    <DeviceSearchDropdown
      hideRef={hideRef}
      dropdownButtonText={dropdownButtonText}
      isDisabled={!dropdownDevices.length}
      isSelectable={true}
      ariaLabel="endpoints menu"
      testId="devices-dropdown-button"
      options={options}
      boundaryElementTestId={boundaryElementTestId || 'dashboard-header'}
      searchPlaceholder="Search Endpoints"
      maxWidth={maxWidth}
      shouldShowSmallView={shouldShowSmallView}
      sxButtonContent={sxButtonContent}
      sx={sx}
      shouldHighlightSelectedEndpoint={shouldHighlightSelectedEndpoint}
    />
  )
}
