import React, { ReactElement } from 'react'
import { Spinner, ThemeUIStyleObject } from 'theme-ui'
import { ButtonProps, Button } from 'ui'
import { Flex } from '@theme-ui/components'

export default function ButtonWithLoadingState({
  isLoading = false,
  sxSpinner,
  children,
  disabled,
  variant = 'secondary',
  shouldDisplaySpinnerAndText,
  buttonRef,
  ...rest
}: ButtonProps & {
  isLoading?: boolean
  sxSpinner?: ThemeUIStyleObject
  shouldDisplaySpinnerAndText?: boolean
}): ReactElement {
  return (
    <Button ref={buttonRef} disabled={isLoading || disabled} variant={variant} {...rest}>
      {isLoading ? (
        <Flex sx={{ alignItems: 'center', gap: '0.4rem' }}>
          <Spinner
            data-testid="button-loading-spinner"
            color="aliceBlue"
            sx={{ width: '2rem', height: '2rem', ...sxSpinner }}
          />
          {shouldDisplaySpinnerAndText && children}
        </Flex>
      ) : (
        children
      )}
    </Button>
  )
}
