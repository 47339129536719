import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialResponseState } from '../fetchingLogic'

export interface AccessState {
  selectedDeviceId?: string
  ipsToDelete: string[]
}

export const accessSlice = createSlice({
  name: 'access',
  initialState: {
    ...initialResponseState,
    selectedDeviceId: undefined,
    ipsToDelete: [],
  } as AccessState,
  reducers: {
    setSelectedDeviceId(state, { payload }: PayloadAction<string | undefined>): void {
      state.selectedDeviceId = payload
    },
    setIpsToDelete(state, { payload }: PayloadAction<string>): void {
      if (state.ipsToDelete?.includes(payload)) {
        state.ipsToDelete = state.ipsToDelete.filter(ip => ip !== payload)
      } else {
        state.ipsToDelete = [...state.ipsToDelete, payload]
      }
    },
    resetIpsToDelete(state): void {
      state.ipsToDelete = []
    },
  },
})

export const { setSelectedDeviceId, setIpsToDelete, resetIpsToDelete } = accessSlice.actions
