import { ApiResponse, EmptyBodyResponse } from 'store/api/http'
import { Profile } from 'store/api/profiles/profiles.interface'
import { EnabledStatus } from 'store/api/rules'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import { devicesApi } from 'store/api/devices'
import { scrubProfilesInDevice } from 'store/multiprofile'
import { baseApi, getQueryArgs, transformErrorResponse } from '../index'

export enum EnforcingStatus {
  FALSE,
  TRUE,
}

export enum RuleOption {
  BLOCK,
  ALLOW,
}

export interface Weekdays {
  mon: EnabledStatus
  tue: EnabledStatus
  wed: EnabledStatus
  thu: EnabledStatus
  fri: EnabledStatus
  sat: EnabledStatus
  sun: EnabledStatus
}

export type DayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

export function weekdaysObjectToArrayRepresentation(weekdays: Weekdays): DayOfWeek[] {
  return (Object.keys(weekdays) as DayOfWeek[]).filter(
    day => weekdays[day] === EnabledStatus.ENABLED,
  )
}

export function weekdaysArrayToObjectRepresentation(weekdays: DayOfWeek[]): Weekdays {
  return {
    mon: +weekdays.includes('mon'),
    tue: +weekdays.includes('tue'),
    wed: +weekdays.includes('wed'),
    thu: +weekdays.includes('thu'),
    fri: +weekdays.includes('fri'),
    sat: +weekdays.includes('sat'),
    sun: +weekdays.includes('sun'),
  }
}

export interface WeekdayListItem {
  name: string
  shortName: keyof Weekdays
}

export interface ScheduleItemData {
  name: string
  time_zone: string
  time_start: string
  time_end: string
  service_action?: RuleOption
  PK?: string
  status?: EnabledStatus
  enforcing?: EnforcingStatus
  enforce_override?: EnforcingStatus
  // IMPORTANT: for READ operations, `weekdays` will be of type `Weekdays` (for example,
  // getting list of schedules from server). For WRITE operations, `weekdays` must be
  // of type `DayOfWeek[]`. This asymmetry should probably be fixed server-side as it's
  // very awkward and needless.
  weekdays: Weekdays | DayOfWeek[]
  services?: string[]

  // These attributes are only ever returned from the server.
  // To *set* the schedule for a profile or device, use the `{profile,device}_id`
  // attributes below.
  device?: DeviceInfo
  profile?: Profile

  // when we want to set a profile for a specific device and/or profile,
  // we set the ID
  device_id?: string
  profile_id?: string
}

export interface SchedulesData {
  schedules: ScheduleItemData[]
}

export type GetSchedulesResponse = ApiResponse<SchedulesData>

export const schedulesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSchedules: builder.query({
      query: () => getQueryArgs('/schedules'),
      transformResponse: (response: GetSchedulesResponse) => response.body,
      transformErrorResponse,
      providesTags: () => ['Schedules'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              data.schedules.forEach(s => {
                if (s.device) {
                  const device = scrubProfilesInDevice(s.device)
                  const deviceIndex = draft?.devices.findIndex(d => d.PK === device?.PK)
                  // the device returned by schedule does not have client info
                  draft.devices[deviceIndex] = { ...draft.devices[deviceIndex], ...device }
                }
              })
            }),
          )
        } catch {}
      },
    }),
    postSchedules: builder.mutation({
      query: (body: ScheduleItemData) => getQueryArgs('/schedules', 'POST', body),
      transformResponse: (response: ApiResponse<ScheduleItemData>) => response.body,
      transformErrorResponse,
      async onQueryStarted(body: ScheduleItemData, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            schedulesApi.util.updateQueryData('getSchedules', '', draft => {
              draft.schedules = [...draft.schedules, data]
            }),
          )
        } catch {}

        try {
          const { data } = await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              const device = (
                data?.device ? scrubProfilesInDevice(data.device) : undefined
              ) as DeviceInfo
              const deviceIndex = draft?.devices.findIndex(d => d.PK === device.PK)
              // the device returned by schedule does not have client info
              draft.devices[deviceIndex] = { ...draft.devices[deviceIndex], ...device }
            }),
          )
        } catch {}
      },
    }),
    putSchedules: builder.mutation({
      query: ({ pk, body }: { pk: string; body: Partial<ScheduleItemData> }) =>
        getQueryArgs(`/schedules/${pk}`, 'PUT', body),
      transformResponse: (response: GetSchedulesResponse) => response.body,
      transformErrorResponse,
      // need to get schedules as we do not get 'enforcing' back in api response
      invalidatesTags: () => ['Schedules'],
    }),
    deleteSchedule: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/schedules/${pk}`, 'DELETE'),
      transformResponse: (response: EmptyBodyResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted(
        { pk }: { pk: string; body: Partial<ScheduleItemData> },
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled
          dispatch(
            // should be '', not {} in the second arg, otherwise data is not updated
            schedulesApi.util.updateQueryData('getSchedules', '', draft => {
              draft.schedules = draft.schedules?.filter(m => m.PK !== pk)
            }),
          )
        } catch {}
      },
    }),
  }),
})

export const {
  endpoints,
  useGetSchedulesQuery,
  usePostSchedulesMutation,
  usePutSchedulesMutation,
  useDeleteScheduleMutation,
} = schedulesApi
