import { useScheduleState } from 'components/Dashboard/Devices/DeviceListItem/ScheduleContextProvider'
import React, { ReactElement, useState } from 'react'
import { EnabledStatus } from 'store/api/rules'
import { Switch } from 'ui'
import { usePutSchedulesMutation } from 'store/api/schedules/schedules'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function ScheduleToggle(): ReactElement {
  const { existingSchedule } = useScheduleState()
  const [isRequestInFlight, setIsRequestInFlight] = useState(false)
  const { data: userData } = useGetUser()
  const haptics = userData?.haptics
  const [putSchedule] = usePutSchedulesMutation()
  return (
    <Switch
      isCompact
      data-testid="schedule-toggle"
      ariaLabel="schedule toggle"
      checked={existingSchedule?.status === EnabledStatus.ENABLED}
      onClick={async (): Promise<void> => {
        setIsRequestInFlight(true)
        await putSchedule({
          pk: existingSchedule?.PK ?? '',
          body: {
            status:
              existingSchedule?.status === EnabledStatus.DISABLED
                ? EnabledStatus.ENABLED
                : EnabledStatus.DISABLED,
          },
        })
        setIsRequestInFlight(false)
      }}
      isLoading={isRequestInFlight}
      hasHapticFeedback={!!haptics}
    />
  )
}
