import React, { ReactElement, useEffect, useRef } from 'react'
import PurpleEllipsis from 'images/barry/purple-ellipsis.svg'
import BarryButtonIcon from 'images/barry/barry-button-icon.svg'
import { iconWobble } from 'components/Barry/utils/IconWobble'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setChatBubbleBeenClosedOn, setIsBarryOpen } from 'store/barry/barry'
import { IconButton } from 'theme-ui'
import BarryPopUp, { BarryHelloMessagAction } from 'components/Barry/BarryPopUp'
import { HelloBarryMessageResponse } from 'store/api/support/support'

const toggleButtonSize = '4.8rem'

export default function BarryIcon({
  handleBarryIconToggle,
  shouldShowBarryPopUp,
  shouldShowBarry,
  setShowBarryHelloMessage,
  barryHelloMessage,
  isBarryHelloMessageSuccess,
}: {
  handleBarryIconToggle: () => void
  setShowBarryHelloMessage: (shouldShowBarry: boolean) => void
  shouldShowBarryPopUp: boolean
  shouldShowBarry: boolean
  showBarryHelloMessage: boolean
  barryHelloMessage: HelloBarryMessageResponse | undefined
  isBarryHelloMessageSuccess: boolean
}): ReactElement {
  const dispatch = useAppDispatch()
  const { isBarryOpen } = useAppSelector(x => x.barry.userExperience)
  const timeoutId = useRef<NodeJS.Timeout | undefined>()

  /**
   * Shows the popup message above Barry with a 3 second delay.
   */
  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    if (isBarryHelloMessageSuccess && !isBarryOpen) {
      setShowBarryHelloMessage(false)
      timeoutId.current = setTimeout(() => {
        setShowBarryHelloMessage(true)
      }, 3000)
    }

    return () => clearTimeout(timeoutId.current)
  }, [isBarryHelloMessageSuccess, isBarryOpen, barryHelloMessage, setShowBarryHelloMessage])

  const handleBarryHelloMessage = (action: BarryHelloMessagAction) => {
    if (action === BarryHelloMessagAction.OPEN) {
      dispatch(setIsBarryOpen(true))
    }
    setShowBarryHelloMessage(false)
    dispatch(setChatBubbleBeenClosedOn(Date.now()))
  }

  return (
    <>
      <IconButton
        sx={{
          zIndex: 'zIndex625',
          opacity: 1,
          margin: 0,
          padding: 0,
          width: toggleButtonSize,
          height: toggleButtonSize,
          borderRadius: '50%',
          position: 'fixed',
          bottom: ['1.6rem', '2.4rem'],
          right: ['1.6rem', '2.4rem'],
          cursor: 'pointer',
          animation: shouldShowBarryPopUp ? `${iconWobble} 4.5s ease 0s infinite` : 'none',
          animationFillMode: 'forwards',
          pointerEvents: 'auto',
          '@media screen and (max-width: 1620px)': {
            opacity: shouldShowBarry ? 1 : 0,
            pointerEvents: shouldShowBarry ? 'auto' : 'none',
          },
        }}
        onClick={() => handleBarryIconToggle()}
        id="barry-toggle"
        data-testid="barry-toggle"
        aria-label="barry toggle"
      >
        {isBarryOpen ? <PurpleEllipsis /> : <BarryButtonIcon />}
      </IconButton>
      {shouldShowBarryPopUp && (
        <BarryPopUp
          barryMessage={barryHelloMessage?.cta}
          handleBarryHelloMessage={action => handleBarryHelloMessage(action)}
        />
      )}
    </>
  )
}
