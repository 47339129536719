import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import BarryIcon from 'images/organization/homepage/headerNavMenu/helpLinkIcons/barry-icon.svg'
import DocumentationIcon from 'images/organization/homepage/headerNavMenu/helpLinkIcons/documentation-icon.svg'
import ApiReferenceIcon from 'images/organization/homepage/headerNavMenu/helpLinkIcons/api-reference.svg'
import ChangelogIcon from 'images/organization/homepage/headerNavMenu/helpLinkIcons/changelog-icon.svg'
import FeedbackIcon from 'images/organization/homepage/headerNavMenu/helpLinkIcons/feedback-icon.svg'
import ContactIcon from 'images/organization/homepage/headerNavMenu/helpLinkIcons/contact-icon.svg'

export const helpLinks = [
  {
    header: 'Talk to Barry',
    icon: BarryIcon,
    description: 'AI chatbot, can help with everything.',
    to: 'barry',
  },
  {
    header: 'Documentation',
    icon: DocumentationIcon,
    description: 'Read about every feature in detail.',
    to: makeUrl('docs'),
  },
  {
    header: 'Feedback',
    icon: FeedbackIcon,
    description: 'Want to suggest a feature? This is the place.',
    to: makeUrl('discuss'),
  },
  {
    header: 'API Reference',
    icon: ApiReferenceIcon,
    description: 'Integrate Control D into your workflows.',
    to: makeUrl('reference/get-started'),
  },
  {
    header: 'Contact',
    icon: ContactIcon,
    description: "Need some assistance? We're here to help.",
    href: '/contact',
  },
  {
    header: 'Changelog',
    icon: ChangelogIcon,
    description: "Check out what's new.",
    to: makeUrl('changelog'),
  },
]

function makeUrl(path: string): string {
  return `${DOCS_CONTROL_D_DOMAIN}/${path}`
}
