import { useCallback, useMemo } from 'react'
import { setQueryFilters } from 'store/activityLog'
import {
  setSelectedDeviceId as setDeviceId,
  setSelectedClientId as setClientId,
} from 'store/analytics'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { DeviceClient, DeviceInfo } from 'store/api/devices/devices.interface'
import { useGetDevicesQuery } from 'store/api/devices'

interface DeviceClientsState {
  selectedClient?: DeviceClient
  setSelectedClientId: (clientId?: string) => void
  selectedDevice?: DeviceInfo
  setSelectedDeviceId: (deviceId: string) => void
}

// TODO: should be updated using one common analytics slice
export function useDeviceClientsData(): DeviceClientsState {
  const dispatch = useAppDispatch()
  const selectedClientId = useAppSelector(s => s.analytics.selectedClientId)
  const { data: devicesData } = useGetDevicesQuery('')
  const selectedDeviceId = useAppSelector(s => s.analytics.selectedDeviceId)
  const selectedDevice = devicesData?.devices.find(device => device.PK === selectedDeviceId)

  const selectedClient = useMemo(
    //@ts-ignore
    () => selectedDevice?.clients?.find(client => client.id === selectedClientId),
    [selectedDevice, selectedClientId],
  )

  const setSelectedClientId = useCallback(
    (clientId?: string) => {
      dispatch(setClientId(clientId))
      dispatch(setQueryFilters({ clientId }))
    },
    [dispatch],
  )

  const setSelectedDeviceId = useCallback(
    (deviceId: string) => {
      dispatch(setDeviceId(deviceId))
      dispatch(setQueryFilters({ deviceId, clientId: undefined }))
    },
    [dispatch],
  )

  return {
    selectedClient,
    setSelectedClientId,
    selectedDevice,
    setSelectedDeviceId,
  }
}
