import React, { ReactElement, useEffect, useMemo, useRef } from 'react'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/ShowAlertOnModalOrTrayWrapper'
import { createPortal } from 'react-dom'
import ConfirmDeletionContent, { ConfirmDeletionContentProps } from './ConfirmDeletionContent'
import ModalDialog from 'ui/NewModalDialog'
import { Flex } from '@theme-ui/components'
import { setIsModalDialogVisible } from 'store/dashboard/dashboard'
import { useAppDispatch } from 'store/hooks'

interface ConfirmDeletionModalDialogProps extends ConfirmDeletionContentProps {
  element: Element | null
  title: string
  testId?: string
  onClose: () => void
}

export default function ConfirmDeletionModalDialog({
  element,
  title,
  testId,
  onClose,
  type,
  timeSinceLastQuery,
  notificationText,
  checkboxText,
  isRequestInFlight,
  shouldShowPassword,
  onDelete,
  onCancel,
  additionalContent,
  customCheckboxes,
}: ConfirmDeletionModalDialogProps): ReactElement | null {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const contentView = useMemo(
    () => (
      <ConfirmDeletionContent
        containerRef={containerRef}
        type={type}
        isRequestInFlight={isRequestInFlight}
        timeSinceLastQuery={timeSinceLastQuery}
        notificationText={notificationText}
        checkboxText={checkboxText}
        shouldShowPassword={shouldShowPassword}
        onCancel={onCancel}
        onDelete={onDelete}
        additionalContent={additionalContent}
        customCheckboxes={customCheckboxes}
      />
    ),
    [
      type,
      isRequestInFlight,
      timeSinceLastQuery,
      notificationText,
      checkboxText,
      shouldShowPassword,
      onCancel,
      onDelete,
      additionalContent,
      customCheckboxes,
    ],
  )

  useEffect(() => {
    dispatch(setIsModalDialogVisible(true))

    return () => {
      dispatch(setIsModalDialogVisible(false))
    }
  }, [dispatch])

  if (!element) {
    return <></>
  }

  return createPortal(
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal>
      <ModalDialog
        isOpen
        title={title}
        dataTestId={testId}
        onCloseClick={onClose}
        dismiss={onClose}
        footer={<Flex key={title} />}
      >
        {contentView}
      </ModalDialog>
    </ShowAlertOnModalOrTrayWrapper>,
    element,
  )
}
