import { EnabledStatus } from 'store/api/rules'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { InitialStateType } from '../SettingsState'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import { otherType } from './DeviceTypeDropdown'
import isEqual from 'lodash/isEqual'

export function deviceNameMask(value: string): string {
  return value?.replace(/\W/g, '-')
}

export const payloadProperties = [
  'name',
  'profile_id',
  'profile_id2',
  'icon',
  'status',
  'learn_ip',
  'desc',
  'nuke_analytics',
  'user_nuke_analytics',
  'deactivation_pin',
  'client_count',
]

export const ddnsSubdomainMinLength = 5
export const deactivationPinMinLength = 1
export const deactivationPinMaxLength = 10

export function isDeactivationPinLengthValid(pin?: number): boolean {
  if (!pin) {
    return false
  }

  const pinLength = pin.toString().length

  return pinLength >= deactivationPinMinLength && pinLength < deactivationPinMaxLength
}

export interface MappedDeviceProperties {
  icon?: string
  profile_id?: string
  profile_id2?: string | number
  restricted?: string | number
  legacy_ipv4_status?: EnabledStatus
  ddns_status?: EnabledStatus
  ddns_subdomain?: string
  ddns_ext_status?: EnabledStatus
  ddns_ext_host?: string
  stats?: StatLevel
  deactivation_pin?: number
  client_count?: number
}

// device properties for compliance with settingsState. These properties are the ones that the api expects.
export const getMappedDeviceProperties = (
  state: InitialStateType,
  device?: Partial<DeviceInfo>,
): MappedDeviceProperties => {
  const mappedObj = {} as MappedDeviceProperties

  if (state.profile?.PK !== device?.profile?.PK) {
    mappedObj.profile_id = state.profile?.PK.toString()
  }

  if (state.profile2?.PK !== device?.profile2?.PK) {
    mappedObj.profile_id2 = state.profile2?.PK || -1
  }

  if (state.restricted !== device?.restricted) {
    mappedObj.restricted = state.restricted
  }

  if (state.stats !== (device?.stats || StatLevel.NO)) {
    mappedObj.stats = state.stats
  }

  if (state.ddns?.status !== (device?.ddns?.status || EnabledStatus.DISABLED)) {
    mappedObj.ddns_status = state.ddns?.status
  }

  if (state.ddns?.subdomain !== (device?.ddns?.subdomain || '')) {
    mappedObj.ddns_subdomain = state.ddns?.subdomain
    mappedObj.ddns_status = EnabledStatus.ENABLED
  }

  if (state.ddns_ext?.status !== (device?.ddns_ext?.status || EnabledStatus.DISABLED)) {
    mappedObj.ddns_ext_status = state.ddns_ext?.status
  }

  if (state.ddns_ext?.host !== (device?.ddns_ext?.host || '')) {
    mappedObj.ddns_ext_host = state.ddns_ext?.host
    mappedObj.ddns_ext_status = EnabledStatus.ENABLED
  }

  if (
    state.legacy_ipv4?.status === EnabledStatus.DISABLED &&
    mappedObj.ddns_ext_status !== device?.ddns_ext?.status
  ) {
    delete mappedObj.ddns_ext_host
    mappedObj.ddns_ext_status = EnabledStatus.DISABLED
  }

  if (state.legacy_ipv4?.status !== (device?.legacy_ipv4?.status || EnabledStatus.DISABLED)) {
    mappedObj.legacy_ipv4_status = state.legacy_ipv4?.status
  }

  if (!!device?.deactivation_pin && state.deactivationStatus === EnabledStatus.DISABLED) {
    mappedObj.deactivation_pin = -1
  }

  if (state?.icon === otherType) {
    mappedObj.icon = undefined
  }
  if (device?.client_count !== state.client_count) {
    mappedObj.client_count = state.client_count
  }

  return mappedObj
}

export const getRequestPayloadFromState = (
  state: InitialStateType,
  device?: Partial<DeviceInfo>,
): MappedDeviceProperties => {
  const mappedProperties = getMappedDeviceProperties(state, device)
  if (!device) {
    return {
      // create an object that contains only truthy values so that unmodified values are not included in the request payload
      ...pickBy({ ...pick(state, ...payloadProperties), ...mappedProperties }),
    }
  }

  const payload = {
    ...pick(state, ...payloadProperties),
    ...mappedProperties,
  }

  // remove properties from requestDevice that are the same as in existing device
  for (const key in payload) {
    if (payload[key] === device[key]) {
      delete payload[key]
    } else if (
      typeof payload[key] === 'object' &&
      typeof device[key] === 'object' &&
      isEqual(payload[key], device[key])
    ) {
      delete payload[key]
    }
  }

  return payload
}
