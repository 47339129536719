import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import {
  AlertPresenterItemInfo,
  AlertPresenterProps,
  AlertState,
} from 'ui/AlertPresenter/AlertPresenter.interface'
import Alert from 'ui/AlertPresenter/Alert'
import { useAlertPresenterState } from 'ui/AlertPresenter/AlertContextProvider'
import ResizeObserver from 'resize-observer-polyfill'
import { MoveFocusInside } from 'react-focus-lock'

export default function AlertPresenter({
  containerRef,
  position = 'fixed',
}: AlertPresenterProps): ReactElement {
  const { alerts, setAlerts, cumulativeHeightRef } = useAlertPresenterState()
  const [viewportWidth, setViewportWidth] = useState(0)
  const [alertDimensions, setAlertDimensions] = useState<DOMRect>()
  const resizeObserver = useMemo(
    () =>
      typeof window !== 'undefined'
        ? new ResizeObserver(entries => {
            for (const entry of entries) {
              setAlertDimensions(entry.target.getBoundingClientRect())
            }
          })
        : null,
    [],
  )
  const windowHeight = typeof window !== 'undefined' ? window?.innerHeight : 0

  useEffect(() => {
    setAlertDimensions(containerRef.current?.getBoundingClientRect() as DOMRect)

    /* https://stackoverflow.com/questions/62538720/typeerror-failed-to-execute-observe-on-resizeobserver-parameter-1-is-not-o
    Wait for this to exist before passing it to observe() */
    containerRef.current && resizeObserver?.observe(containerRef.current as Element)

    return (): void => resizeObserver?.disconnect()
  }, [containerRef, resizeObserver])

  useLayoutEffect(() => {
    const handleResize = (): void => {
      setViewportWidth(window.innerWidth * 0.01)
    }

    document.documentElement.style.setProperty('--vw', `${viewportWidth}px`)
    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
  }, [viewportWidth])

  useEffect(() => {
    return () => {
      setAlerts([])
    }
  }, [setAlerts])

  let cumulativeHeight = 0

  return (
    <Box
      sx={{
        width: `${alertDimensions?.width ?? 0}px`,
        height: `${alertDimensions?.height ?? 0}px`,
        top: `${Math.max(
          0,
          windowHeight - (alertDimensions?.height ?? 0) - Math.abs(alertDimensions?.top ?? 0),
        )}px`,
        left: `${alertDimensions?.left}px`,
        position: position,
        overflow: 'hidden',
        zIndex: 'zIndex650',
        pointerEvents: 'none',
      }}
      data-testid="alert-presenter"
    >
      {alerts.map(alert => {
        // we omit the height of alerts in the `DISMISSING` state so that the alerts above them
        // will start to transition downwards as soon as the dismissal starts
        const height =
          alert.state === AlertState.DISMISSING ? 0 : alert.ref?.current?.offsetHeight || 0
        const item = (
          <AlertPresenterItem
            key={alert.id}
            {...alert}
            cumulativeHeight={cumulativeHeight}
            onDismissTransitionEnd={(): void => {
              // dismissing transition is done, so we remove it from the `alerts` array
              setAlerts(prevAlerts => prevAlerts.filter(currAlert => currAlert.id !== alert.id))
            }}
            onPresentTransitionEnd={(): void => {
              // presenting transition is done, so we update the alert state to `PRESENTED`
              setAlerts(prevAlerts =>
                prevAlerts.map(currAlert => {
                  if (currAlert.id === alert.id) {
                    return { ...currAlert, state: AlertState.PRESENTED }
                  } else {
                    return currAlert
                  }
                }),
              )
            }}
          />
        )
        cumulativeHeight += height
        if (cumulativeHeight || alert.state === AlertState.DISMISSING) {
          cumulativeHeightRef.current = cumulativeHeight
        }
        return item
      })}
    </Box>
  )
}

interface AlertPresenterItemProps extends AlertPresenterItemInfo {
  cumulativeHeight: number
  onDismissTransitionEnd: () => void
  onPresentTransitionEnd: () => void
}

const alertPresenterTestId = 'alert-presenter-item'
const alertPresenterPresentedTestId = `${alertPresenterTestId}-presented`

const AlertPresenterItem = forwardRef<HTMLDivElement, AlertPresenterItemProps>(
  function AlertPresenterItem(
    {
      alert,
      state,
      cumulativeHeight,
      onDismissTransitionEnd,
      onPresentTransitionEnd,
    }: AlertPresenterItemProps,
    ref,
  ): ReactElement {
    const [hasRendered, setHasRendered] = useState(false)
    const [isTransitionEnd, setIsTransitionEnd] = useState(state === AlertState.PRESENTED)
    const translateY = ((): string => {
      if (state === AlertState.DISMISSING || !hasRendered) {
        return '100%'
      } else {
        return `-${cumulativeHeight}px`
      }
    })()

    useEffect(() => {
      // We keep track of whether we've rendered yet so that we can set `transform` to its initial
      // value and render again, triggering a transition.
      // NOTE: This is wrapped in a 50ms `setTimeout` so that it works consistently in Chrome and Firefox.
      // It seems like in these browsers if there is very little time between first render and changing
      // the `transform` property, the animation doesn't work.
      const timeout = setTimeout(() => {
        if (!hasRendered) {
          setHasRendered(true)
        }
      }, 50)

      return (): void => clearTimeout(timeout)
    }, [hasRendered])

    return (
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          transform: `translateY(${translateY})`,
          transitionProperty: 'transform',
          transitionDuration: '200ms',
          // this gives us a uniform transition feel in both directions
          transitionTimingFunction: state === AlertState.PRESENTING ? 'ease-out' : 'ease-in',
          width: '100%',
          willChange: 'transform',
          px: 0,
          pointerEvents: 'auto',
          bottom: '0px',
        }}
        onTransitionEnd={(): void => {
          if (state === AlertState.DISMISSING) {
            onDismissTransitionEnd()
          } else if (state === AlertState.PRESENTING) {
            onPresentTransitionEnd()
          }
          setIsTransitionEnd(true)
        }}
        data-testid={isTransitionEnd ? alertPresenterPresentedTestId : alertPresenterTestId}
      >
        <Box sx={{ mb: '1.6rem' }}>
          {/* this can actually be passed in via a prop of type ElementType<AlertInfo>
        to be extra generic */}
          <MoveFocusInside disabled={!isTransitionEnd}>
            <Alert {...alert} />
          </MoveFocusInside>
        </Box>
      </Box>
    )
  },
)
