export enum VerdictType {
  BYPASSED = 'bypassed',
  REDIRECTED = 'redirected',
  BLOCKED = 'blocked',
}

export enum MostActiveType {
  FILTERS = 'filters',
  SERVICES = 'services',
  LOCATIONS = 'locations',
}

export enum TrafficType {
  SOURCE = 'source',
  DESTINATION = 'destination',
  ISP = 'isp',
}

export enum TimeGranularity {
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
}

export interface AnalyticsTimeSeriesDataPoint {
  ts: string
  count: Record<string, number>
}
export interface AnalyticsTimeSeriesData {
  startTs: number
  endTs: number
  granularity: TimeGranularity
  queries: AnalyticsTimeSeriesDataPoint[]
}

export interface AnalyticsPieChartDataPoint {
  [index: string]: number
}

export interface MostActiveDataPoint {
  [index: string]: number | { [index: number]: number }
}

export interface MostActiveData {
  startTs: number
  endTs: number
  queries: MostActiveDataPoint
}

export interface AnalyticsPieChartData {
  startTs: number
  endTs: number
  queries: AnalyticsPieChartDataPoint
}

export type CommonParams = {
  startTs?: number
  endTs?: number
  deviceId?: string
  clientId?: string
}

export enum QueryFilterType {
  FILTERS = 'filters',
  SERVICES = 'services',
  LOCATIONS = 'locations',
  ALL = 'all',
}

export interface StatEndpointInfo {
  PK: string
  title: string
  country_code?: string
}

export interface StatLevelInfo {
  PK: StatLevel
  title: string
}

export enum StatLevel {
  NO,
  SOME,
  FULL,
}
