import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { ApiResponse } from 'store/api/http'
import { Message } from 'store/barry/barry'

export interface MessageResponse {
  conversationId: string
  intents: Intent[]
  output: string[]
  timestamp: number
}

interface Intent {
  confidence: number
  intent: string
}

export type PreAuthDataResponse = {
  token: string
}

interface BarrySupportTicketRequest {
  email: string
  subject: string
  chatlog: Message[]
  message?: string
  channel: string
  token: string
}

export interface HelloBarryMessageRequest {
  on_page: string
}

export interface HelloBarryMessageResponse {
  cta: string
}

export const supportApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    sendSupportTicket: builder.mutation({
      query: (body: BarrySupportTicketRequest) => getQueryArgs(`/support`, 'POST', body),
      transformErrorResponse,
    }),
    preAuthToken: builder.mutation({
      query: ({ userEmail }: { userEmail: string }) =>
        getQueryArgs(`/preauth/support`, 'POST', { email: userEmail }),
      transformResponse: (response: ApiResponse<PreAuthDataResponse>) => response.body,
      transformErrorResponse,
    }),
    getHelloBarryMessage: builder.query({
      query: ({ on_page }: HelloBarryMessageRequest) => ({
        url: `/support/hello-barry?on_page=${on_page}`,
        method: 'GET',
      }),
      transformResponse: (response: ApiResponse<HelloBarryMessageResponse>) => response.body,
      transformErrorResponse,
    }),
  }),
})

export const {
  useSendSupportTicketMutation,
  usePreAuthTokenMutation,
  useGetHelloBarryMessageQuery,
} = supportApi
