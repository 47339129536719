import React, { ReactElement } from 'react'
import { Box, Text } from 'theme-ui'
import ServicesImage from 'images/tutorialFlows/Services/services-slide.svg'
import ServicesImageLight from 'images/tutorialFlows/Services/services-slide-light.svg'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import { CONTROLDText } from 'ui/CONTROLD'
import useGetColorMode from 'utils/useGetColorMode'

export function ServicesSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? ServicesImageLight : ServicesImage}
      description={
        <Box sx={{ mt: '2.4rem' }}>
          <Text>
            A Service allows you to <Highlight svg={tutorialSlideIcons.block}>Block</Highlight>,{' '}
            <Highlight svg={tutorialSlideIcons.redirect}>Redirect</Highlight> or
            <Highlight svg={tutorialSlideIcons.bypass}>Bypass</Highlight> popular websites and apps
            (such as social media platforms or location restricted services). <br /> <br /> Use the
            Three-Way Toggle to either <Highlight svg={tutorialSlideIcons.block}>Block</Highlight>{' '}
            the service, <Highlight svg={tutorialSlideIcons.redirect}>Redirect</Highlight> it
            through one of {CONTROLDText}&apos;s many locations (or spoof its IP) or{' '}
            <Highlight svg={tutorialSlideIcons.bypass}>Bypass</Highlight> it completely (so that it
            resolves to its true IP).
          </Text>
        </Box>
      }
    />
  )
}
