import { ThemeUIStyleObject, Theme } from 'theme-ui'
import { Interpolation, Theme as EmotionTheme } from '@emotion/react'

export const visuallyHiddenStyle: ThemeUIStyleObject = {
  position: 'absolute',
  height: '1px',
  width: '1px',
  overflow: 'hidden',
  clip: 'rect(1px, 1px, 1px, 1px)',
  whiteSpace: 'nowrap',
}

export function visuallyHiddenOnMobileStyle(theme: Theme): ThemeUIStyleObject {
  return {
    [`@media screen and (max-width: ${theme?.breakpoints?.[0]})`]: visuallyHiddenStyle,
  }
}

export const forScreenReaderOnlyStyle: ThemeUIStyleObject = {
  position: 'absolute',
  left: '-99999px',
  height: '1px',
  width: '1px',
  overflow: 'hidden',
}

export const customDesktopScrollbarStyle = (
  theme: Theme,
): // The strange return type is because this is used with the `<Global />` component for
// global CSS, which although included in `theme-ui` is actually directly from
// `@emotion/core`, so we need to use *their* type.
Interpolation<EmotionTheme> => {
  return {
    // Using the responsive array like in theme-ui doesn't seem to work in Emotion's
    // `Global` component so we make the equivalent media query.
    [`@media screen and (min-width: ${theme.breakpoints?.[1]})`]: {
      '::-webkit-scrollbar': { width: '1.2rem' },
      '::-webkit-scrollbar-track': {
        background: theme.colors?.background,
        borderLeft: '3px solid black',
        borderRight: '3px solid black',
      },
      '::-webkit-scrollbar-thumb': {
        background: theme.colors?.scrollbarThumb,
        borderLeft: '3px solid black',
        borderRight: '3px solid black',
        borderRadius: '5px',
      },
    },
  } as Interpolation<EmotionTheme> // foccibly casting this to get the types to match as the Theme from Emotion is an empty interface
}
