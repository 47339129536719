import { ApiResponse } from 'store/api/http'
import { RuleAction } from 'utils/uniteRules'

export enum RuleType {
  RESET = -1,
  BLOCK = 0,
  WHITELIST = 1,
  SPOOF_IP = 2, // ip or hostname
  SPOOF_TAG = 3, // location
}

export enum EnabledStatus {
  DISABLED,
  ENABLED,
}

export interface CustomRuleData {
  PK: string //hostname
  order?: number
  group: number
  action: RuleAction
  profileId?: string
  comment?: string
}

export type CustomRulesResponseData = { rules: CustomRuleData[] }
export type CustomRulesResponse = ApiResponse<{
  rules: CustomRuleData[]
}>

export interface SingleCustomRuleBody {
  do?: RuleType
  via?: string
  via_v6?: string
  status: EnabledStatus
  group?: number
  ttl?: number
  comment?: string
}

export interface CustomRulePostBody {
  hostnames: string[]
  do?: RuleType
  via?: string
  via_v6?: string
  status: EnabledStatus
  group?: number
  ttl?: number
  comment?: string
}

export interface MultipleCustomRulesPutBody {
  hostnames: string[]
  do?: RuleType
  via?: string
  via_v6?: string
  status?: EnabledStatus
  group?: number
  ttl?: number
  profileId?: string
  comment?: string
}

export interface MultipleCustomRulesPostBody extends MultipleCustomRulesPutBody {
  status: EnabledStatus
}

export interface CustomRulesDeleteBody {
  hostnames: string[]
}

export type MultipleCustomRulesPutResponse = ApiResponse<MultipleCustomRulesPutBody[]>

export type CustomRulesPostResponse = ApiResponse<{ rules: CustomRulePostBody[] }>
export type CustomRulesPutResponse = ApiResponse<SingleCustomRuleBody>
export type CustomRulesDeleteResponse = ApiResponse<CustomRulesDeleteBody>
export type MultipleCustomRulesBody = MultipleCustomRulesPostBody
