import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { ApiResponse } from 'store/api/http'

export interface PutTwoFAArgs {
  code: string
}

export interface TwoFAArgs {
  current_password: string
}

export interface TwofaData {
  twofa: {
    secret: string
    qr: string
  }
}

type TwofaPostResponse = ApiResponse<TwofaData>
type TwofaPutDelResponse = ApiResponse

export const twoFaApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    putTwofa: builder.mutation({
      query: (body: PutTwoFAArgs) => getQueryArgs('/twofa', 'PUT', body),
      transformResponse: (response: TwofaPutDelResponse) => response.body,
      transformErrorResponse,
    }),
    postTwofa: builder.mutation({
      query: (body: TwoFAArgs) => getQueryArgs('/twofa', 'POST', body),
      transformResponse: (response: TwofaPostResponse) => response.body,
      transformErrorResponse,
    }),
    deleteTwofa: builder.mutation({
      query: (body: TwoFAArgs) => getQueryArgs('/twofa', 'DELETE', body),
      transformResponse: (response: TwofaPutDelResponse) => response.body,
      transformErrorResponse,
    }),
  }),
})

export const { endpoints } = twoFaApi
