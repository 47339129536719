import React, { ReactNode } from 'react'
import { Text } from 'theme-ui'
import { Flex } from '@theme-ui/components'

export default function SelectVersionWrapper({
  children,
}: {
  children?: ReactNode
}): React.ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '4rem',
        py: '4rem',
      }}
    >
      <Text variant="size14Weight400Line138" sx={{ color: 'aliceBlue' }}>
        For what version?
      </Text>
      {children}
    </Flex>
  )
}
