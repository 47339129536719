import pick from 'lodash/pick'
import { PricePoint } from 'store/api/billing/payments.interface'
import { defaultCurrencyCode } from 'store/api/billing'

export interface PriceDetails {
  price: number
  original_price?: number
  stripe_id: string
  duration: number
}
export default function getPriceDetailsForCurrency(
  pricePoint: PricePoint,
  currencyCode?: string,
): PriceDetails {
  if (currencyCode && currencyCode !== defaultCurrencyCode) {
    return {
      price: pricePoint[`${currencyCode}_price`],
      original_price: pricePoint[`${currencyCode}_original_price`],
      stripe_id: pricePoint[`${currencyCode}_stripe_id`],
      duration: pricePoint.duration,
    }
  }

  return pick(pricePoint, ['price', 'stripe_id', 'original_price', 'duration'])
}
