import { useEffect } from 'react'

interface useArrowNavForHelpPaneProps {
  onLeftArrowPress: () => void
  onRightArrowPress: () => void
  onClose: () => void
  isButtonVisible: boolean
  isConfigured?: boolean
}

export default function useArrowNavForHelpPane({
  onLeftArrowPress,
  onRightArrowPress,
  onClose,
  isButtonVisible,
  isConfigured = false,
}: useArrowNavForHelpPaneProps): void {
  useEffect(() => {
    const handleKeydown = (e): void => {
      if (e.key === 'ArrowRight' && isButtonVisible) {
        onRightArrowPress()
      }
      if (e.key === 'ArrowLeft' && isButtonVisible) {
        onLeftArrowPress()
      }
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keydown', handleKeydown)
    return (): void => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [onLeftArrowPress, onRightArrowPress, onClose, isButtonVisible, isConfigured])
}
