import React, { ReactElement, ReactNode } from 'react'
import range from 'lodash/range'
import { Box, Flex } from 'theme-ui'
import { keyframes } from '@emotion/react'

export const foregroundStyle = {
  borderRadius: '1.2rem',
  backgroundColor: 'white15',
  opacity: '0.5',
  animation: `${keyframes({ '50%': { opacity: '1' } })} 3s 1s backwards infinite`,
}

export default function ProvisionPlaceholder(): ReactElement {
  const rows = [...range(4).map(i => <RowPlaceholder key={i} />)]
  const columns = [...range(5).map(i => <ColumnPlaceholder key={i}>{rows}</ColumnPlaceholder>)]

  return (
    <Flex
      sx={{
        width: '100%',
        gap: '3.2rem',
        flexDirection: 'column',
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'cardBg',
          borderRadius: [0, '1.2rem'],
          width: '100%',
          flexDirection: 'column',
          p: '1.6rem',
          border: '1px solid',
          borderColor: 'blueYonder15',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            gap: ['2.4rem', '0.8rem'],
          }}
        >
          {columns}
        </Flex>
      </Flex>
    </Flex>
  )
}

function RowPlaceholder(): ReactElement {
  return (
    <Flex sx={{ width: '100%', alignItems: 'center', display: ['none', 'flex'] }}>
      <Box
        sx={{
          ...foregroundStyle,
          width: ['40%', '85%'],
          height: '2.4rem',
        }}
      />
    </Flex>
  )
}

function ColumnPlaceholder({ children }: { children?: ReactNode }): ReactElement {
  return (
    <Flex
      sx={{
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        gap: '2.4rem',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            ...foregroundStyle,
            width: ['40%', '60%'],
            height: '2.4rem',
          }}
        />
      </Flex>
      {children}
    </Flex>
  )
}
