import { navigate } from 'gatsby'
import omit from 'lodash/omit'
import { useEffect } from 'react'
import { TutorialType } from 'store/tutorial/tutorial'
import useQueryString from 'utils/useQueryString'

/**
 * This hook is used to redirect the user to the new url for old urls that used query params.
 */
export default function useRedirects(): void {
  const { qs, nav } = useQueryString()
  useEffect(() => {
    if (qs.overlay === 'profile-management') {
      if (qs.tab === 'device') {
        navigate('/dashboard/endpoints')
      } else {
        nav({ ...omit(qs, ['overlay', 'tab']) })
      }
    }
    if (qs.overlay === 'account') {
      switch (qs.option) {
        case 'billing':
          navigate('/dashboard/billing')
          break
        case 'my-account':
          navigate('/dashboard/account')
          break
        case 'api':
          navigate('/dashboard/api')
          break
      }
    }
    if (qs.tutorial?.toString() === 'profile-management') {
      if (qs.slide?.toString() === '1') {
        nav({ ...qs, tutorial: TutorialType.ENDPOINTS, slide: 0 })
      }
      if (qs.slide?.toString() === '0') {
        nav({ ...qs, tutorial: TutorialType.PROFILES })
      }
    }
  }, [nav, qs])
}
