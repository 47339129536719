import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FeaturedProfile } from 'store/api/featuredProfiles'
import { initialResponseState, ResponseState } from 'store/fetchingLogic'
import { sessionLogout } from 'store/session'

export interface FeaturedProfilesState extends ResponseState<{ profiles: FeaturedProfile[] }> {
  selectedFeaturedProfile: FeaturedProfile | undefined
}

const initialState: FeaturedProfilesState = {
  ...initialResponseState,
  data: { profiles: [] },
  selectedFeaturedProfile: undefined,
}

export const featuredProfilesSlice = createSlice({
  name: 'featuredProfiles',
  initialState,
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    setSelectedFeaturedProfile(state, action: PayloadAction<FeaturedProfile | undefined>): void {
      state.selectedFeaturedProfile = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(sessionLogout.fulfilled, () => initialState)
  },
})

export const { setSelectedFeaturedProfile } = featuredProfilesSlice.actions
