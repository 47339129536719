import React, { MutableRefObject, ReactElement, ReactNode, useEffect, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import WarningIcon from 'images/dashboard/warning-icon.svg'
import { Checkbox, Svg } from 'ui'
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState'
import PasswordInput from 'components/PasswordInput'
import InputProps from 'ui/Inputs/Input.interface'
import { modalFooterRef } from 'ui/NewModalDialog/ModalDialogFooter'
import { createPortal } from 'react-dom'

export enum DeletionType {
  ACCOUNT = 'account',
  PROFILE = 'profile',
  ANALYTICS = 'analytics',
  DEVICE = 'device',
  SUB_ORG = 'sub-org',
}

export interface ConfirmDeletionContentProps {
  type?: DeletionType
  timeSinceLastQuery?: string
  notificationText: string
  checkboxText?: string
  isRequestInFlight?: boolean
  shouldShowPassword?: boolean
  onCancel?: () => void
  onDelete?: (password?: string) => void
  containerRef?: MutableRefObject<HTMLDivElement | null>
  additionalContent?: ReactNode
  customCheckboxes?: ReactNode
}

const ConfirmDeletionContent = ({
  type,
  timeSinceLastQuery,
  notificationText,
  checkboxText,
  isRequestInFlight,
  shouldShowPassword,
  onDelete,
  onCancel,
  containerRef,
  additionalContent,
  customCheckboxes,
}: ConfirmDeletionContentProps): ReactElement => {
  const [acknowledgeDeletion, setAcknowledgeDeletion] = useState(false)
  const [password, setPassword] = useState('')

  return (
    <Flex
      ref={containerRef}
      data-testid={`${type}-confirm-deletion`}
      sx={{
        position: 'relative',
        height: ['100%', 'auto'],
        maxWidth: ['100%', '43.5rem'],
        pb: 0,
        flexDirection: 'column',
        justifyContent: ['flex-start', 'center'],
        alignItems: 'center',
        textAlign: 'center',
        color: 'aliceBlue60',
      }}
    >
      <Flex sx={{ px: '1.6rem', py: '3.2rem', flexDirection: 'column', alignItems: 'center' }}>
        <Svg svg={WarningIcon} />
        <Text
          variant="size15Weight400Line138"
          sx={{
            mt: '1.2rem',
          }}
        >
          {notificationText}
        </Text>
        {timeSinceLastQuery && (
          <Flex
            sx={{
              mt: '1.6rem',
              px: '1.4rem',
              py: '0.8rem',
              backgroundColor: timeSinceLastQuery ? 'greenApple15' : 'banana50',
              borderRadius: '1rem',
              color: timeSinceLastQuery ? 'greenApple' : 'white',
            }}
          >
            Last query: {timeSinceLastQuery ? timeSinceLastQuery : 'Never'}
          </Flex>
        )}
        {additionalContent}
        {shouldShowPassword && (
          <Flex sx={{ width: ['100%', '36rem'], mt: '2.4rem' }}>
            <ConfirmDeletionPassword onChange={e => setPassword(e.target.value)} />
          </Flex>
        )}
      </Flex>

      {checkboxText && (
        <Flex
          sx={{
            width: '100%',
            position: ['absolute', 'static'],
            left: 0,
            bottom: 0,
            px: '1.2rem',
            py: '0.8rem',
            backgroundColor: 'white8',
            gap: '0.8rem',
            flexDirection: 'column',
          }}
        >
          <Checkbox
            text={checkboxText}
            isChecked={acknowledgeDeletion}
            containerSx={{
              width: '100%',
              textAlign: 'left',
              justifyContent: 'flex-start',
              fontSize: '1.2rem',
              fontWeight: 400,
              lineHeight: '140%',
            }}
            sx={{
              width: '2.8rem',
              pt: '0.4rem',
              mb: 'auto',
              flexShrink: 0,
            }}
            onChange={() => {
              setAcknowledgeDeletion(!acknowledgeDeletion)
            }}
          />
          {customCheckboxes}
        </Flex>
      )}
      <DeleteAndCancelButtons
        onDeleteClick={() => onDelete?.(password)}
        onCancelClick={onCancel}
        isDeleteButtonDisabled={
          checkboxText ? !acknowledgeDeletion : !!shouldShowPassword && !password
        }
        isDeleteLoading={!!isRequestInFlight}
        deleteTestId={`delete-${type}-button`}
      />
    </Flex>
  )
}

function DeleteAndCancelButtons({
  isDeleteButtonDisabled,
  isDeleteLoading,
  onCancelClick,
  onDeleteClick,
  deleteTestId,
}: {
  isDeleteButtonDisabled: boolean
  isDeleteLoading: boolean
  onCancelClick?: () => void
  onDeleteClick?: () => void
  deleteTestId?: string
}): ReactElement | null {
  const [shouldAppendButton, setShouldAppendButton] = useState(false)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current
    ? createPortal(
        <Flex sx={{ width: '100%', justifyContent: 'flex-end', gap: '1.2rem' }}>
          <ButtonWithLoadingState
            variant="newTertiary"
            data-testid="cancel-button"
            ariaLabel="cancel-button"
            onClick={onCancelClick}
            sxSpinner={{ width: '2rem', height: '2rem' }}
            sx={{
              width: ['50%', 'auto'],
            }}
          >
            Cancel
          </ButtonWithLoadingState>
          <ButtonWithLoadingState
            variant="newPrimary"
            disabled={isDeleteButtonDisabled}
            data-testid={deleteTestId}
            ariaLabel="delete button"
            isLoading={isDeleteLoading}
            onClick={onDeleteClick}
            sxSpinner={{ width: '2rem', height: '2rem' }}
            sx={{
              width: ['50%', 'auto'],
            }}
          >
            Delete
          </ButtonWithLoadingState>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}

export default ConfirmDeletionContent

function ConfirmDeletionPassword({ onChange }: Pick<InputProps, 'onChange'>): ReactElement {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <PasswordInput
      isCompact
      type={showPassword ? 'text' : 'password'}
      id="password-input"
      data-testid="password-input"
      name="password"
      label="Password"
      onChange={onChange}
      aria-label="password"
      sxShowPasswordStyle={{
        bottom: '0.8rem',
        right: '1.2rem',
      }}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      shouldShowShowPasswordButton
      autoComplete="off"
    />
  )
}
