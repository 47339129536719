import { useLocation } from '@reach/router'

const personalHeaderPages = [
  'personal',
  'browse-faster',
  'improve-productivity',
  'teleport',
  'parental-controls',
  'multi-device',
  'available-services',
  'plans',
]

export default function useDetermineTypeOfHeader(): {
  isPersonalHeader: boolean
} {
  const location = useLocation()
  const isPersonalHeader = personalHeaderPages.some(
    page =>
      location.pathname.includes(page) ||
      (location.state as { prevPath: string })?.prevPath?.includes(page),
  )

  return {
    isPersonalHeader,
  }
}
