import {
  BLOG_CONTROL_D_DOMAIN,
  DOCS_CONTROL_D_DOMAIN,
  FEEDBACK_CONTROL_D_DOMAIN,
} from 'gatsby-env-variables'

export interface StaticPageNavMenuLink {
  text: string
  to?: string
  isExternal?: boolean
  icon?: React.ElementType
}

export interface StaticPageNavMenuLinks {
  useCasesLinks: StaticPageNavMenuLink[]
  supportLinks: StaticPageNavMenuLink[]
  companyLinks: StaticPageNavMenuLink[]
}

const navMenuLinks: StaticPageNavMenuLinks = {
  useCasesLinks: [
    {
      text: 'Features',
    },
    {
      text: 'Browse Faster',
      to: '/browse-faster',
    },
    {
      text: 'Improve Productivity',
      to: '/improve-productivity',
    },
    { text: 'Switch Your Location', to: '/teleport' },

    {
      text: 'Protect Your Kids',
      to: '/parental-controls',
    },
    { text: 'Customize Multiple Profiles', to: '/multi-device' },
  ],
  supportLinks: [
    {
      text: 'Resources',
    },
    {
      text: 'Documentation',
      to: `${DOCS_CONTROL_D_DOMAIN}/docs`,
      isExternal: true,
    },
    {
      text: 'API Reference',
      to: `${DOCS_CONTROL_D_DOMAIN}/reference`,
      isExternal: true,
    },
    {
      text: 'Changelog',
      to: `${DOCS_CONTROL_D_DOMAIN}/changelog`,
      isExternal: true,
    },
    {
      text: 'Feedback',
      to: FEEDBACK_CONTROL_D_DOMAIN,
      isExternal: true,
    },
  ],
  companyLinks: [
    {
      text: 'Company',
    },
    {
      text: 'Blog',
      to: BLOG_CONTROL_D_DOMAIN,
      isExternal: true,
    },
    { text: 'About Us', to: '/about-us' },
    {
      text: 'Free DNS',
      to: '/free-dns',
    },
    {
      text: 'Network',
      to: '/network',
    },
    { text: 'Contact', to: '/contact' },
    { text: 'Privacy', to: '/privacy' },
    { text: 'Terms', to: '/terms' },
    { text: 'Status', to: '/status' },
  ],
}

export default navMenuLinks
