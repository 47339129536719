import { PromoCodeContainerProps } from 'components/Plans/SelectPlan/PromoCode/PromoCodeContainer'
import TabButton from 'components/Plans/SelectPlan/PromoCode/TabButton'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { PromoMode, useLazyGetProductsQuery } from 'store/api/billing'
import { clearError, setPromoCode, setPromoMode } from 'store/billing'
import { useAppDispatch } from 'store/hooks'
import { Flex, Text } from 'theme-ui'
import { Input } from 'ui'
import { resetPromo } from 'store/billing/products'
import { SerializedError } from '@reduxjs/toolkit'
import ErrorMessage from 'components/Plans/SelectPlan/PromoCode/ErrorMessage'
import CloseButton from 'components/Plans/SelectPlan/PromoCode/CloseButton'
import { clearErrors } from 'store/errors'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'

interface PromoCodeFormProps extends PromoCodeContainerProps {
  promoMode?: PromoMode
  promoCodeError?: SerializedError
  closeForm: () => void
}
export default function PromoCodeForm({
  tabsContainerSx,
  promoMode,
  promoCodeError,
  inputSx,
  closeForm,
}: PromoCodeFormProps): ReactElement {
  const dispatch = useAppDispatch()
  const [promo, setPromo] = useState('')
  const [getProducts, { isLoading }] = useLazyGetProductsQuery()
  const isMobile = useBreakpointIndex() === 0

  const applyCode = useCallback(async (): Promise<void> => {
    const resp = await getProducts({ promoMode, promoCode: promo })

    if (!resp.error) {
      dispatch(setPromoCode(promo))
    }
  }, [dispatch, getProducts, promo, promoMode])

  //clear promo code and error on load
  useEffect(() => {
    setPromo('')

    return () => {
      dispatch(clearError())
      dispatch(clearErrors())
    }
  }, [dispatch])

  return (
    <>
      <Flex
        data-testid="promo-code-form"
        sx={{
          flexDirection: 'column',
          width: ['100%', '100%', '46.8rem'],
          textAlign: 'left',
          gap: '0.8rem',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            ...tabsContainerSx,
          }}
        >
          <TabButton
            onClick={() => {
              dispatch(setPromoMode(PromoMode.PROMO))
              dispatch(clearError())
            }}
            isSelected={promoMode === PromoMode.PROMO}
            sx={{ mr: '0.8rem' }}
          >
            Promo Code
          </TabButton>
          <TabButton
            onClick={() => {
              dispatch(setPromoMode(PromoMode.USERNAME))
              dispatch(clearError())
            }}
            isSelected={promoMode === PromoMode.USERNAME}
          >
            Windscribe Username
          </TabButton>
        </Flex>
        <Flex
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: ['column', 'row'],
            gap: ['1.2rem', 0],
          }}
        >
          <Flex
            sx={{
              position: 'relative',
              width: '100%',
              flexDirection: ['column', 'row'],
              alignItems: ['flex-start', 'center'],
              mr: [0, '1.2rem'],
              gap: ['1.2rem', 0],
              textAlign: 'left',
              ...inputSx,
            }}
          >
            <Input
              data-testid="promo-code-input"
              sx={{
                fontSize: '1.4rem',
                pl: '1.2rem',
                py: '0.8rem',
                pr: '0.4rem',
              }}
              containerStyle={{
                width: '100%',
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  applyCode()
                }
              }}
              placeholder={promoMode === PromoMode.PROMO ? 'Enter Code' : 'Enter Username'}
              aria-label={promoMode === PromoMode.PROMO ? 'enter promo code' : 'enter username'}
              onChange={e => {
                setPromo(e.target.value)
              }}
              onFocus={() => {
                dispatch(clearError())
              }}
            />
            {isMobile && (
              <DescriptionAndErrorMessage
                errorMessage={promoCodeError?.message}
                isUserName={promoMode === PromoMode.USERNAME}
              />
            )}
            <ButtonWithLoadingState
              isLoading={isLoading}
              variant="newSecondary"
              data-testid="apply-promo-code-button"
              ariaLabel="apply promo code button"
              onClick={applyCode}
              sx={{
                position: ['static', 'absolute'],
                width: ['100%', '9.2rem'],
                right: '0.4rem',
                height: '3.8rem',
              }}
            >
              Apply
            </ButtonWithLoadingState>
          </Flex>

          <CloseButton
            data-testid="close-promo-code-form"
            onClick={async () => {
              dispatch(resetPromo())
              closeForm()
            }}
          />
        </Flex>
        {!isMobile && (
          <DescriptionAndErrorMessage
            errorMessage={promoCodeError?.message}
            isUserName={promoMode === PromoMode.USERNAME}
          />
        )}
      </Flex>
    </>
  )
}

function DescriptionAndErrorMessage({
  errorMessage,
  isUserName,
}: {
  errorMessage?: string
  isUserName?: boolean
}) {
  return (
    <>
      {errorMessage && <ErrorMessage promoCodeError={errorMessage} />}
      {isUserName && (
        <Text
          variant="size12Weight400"
          sx={{
            color: 'aliceBlue60',
          }}
        >
          Enter existing Windscribe username here. Promo and Build-a-Plan not eligible.
        </Text>
      )}
    </>
  )
}
