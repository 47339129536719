import { useStaticQuery, graphql } from 'gatsby'

function useOsImageQuery(): GatsbyTypes.ImageQueryQuery {
  const data = useStaticQuery<GatsbyTypes.ImageQueryQuery>(graphql`
    query ImageQuery {
      macPref: file(relativePath: { eq: "helpPaneSteps/macos/macosscreen_02@2x.png" }) {
        ...fluidImage
      }
      macNetwork: file(relativePath: { eq: "helpPaneSteps/macos/macosscreen_03@2x.png" }) {
        ...fluidImage
      }
      macAdvanced: file(relativePath: { eq: "helpPaneSteps/macos/macosscreen_04@2x.png" }) {
        ...fluidImage
      }
      macDns: file(relativePath: { eq: "helpPaneSteps/macos/macosscreen_05@2x.png" }) {
        ...fluidImage
      }
      macApp: file(relativePath: { eq: "helpPaneSteps/cdu-mac.png" }) {
        ...fluidImage
      }
      winApp: file(relativePath: { eq: "helpPaneSteps/cdu-win.png" }) {
        ...fluidImage
      }
      winStartMenu: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_01@2x.png" }) {
        ...fluidImage
      }
      winNetwork: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_02@2x.png" }) {
        ...fluidImage
      }
      winSharing: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_03@2x.png" }) {
        ...fluidImage
      }
      winDns: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_04@2x.png" }) {
        ...fluidImage
      }
      winSettings: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_05@2x.png" }) {
        ...fluidImage
      }
      winProperties: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_06@2x.png" }) {
        ...fluidImage
      }
      winProtocol: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_07@2x.png" }) {
        ...fluidImage
      }
      winServer: file(relativePath: { eq: "helpPaneSteps/windows/windowsscreen_08@2x.png" }) {
        ...fluidImage
      }
      winDnsSettings: file(relativePath: { eq: "helpPaneSteps/windows/win-dnssetings-1.png" }) {
        ...fluidImage
      }
      winDnsEncryption: file(
        relativePath: { eq: "helpPaneSteps/windows/win-dnsencryption-1.png" }
      ) {
        ...fluidImage
      }
      droidSettings: file(relativePath: { eq: "helpPaneSteps/android/androidscreen_01@2x.png" }) {
        ...fluidImage
      }
      droidSearch: file(relativePath: { eq: "helpPaneSteps/android/androidscreen_02@2x.png" }) {
        ...fluidImage
      }
      droidTap: file(relativePath: { eq: "helpPaneSteps/android/androidscreen_03@2x.png" }) {
        ...fluidImage
      }
      droidChoose: file(relativePath: { eq: "helpPaneSteps/android/androidscreen_04@2x.png" }) {
        ...fluidImage
      }
      androidTv1: file(relativePath: { eq: "helpPaneSteps/android-tv/android_tv_01.png" }) {
        ...fluidImage
      }
      androidTv2: file(relativePath: { eq: "helpPaneSteps/android-tv/android_tv_02.png" }) {
        ...fluidImage
      }
      androidTv3: file(relativePath: { eq: "helpPaneSteps/android-tv/android_tv_03.png" }) {
        ...fluidImage
      }
      androidTv4: file(relativePath: { eq: "helpPaneSteps/android-tv/android_tv_04.png" }) {
        ...fluidImage
      }
      androidTv5: file(relativePath: { eq: "helpPaneSteps/android-tv/android_tv_05.png" }) {
        ...fluidImage
      }
      androidTv6: file(relativePath: { eq: "helpPaneSteps/android-tv/android_tv_06.png" }) {
        ...fluidImage
      }
      androidTv7: file(relativePath: { eq: "helpPaneSteps/android-tv/android_tv_07.png" }) {
        ...fluidImage
      }
      chromeSettings: file(relativePath: { eq: "helpPaneSteps/chrome/chromescreen-01@2x.png" }) {
        ...fluidImage
      }
      chromePrivacy: file(relativePath: { eq: "helpPaneSteps/chrome/chromescreen_02@2x.png" }) {
        ...fluidImage
      }
      chromeSecurity: file(relativePath: { eq: "helpPaneSteps/chrome/chromescreen_03@2x.png" }) {
        ...fluidImage
      }
      chromeCustom: file(relativePath: { eq: "helpPaneSteps/chrome/chromescreen_04@2x.png" }) {
        ...fluidImage
      }
      firefox1: file(relativePath: { eq: "helpPaneSteps/firefox/firefox-1.png" }) {
        ...fluidImage
      }
      firefox2: file(relativePath: { eq: "helpPaneSteps/firefox/firefox-2.png" }) {
        ...fluidImage
      }
      firefox3: file(relativePath: { eq: "helpPaneSteps/firefox/firefox-3.png" }) {
        ...fluidImage
      }
      firefox5: file(relativePath: { eq: "helpPaneSteps/firefox/firefox-5.png" }) {
        ...fluidImage
      }
      firefox6: file(relativePath: { eq: "helpPaneSteps/firefox/firefox-6.png" }) {
        ...fluidImage
      }
      firefox7: file(relativePath: { eq: "helpPaneSteps/firefox/firefox-7.png" }) {
        ...fluidImage
      }
      firefox8: file(relativePath: { eq: "helpPaneSteps/firefox/firefox-8.png" }) {
        ...fluidImage
      }
      edgeSettings: file(relativePath: { eq: "helpPaneSteps/edge/msedgescreen_01@2x.png" }) {
        ...fluidImage
      }
      edgeDns: file(relativePath: { eq: "helpPaneSteps/edge/msedgescreen_02@2x.png" }) {
        ...fluidImage
      }
      edgeServiceProvider: file(relativePath: { eq: "helpPaneSteps/edge/msedgescreen_03@2x.png" }) {
        ...fluidImage
      }
      operaSettings: file(relativePath: { eq: "helpPaneSteps/opera/operascreen_01@2x.png" }) {
        ...fluidImage
      }
      operaDns: file(relativePath: { eq: "helpPaneSteps/opera/operascreen_02@2x.png" }) {
        ...fluidImage
      }
      operaCustomDns: file(relativePath: { eq: "helpPaneSteps/opera/operascreen_03@2x.png" }) {
        ...fluidImage
      }
      iosSettings: file(relativePath: { eq: "helpPaneSteps/ios/iosscreen_14_01@2x.png" }) {
        ...fluidImage
      }
      iosInstallProfile: file(relativePath: { eq: "helpPaneSteps/ios/iosscreen_14_02@2x.png" }) {
        ...fluidImage
      }
      bigSurPreferences: file(relativePath: { eq: "helpPaneSteps/macos/macosscreen_02@2x.png" }) {
        ...fluidImage
      }
      bigSurProfiles: file(
        relativePath: { eq: "helpPaneSteps/macos/macosscreen_bigsur_01@2x.png" }
      ) {
        ...fluidImage
      }
      bigSurInstall: file(
        relativePath: { eq: "helpPaneSteps/macos/macosscreen_bigsur_02@2x.png" }
      ) {
        ...fluidImage
      }
      bigSurChromeOpenProfile: file(
        relativePath: { eq: "helpPaneSteps/macos/macosscreen_01@2x.png" }
      ) {
        ...fluidImage
      }
      bigSurFirefoxOpenProfile: file(
        relativePath: { eq: "helpPaneSteps/macos/firefoxscreen_06@2x.png" }
      ) {
        ...fluidImage
      }
      appleTv1: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletvscreen_01@2x.png" }) {
        ...fluidImage
      }
      appleTv2: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletvscreen_02@2x.png" }) {
        ...fluidImage
      }
      appleTv3: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletvscreen_03@2x.png" }) {
        ...fluidImage
      }
      appleTv4: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletvscreen_04@2x.png" }) {
        ...fluidImage
      }
      appleTv5: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletvscreen_05@2x.png" }) {
        ...fluidImage
      }
      appleTv6: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletvscreen_06@2x.png" }) {
        ...fluidImage
      }
      appleTv14_2: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletv14_02@2x.png" }) {
        ...fluidImage
      }
      appleTv14_3: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletv14_03@2x.png" }) {
        ...fluidImage
      }
      appleTv14_4: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletv14_04@2x.png" }) {
        ...fluidImage
      }
      appleTv14_5: file(relativePath: { eq: "helpPaneSteps/apple-tv/appletv14_05@2x.png" }) {
        ...fluidImage
      }
      fireTv1: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-1.png" }) {
        ...fluidImage
      }
      fireTv2: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-2.png" }) {
        ...fluidImage
      }
      fireTv3: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-3.png" }) {
        ...fluidImage
      }
      fireTv4: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-4.png" }) {
        ...fluidImage
      }
      fireTv5: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-5.png" }) {
        ...fluidImage
      }
      fireTv6: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-6.png" }) {
        ...fluidImage
      }
      fireTv7: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-7.png" }) {
        ...fluidImage
      }
      fireTv8: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-8.png" }) {
        ...fluidImage
      }
      fireTv9: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-9.png" }) {
        ...fluidImage
      }
      fireTv10: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-10.png" }) {
        ...fluidImage
      }
      fireTv11: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-11.png" }) {
        ...fluidImage
      }
      fireTv12: file(relativePath: { eq: "helpPaneSteps/fireTV/fire-tv-12.png" }) {
        ...fluidImage
      }
      samsungTv1: file(relativePath: { eq: "helpPaneSteps/samsungTV/samsung-tv-1.png" }) {
        ...fluidImage
      }
      samsungTv2: file(relativePath: { eq: "helpPaneSteps/samsungTV/samsung-tv-2.png" }) {
        ...fluidImage
      }
      samsungTv3: file(relativePath: { eq: "helpPaneSteps/samsungTV/samsung-tv-3.png" }) {
        ...fluidImage
      }
      samsungTv4: file(relativePath: { eq: "helpPaneSteps/samsungTV/samsung-tv-4.png" }) {
        ...fluidImage
      }
      samsungTv5: file(relativePath: { eq: "helpPaneSteps/samsungTV/samsung-tv-5.png" }) {
        ...fluidImage
      }
      samsungTv6: file(relativePath: { eq: "helpPaneSteps/samsungTV/samsung-tv-6.png" }) {
        ...fluidImage
      }
    }
  `)

  return data
}

export default useOsImageQuery

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
`
