import React, { ReactElement, useMemo } from 'react'
import ResolverTabContent from 'components/SetupGuide/ResolverTabs/Components/UserResolver/ResolverTabContent'
import useResolverTabHighlight from 'components/SetupGuide/ResolverTabs/Components/useResolverTabHighlight'
import { LegacyResolver } from 'components/SetupGuide/ResolverTabs/ResolverTabs'
import useQueryString from 'utils/useQueryString'
import useGetProvisioningState from 'store/provisioning/useGetProvisioningState'

export default function ProvisioningResolverTabs(): ReactElement {
  const { resolver, uid, icon } = useGetProvisioningState()
  const deviceResolvers = resolver
  const deviceUid = uid
  const { qs } = useQueryString()

  const { highlight } = useResolverTabHighlight(icon ?? qs.setupOs)
  const resolverTabData = useMemo(() => {
    return {
      ...(deviceResolvers?.v4
        ? {
            legacy: {
              ipv4Primary: deviceResolvers?.v4?.[LegacyResolver.PRIMARY],
              ipv4Secondary: deviceResolvers?.v4?.[LegacyResolver.SECONDARY],
              ipv6Primary: deviceResolvers?.v6?.[LegacyResolver.PRIMARY],
              ipv6Secondary: deviceResolvers?.v6?.[LegacyResolver.SECONDARY],
            },
          }
        : {}),
      secure: {
        resolverId: deviceUid,
        doh: deviceResolvers?.doh,
        dot: deviceResolvers?.dot,
      },
    }
  }, [deviceResolvers, deviceUid])
  return (
    <ResolverTabContent
      {...{
        resolverTabData,
        highlight,
      }}
    />
  )
}
