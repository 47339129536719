import React, { ReactElement, ReactNode } from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import { Button, ButtonVariant } from 'ui'

export default function TabsContainer({
  children,
  sx,
}: {
  children?: ReactNode
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        borderBottom: ({ colors }): string => `1px solid ${colors?.accent}`,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export function Tab({
  variant,
  onClick,
  isActive,
  children,
  activeColor,
  inactiveColor,
  sxTab,
}: {
  variant?: ButtonVariant
  onClick: () => void
  isActive: boolean
  activeColor?: string
  inactiveColor?: string
  children?: ReactNode
  sxTab?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Button
      ariaLabel="open tab"
      variant={variant || 'simple'}
      onClick={onClick}
      sx={{
        flex: 1,
        borderBottom: isActive ? `1px solid` : 'none',
        borderBottomColor: ({ colors }): string =>
          isActive ? `${activeColor || colors?.primary}` : 'none',
        mb: '-1px',
        color: isActive
          ? activeColor || 'greenApple'
          : inactiveColor
          ? inactiveColor
          : 'aliceBlue60',
        fontWeight: 1,
        pb: 4,
        ...sxTab,
      }}
    >
      {children}
    </Button>
  )
}
