import { ApiResponse } from '../http'
import { CAN_I_HAZ_TRIAL } from 'store/api/user/user.interface'
import { PaymentMethod } from '@stripe/stripe-js'

export interface CreateCoingateOrderRequestBody {
  price_point_id: string
  currency: string
  [mode: string]: string
}

export interface CreateStripeCustomerBody {
  currency: string
  email?: string
}

export interface CreateOrgStripeSubscriptionBody {
  customerId: string
  paymentMethodId: string
}

export interface CreateStripeSubscriptionBody extends CreateOrgStripeSubscriptionBody {
  priceId: string
  fullname?: string
  currency?: string
}

export interface SubscriptionsData {
  subscriptions: Array<Subscription>
}

export interface UpdateOrgStripeSubscriptionBody {
  subscriptionId: string
}

export interface UpdateStripeSubscriptionBody extends UpdateOrgStripeSubscriptionBody {
  newPriceId: string
  currency: string
  [mode: string]: string
}

// used to represent the common price intervals used although the plans can be for any number of months
export enum CommonPriceInterval {
  YEARLY = 12,
  MONTHLY = 1,
  TRIAL = 0,
}

export enum ProductType {
  FULL = 'Full Control',
  SOME = 'Some Control',
  NONE = 'No Control',
  TRIAL = 'Try Control',
}

export interface PricePoint {
  product_id: number
  duration: number
  original_price?: number
  type: string // standard or promo
  // please go through #1765 to see how already_billed is being used for providing prepaid accounts
  already_billed: 0 | 1 // 0 when customer has to pay. 1 when prepaid and customer does not have to pay now
  PK: string
  price: number //USD
  stripe_id: string //USD
  aud_price: number
  aud_stripe_id: string
  cad_price: number
  cad_stripe_id: string
  chf_price: number
  chf_stripe_id: string
  eur_price: number
  eur_stripe_id: string
  jpy_price: number
  jpy_stripe_id: string
  gbp_price: number
  gbp_stripe_id: string
}

export interface Subscription {
  user: number
  product: number
  method: string
  amount: number
  started: string
  PK: string
  status: number
  currency?: string
  currency_amount?: number
  next_rebill_date?: string
  next_bill?: number
  state?: 'active' | 'failed' | 'cancelled'
}

export interface Product {
  name: string
  type: string
  proxy_access: number
  PK: string
  expiry: string
  price_points: PricePoint[]
}

export interface BillingProduct {
  name: string
  type: string
  proxy_access: number
  PK: string
  expiry: string
  subscription?: Subscription
  price?: PricePoint
}
export interface Payment {
  user: number
  date: string
  ts: number
  tx_id: string
  sub_id: string
  fingerprint: string
  price_point: PricePoint
  product: Product
  method: string
  amount: number
  balance: number
  PK: string
  tx_status: number
  currency?: string
  currency_amount?: number
  tx_refunded: number
}

export enum InvoiceState {
  CANCELLED = -1,
  PENDING = 0,
  PAID = 1,
}

export interface OrganizationInvoice {
  date: string
  tx_id: string
  amount: number
  ts: number
  balance: number
  method: string
  currency: string
  iv_state: InvoiceState
  org: string
  iv_meta: {
    num_users: number
    price_users: number
    num_routers: number
    price_routers: number
  }
  PK: string
  tx_status: number
  tx_refunded: number
  currency_amount: number
  sub_id: string
}

export interface Invoice {
  id: string
  payment_intent: PaymentIntent
}
export interface PaymentIntent {
  status: string
  client_secret: string
  payment_method: string
}

export interface StripeSubscription {
  id: string
  latest_invoice: Invoice
  status: string
}
export interface ProductsData {
  products: Array<Product>
}

export interface PaymentsData {
  payments: Array<Payment> | Array<OrganizationInvoice>
}

export type BillingPaymentsResponse = ApiResponse<PaymentsData>

export interface TrialEligibilityData {
  trial?: boolean
}

export type TrialEligibilityResponse = ApiResponse<TrialEligibilityData>

export interface ActivateTrialOrFreePromoCodeBody {
  can_i_haz_trial?: CAN_I_HAZ_TRIAL
  promo_code?: string
}

export type BillingSubscriptionsResponse = ApiResponse<SubscriptionsData>

export interface UpdatePaymentMethodBody {
  paymentMethodId: string | PaymentMethod
  fullname: string
}

export interface CancelSubscriptionBody {
  reasons: string[]
  comment?: string
  password: string
  subscriptionId: string
}

export type CreateStripeCustomerResponse = ApiResponse<{
  customer: string
}>

export interface SetupIntentData {
  setup_intent: {
    client_secret: string
  }
}
