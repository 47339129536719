import React, { ReactElement } from 'react'
import { Flex, Text } from '@theme-ui/components'
import { Button, Svg } from 'ui'
import LogoIcon from 'images/dashboard/billing/logo-icon.svg'
import { navigate } from 'gatsby'

export default function UpgradeOrganizationPane({
  isSubscriptionActive,
  trialEnd,
  isExpired,
}: {
  isSubscriptionActive: boolean
  trialEnd?: string
  isExpired?: boolean
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        minHeight: '5.4rem',
        flexDirection: ['column', 'row'],
        position: 'relative',
        borderRadius: [0, '1.6rem'],
        border: '1px solid',
        borderColor: 'white17',
        backgroundColor: 'blue600',
        overflow: 'hidden',
        alignItems: 'center',
        gap: '1.2rem',
        px: '1.2rem',
        py: '0.8rem',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.07)',
      }}
    >
      <Svg
        svg={LogoIcon}
        sx={{
          position: 'absolute',
          top: [0, 'unset'],
          bottom: ['unset', '-0.2rem'],
          left: 0,
          height: 'fit-content',
          '& path': {
            height: 'fit-content',
          },
        }}
      />
      <Flex
        sx={{
          width: '100%',
          gap: [0, 0, '1.6rem'],
          alignItems: ['flex-start', 'flex-start', 'center'],
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Text variant="size15Weight700" sx={{ color: 'text' }}>
          {isExpired ? 'Your Trial Has Expired' : `Trial Expires on ${trialEnd}`}
        </Text>
        <Text
          variant="size15Weight500"
          sx={{
            color: 'muted',
          }}
        >
          {isSubscriptionActive
            ? 'You will be billed on this date.'
            : isExpired
            ? 'Please add a method of payment to contnue using Control D.'
            : 'Add payment method to keep your account active.'}
        </Text>
      </Flex>
      {!isSubscriptionActive && (
        <Button
          ariaLabel="navigate to change-payment-method page"
          variant="transparent"
          sx={{
            width: ['100%', 'fit-content'],
            flexShrink: 0,
            px: '1.6rem',
            backgroundColor: 'aliceBlue',
            borderRadius: '10rem',
          }}
          onClick={() => {
            navigate('/change-payment-method')
          }}
        >
          <Text variant="size15Weight700" sx={{ color: 'black' }}>
            Add Payment Method
          </Text>
        </Button>
      )}
    </Flex>
  )
}
