import React, { ReactElement, useState } from 'react'
import { Accordion } from 'ui'
import AdvancedSettingsIcon from 'images/dashboard/devices/advanced-settings.svg'

export default function AdvancedSettings({ children }: { children?: ReactElement }): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Accordion
      id="advanced-settings"
      title="Advanced Settings"
      buttonVariant="simple"
      titleIcon={AdvancedSettingsIcon}
      sxTitleContainer={{
        pr: '2.4rem',
        gap: '1.2rem',
      }}
      titleStyle={{
        color: 'aliceBlue60',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        textAlign: 'left',
      }}
      openTitleStyle={{
        color: 'aliceBlue',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        textAlign: 'left',
      }}
      containerStyle={{
        width: '100%',
        '& > div:first-of-type': {
          width: '100%',
          px: '1.6rem',
          backgroundColor: isOpen ? 'blue' : 'transparent',
          borderTop: '1px solid',
          borderColor: 'blueYonder15',
        },
        alignItems: 'center',
      }}
      buttonStyle={{
        width: '100%',
        justifyContent: 'space-between',
        px: 0,
        py: '0.8rem',
        fontSize: '1.8rem',
        fontWeight: 'bold',
      }}
      svgStyle={{
        backgroundColor: 'transparent',
        flexShrink: 0,
        '& svg': {
          flexShrink: 0,
        },
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={setIsOpen}
    >
      {children}
    </Accordion>
  )
}
