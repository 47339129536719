import React, { ReactElement, useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import TabOutline from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/TabOutline'
import { ResolverType } from 'components/SetupGuide/SetupGuide.interface'
import ResolverContainer from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/ResolverContainer'
import ResolverTabTitle from 'components/SetupGuide/ResolverTabs/Components/ResolverTabTitle'
import LegacyResolver from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/LegacyResolver'
import SecureResolver from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/SecureResolver'
import { ResolverTabData } from 'components/SetupGuide/ResolverTabs/ResolverTabs'
import { freeDnsPagePath } from 'components/FreeDNSPage/FreeDNSPage'
import StandardConfigurationsDropdown from './StandardConfigurationsDropdown'
import { HighlightProtocolInSetupGuide } from 'store/api/devices/devices.interface'
import { Flex } from '@theme-ui/components'

export default function ResolverTabContent({
  resolverTabData,
  highlight,
  deviceSelector,
}: {
  resolverTabData: ResolverTabData
  highlight?: HighlightProtocolInSetupGuide[]
  deviceSelector?: ReactElement
}): ReactElement {
  const location = useLocation()
  // this state is used to keep track of which tab is open
  const [activeType, setActiveType] = useState<ResolverType | undefined>()
  const alwaysOpen = !highlight
  const shouldShowDropdownButton = Object.entries(resolverTabData).length > 1

  useEffect(() => {
    if (
      highlight?.includes(HighlightProtocolInSetupGuide.DOH) ||
      highlight?.includes(HighlightProtocolInSetupGuide.DOT) ||
      highlight?.includes(HighlightProtocolInSetupGuide.ResolverID)
    ) {
      setActiveType(ResolverType.SECURE)
    }
    if (
      resolverTabData.legacy &&
      (highlight?.includes(HighlightProtocolInSetupGuide.IPv4) ||
        highlight?.includes(HighlightProtocolInSetupGuide.IPv6))
    ) {
      setActiveType(ResolverType.LEGACY)
    }
  }, [highlight, resolverTabData.legacy])

  return (
    <TabOutline
      dropdown={
        deviceSelector ||
        (location.pathname.includes(freeDnsPagePath) && <StandardConfigurationsDropdown />)
      }
    >
      {resolverTabData.legacy && (
        <ResolverContainer
          type={ResolverType.LEGACY}
          title={<ResolverTabTitle type={ResolverType.LEGACY} />}
          isOpen={(!activeType && alwaysOpen) || activeType === ResolverType.LEGACY}
          setIsOpen={setActiveType}
          shouldShowDropdownButton={shouldShowDropdownButton}
        >
          <LegacyResolver highlight={highlight} legacyResolverData={resolverTabData.legacy} />
        </ResolverContainer>
      )}

      <ResolverContainer
        type={ResolverType.SECURE}
        title={<ResolverTabTitle type={ResolverType.SECURE} />}
        isOpen={
          (!activeType && (!resolverTabData.legacy || alwaysOpen)) ||
          activeType === ResolverType.SECURE
        }
        setIsOpen={setActiveType}
        shouldShowDropdownButton={shouldShowDropdownButton}
      >
        {Object.entries(resolverTabData.secure).map(([type, address]) => (
          <Flex
            key={type}
            data-testid={type}
            sx={{
              listStyle: 'none',
              mx: 0,
              mt: 0,
              '& > div:not(:last-of-type)': {
                mb: '1.2rem',
              },
              backgroundColor: 'transparent',
              color: highlight?.includes(type as HighlightProtocolInSetupGuide)
                ? 'greenApple'
                : 'aliceBlue60',
            }}
          >
            <SecureResolver
              type={type}
              isResolverTypeHighlighted={
                !!highlight?.includes(type as HighlightProtocolInSetupGuide)
              }
              address={address}
              canCopy={true}
            />
          </Flex>
        ))}
      </ResolverContainer>
    </TabOutline>
  )
}
