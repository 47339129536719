import React, { ReactElement } from 'react'
import { Button, BackIcon } from 'ui'
import { searchRules } from 'store/customRules'
import { useAppDispatch } from 'store/hooks'
import { useLocation } from '@reach/router'
import useQueryString from 'utils/useQueryString'

const BackButton = (): ReactElement => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { relocate } = useQueryString()

  const onClickBackButton = async (): Promise<void> => {
    dispatch(searchRules(''))
    relocate(location.pathname.slice(0, location.pathname.lastIndexOf('/')))
  }

  return (
    <Button
      ariaLabel="folder back button"
      data-testid="folder-back-button"
      onClick={onClickBackButton}
      sx={{
        width: '4rem',
        height: '4rem',
        border: 'none',
        backgroundColor: ['transparent', 'white6'],
        borderRadius: '50%',
        p: [0, '0.8rem'],
        mr: '1.2rem',
        flexShrink: 0,
        '&:hover svg path': {
          fill: 'white',
        },
      }}
      variant="simple"
    >
      <BackIcon
        fill="white60"
        sx={{
          width: '2.4rem',
          height: '2.4rem',
        }}
      />
    </Button>
  )
}

export default BackButton
