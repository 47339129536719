import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import { MainNavMenuItems } from './NavMenu'
import { PreferencesNavMenu } from './PreferencesNavMenu'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function NonOrganizationNavBarContent(): ReactElement {
  const isMobile = useBreakpointIndex() === 0

  return (
    <Flex
      sx={{
        position: 'relative',
        backgroundColor: isMobile ? 'blue800' : 'inherit',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pb: [0, '1.2rem'],
        pt: ['1.2rem', 0],
        px: ['1.2rem', 0],
        flexShrink: [0, 1],
      }}
    >
      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          width: '100%',
          top: 0,
          pb: '1.8rem',
          backgroundImage: (theme): string => {
            return `linear-gradient(180deg, ${theme.colors?.blue800} 40%, rgba(18, 19, 28, 0) 100%)`
          },
          zIndex: 'zIndex50',
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          flexShrink: 0,
          pb: ['1.6rem', '1.2rem'],
          pt: [0, '1.6rem'],
          maxHeight: ['fit-content', 'calc(100% - 4.8rem)'],
          overflowY: ['hidden', 'auto'],
          gap: '0.1rem',
        }}
        className="hide-scrollbar"
      >
        <MainNavMenuItems />
        <PreferencesNavMenu />
      </Flex>
    </Flex>
  )
}
