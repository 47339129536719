import {
  BASE_URL,
  BASE_URL_ASSETS,
  DOWNLOAD_ANDROID_APP_URL,
  DOWNLOAD_IOS_APP_URL,
} from 'gatsby-env-variables'
import { SetupGuideMode } from 'components/SetupGuide/SetupGuide.interface'
import { supportsDnsSetupViaProfile } from 'utils/platform'
import useQueryString from 'utils/useQueryString'
import usePathSuffix from 'components/SetupGuide/usePathSuffix'
import type { AdvancedSettingsType } from 'components/SetupGuide/Components/ProfileDownload'
import { Option } from 'ui'

export default function useMobileConfigUrl({
  advancedSettings,
  excludedDomains,
  excludedNetworks,
  shouldUsePlatformDetection,
  isQrCode = false,
}: {
  shouldUsePlatformDetection?: boolean
  excludedDomains?: Option[] | null
  excludedNetworks?: Option[] | null
  advancedSettings?: AdvancedSettingsType
  isQrCode?: boolean
} = {}): string {
  const { qs } = useQueryString()
  const pathSuffix = usePathSuffix()
  const { isCommonExclude, shouldTrustRootCa, shouldPreventDisablement, clientId } =
    advancedSettings || {}

  let path = `${BASE_URL_ASSETS}/utility/controld.exe`
  const queryParams = new URLSearchParams()

  clientId && queryParams.append('client_id', clientId)

  excludedDomains?.length &&
    queryParams.append(
      'exclude_domain[]',
      excludedDomains?.map(domain => encodeURIComponent(domain.label))?.join('&exclude_domain[]='),
    )

  excludedNetworks?.length &&
    queryParams.append(
      'exclude_wifi[]',
      excludedNetworks?.map(network => encodeURIComponent(network.label))?.join('&exclude_wifi[]='),
    )

  isCommonExclude && queryParams.append('exclude_common', '1')
  shouldTrustRootCa && queryParams.append('trust_root', '1')
  shouldPreventDisablement && queryParams.append('prevent_disable', '1')
  isQrCode && queryParams.append('qr', '1')
  const queryParamsString = decodeURIComponent(`&${queryParams.toString()}`)

  if (
    qs.setupOs?.includes('mac') ||
    qs.setupOs?.includes('linux') ||
    qs.setupOs?.includes('router')
  ) {
    if (qs.mode === SetupGuideMode.AUTO) {
      path = `${BASE_URL_ASSETS}/utility/controld.dmg`
    } else {
      const isFreeDns = pathSuffix?.includes('type=free')
      path = `${BASE_URL}/mobileconfig/${pathSuffix}${
        queryParamsString &&
        `${isFreeDns ? '' : '?'}${isFreeDns ? queryParamsString : queryParamsString.substring(1)}`
      }`
    }
  }

  if (
    (qs.setupOs?.includes('ios') || (shouldUsePlatformDetection && supportsDnsSetupViaProfile())) &&
    qs.mode === SetupGuideMode.MANUAL
  ) {
    const isFreeDns = pathSuffix?.includes('type=free')
    path = `${BASE_URL}/mobileconfig/${pathSuffix}${
      queryParamsString &&
      `${isFreeDns ? '' : '?'}${isFreeDns ? queryParamsString : queryParamsString.substring(1)}`
    }`
  }

  if (qs.setupOs?.includes('ios') && qs.mode === SetupGuideMode.AUTO) {
    path = DOWNLOAD_IOS_APP_URL
  }

  if (qs.setupOs?.includes('android') && qs.mode === SetupGuideMode.AUTO) {
    path = DOWNLOAD_ANDROID_APP_URL
  }

  return path
}
