import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AddOrEditDeviceView from './AddOrEditDevice/AddOrEditDevice/AddOrEditDeviceView'
import useQueryString from 'utils/useQueryString'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { useAppSelector } from 'store/hooks'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/ShowAlertOnModalOrTrayWrapper'
import { useGetDevicesQuery } from 'store/api/devices'
import ModalDialog from 'ui/NewModalDialog'
import { Flex } from '@theme-ui/components'
import DeleteDeviceConfirmation from './AddOrEditDevice/AddOrEditDevice/DeleteDeviceConfirmation'
import { navigate } from 'gatsby'
import ScheduleView from './AddOrEditDevice/Scheduling/ScheduleView'

export enum DeviceDialogType {
  ADD = 'add',
  EDIT = 'edit',
  SCHEDULE = 'schedule',
}
const DeviceModalDialog = (): ReactElement | null => {
  const { qs, nav } = useQueryString()
  const { data: devicesData } = useGetDevicesQuery('')
  const { editDeviceId } = useAppSelector(s => s.devices)
  const device = useMemo(
    () => devicesData?.devices.find(d => d.PK === editDeviceId),
    [devicesData?.devices, editDeviceId],
  )
  const [deviceToDeletePk, setDeviceToDeletePk] = useState('')
  const modalDialogRef = useRef<HTMLDivElement | null>(null)

  const onClose = useCallback(() => {
    nav({
      ...omit(
        qs,
        'deviceDialog',
        'setupOs',
        'deviceName',
        'parentDeviceId',
        'clientId',
        'legacyIpv4Status',
        'learnIp',
        'stats',
      ),
    })
  }, [qs, nav])

  const currentView = useMemo(() => {
    switch (qs.deviceDialog) {
      case DeviceDialogType.ADD:
        const parentDevice = devicesData?.devices.find(d => d.PK === qs.parentDeviceId)
        const clientDevice = {
          ...pick(parentDevice, ['profile', 'profile2', 'stats']),
          remap_device_id: parentDevice?.PK,
          remap_client_id: qs.clientId,
        }
        return {
          title: 'Create Endpoint',
          content: <AddOrEditDeviceView dismiss={onClose} device={parentDevice && clientDevice} />,
        }
      case DeviceDialogType.EDIT:
        return {
          title: 'Edit Endpoint',
          content: (
            <AddOrEditDeviceView
              dismiss={onClose}
              device={device}
              onDeleteClick={pk => setDeviceToDeletePk(pk)}
            />
          ),
        }
      case DeviceDialogType.SCHEDULE:
        return {
          title: 'Schedule',
          content: <ScheduleView device={device} dismiss={onClose} />,
        }
      default:
        return
    }
  }, [qs.deviceDialog, qs.clientId, qs.parentDeviceId, devicesData?.devices, onClose, device])

  // show the device dialog in edit mode if the device exist
  // this is used to prevent the modal from showing on page reload.
  useEffect(() => {
    if (qs.deviceDialog === DeviceDialogType.EDIT && !device) {
      nav(omit(qs, 'deviceDialog'))
    }
  }, [device, nav, qs])

  const shouldShowDeviceDialog = useMemo(
    () => (qs.deviceDialog === DeviceDialogType.EDIT ? !!device : true),
    [device, qs.deviceDialog],
  )

  const isOpen = !!qs.deviceDialog && shouldShowDeviceDialog

  return shouldShowDeviceDialog ? (
    !deviceToDeletePk ? (
      <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={isOpen}>
        <ModalDialog
          modalDialogRef={modalDialogRef}
          title={currentView?.title}
          isOpen={isOpen}
          dataTestId="device-dialog"
          dismiss={onClose}
          isLeftContentVisible={qs.deviceDialog === DeviceDialogType.SCHEDULE}
          isRightContentVisible={qs.deviceDialog !== DeviceDialogType.SCHEDULE}
          onCloseClick={onClose}
          onBackClick={() => {
            navigate(-1)
          }}
          footer={<Flex sx={{ height: '3.8rem' }} />}
        >
          {currentView?.content}
        </ModalDialog>
      </ShowAlertOnModalOrTrayWrapper>
    ) : (
      <DeleteDeviceConfirmation
        devicePk={deviceToDeletePk}
        deviceToDeletePk={deviceToDeletePk}
        setDeviceToDeletePk={setDeviceToDeletePk}
        lastActivity={device?.last_activity}
        dismiss={() => {
          setDeviceToDeletePk('')
          onClose()
        }}
      />
    )
  ) : null
}

export default DeviceModalDialog
