import React, { ReactElement } from 'react'
import { ThemeUIStyleObject, Text } from 'theme-ui'

interface CONTROLDProps {
  CONTROLsx?: ThemeUIStyleObject
  Dsx?: ThemeUIStyleObject
}

// Use this in copy
export const CONTROLDText = 'Control D'

// Use this for logos, where the D should be green, and using the product name in headings, where the D should be white
export default function CONTROLDLogo({ CONTROLsx, Dsx }: CONTROLDProps): ReactElement {
  return (
    <Text
      sx={{ fontWeight: 'bold', letterSpacing: '-0.11em', ...CONTROLsx }}
      aria-label="Control D"
    >
      CONTROL
      <Text sx={{ ...Dsx }}>D</Text>
    </Text>
  )
}
