import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { EnabledStatus, RuleType } from 'store/api/rules'
import BlockIcon from 'images/block-icon-log.svg'
import WhitelistIcon from 'images/whitelist-icon-log.svg'
import RedirectIcon from 'images/redirect-icon.svg'
import ActionIconButton from 'components/Dashboard/Profiles/RuleActions/ActionIconButton'
import RedirectButton from 'components/Dashboard/Profiles/RuleActions/RedirectButton'
import { ActionableRule, isCustomRule, isServiceRule } from 'utils/uniteRules'
import { ServiceData } from 'store/api/services'
import { ProxyLocation } from 'store/api/proxies'
import ActionButtonTooltip, { tooltipVisibility } from './ActionButtonTooltip'
import useOnClickOutside from 'utils/useOnClickOutside'
import { setRuleTooltipSettings } from 'store/tutorial/tutorial'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useBoundaryElement from 'utils/useBoundaryElement'
import useGetUser from 'components/Dashboard/utils/useGetUser'

interface RuleActionProps {
  rule: ActionableRule
  handleRuleTypeClick: (ruleType: RuleType) => void
  setRedirectButtonHover?: (x: boolean) => void
  /**
   * Used for forcing a custom rule to have the folder rule type
   */
  forceRuleType?: RuleType
  sxContainer?: ThemeUIStyleObject
  redirectLocation?: ProxyLocation
  isEnabledService?: boolean
}
export default function RuleActions({
  rule,
  handleRuleTypeClick,
  setRedirectButtonHover,
  forceRuleType,
  sxContainer,
  redirectLocation,
  isEnabledService,
}: RuleActionProps): ReactElement {
  const dispatch = useAppDispatch()
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''
  const viewedStateByUserPk = useAppSelector(s => s.tutorial.viewedStateByUserPk[userPk ?? ''])
  const selectedRuleType = viewedStateByUserPk?.selectedRuleType
  const isBlocked = (forceRuleType ?? rule?.action?.do) === RuleType.BLOCK
  const isWhiteListed = (forceRuleType ?? rule?.action?.do) === RuleType.WHITELIST
  const isRedirect =
    (forceRuleType ?? rule?.action?.do) === RuleType.SPOOF_TAG ||
    (forceRuleType ?? rule?.action?.do) === RuleType.SPOOF_IP
  const isSwitchChecked = rule?.action?.status === EnabledStatus.ENABLED || isCustomRule(rule)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const boundaryElement = useBoundaryElement('dashboard-services')
  // check if the rule has a ServiceData type
  const serviceName = 'name' in rule ? rule.name : ''
  useOnClickOutside([containerRef], () => {
    setIsTooltipVisible(false)
  })

  useEffect(() => {
    isEnabledService && setIsTooltipVisible(true)
  }, [isEnabledService])

  const onHideOrClick = useCallback(() => {
    dispatch(
      setRuleTooltipSettings({
        userPk,
        [tooltipVisibility[selectedRuleType]]: true,
      }),
    )
  }, [dispatch, userPk, selectedRuleType])

  const onClick = (ruleType: RuleType) => {
    if (forceRuleType) {
      return
    }

    onHideOrClick()
    handleRuleTypeClick(ruleType)
    setIsTooltipVisible(true)
  }

  return (
    <ActionButtonTooltip
      ariaLabel="action button tooltip"
      appendTo={boundaryElement}
      isVisible={isTooltipVisible}
      cityName={redirectLocation?.city}
      serviceName={serviceName}
      onHideOrClick={onHideOrClick}
    >
      <Flex
        ref={containerRef}
        sx={{
          flexShrink: 0,
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: isServiceRule(rule) ? 'flex-end' : 'unset',
          width: '9.6rem',
          height: '3.2rem',
          borderRadius: '3.2rem',
          position: 'relative',
          border: '1px solid',
          borderColor: 'blueYonder30',
          backgroundColor: isSwitchChecked && forceRuleType === undefined ? 'blue800' : 'white6',
          ...sxContainer,
        }}
        role="menu"
      >
        <ActionIconButton
          onClick={(): void => onClick(RuleType.BLOCK)}
          label="Block"
          ariaLabel="block button"
          isActive={isBlocked}
          role="menuitemradio"
          isSwitchChecked={isSwitchChecked}
          icon={BlockIcon}
          disabled={forceRuleType !== undefined}
        />
        <ActionIconButton
          onClick={(): void => onClick(RuleType.WHITELIST)}
          label="Bypass"
          ariaLabel="bypass button"
          isActive={isWhiteListed}
          role="menuitemradio"
          isSwitchChecked={isSwitchChecked}
          icon={WhitelistIcon}
          disabled={forceRuleType !== undefined}
        />
        {isServiceRule(rule) ? (
          <RedirectButton
            serviceRule={rule as ServiceData}
            isSwitchChecked={isSwitchChecked}
            setRedirectButtonHover={setRedirectButtonHover}
            onFirstClick={(): void => onClick(RuleType.SPOOF_TAG)}
            redirectLocation={redirectLocation}
          />
        ) : (
          <ActionIconButton
            onClick={(): void => {
              if (forceRuleType) {
                return
              }

              if (
                rule?.action?.do !== RuleType.SPOOF_IP &&
                rule?.action?.do !== RuleType.SPOOF_TAG
              ) {
                onClick(RuleType.SPOOF_TAG)
              }
            }}
            label="Redirect"
            ariaLabel="redirect button"
            isActive={isRedirect}
            isSwitchChecked={isSwitchChecked}
            role="menuitemradio"
            icon={RedirectIcon}
            disabled={forceRuleType !== undefined}
          />
        )}
      </Flex>
    </ActionButtonTooltip>
  )
}
