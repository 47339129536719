import React, { ReactElement, useMemo, useState, useRef } from 'react'
import { Flex } from 'theme-ui'
import {
  Dropdown,
  IconButtonContent,
  SimpleActionMenu,
  SimpleActionMenuItemProps,
  Svg,
  useAlertPresenter,
} from 'ui'
import { useAppDispatch } from 'store/hooks'
import OptionsMenuIcon from 'images/profileManagement/options-menu-icon.svg'
import useQueryString from 'utils/useQueryString'
import { setSelectProvisionPk } from 'store/organization/organization'
import InstructionIcon from 'images/organization/instruction-icon.svg'
import EmailIcon from 'images/organization/email-icon.svg'
import InvalidateCodeIcon from 'images/organization/invalidate-code-icon.svg'
import DeleteIcon from 'images/delete-icon.svg'
import { useDeleteProvisionMutation, usePutProvisionInvalidateMutation } from 'store/api/provision'
import { ProvisionDialogType } from 'components/Organization/Provision'
import ErrorIcon from 'images/erroricon.svg'
import useBoundaryElement from 'utils/useBoundaryElement'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useGetColorMode from 'utils/useGetColorMode'

const deleteAlertId = 'delete-alert'

export default function ProvisionOptionsMenu({
  provisionPk,
}: {
  provisionPk: string
}): ReactElement {
  const dispatch = useAppDispatch()
  const { nav, qs } = useQueryString()
  const { presentAlert, dismissAlert } = useAlertPresenter()
  const [putProvisionInvalidateCode] = usePutProvisionInvalidateMutation()
  const [deleteProvision] = useDeleteProvisionMutation()
  const isMobileOrTablet = useBreakpointIndex() < 2
  const boundaryElement = useBoundaryElement(isMobileOrTablet ? 'provision-accordion' : 'parent')
  const { isLightMode } = useGetColorMode()

  const options: SimpleActionMenuItemProps[] = useMemo(
    () => [
      {
        ariaLabel: 'email code option',
        children: <IconButtonContent startIcon={EmailIcon}>Email Code</IconButtonContent>,
        onClick: () => {
          dispatch(setSelectProvisionPk(provisionPk))
          nav({ ...qs, provisionDialog: ProvisionDialogType.EMAIL })
        },
      },
      {
        ariaLabel: 'invalidate code option',
        children: (
          <IconButtonContent startIcon={InvalidateCodeIcon}>Invalidate Code</IconButtonContent>
        ),
        onClick: () => {
          putProvisionInvalidateCode({ pk: provisionPk })
        },
      },
      {
        ariaLabel: 'provisioning instructions option',
        children: (
          <IconButtonContent startIcon={InstructionIcon}>
            Provisioning Instructions
          </IconButtonContent>
        ),
        onClick: () => {
          dispatch(setSelectProvisionPk(provisionPk))
          nav({
            ...qs,
            provisionDialog: ProvisionDialogType.INSTRUCTIONS,
          })
        },
      },
      {
        ariaLabel: 'delete code option',
        isTopDivider: true,
        children: <IconButtonContent startIcon={DeleteIcon}>Delete Code</IconButtonContent>,
        onClick: () => {
          presentAlert(
            {
              message: `Deleting the provisioning code will prevent onboarding of more devices via RMM that use this code.
               Are you sure you want to do this?`,
              variant: 'primary',
              isSticky: true,
              shouldDismissOnClickOutside: true,
              icon: ErrorIcon,
              buttons: [
                {
                  onClick: () => {
                    deleteProvision({ pk: provisionPk })
                    dismissAlert(deleteAlertId)
                  },
                  text: 'Yes',
                },
                {
                  onClick: () => {
                    dismissAlert(deleteAlertId)
                  },
                  text: 'No',
                },
              ],
            },
            deleteAlertId,
          )
        },
      },
    ],
    [
      dispatch,
      provisionPk,
      nav,
      qs,
      putProvisionInvalidateCode,
      presentAlert,
      deleteProvision,
      dismissAlert,
    ],
  )

  const [isOpen, setIsOpen] = useState(false)
  const hideRef = useRef<() => void>()

  return (
    <Dropdown
      data-testid="provision-options-dropdown-button"
      ariaLabel="provision options dropdown button"
      variant="simple"
      sx={{ p: 0 }}
      tippyprops={{
        appendTo: boundaryElement,
        animation: 'fade',
        duration: 300,
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        onShow: () => {
          setIsOpen(true)
        },
        onHidden: () => {
          setIsOpen(false)
        },
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
      }}
      dropdowncontent={
        <Flex
          data-testid="provision-options-menu"
          sx={{
            width: ['100%', '24rem'],
            maxHeight: '26.4rem',
            borderRadius: '1.2rem',
            opacity: 1,
          }}
          className="show-scrollbar"
        >
          <SimpleActionMenu
            customHoverStyles={{
              background: 'white6',
            }}
            hideRef={hideRef}
            items={options}
            sxContainer={{ width: '100%', p: '0.4rem' }}
            sxButton={{
              maxHeight: 'auto',
              borderRadius: '0.8rem',
              m: 0,
              px: '0.8rem',
            }}
          />
        </Flex>
      }
    >
      <Svg
        svg={OptionsMenuIcon}
        fill={isOpen ? 'aliceBlue' : 'aliceBlue60'}
        sx={{
          width: '2.4rem',
          height: '2.4rem',
          '&:hover path': {
            fill: 'aliceBlue',
          },
        }}
      />
    </Dropdown>
  )
}
