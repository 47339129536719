import React, { ReactElement, useRef, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import CopyToClipboard from 'components/CopyToClipboard'
import { tokenApi } from 'store/api/token'
import ShowEyeIcon from 'images/ui/show-eye.svg'
import HiddenEyeIcon from 'images/ui/hidden-eye.svg'
import DeleteIcon from 'images/ui/delete-icon.svg'
import { Setter } from 'utils'
import useOnClickOutside from 'utils/useOnClickOutside'

const buttonStyle = {
  height: '3.2rem',
  px: '1.6rem',
  py: 0,
  backgroundColor: 'white4',
  span: {
    opacity: 0.6,
  },
  ':hover': {
    backgroundColor: 'white4',
    span: {
      opacity: 1,
    },
  },
}

const svgButtonStyle = {
  width: '2.4rem',
  height: '2.4rem',
  ':hover': {
    g: {
      opacity: 1,
    },
  },
}

interface TokenActionsProps {
  PK: string
  token: string
  showPassword: boolean
  setShowPassword: Setter<boolean>
}

export default function TokenActions({
  PK,
  token,
  showPassword,
  setShowPassword,
}: TokenActionsProps): ReactElement {
  const [isConfirmDeletionOpen, setIsConfirmDeletionOpen] = useState(false)
  const [removeToken, { status }] = tokenApi.useRemoveTokenMutation()

  const deletionConfirmationRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([deletionConfirmationRef], () => {
    setIsConfirmDeletionOpen(false)
  })

  return (
    <Flex
      sx={{
        width: '100%',
        height: isConfirmDeletionOpen ? '3.4rem' : 'auto',
        justifyContent: 'flex-end',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          gap: '1.2rem',
          width: ['100%', 'fit-content'],
          justifyContent: ['space-between', 'flex-end'],
          opacity: isConfirmDeletionOpen ? '0' : '1',
        }}
      >
        <Flex sx={{ gap: '1.2rem' }}>
          <Button
            variant="transparent"
            ariaLabel="show password"
            data-testid="show-hide-token"
            onClick={(): void => setShowPassword(!showPassword)}
            sx={{
              p: 0,
              flexShrink: 0,
              alignItems: 'center',
            }}
            tabIndex={isConfirmDeletionOpen ? -1 : 0}
          >
            <Svg
              svg={showPassword ? ShowEyeIcon : HiddenEyeIcon}
              fill="aliceBlue"
              sx={svgButtonStyle}
            />
          </Button>
          <CopyToClipboard
            data={token}
            ariaLabelText="copy to clipboard"
            smallIcon
            theme="orgs-homepage"
            fillSvg="aliceBlue60"
            sx={svgButtonStyle}
          />
        </Flex>
        <Button
          variant="transparent"
          ariaLabel="delete token"
          data-testid="delete-token"
          onClick={(): void => setIsConfirmDeletionOpen(!isConfirmDeletionOpen)}
          sx={{
            p: 0,
            flexShrink: 0,
            alignItems: 'center',
            order: [-1, 0],
          }}
          tabIndex={isConfirmDeletionOpen ? -1 : 0}
        >
          <Svg svg={DeleteIcon} sx={svgButtonStyle} fill="aliceBlue" />
        </Button>
      </Flex>

      <Flex
        ref={deletionConfirmationRef}
        sx={{
          justifyContent: 'flex-end',
          position: 'absolute',
          right: ['50%', 0],
          transform: ['translateX(50%)', 'none'],
          gap: '0.8rem',
          width: isConfirmDeletionOpen ? 'fit-content' : '0',
          overflow: 'hidden',
        }}
      >
        <ButtonWithLoadingState
          variant="roundedSimple"
          sx={{ ...buttonStyle, minWidth: '8rem', color: 'pomegranate' }}
          onClick={() => {
            removeToken(PK)
          }}
          sxSpinner={{
            width: '1.6rem',
            height: '1.6rem',
          }}
          isLoading={status === 'pending'}
          tabIndex={isConfirmDeletionOpen ? 0 : -1}
          data-testid="confirm-delete-rule-button"
          ariaLabel="confirm deletion"
        >
          <Text variant="size15Weight600">Delete</Text>
        </ButtonWithLoadingState>
        <Button
          variant="roundedSimple"
          sx={{ ...buttonStyle, color: 'aliceBlue' }}
          onClick={() => setIsConfirmDeletionOpen(false)}
          tabIndex={isConfirmDeletionOpen ? 0 : -1}
          data-testid="cancel-delete-rule-button"
          ariaLabel="cancel deletion"
        >
          <Text variant="size15Weight600">Cancel</Text>
        </Button>
      </Flex>
    </Flex>
  )
}
