import React, { ReactElement, useRef } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { modalDialogWidth } from 'ui/ModalDialog/ModalDialog.base'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import DashboardAlerts from 'components/Dashboard/DashboardAlerts'

export default function ShowAlertOnModalOrTrayWrapper({
  shouldShowAlertOnModal,
  children,
  sxAlertContainer,
}: {
  shouldShowAlertOnModal: boolean
  children: React.ReactNode
  sxAlertContainer?: ThemeUIStyleObject
}): ReactElement {
  const breakpoint = useBreakpointIndex()
  const alertContainerRef = useRef<HTMLElement>(null)
  return (
    <Flex
      data-testid="show-alert-on-modal-or-tray-wrapper"
      sx={{
        display: shouldShowAlertOnModal ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: `${document.documentElement.clientHeight}px`,
        px: ['1.6rem', 0],
        justifyContent: 'center',
        zIndex: 'zIndex450',
      }}
    >
      <Flex
        ref={alertContainerRef}
        sx={{
          width: [modalDialogWidth[breakpoint], `calc(${modalDialogWidth[breakpoint]} - 3.2rem)`],
          ...sxAlertContainer,
        }}
      >
        {children}
        {shouldShowAlertOnModal && (
          <DashboardAlerts resizeableAlertContainerRef={alertContainerRef} />
        )}
      </Flex>
    </Flex>
  )
}
