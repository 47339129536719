import React, { ReactElement } from 'react'
import useQueryString from 'utils/useQueryString'
import { LegacyResolverData } from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/LegacyResolver'
import { SecureResolverData } from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/SecureResolver'
import FreeResolverTabs from 'components/SetupGuide/ResolverTabs/FreeResolverTabs'
import UserResolverTabs from 'components/SetupGuide/ResolverTabs/UserResolverTabs'
import ProvisioningResolverTabs from 'components/SetupGuide/ResolverTabs/ProvisioningResolverTabs'

export enum LegacyResolver {
  PRIMARY,
  SECONDARY,
}

export interface ResolverTabData {
  legacy?: LegacyResolverData
  secure: SecureResolverData
}

export default function ResolverTabs(): ReactElement {
  const { qs } = useQueryString()
  if (!!qs.resolverUid) {
    return <ProvisioningResolverTabs />
  }

  if (!!qs.freeResolverType) {
    return <FreeResolverTabs />
  }

  return <UserResolverTabs />
}
