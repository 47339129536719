import React, { ReactElement, useEffect, useMemo, useRef } from 'react'
import { Profile } from 'store/api/profiles/profiles.interface'
import { SimpleActionMenuItemProps, Svg } from 'ui'
import { Flex } from '@theme-ui/components'
import { Text, ThemeUIStyleObject } from 'theme-ui'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import CheckIcon from 'images/analytics/check.svg'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'
import { Setter } from 'utils'
import { useGetProfilesQuery } from 'store/api/profiles'

export interface ProfileDropDownItem extends Profile {
  text: string
  isShared?: boolean
}

interface ProfileSelectionDropDownProps {
  label?: string
  selectedProfile?: ProfileDropDownItem
  setSelectedProfile:
    | Setter<ProfileDropDownItem | undefined>
    | ((selectedItem: ProfileDropDownItem) => void)
  disabledProfilePk?: string
  globalProfilePk?: string
  description?: string
  sx?: ThemeUIStyleObject
  tooltipText?: string
  testId?: string
  isDropdownDisabled?: boolean
  boundaryElementTestId?: string
  inheritedProfilePk?: string
}
export default function ProfileSelectionDropDown({
  label,
  selectedProfile,
  disabledProfilePk,
  globalProfilePk,
  setSelectedProfile,
  description,
  sx,
  tooltipText,
  testId,
  boundaryElementTestId = 'parent',
  inheritedProfilePk,
  isDropdownDisabled,
}: ProfileSelectionDropDownProps): ReactElement {
  const { data: profilesData } = useGetProfilesQuery('')
  const hideRef = useRef<() => void>()
  const firstProfile = useMemo(() => {
    const p = profilesData?.profiles[0]

    if (p) {
      return { ...p, text: p.name }
    }
    return undefined
  }, [profilesData])

  useEffect(() => {
    if (!selectedProfile && !!firstProfile && firstProfile.PK !== disabledProfilePk) {
      setSelectedProfile(firstProfile)
    }
  }, [firstProfile, selectedProfile, setSelectedProfile, disabledProfilePk])

  const profileData: ProfileDropDownItem[] = useMemo(
    () =>
      [
        ...(profilesData?.profiles || []).map(p => ({ ...p, text: `${p.name}` })),
        ...(profilesData?.shared_profiles?.map(p => ({
          ...p,
          text: `${p.name}`,
          isShared: true,
        })) || []),
      ] ?? [],
    [profilesData],
  )

  const options: SimpleActionMenuItemProps[] = useMemo(
    () =>
      profileData.map(profile => ({
        dataTestId: `testid-${profile.text}`,
        ariaLabel: `${profile?.text} option`,
        isDisabled:
          profile.PK === disabledProfilePk || (profile.PK === globalProfilePk && profile.isShared),
        isSelected: profile.PK === selectedProfile?.PK,
        isBackgroundHighlighted: true,
        buttonContent: (
          <TextWithOverFlowAndTippyPopup
            content={profile?.text ?? ''}
            ariaLabel={profile?.text ?? ''}
            sxText={{
              maxWidth: '44rem',
            }}
          />
        ),
        children: (
          <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                justifyContent: 'space-between',
                flex: 1,
                alignItems: ['flex-start', 'center'],
                mr: profile.PK === selectedProfile?.PK && (profile.isShared ? '0.8rem' : '1.6rem'),
              }}
            >
              <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
                <TextWithOverFlowAndTippyPopup
                  variant="size15Weight600"
                  content={profile?.text ?? ''}
                  ariaLabel={profile?.text ?? ''}
                  sxText={{
                    maxWidth: '32rem',
                  }}
                />

                {profile.isShared && (
                  <Text
                    variant="size10Weight700"
                    className="shared-profile-badge"
                    sx={{
                      color: 'commonBlack',
                      backgroundColor: 'commonCyan800',
                      px: '0.4rem',
                      borderRadius: '10rem',
                      flexShrink: 0,
                      height: '1.6rem',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    {profile.PK === globalProfilePk ? 'Global' : 'Shared'}
                  </Text>
                )}

                {inheritedProfilePk === profile.PK.toString() && (
                  <Text
                    variant="size12Weight500"
                    className="inherited-profile-badge"
                    sx={{
                      color: 'aliceBlue60',
                      backgroundColor: 'white6',
                      px: '0.8rem',
                      borderRadius: '10rem',
                      flexShrink: 0,
                    }}
                  >
                    Inherited
                  </Text>
                )}
              </Flex>
            </Flex>
            {profile.PK === selectedProfile?.PK && (
              <Svg svg={CheckIcon} sx={{ flexShrink: 0, fill: 'aliceBlue', opacity: 0.5 }} />
            )}
          </Flex>
        ),
        onClick: () => {
          setSelectedProfile(profile)
          hideRef.current?.()
        },
      })),
    [
      profileData,
      disabledProfilePk,
      globalProfilePk,
      selectedProfile?.PK,
      inheritedProfilePk,
      setSelectedProfile,
    ],
  )

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        pt: '1.6rem',
        '& > button': {
          px: '2.4rem',
        },
        ...sx,
      }}
      data-testid={testId ?? 'select-profile-menu'}
    >
      <SimpleDropdown
        isSearchVisible
        testId="select-profile-dropdown-desktop"
        ariaLabel="select profile dropdown"
        disabled={
          isDropdownDisabled ||
          (!profilesData?.profiles.length && !profilesData?.shared_profiles?.length)
        }
        items={options}
        hideRef={hideRef}
        label={label ?? 'Linked Profile'}
        tooltipText={tooltipText}
        sx={{ mb: description ? '0.8rem' : 0 }}
        sxDropdown={{
          ':disabled': {
            opacity: 0.5,
          },
        }}
        sxTooltipContainer={{
          maxWidth: '14rem',
        }}
        defaultValue="Select Profile"
        mobileContentWidth="100%"
        boundaryElementTestId={boundaryElementTestId}
        sxDropdownContent={{
          maxHeight: '21rem',
        }}
      />
      <Text sx={{ pl: '1.6rem', fontSize: '1.2rem', color: 'aliceBlue60' }}>
        {description ?? 'The Profile whose rules you want to enforce on this Endpoint'}
      </Text>
    </Flex>
  )
}
