import React, { ReactElement } from 'react'
import sortBy from 'lodash/sortBy'
import { Currency } from 'store/api/billing'
import OrganizationInvoiceElement from 'components/Organization/OrganizationBilling/OrganizationInvoiceElement'
import { OrganizationInvoice } from 'store/api/billing/payments.interface'
import NoResults from 'components/Dashboard/NoResults'
import { Table, TableBody, TableHeader } from 'ui/Table'
import { Flex } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

const tableHeaders = [
  'Date',
  'Transition ID',
  'Payment Method',
  'Amount',
  'Status',
  '', // header for open receipt button icon column
]

interface OrganizationInvoiceTableProps {
  paymentDataArray: Array<OrganizationInvoice> | undefined
  currencies: Array<Currency>
}

export default function OrganizationInvoicesTable({
  paymentDataArray,
  currencies,
}: OrganizationInvoiceTableProps): ReactElement {
  const sortedData = sortBy(paymentDataArray, ['ts']).reverse()
  const isMobileOrTablet = useBreakpointIndex() < 2

  return (
    <Flex
      sx={{
        color: 'aliceBlue',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: ['transparent', 'blueYonder15'],
        borderRadius: [0, '1.2rem'],
      }}
    >
      {!sortedData?.length ? (
        <NoResults message="No Invoices!" />
      ) : isMobileOrTablet ? (
        sortedData?.map(invoice => (
          <OrganizationInvoiceElement
            key={`${invoice.tx_id}-mobile`}
            invoice={invoice}
            currencies={currencies}
            tableHeaders={tableHeaders}
          />
        ))
      ) : (
        <Table>
          <TableHeader
            names={tableHeaders}
            sx={{
              ':nth-of-type(3)': { pl: '1.2rem' },
              ':last-of-type': { flex: '0 1 2.4rem' },
            }}
          />
          <TableBody>
            {sortedData?.map(invoice => (
              <OrganizationInvoiceElement
                key={`${invoice.tx_id}-desktop`}
                invoice={invoice}
                currencies={currencies}
                tableHeaders={tableHeaders}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </Flex>
  )
}
