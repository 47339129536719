import { useAppSelector } from 'store/hooks'
import { useGetOrganizationQuery } from 'store/api/organization'
import { Organization } from 'store/api/organization/organization.interface'
import useGetUserState from 'store/api/user/useGetUserState'

// it helps to get rid of bugs associated with an unexpected useGetOrganizationQuery call
// useGetOrganizationQuery should always depend on sessionToken and isOrganization
export default function useGetOrganization(options?: {
  skip?: boolean
  refetchOnMountOrArgChange?: boolean
}): {
  data:
    | {
        organization: Organization
      }
    | undefined
  isLoading: boolean
  isFetching: boolean
  isError: boolean
} {
  const { isOrganization } = useGetUserState()
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data, isLoading, isFetching, isError } = useGetOrganizationQuery('', {
    skip: options ? options?.skip : !sessionToken || !isOrganization,
    refetchOnMountOrArgChange: options?.refetchOnMountOrArgChange,
  })

  return {
    data,
    isLoading,
    isFetching,
    isError,
  }
}
