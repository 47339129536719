import React, { useMemo, ReactElement } from 'react'
import { FREE_DNS_DOMAIN } from 'gatsby-env-variables'
import { useAppSelector } from 'store/hooks'
import {
  configDownloadPathsByResolverType,
  DnsProtocol,
  dnsServersByProtocol,
} from 'constants/dnsServers'
import useResolverTabHighlight from 'components/SetupGuide/ResolverTabs/Components/useResolverTabHighlight'
import ResolverTabContent from 'components/SetupGuide/ResolverTabs/Components/FreeDnsResolver/ResolverTabContent'
import { ResolverTabData } from 'components/SetupGuide/ResolverTabs/ResolverTabs'
import { ResolverKey } from 'components/FreeDNSPage/constants/freeDNS'
import useQueryString from 'utils/useQueryString'
import { useGetFreeDNSFiltersQuery } from 'store/api/freeDns'

export default function FreeResolverTabs(): ReactElement {
  const selectedFiltersPK = useAppSelector(s => s.freeDNS.selectedFiltersPKs)
  const selected3rdPartyFilterPK = useAppSelector(s => s.freeDNS.selected3rdPartyFilterPK)
  const { data: freeDnsData } = useGetFreeDNSFiltersQuery('')
  const { qs } = useQueryString()
  const { highlight } = useResolverTabHighlight(qs.setupOs)
  const freeResolverType = qs.freeResolverType as ResolverKey

  const resolverTabData: ResolverTabData = useMemo(() => {
    if (selected3rdPartyFilterPK) {
      const filterResolvers = freeDnsData?.external_filters.find(
        filter => filter.PK === selected3rdPartyFilterPK,
      )?.resolvers

      return {
        legacy: {
          ipv4Primary: filterResolvers?.v4[0],
          ipv4Secondary: filterResolvers?.v4[1],
          ipv6Primary: filterResolvers?.v6[0],
          ipv6Secondary: filterResolvers?.v6[1],
        },
        secure: {
          resolverId: selected3rdPartyFilterPK,
          doh: `https://${FREE_DNS_DOMAIN}/${selected3rdPartyFilterPK}`,
          dot: `${selected3rdPartyFilterPK}.${FREE_DNS_DOMAIN}`,
        },
      }
    }

    if (!!selectedFiltersPK?.length) {
      const filterPK = selectedFiltersPK?.join('-')
      // custom
      return {
        secure: {
          resolverId: `no-${filterPK}`,
          doh: `https://${FREE_DNS_DOMAIN}/no-${filterPK}`,
          dot: `no-${filterPK}.${FREE_DNS_DOMAIN}`,
        },
      }
    } else {
      // pre configured
      return {
        legacy: {
          ipv4Primary: dnsServersByProtocol[DnsProtocol.LEGACY_DNS_V4][freeResolverType],
          ipv4Secondary: dnsServersByProtocol[DnsProtocol.LEGACY_DNS_V4_ALT][freeResolverType],
          ipv6Primary: dnsServersByProtocol[DnsProtocol.LEGACY_DNS_V6][freeResolverType],
          ipv6Secondary: dnsServersByProtocol[DnsProtocol.LEGACY_DNS_V6_ALT][freeResolverType],
        },
        secure: {
          resolverId: configDownloadPathsByResolverType[freeResolverType],
          doh: dnsServersByProtocol[DnsProtocol.DNS_OVER_HTTPS][freeResolverType],
          dot: dnsServersByProtocol[DnsProtocol.DNS_OVER_TLS][freeResolverType],
        },
      }
    }
  }, [freeResolverType, selectedFiltersPK, selected3rdPartyFilterPK, freeDnsData?.external_filters])

  return (
    <ResolverTabContent
      {...{
        resolverTabData,
        highlight,
      }}
    />
  )
}
