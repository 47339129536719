import React from 'react'
import { PrivateRouteProps } from 'components/PrivateRoute'
import { useAppSelector } from 'store/hooks'
import useRequireAuthentication from 'utils/useRequireAuthentication'

const PrivateRoute = ({
  component: Component,
  children,
  ...props
}: PrivateRouteProps): React.ReactElement | null => {
  const isLoggedIn = useAppSelector(s => !!s.persistData.sessionToken)
  const isInMaintenanceMode = useAppSelector(s => s.errors.isInMaintenanceMode)

  useRequireAuthentication()

  if (isLoggedIn || isInMaintenanceMode) {
    return <Component {...props}>{children}</Component>
  } else {
    return null
  }
}

export default PrivateRoute
