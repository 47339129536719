import { memo, FunctionComponent, Dispatch, NamedExoticComponent, SetStateAction } from 'react'
import omit from 'lodash/omit'
import { shallowEqualObjects } from 'shallow-equal'

const memoOmittingProps = <T extends object>(
  Component: FunctionComponent<T>,
  propsToOmit: (keyof T)[],
): NamedExoticComponent<T> => {
  const isEqualOmittingKeys = (p1: T, p2: T) =>
    shallowEqualObjects(omit(p1, propsToOmit), omit(p2, propsToOmit))

  return memo(Component, isEqualOmittingKeys)
}

const vibrate = (duration: number): void => {
  if (window?.navigator?.vibrate) {
    try {
      window.navigator.vibrate(duration)
    } catch (e) {}
  }
}

export type Setter<T> = Dispatch<SetStateAction<T>>

export function kebabCaseIdFromString(s = ''): string {
  return s
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .replace(/^\s+/, '')
    .replace(/\s+$/, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export function roundToDecimalPlaces(n: number, numDecimalPlaces: number): number {
  const powerOf10 = Math.pow(10, numDecimalPlaces)
  return Math.round((n + Number.EPSILON) * powerOf10) / powerOf10
}

// Partial<T> for nested types https://grrr.tech/posts/2021/typescript-partial/
export type Subset<K> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr]
}
export { memoOmittingProps, vibrate }

export const defaultFuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 200,
  minMatchCharLength: 1,
  includeMatches: true,
  useExtendedSearch: true,
}
