import React, { FormEvent, useState } from 'react'
import { Setter } from 'utils'
import { TwoFactorStep } from 'store/twofa'
import { useAppSelector } from 'store/hooks'
import { Flex, Image, Text } from 'theme-ui'
import { LoadingState } from 'store/fetchingLogic'
import ContinueButton from '../ContinueButton'
import { Checkbox } from 'ui'

export default function ScanOrEnterCodeStep({
  setStep,
}: {
  setStep: Setter<TwoFactorStep>
}): React.ReactElement {
  const twofaData = useAppSelector(s => s.twofa)
  const twofa = useAppSelector(s => s.twofa.data?.twofa)
  const [isChecked, setIsChecked] = useState(false)

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault()
    setStep(TwoFactorStep.ENABLE_TWOFA)
  }

  return (
    <Flex
      as="form"
      onSubmit={onSubmit}
      sx={{
        width: '100%',
        gap: '1.6rem',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: ['column', 'column', 'row'],
      }}
    >
      <Image
        sx={{
          width: '11.9rem',
          height: '11.9rem',
          flexShrink: 0,
        }}
        src={`data:image/jpeg;base64,${twofa?.qr}`}
      />
      <Flex
        sx={{
          gap: '1.2rem',
          flexDirection: 'column',
          textAlign: 'left',
        }}
      >
        <Flex sx={{ gap: '1.2rem', flexDirection: 'column' }}>
          <Text variant="size15Weight700" sx={{ color: 'aliceBlue' }}>
            Scan The QR Code
          </Text>
          <Text variant="size15Weight400" sx={{ color: 'aliceBlue60' }}>
            Use an authentication app (Google Authenticator or Authy) to scan the QR code using your
            phone’s camera.
          </Text>
        </Flex>
        <Flex sx={{ gap: '1.2rem', flexDirection: 'column' }}>
          <Text variant="size15Weight700" sx={{ color: 'aliceBlue' }}>
            Secret Key
          </Text>
          <Text variant="size15Weight400" sx={{ color: 'aliceBlue60', overflowWrap: 'break-word' }}>
            {twofa?.secret}
          </Text>
        </Flex>
        <Checkbox
          text="I backed up the Secret Key"
          isChecked={isChecked}
          onChange={newValue => setIsChecked(newValue)}
        />
        <ContinueButton
          isButtonDisabled={!isChecked}
          isRequestInFlight={twofaData.loading === LoadingState.PENDING}
          ariaLabel="scan or enter button"
          dataTestId="scan-or-enter-button"
          onClick={onSubmit}
        />
      </Flex>
    </Flex>
  )
}
