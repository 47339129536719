import React, { useRef, useState, ReactElement, useMemo, MutableRefObject, useEffect } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Dropdown, InputSearch, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import useBoundaryElement from 'utils/useBoundaryElement'
import DropdownIcon from 'images/profileManagement/context-menu-arrow-down.svg'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import Fuse from 'fuse.js'
import { defaultFuseOptions } from 'utils'
import useMobileInputFocus from 'utils/useMobileInputFocus'
import NoResults from 'components/Dashboard/NoResults'
import { useHotkeys } from 'react-hotkeys-hook'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { setIsSearchDropdownVisible } from 'store/dashboard/dashboard'
import { useAppDispatch } from 'store/hooks'
import useGetColorMode from 'utils/useGetColorMode'

const dropdownWithoutDeviceSelectionStyles = {
  ':hover': {
    'svg path': {
      fill: 'white50',
    },
  },
  ':disabled': {
    backgroundColor: 'transparent',
    opacity: 1,
  },
}

interface SearchDropdownProps {
  ariaLabel: string
  options: SimpleActionMenuItemProps[]
  testId?: string
  dropdownButtonText?: string
  boundaryElementTestId?: string
  searchPlaceholder?: string
  isDisabled?: boolean
  isSelectable?: boolean
  hideRef: MutableRefObject<(() => void) | undefined>
  maxWidth?: string
  shouldShowSmallView?: boolean
  sxButtonContent?: ThemeUIStyleObject
  sx?: ThemeUIStyleObject
  shouldHighlightSelectedEndpoint?: boolean
}

export default function DeviceSearchDropdown({
  options,
  ariaLabel,
  testId,
  dropdownButtonText,
  boundaryElementTestId = '',
  isDisabled = false,
  isSelectable,
  searchPlaceholder,
  hideRef,
  maxWidth,
  shouldShowSmallView,
  sxButtonContent,
  sx,
  shouldHighlightSelectedEndpoint,
}: SearchDropdownProps): ReactElement | null {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const boundaryElement = useBoundaryElement(boundaryElementTestId)
  const [searchText, setSearchText] = useState('')
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const dropdownContentRef = useRef<HTMLDivElement | null>(null)
  const [hoverIndex, setHoverIndex] = useState(0)
  const isMobile = useBreakpointIndex() === 0
  const isDesktop = useBreakpointIndex() === 2
  const hasInputFocus = hoverIndex === -1
  const dropdownButtonRef = useRef<HTMLButtonElement>(null)
  const { isLightMode } = useGetColorMode()

  useMobileInputFocus(searchInputRef, isDesktop ? isOpen : false)

  // should make the search dropdown visible when keyboard is activated on mobile
  useEffect(() => {
    let timerId
    const bottomSpace = dropdownContentRef.current?.getBoundingClientRect().bottom || 0

    if (isMobile && maxWidth && isOpen && document.documentElement.clientHeight < bottomSpace) {
      timerId = setTimeout(() => {
        dropdownContentRef.current?.scrollIntoView({ block: 'end' })
      }, 500)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [hasInputFocus, isOpen, maxWidth, isMobile])

  const searchOptions = useMemo((): SimpleActionMenuItemProps[] => {
    const fuzzySearchList = new Fuse(options, {
      ...defaultFuseOptions,
      keys: ['children.props.text'],
    })
    if (!searchText) {
      return options
    }
    return fuzzySearchList.search(searchText).map(matches => matches.item)
  }, [options, searchText])

  const onKeyDown = event => {
    if (event.code === 'Enter' && isSelectable && searchOptions.length === 1) {
      searchOptions[0]?.onClick?.(event)
      hideRef.current?.()
    }

    if (event.code === 'Escape') {
      setSearchText('')
      hideRef.current?.()
    }
  }

  useHotkeys(
    'up',
    () => {
      if (hoverIndex) {
        setHoverIndex(hoverIndex - 1)
      } else {
        searchInputRef.current?.focus()
      }
    },
    { ignoreEventWhen: () => !isOpen },
    [hoverIndex, searchText, isOpen],
  )

  useHotkeys(
    ['down', 'Tab'],
    e => {
      if (hoverIndex < 1 && e.key !== 'Tab') {
        e.preventDefault()
      }
      if (hoverIndex !== searchOptions.length - 1) {
        setHoverIndex(hoverIndex + 1)
      }
    },
    { ignoreEventWhen: () => !isOpen },
    [hoverIndex, searchText, isOpen],
  )

  useHotkeys(
    'Escape',
    e => {
      e.preventDefault()
      hideRef.current?.()
    },
    [hoverIndex],
  )

  return (
    <Dropdown
      buttonRef={dropdownButtonRef}
      disabled={isDisabled}
      data-testid={testId}
      ariaLabel={ariaLabel}
      variant="simple"
      sx={{
        p: 0,
        justifyContent: 'flex-start',
        color: 'aliceBlue',
        ':disabled': { backgroundColor: 'transparent' },
        ...sx,
      }}
      tabIndex={0}
      tippyprops={{
        appendTo: boundaryElementTestId ? boundaryElement : 'parent',
        animation: 'fade',
        onHide: () => {
          setSearchText('')
          setHoverIndex(0)
          setIsOpen(false)
          dispatch(setIsSearchDropdownVisible(false))
        },
        onShow: () => {
          setIsOpen(true)
          dispatch(setIsSearchDropdownVisible(true))
        },
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        offset: [0, 8],
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
        placement: 'bottom-start',
        maxWidth: maxWidth || '100%',
        zIndex: 300,
      }}
      dropdowncontent={
        <Flex
          ref={dropdownContentRef}
          sx={{
            position: 'relative',
            width: ['100%', '28rem'],
            maxHeight: ['25rem', '33rem'],
            overflow: 'hidden',
            flexDirection: 'column',
            borderRadius: '1.6rem',
          }}
        >
          <Flex
            sx={{
              position: 'sticky',
              top: 0,
              width: '100%',
              flexDirection: 'column',
              zIndex: 'zIndex50',
            }}
          >
            <InputSearch
              isCompact
              variant="newPrimary"
              data-testid="devices-search"
              ref={searchInputRef}
              containerStyle={{
                alignItems: 'center',
                p: '0.4rem',
              }}
              svgContainerStyle={{
                left: '1.2rem',
              }}
              placeholder={searchPlaceholder}
              value={searchText}
              clearInput={() => {
                setSearchText('')
                searchInputRef.current?.focus()
              }}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={onKeyDown}
              tabIndex={0}
              hasFocus={hasInputFocus}
              onFocus={() => setHoverIndex(-1)}
            />
          </Flex>
          {searchOptions.length ? (
            <SimpleActionMenu
              hideRef={hideRef}
              items={searchOptions}
              sxContainer={{ p: '0.4rem', gap: '0.1rem', overflowY: 'auto' }}
              customHoverIndex={isMobile ? undefined : hoverIndex || undefined}
              className="hide-scrollbar"
              sxButton={{
                maxHeight: 'auto',
                borderRadius: '0.8rem',
                m: 0,
                px: '0.8rem',
                py: '0.4rem',
                minHeight: '3.8rem',
                ...(isSelectable ? {} : dropdownWithoutDeviceSelectionStyles),
                ':not(:has(div.not-configured))': {
                  opacity: 1,
                },
                borderColor: 'transparent',
                '&:hover': {
                  ':not([disabled])': {
                    ':not(:has(div.not-configured))': {
                      '.device-graph': { visibility: 'visible' },
                      '.clients-count': { opacity: 1 },
                      backgroundColor: 'white6',
                      opacity: 1,
                      svg: {
                        opacity: 1,
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <NoResults
              hideIcon={true}
              message="No Results."
              sx={{
                my: ['1.6rem', '2.4rem'],
                span: {
                  fontSize: '1.5rem',
                },
              }}
            />
          )}
        </Flex>
      }
    >
      <Flex
        sx={{
          width: '100%',
          flexShrink: 0,
          alignItems: 'center',
          svg: {
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
            g: {
              opacity: 1,
            },
          },
          '&:hover': {
            ':not([disabled])': {
              opacity: 1,
            },
          },
          mr: [0, '2.4rem'],
          ml: 0,
          ...sxButtonContent,
        }}
      >
        <TextWithOverFlowAndTippyPopup
          shouldResetParentSettings
          content={dropdownButtonText ?? ''}
          ariaLabel={dropdownButtonText ?? ''}
          variant={shouldShowSmallView || isMobile ? 'size12Weight400' : 'size17Weight600Line138'}
          sxText={{
            maxWidth: ['15rem', '15rem', '15rem'],
            wordWrap: 'break-word',
            textAlign: 'left',
            color: isOpen || shouldHighlightSelectedEndpoint ? 'aliceBlue' : 'aliceBlue60Black60',
          }}
          maxWidth="fit-content"
        />
        <Svg
          fill={isOpen ? 'aliceBlue' : 'aliceBlue60Black60'}
          svg={DropdownIcon}
          sx={{
            width: shouldShowSmallView ? '1.2rem' : '1.6rem',
            height: shouldShowSmallView ? '1.2rem' : '1.6rem',
            ml: '0.4rem',
            flexShrink: 0,
          }}
        />
      </Flex>
    </Dropdown>
  )
}
