import { CustomRuleData, useGetAllRulesQuery } from 'store/api/rules'
import { useLocation } from '@reach/router'
import { AnalyticsNavRelativePath } from 'components/Dashboard/NavBar/AnalyticsNavMenu'
import { useAppSelector } from 'store/hooks'
import { useGetProfilesQuery } from 'store/api/profiles'

export function useGetExistingDraftRule(): {
  existingDraftRule: CustomRuleData | undefined
  areRulesLoading: boolean
  isUninitialized: boolean
  isAnalytics: boolean
  isStatistics: boolean
} {
  const location = useLocation()
  const isAnalytics = [
    AnalyticsNavRelativePath.STATISTICS,
    AnalyticsNavRelativePath.ACTIVITY_LOG,
  ].some(path => location.pathname.includes(path))
  const isStatistics = location.pathname.endsWith(AnalyticsNavRelativePath.STATISTICS)
  const draftCustomRule = useAppSelector(s => s.ruleTray.draftCustomRule)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: profilesData } = useGetProfilesQuery('', { skip: !sessionToken })

  const isSharedProfile = !!profilesData?.shared_profiles?.find(
    profile => profile.PK === draftCustomRule.profileId,
  )

  const {
    data: allRulesData,
    isLoading,
    isUninitialized,
  } = useGetAllRulesQuery(
    {
      profileId: draftCustomRule?.profileId?.toString() ?? '',
      ignoreImpersonation: isSharedProfile,
    },
    {
      skip: !draftCustomRule?.profileId?.toString() || !isAnalytics,
      refetchOnMountOrArgChange: true,
    },
  )

  return {
    existingDraftRule: allRulesData?.rules?.find(rule => rule.PK === draftCustomRule.PK),
    areRulesLoading: isLoading,
    isAnalytics,
    isUninitialized,
    isStatistics,
  }
}
