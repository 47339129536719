import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { initialResponseState } from 'store/fetchingLogic'
import moment from 'moment'

interface Consumption {
  code: string
  expTs?: number
}

export interface ProvisioningState {
  consumption: Consumption
}

const initialState: ProvisioningState = {
  ...initialResponseState,
  consumption: {
    code: '',
  },
}

// slice can be removed when implementing data persistence using custom hook with rtk query
export const provisionSlice = createSlice<ProvisioningState, SliceCaseReducers<ProvisioningState>>({
  name: 'provisioning',
  initialState,
  reducers: {
    clearProvisioningData(state): ProvisioningState {
      return { ...initialResponseState, consumption: state.consumption }
    },
    setCode(state, action: PayloadAction<string>): void {
      const code = action.payload
      const expTs = action.payload ? moment().add(1, 'day').valueOf() : 0

      state.consumption = { code, expTs }
    },
  },
})

export const { clearProvisioningData, setCode, setProvisioningState } = provisionSlice.actions
