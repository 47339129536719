import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import MoreFiltersIcon from 'images/freeDNS/more-filters.svg'
import CustomRulesIcon from 'images/freeDNS/custom-rules.svg'
import DefaultLocationIcon from 'images/freeDNS/default-location.svg'
import ContentControlIcon from 'images/freeDNS/content-control.svg'
import { Button, Svg } from 'ui'
import { navigate } from 'gatsby'
import { useAppSelector } from 'store/hooks'
import ArrowRightIcon from 'images/organization/homepage/sections/industries/arrow-right.svg'
import { plansFullControlPath } from 'utils/useCreateOrManageAccountUri'
import { numberOfServices } from 'components/Plans/SelectPlan/SelectPlan'

export const freeDnsPagePath = 'free-dns'

const contentData = [
  {
    key: 'more-filters',
    icon: MoreFiltersIcon,
    title: 'More Filters ',
    text: 'Access to more Filters with the ability to change them without updating your DNS configuration.',
  },
  {
    key: 'custom-rules',
    icon: CustomRulesIcon,
    title: 'Custom Rules ',
    text: 'Customize your profile by blocking, bypassing or redirecting any domain name of your choice.',
  },
  {
    key: 'default-location',
    icon: DefaultLocationIcon,
    title: 'Default Location ',
    text: 'Redirect all activity through a proxy location of your choice and appear to be in a different country.',
  },
  {
    key: 'content-control',
    icon: ContentControlIcon,
    title: 'Content Control ',
    text: `Block or unblock over ${numberOfServices} different services like iPlayer, Facebook, Minecraft and a whole lot more.`,
  },
]

export default function NeedMoreControl(): ReactElement {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)

  return (
    <Flex
      sx={{
        width: '100%',
        maxWidth: '99.9rem',
        mt: ['4.8rem', '8rem', '6rem'],
        flexDirection: 'column',
        textAlign: 'center',
        px: ['1.2rem', '1.6rem', 0],
        mb: '6.4rem',
        zIndex: 'zIndex5',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          mb: ['2.4rem', '4.8rem'],
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: ['column', 'row'],
            textAlign: ['center', 'left'],
            alignItems: ['center', 'flex-start'],
            justifyContent: 'space-between',
            gap: '2.4rem',
          }}
        >
          <Text
            variant="size40Weight600"
            sx={{
              color: 'aliceBlue',
            }}
            tabIndex={0}
            as="h2"
          >
            Need More Control?
          </Text>
          <Button
            variant="gradientButton"
            sx={{
              width: 'fit-content',
              borderRadius: '10rem',
              border: 'none',
              py: '1.2rem',
              px: '2rem',
              color: 'white',
              alignItems: 'center',
            }}
            onClick={() =>
              navigate(sessionToken ? '/plans' : plansFullControlPath, {
                state: { prevPage: freeDnsPagePath },
              })
            }
            ariaLabel={sessionToken ? 'Upgrade to premium' : 'Sign Up - Free Trial'}
          >
            <Text variant="size17Weight600Line138" sx={{ mr: '0.8rem' }}>
              {sessionToken ? 'Upgrade' : 'Sign Up - Free Trial'}
            </Text>
            <Svg svg={ArrowRightIcon} fill="white" />
          </Button>
        </Flex>
      </Flex>
      <Flex
        sx={{
          justifyContent: 'space-between',
          gap: ['1.6rem', '1.6rem', '3.2rem'],
          flexDirection: ['column', 'row'],
          flexWrap: ['wrap', 'wrap', 'nowrap'],
        }}
      >
        {contentData.map(item => (
          <Flex
            key={item.key}
            sx={{
              flex: 1,
              flexDirection: 'column',
              border: '1px solid',
              borderColor: 'blueYonder50',
              borderRadius: '1.2rem',
              p: ['2.4rem', '1.6rem', '2.4rem'],
              textAlign: 'left',
              gap: '1.6rem',
              '@media (min-width: 768px) and (max-width: 900px)': {
                px: '1rem',
              },
            }}
          >
            <Svg
              svg={item.icon}
              sx={{
                width: '3.2rem',
                height: '3.2rem',
                mr: ['2.4rem', 0],
              }}
            />
            <Flex
              sx={{
                flexDirection: 'column',
                gap: '1.6rem',
              }}
            >
              <Text
                variant="size21Weight600Line138"
                as="h2"
                sx={{
                  '@media (min-width: 768px) and (max-width: 900px)': {
                    fontSize: '1.8rem',
                  },
                  color: 'aliceBlue',
                }}
                tabIndex={0}
              >
                {item.title}
              </Text>
              <Text
                variant="size16Weight400"
                sx={{
                  color: 'lightSteelBlue',
                }}
                tabIndex={0}
              >
                {item.text}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
