import { Button, ButtonProps } from 'ui/Buttons'
import React, { MutableRefObject, ReactElement, ReactNode, useMemo, useRef } from 'react'
import { Divider, ThemeUIStyleObject } from 'theme-ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import omit from 'lodash/omit'

export interface SimpleActionMenuItemProps extends ButtonProps {
  dataTestId?: string
  buttonContent?: string | ReactNode
  isBackgroundHighlighted?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  customOpacity?: number
  isTopDivider?: boolean
  ref?: MutableRefObject<HTMLButtonElement | null>
  tabIndex?: number
  isKeyboardHover?: boolean
  highlightedBackgroundColor?: string
  sxDividerStyles?: ThemeUIStyleObject
  shouldHighlightOnHover?: boolean
  customHoverStyles?: ThemeUIStyleObject // pass custom hover styles in this prop to get the same hover styles using the keyboard or mouse
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
}
export function SimpleActionMenuItem({
  children,
  dataTestId,
  isBackgroundHighlighted,
  highlightedBackgroundColor,
  isDisabled,
  isSelected,
  customOpacity,
  isTopDivider,
  ref,
  sx,
  tabIndex,
  isKeyboardHover,
  customHoverStyles,
  sxDividerStyles,
  ...buttonProps
}: SimpleActionMenuItemProps): ReactElement | null {
  const refButton = useRef<HTMLButtonElement | null>(null)

  if (isKeyboardHover) {
    refButton.current?.focus()
  }

  const keyboardHoverStyles = useMemo(
    () =>
      isKeyboardHover
        ? customHoverStyles || {
            color: 'aliceBlue',
            backgroundColor: 'white10',
            svg: {
              opacity: !isDisabled && 1,
            },
            a: {
              color: 'aliceBlue',
            },
            '.configure-text': {
              color: 'pomegranate',
              opacity: 1,
            },
          }
        : {},
    [isKeyboardHover, customHoverStyles, isDisabled],
  )

  return children ? (
    <>
      {isTopDivider && (
        <Divider
          key={`${buttonProps.key || 'menu-item'}-divider`}
          sx={{
            backgroundColor: 'blueYonder30',
            border: 'none',
            height: '1px',
            my: '0.4rem',
            ...sxDividerStyles,
          }}
        />
      )}
      <Button
        ref={ref || refButton}
        data-testid={dataTestId}
        disabled={isDisabled}
        variant="transparent"
        sx={{
          borderRadius: '0.8rem',
          color: 'aliceBlue',
          opacity: customOpacity || (isSelected && !isDisabled) ? 1 : 0.6,
          border: '1px solid',
          borderColor: isSelected ? 'littleBoyBlue' : 'transparent',
          fontSize: '1.4rem',
          fontWeight: 500,
          justifyContent: 'flex-start',
          flexShrink: 0,
          maxHeight: 'fit-content',
          mx: '0.4rem',
          px: '0.8rem',
          py: '0.4rem',
          a: {
            color: 'aliceBlue',
          },
          ...keyboardHoverStyles,
          '&:hover': {
            ':not([disabled])': {
              backgroundColor:
                !isSelected && (isBackgroundHighlighted || isKeyboardHover)
                  ? highlightedBackgroundColor
                    ? highlightedBackgroundColor || 'littleBoyBlue5'
                    : 'littleBoyBlue6'
                  : 'transparent',
              color: 'aliceBlue',
              opacity: 1,
              svg: {
                opacity: 1,
              },
              '.configure-text': {
                color: 'pomegranate',
                opacity: 1,
              },
              ...customHoverStyles,
            },
          },
          ':disabled': {
            backgroundColor: 'transparent',
            opacity: 0.5,
            '& svg,path': {
              fill: 'aliceBlue',
            },
          },
          ...sx,
        }}
        tabIndex={tabIndex}
        onKeyDown={stopPropagationOnKeyPress}
        {...omit(
          buttonProps,
          'buttonContent',
          'shouldHighlightOnHover',
          'isBackgroundHighlightedOnHover',
        )}
      >
        {children}
      </Button>
    </>
  ) : null
}
