import { useCallback, useEffect } from 'react'

export const useForClosingTray = ({ closeTray }: { closeTray: () => void }): void => {
  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        closeTray()
      }
    },
    [closeTray],
  )

  // useHotkeys hook doesn't work when opened tray contains a focused input (for example: Redirect Proxy tray for Services)
  // this solution works correctly for all cases
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])
}
