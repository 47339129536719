import { useEffect, useState } from 'react'

export default function useIsElementVisibleInDOM(element?: HTMLDivElement | null): boolean {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    if (element) {
      observer.observe(element)
    }
    return () => {
      observer.disconnect()
    }
  }, [element])

  return isIntersecting
}
