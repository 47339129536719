const inputSharedStyles = {
  border: 'hidden',
  p: 0,
  height: '4.4rem',
  borderRadius: 0,
  color: 'aliceBlue',
}

const inputSecondaryStyles = {
  ...inputSharedStyles,
  bg: 'white15',
  border: 'none',
  height: '4.4rem',
  borderRadius: 0,
}

export const newInputSharedStyles = {
  width: '100%',
  height: '4.8rem',
  alignSelf: 'end',
  borderRadius: '0.8rem',
  px: '1.2rem',
  fontSize: '1.4rem',
  color: 'aliceBlue',
  fontWeight: 400,
  letterSpacing: '0.6px',
  border: '1px solid',
  borderColor: 'blueYonder40',
  backgroundColor: 'blue',
  '--theme-ui-input-autofill-bg': 'blue',
  '& + div > label': {
    mb: '0rem',
  },
  '::placeholder': {
    color: 'aliceBlue30',
    fontWeight: 400,
    letterSpacing: '0.6px',
  },
  '&:hover': {
    ':not([disabled])': {
      ':not(:focus)': {
        borderColor: 'blueYonder80',
      },
    },
  },
  '&:focus': {
    borderColor: 'cyan800',
    color: 'white',
    '& + div > label': {
      color: 'aliceBlue60',
      span: {
        color: 'aliceBlue60',
      },
    },
    backgroundColor: 'blue',
  },
  '&:focus-visible': {
    outline: 0,
  },
  ':disabled': {
    borderColor: 'blueYonder40',
    backgroundColor: 'blueYonder20',
    '& + div label': {
      color: 'aliceBlue70',
    },
  },
  '&:-webkit-autofill': {
    boxShadow: ({ colors }): string => `0 0 0 10rem ${colors?.blue} inset !important`,
    WebkitTextFillColor: ({ colors }): string => `${colors.aliceBlue} !important`,
    caretColor: 'aliceBlue',
    fontSize: '14px !important',
  },
  '&:-webkit-autofill::first-line': {
    fontSize: '14px !important',
  },
}

export default {
  input: {
    primary: {
      ...inputSharedStyles,
      bg: 'transparent',
      borderBottom: '1px solid',
      borderBottomColor: 'white25',
      ':focus': {
        borderBottomColor: 'white',
        '& + label': {
          color: 'aliceBlue60',
        },
      },
      ':disabled': {
        borderBottomColor: 'white',
      },
      '@keyframes autofill': {
        to: {
          color: ({ colors }): string => colors.text,
          background: 'transparent',
        },
      },
      '&:-webkit-autofill': {
        transitionDelay: '99999s',
        animationName: 'autofill',
        animationFillMode: 'both',
      },
    },
    secondary: {
      ...inputSecondaryStyles,
      p: '1.2rem',
      '& + label': {
        fontWeight: 1,
        mb: 1,
      },
      ':focus': {
        '& + label': {
          color: 'white',
        },
      },
      '&:-webkit-autofill': {
        boxShadow: '0 0 0 3rem #474747 inset !important',
        WebkitTextFillColor: ({ colors }): string => `${colors.white} !important`,
        caretColor: 'white',
      },
    },
    search: {
      ...inputSecondaryStyles,
      p: 0,
      background: 'transparent',
      borderBottom: ({ colors }): string => `1px solid ${colors.accent}`,
      '&:-webkit-autofill': {
        WebkitTextFillColor: 'white',
        transition: 'background-color 2147483647s',
      },
    },
    newPrimary: {
      ...newInputSharedStyles,
    },
    newSearch: {
      ...newInputSharedStyles,
      background: 'blueYonder50',
      border: '1px solid',
      borderColor: 'wakaiwaGrey50',
      color: 'aliceBlue60',
      fontWeight: 400,
      '&:focus': {
        borderColor: 'cyan800',
        color: 'aliceBlue',
        opacity: 1,
      },
      '&:hover:not(:focus)': {
        borderColor: 'wakaiwaGrey80',
      },
      '::placeholder': {
        opacity: 0.6,
        letterSpacing: '0.6px',
      },
    },
  },
  textarea: {
    primary: {
      font: 'inherit',
      borderRadius: '1.6rem',
      border: '1px solid',
      borderColor: 'white25',
      bg: 'white10',
      p: '1.6rem 2.4rem',
      '::placeholder': {
        color: 'aliceBlue60',
      },
      ':focus': {
        borderColor: 'white',
        '& + label': {
          color: 'aliceBlue60',
        },
      },
      ':disabled': {
        borderColor: 'white',
      },
      '@keyframes autofill': {
        to: {
          color: ({ colors }): string => colors.text,
          background: 'white10',
        },
      },
      '&:-webkit-autofill': {
        transitionDelay: '99999s',
        animationName: 'autofill',
        animationFillMode: 'both',
      },
    },
    barryIssueArea: {
      borderRadius: '1.2rem',
      border: '1px solid',
      outline: 'none',
      borderColor: 'borderLight',
      p: '1.6rem',
      fontSize: '1.5rem',
      color: 'title60',
      fontWeight: 400,
      letterSpacing: '0.6px',
      '&:focus': {
        borderColor: 'cyan70',
        color: 'aliceBlue',
        opacity: 1,
      },
      '&:hover:not(:focus)': {
        borderColor: 'blueYonder80',
      },
      '::placeholder': {
        opacity: 0.6,
        color: 'caption',
        fontWeight: 500,
        letterSpacing: '0.6px',
      },
    },
  },
  label: {
    color: 'aliceBlue',
    fontFamily: 'body',
  },
}
