import React, { ReactElement, useCallback, useMemo } from 'react'
import useQueryString from 'utils/useQueryString'
import omit from 'lodash/omit'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/ShowAlertOnModalOrTrayWrapper'
import { AddProvisionView } from './AddProvisionView'
import { ProvisionDialogType } from 'components/Organization/Provision'
import { InstructionsView } from './InstractionsView'
import ModalDialog from 'ui/NewModalDialog'
import { Flex } from '@theme-ui/components'

const ProvisionTrayOrModalDialog = ({
  resolverId,
  deviceType,
}: {
  resolverId?: string
  deviceType?: string
}): ReactElement | null => {
  const { qs, nav } = useQueryString()

  const onClose = useCallback(() => {
    nav({ ...omit(qs, 'provisionDialog') })
  }, [qs, nav])

  const currentView = useMemo(() => {
    switch (qs.provisionDialog) {
      case ProvisionDialogType.ADD:
        return {
          title: 'Create Provisioning Code',
          content: <AddProvisionView dismiss={onClose} />,
          testId: 'add-provision',
        }
      case ProvisionDialogType.INSTRUCTIONS:
        return {
          title: 'Provisioning Instructions',
          content: <InstructionsView resolverId={resolverId} deviceType={deviceType} />,
          testId: 'provision-instructions',
        }
      default:
        return
    }
  }, [deviceType, onClose, qs.provisionDialog, resolverId])

  return (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={!!qs.provisionDialog}>
      <ModalDialog
        isOpen={!!qs.provisionDialog}
        title={currentView?.title}
        dataTestId={`${currentView?.testId}-dialog`}
        onCloseClick={onClose}
        dismiss={onClose}
        footer={qs.provisionDialog === ProvisionDialogType.ADD ? <Flex /> : null}
      >
        {currentView?.content}
      </ModalDialog>
    </ShowAlertOnModalOrTrayWrapper>
  )
}

export default ProvisionTrayOrModalDialog
