import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import {
  Stripe,
  StripeCardNumberElement,
  PaymentRequest,
  PaymentMethod as StripePaymentMethod,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js'
import {
  fetchPending,
  ResponseState,
  initialResponseState,
  LoadingState,
} from 'store/fetchingLogic'
import { sessionLogout } from 'store/session'
import { RootState } from 'store/rootReducer'
import { Currency, defaultCurrencyCode, PromoMode } from 'store/api/billing'
import getPriceDetailsForCurrency, { PriceDetails } from 'utils/getPriceDetailsForCurrency'
import { paymentsApi } from 'store/api/billing/payments'
import {
  Invoice,
  PaymentsData,
  SetupIntentData,
  StripeSubscription,
  Subscription,
} from 'store/api/billing/payments.interface'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { productsApi } from 'store/api/billing/products'
import { ApiResponse, ControlDError } from 'store/api/http'
import { userApi } from 'store/api/user'

export const defaultErrorMessage = 'Payment unsuccessful. Please try again or contact support.'

export enum TransactionStatus {
  STOPPED = 'STOPPED',
  INPROGRESS = 'INPROGRESS',
  SUCCESS = 'SUCCESS',
  RETRYING = 'RETRYING',
  FAILURE = 'FAILURE',
}
export enum PaymentMethod {
  CREDIT = 'CREDIT',
  CRYPTO = 'CRYPTO',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  AMAZON = 'AMAZON',
  BROWSER = 'BROWSER',
}

interface BillingState extends ResponseState<PaymentsData> {
  activeSubscription?: Subscription
}

const initialBillingPaymentsState: BillingState = {
  ...initialResponseState,
}
export const billingPaymentsSlice = createSlice({
  name: 'billingPayments',
  initialState: {
    ...initialBillingPaymentsState,
  },
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, s => {
        s.data = null
      })
      .addMatcher(userApi.endpoints.deleteUser.matchFulfilled, state => {
        state.data = null
      })
      .addMatcher(paymentsApi.endpoints.getBillingSubscriptions.matchFulfilled, (state, action) => {
        state.activeSubscription = action.payload?.subscriptions?.find(
          subscription => subscription.status,
        )
      })
  },
})

interface TrialEligibilityState extends ResponseState {
  isEligibleForTrial: boolean
}

const initialTrialEligibilityState: TrialEligibilityState = {
  ...initialResponseState,
  isEligibleForTrial: true,
}

export const trialEligibilitySlice = createSlice({
  name: 'trialEligibility',
  initialState: {
    ...initialTrialEligibilityState,
  },
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, state => {
        state.isEligibleForTrial = true
        state.data = null
      })
      .addMatcher(userApi.endpoints.deleteUser.matchFulfilled, state => {
        state.isEligibleForTrial = true
        state.data = null
      })
      .addMatcher(paymentsApi.endpoints.checkTrialEligibility.matchFulfilled, state => {
        state.loading = LoadingState.FULFILLED
        state.isEligibleForTrial = true
      })
      .addMatcher(paymentsApi.endpoints.checkTrialEligibility.matchRejected, state => {
        state.loading = LoadingState.REJECTED
        state.isEligibleForTrial = false
      })
      .addMatcher(paymentsApi.endpoints.checkTrialEligibility.matchPending, state => {
        state.loading = LoadingState.PENDING
      })
  },
})

export enum DeactivateSubscriptionReasons {
  DID_NOT_WORK = "I can't get it to work",
  CAN_NOT_AFFORD = "I can't afford it",
  NOT_GOOD = "It's not good enough",
  NO_NEED = "I don't need it",
  WILL_BE_BACK = "I'll be back later",
  CHANGE_PLANS = 'I want to change plans',
  CHANGE_CREDIT_CARD = 'I want to change credit cards',
  OTHER = 'Other',
}
export interface DeactivateSubscriptionArgs {
  reasons: DeactivateSubscriptionReasons[]
  comment?: string
  password: string
  subscriptionId: string
}
export const deactivateSubscription = createAsyncThunk(
  'payments/deactivate',
  async (
    { reasons, comment, password, subscriptionId }: DeactivateSubscriptionArgs,
    { dispatch },
  ) => {
    if (reasons.length === 0) {
      throw new Error('Should be at least one reason for deactivation.')
    }

    const response = await dispatch(
      paymentsApi.endpoints.cancelSubscription.initiate({
        subscriptionId,
        reasons,
        comment,
        password,
      }),
    )

    dispatch(productsApi.endpoints.getBillingProducts.initiate({}, { forceRefetch: true }))

    return response
  },
)

interface SubmitPaymentBody {
  stripe: Stripe
  cardElement: StripeCardNumberElement
  fullName: string
  email?: string
  isOrganization?: boolean
}

export type ResponseType = {
  data: { invoice: Invoice }
} & { error: FetchBaseQueryError | SerializedError }

async function retryPayment(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  customer: string,
  stripePaymentMethod: StripePaymentMethod,
  latestInvoiceId: string,
  stripe: Stripe,
): Promise<Partial<PaymentsState>> {
  let errorMessage = defaultErrorMessage
  dispatch(updateTransaction({ transactionStatus: TransactionStatus.RETRYING }))
  try {
    // Update the payment method and retry invoice payment
    const response = (await dispatch(
      paymentsApi.endpoints.retryStripeInvoice.initiate({
        customerId: customer,
        paymentMethodId: stripePaymentMethod.id,
        invoiceId: latestInvoiceId,
      }),
    )) as ResponseType

    const invoice = response.data?.invoice
    if (invoice.payment_intent.status === 'requires_payment_method') {
      const result = await stripe?.confirmCardPayment(invoice.payment_intent.client_secret, {
        payment_method: stripePaymentMethod.id,
      })

      if (result?.paymentIntent?.status === 'succeeded') {
        return {
          transactionStatus: TransactionStatus.SUCCESS,
        }
      }
      if (result?.error?.message) {
        errorMessage = result.error.message
      }
    }
  } catch (err) {
    errorMessage = (err as Error).message
  }

  return {
    transactionStatus: TransactionStatus.FAILURE,
    errorMessage,
  }
}

function waitForStripeWebhook(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  selectedPrice: PriceDetails | undefined,
) {
  // poll until stripe webhook has completed the flow
  const id = setInterval(async () => {
    const { data: billingProductsData } = await dispatch(
      productsApi.endpoints.getBillingProducts.initiate({}, { forceRefetch: true }),
    )
    const currentProduct = billingProductsData?.products[0]

    const currentPrice =
      currentProduct?.price &&
      getPriceDetailsForCurrency(currentProduct.price, currentProduct.subscription?.currency)
    if (currentPrice?.stripe_id === selectedPrice?.stripe_id) {
      clearInterval(id)
      dispatch(
        updateTransaction({
          transactionStatus: TransactionStatus.SUCCESS,
        }),
      )
    }
  }, 1000)
  dispatch(paymentsSlice.actions.setPollingHandle(id))
}

export type CreateNewSubscriptionResponseType = {
  data: { subscription: StripeSubscription }
  success: boolean
} & { error: SerializedError }

export type UpdateMethodResponseType = {
  data: {
    success: boolean
  }
} & { error: FetchBaseQueryError | SerializedError }

async function createNewSubscription(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  customer: string,
  stripe: Stripe,
  fullName: string,
  promoCode?: string,
  promoMode?: string,
  stripePaymentMethod?: StripePaymentMethod,
  selectedPrice?: PriceDetails,
  currency?: Currency,
  isOrganization?: boolean,
): Promise<Partial<PaymentsState>> {
  let errorMessage = defaultErrorMessage
  try {
    if (stripePaymentMethod?.id) {
      const subscriptionResponse = (
        isOrganization
          ? await dispatch(
              paymentsApi.endpoints.createOrgStripeSubscription.initiate({
                customerId: customer,
                paymentMethodId: stripePaymentMethod?.id || '',
              }),
            )
          : await dispatch(
              paymentsApi.endpoints.createStripeSubscription.initiate({
                customerId: customer,
                paymentMethodId: stripePaymentMethod?.id || '',
                priceId: selectedPrice?.stripe_id || '',
                fullname: fullName,
                currency: currency?.code,
                ...(promoMode && promoCode
                  ? {
                      [promoMode]: promoCode,
                    }
                  : {}),
              }),
            )
      ) as CreateNewSubscriptionResponseType

      if (subscriptionResponse.error) {
        return {
          transactionStatus: TransactionStatus.FAILURE,
        }
      }

      const subscription = subscriptionResponse.data?.subscription
      const paymentIntent = subscription?.latest_invoice?.payment_intent
      // payment was successful
      if (
        subscription?.status === 'active' ||
        paymentIntent?.status === 'succeeded' ||
        subscription?.status === 'trialing'
      ) {
        return {
          transactionStatus: TransactionStatus.SUCCESS,
        }
      }
      // 3D secure flow
      if (
        paymentIntent?.status === 'requires_action' ||
        paymentIntent?.status === 'requires_payment_method'
      ) {
        const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: stripePaymentMethod?.id || '',
        })
        if (result?.paymentIntent?.status === 'succeeded') {
          return {
            transactionStatus: TransactionStatus.SUCCESS,
          }
        }

        if (result.error) {
          errorMessage = result.error.message ?? ''
        } else if (
          // or should this be result.paymentIntent???
          subscription?.latest_invoice.payment_intent?.status === 'requires_payment_method'
        ) {
          // Using redux to manage the state of the retry here,
          // Store the latest invoice ID and status.
          dispatch(
            setLatestInvoiceState({
              id: subscription.latest_invoice.id,
              status: subscription.latest_invoice.payment_intent?.status,
            }),
          )
          errorMessage = 'Your card was declined.'
        }
      }
    }
  } catch (err) {
    errorMessage = (err as Error).message
  }
  // if there is a error message present then payment failed
  if (errorMessage) {
    return {
      transactionStatus: TransactionStatus.FAILURE,
      errorMessage,
    }
  }
  // else poll for success/failure
  waitForStripeWebhook(dispatch, selectedPrice)
  // keep transaction going
  return { transactionStatus: TransactionStatus.INPROGRESS }
}

export type CreateStripeCustomerResponseType = {
  data: { customer: string }
} & { error: FetchBaseQueryError | SerializedError }

export const submitCreditCardPayment = createAsyncThunk(
  'payments/submitCreditCardPayment',
  async (
    { stripe, cardElement, fullName, email, isOrganization }: SubmitPaymentBody,
    { getState, dispatch },
  ): Promise<Partial<PaymentsState>> => {
    // set transaction status
    dispatch(
      updateTransaction({
        transactionStatus: TransactionStatus.INPROGRESS,
      }),
    )
    // create stripe payment method
    const { error: paymentMethodError, paymentMethod: stripePaymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: fullName,
        },
      })

    // if payment method creation failed, error out
    if (paymentMethodError) {
      return {
        errorMessage: paymentMethodError.message,
        transactionStatus: TransactionStatus.FAILURE,
      }
    }
    // get the payment data from redux. get price for currency selected
    const state = getState() as RootState
    const { latestInvoicePaymentIntentStatus, latestInvoiceId } = state.payments
    const {
      data: products,
      selectedProductPk,
      priceInterval,
      promoCode,
      promoMode,
      selectedCurrency,
    } = state.products

    // create stripe customer data object
    const createCustomerData = (await dispatch(
      paymentsApi.endpoints.createStripeCustomer.initiate({
        ...(isOrganization ? {} : { email }),
        currency: selectedCurrency?.code ?? defaultCurrencyCode,
      }),
    )) as CreateStripeCustomerResponseType

    const selectedPricePoint = products
      ?.find(p => p.PK === selectedProductPk)
      ?.price_points.find(pp => pp.duration === priceInterval)
    const selectedPrice =
      selectedPricePoint && getPriceDetailsForCurrency(selectedPricePoint, selectedCurrency?.code)

    // If a previous payment was attempted, get the latest invoice & retry payment
    if (
      latestInvoicePaymentIntentStatus === 'requires_payment_method' &&
      stripePaymentMethod &&
      selectedPrice &&
      latestInvoiceId
    ) {
      return await retryPayment(
        dispatch,
        createCustomerData.data.customer,
        stripePaymentMethod,
        latestInvoiceId,
        stripe,
      )
    } else {
      // create new subscription
      return await createNewSubscription(
        dispatch,
        createCustomerData.data.customer,
        stripe,
        fullName,
        promoCode,
        promoMode,
        stripePaymentMethod,
        selectedPrice,
        selectedCurrency,
        isOrganization,
      )
    }
  },
)
export const submitXPayPayment = createAsyncThunk(
  'payments/submitXPayPayment',
  async (
    { event, email }: { event: PaymentRequestPaymentMethodEvent; email?: string },
    { getState, dispatch },
  ): Promise<Partial<PaymentsState>> => {
    dispatch(
      updateTransaction({
        transactionStatus: TransactionStatus.INPROGRESS,
      }),
    )

    let errorMessage = defaultErrorMessage
    const state = getState() as RootState
    const { selectedProductPk, priceInterval, promoMode, promoCode, selectedCurrency } =
      state.products
    const { data: productsData } = await dispatch(
      productsApi.endpoints.getProducts.initiate({ promoMode, promoCode }, { forceRefetch: true }),
    )

    const selectedPricePoint = productsData?.products
      ?.find(p => p.PK === selectedProductPk)
      ?.price_points.find(pp => pp.duration === priceInterval)
    const selectedPrice =
      selectedPricePoint && getPriceDetailsForCurrency(selectedPricePoint, selectedCurrency?.code)
    if (event.paymentMethod) {
      try {
        const createCustomerData = (await dispatch(
          paymentsApi.endpoints.createStripeCustomer.initiate({
            email: email ?? '',
            currency: selectedCurrency?.code ?? defaultCurrencyCode,
          }),
        )) as CreateStripeCustomerResponseType

        const stripeSubscriptionBody = {
          customerId: createCustomerData.data.customer,
          paymentMethodId: event.paymentMethod.id,
          priceId: selectedPrice?.stripe_id ?? '',
          currency: selectedCurrency?.code,
        }
        if (promoMode === PromoMode.USERNAME) {
          stripeSubscriptionBody[promoMode] = promoCode
        }

        const subscriptionData = (await dispatch(
          paymentsApi.endpoints.createStripeSubscription.initiate(stripeSubscriptionBody),
        )) as CreateNewSubscriptionResponseType
        if (subscriptionData.data) {
          event.complete('success')
          return {
            transactionStatus: TransactionStatus.SUCCESS,
          }
        }
      } catch (err) {
        errorMessage = (err as Error).message
      }
    }
    event.complete('fail')
    return {
      transactionStatus: TransactionStatus.FAILURE,
      errorMessage,
    }
  },
)

export type CreateSetupIntentResponseType = {
  data: SetupIntentData
} & { error: FetchBaseQueryError | SerializedError }

export interface UpdatePaymentMethodInput {
  stripe: Stripe | null
  cardElement: StripeCardNumberElement | null
  fullName: string
}
export const updatePaymentMethod = createAsyncThunk(
  'payments/updatePaymentMethod',
  async (
    { stripe, cardElement, fullName }: UpdatePaymentMethodInput,
    { dispatch },
  ): Promise<Partial<PaymentsState>> => {
    let errorMessage
    dispatch(
      updateTransaction({
        paymentMethod: PaymentMethod.CREDIT,
        transactionStatus: TransactionStatus.INPROGRESS,
      }),
    )

    const setupIntent = (await dispatch(
      paymentsApi.endpoints.createSetupIntent.initiate({}),
    )) as CreateSetupIntentResponseType

    if (stripe && cardElement) {
      const { error: paymentMethodError, setupIntent: confirmedSetupIntent } =
        await stripe.confirmCardSetup(setupIntent.data.setup_intent.client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: fullName,
            },
          },
        })

      if (paymentMethodError) {
        errorMessage = paymentMethodError.message
      } else if (confirmedSetupIntent) {
        try {
          const response = (await dispatch(
            paymentsApi.endpoints.updatePaymentMethod.initiate({
              fullname: fullName,
              paymentMethodId: confirmedSetupIntent.payment_method ?? '',
            }),
          )) as UpdateMethodResponseType

          if (response?.data?.success) {
            return {
              transactionStatus: TransactionStatus.SUCCESS,
            }
          }
        } catch (err) {
          errorMessage = (err as Error).message
        }
      }
    } else {
      errorMessage = defaultErrorMessage
    }

    return {
      paymentMethod: PaymentMethod.CREDIT,
      transactionStatus: TransactionStatus.FAILURE,
      errorMessage,
    }
  },
)

export const updateProductSubscription = createAsyncThunk(
  'payments/updateProductSubscription',
  async ({ stripe }: { stripe: Stripe }, { getState, dispatch }) => {
    let errorMessage

    dispatch(
      updateTransaction({
        transactionStatus: TransactionStatus.INPROGRESS,
      }),
    )

    const {
      selectedProductPk,
      priceInterval,
      currentProduct,
      promoCode,
      promoMode,
      selectedCurrency,
    } = (getState() as RootState).products
    const { data: productsData } = await dispatch(
      productsApi.endpoints.getProducts.initiate({ promoCode, promoMode }, { forceRefetch: true }),
    )

    const selectedPricePoint = productsData?.products
      ?.find(p => p.PK === selectedProductPk)
      ?.price_points.find(pp => pp.duration === priceInterval)
    const selectedPrice =
      selectedPricePoint && getPriceDetailsForCurrency(selectedPricePoint, selectedCurrency?.code)
    try {
      const subscriptionResponse = (await dispatch(
        paymentsApi.endpoints.updateStripeSubscription.initiate({
          subscriptionId: currentProduct?.subscription?.PK ?? '',
          newPriceId: selectedPrice?.stripe_id ?? '',
          currency: selectedCurrency?.code ?? 'usd',
          ...(promoMode && promoCode
            ? {
                [promoMode]: promoCode,
              }
            : {}),
        }),
      )) as CreateNewSubscriptionResponseType

      if (subscriptionResponse.error) {
        return {
          transactionStatus: TransactionStatus.FAILURE,
          errorMessage: subscriptionResponse.error.message,
        }
      }

      const subscription = subscriptionResponse?.data.subscription
      const paymentIntent = subscription?.latest_invoice.payment_intent

      // 3D secure flow
      if (
        paymentIntent?.status === 'requires_action' ||
        paymentIntent?.status === 'requires_payment_method'
      ) {
        const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentIntent.payment_method,
        })
        if (result?.paymentIntent?.status === 'succeeded') {
          return {
            transactionStatus: TransactionStatus.SUCCESS,
          }
        }

        if (result.error) {
          errorMessage = result.error.message ?? ''
        } else if (
          // or should this be result.paymentIntent???
          subscription?.latest_invoice.payment_intent.status === 'requires_payment_method'
        ) {
          // Using redux to manage the state of the retry here,
          // Store the latest invoice ID and status.
          dispatch(
            setLatestInvoiceState({
              id: subscription.latest_invoice.id,
              status: subscription.latest_invoice.payment_intent?.status,
            }),
          )
          errorMessage = 'Your card was declined.'
        }
      }
    } catch (error) {
      errorMessage = (error as Error).message
    }
    // if there is a error message present then payment failed
    if (errorMessage) {
      return {
        transactionStatus: TransactionStatus.FAILURE,
        errorMessage,
      }
    }
    waitForStripeWebhook(dispatch, selectedPrice)
    return { transactionStatus: TransactionStatus.INPROGRESS }
  },
)
interface PaymentsState extends ResponseState {
  transactionStatus: TransactionStatus
  latestInvoiceId?: string
  latestInvoicePaymentIntentStatus?: string
  errorMessage?: string
  paymentRequest?: PaymentRequest
  paymentMethod: PaymentMethod
  paymentUrl?: string //coingate
  xPayPaymentMethod?: PaymentMethod // for Xpay button
  pollingHandle?: NodeJS.Timeout // the handle of the setInterval call used to poll the stripe webhook
}
const initialPaymentsState: PaymentsState = {
  ...initialResponseState,
  transactionStatus: TransactionStatus.STOPPED,
  paymentMethod: PaymentMethod.CREDIT,
}
export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: { ...initialPaymentsState },
  reducers: {
    setLatestInvoiceState(state, action) {
      state.latestInvoiceId = action.payload.id
      state.latestInvoicePaymentIntentStatus = action.payload.status
    },
    startTransaction(state) {
      state.transactionStatus = TransactionStatus.INPROGRESS
    },
    updateTransaction(state, action: PayloadAction<Partial<PaymentsState>>) {
      return { ...state, ...action.payload }
    },
    setPaymentRequest(state, action: PayloadAction<PaymentRequest | undefined>) {
      state.paymentRequest = action.payload
    },
    setXPayPaymentMethod(state, action: PayloadAction<PaymentMethod | undefined>) {
      state.xPayPaymentMethod = action.payload
    },
    setPaymentMethod(state, action: PayloadAction<PaymentMethod>) {
      state.paymentMethod = action.payload
    },
    resetPaymentsState() {
      return { ...initialPaymentsState }
    },
    setPollingHandle(state, action: PayloadAction<NodeJS.Timeout>) {
      state.pollingHandle = action.payload
    },
  },
  extraReducers: builder =>
    builder
      .addCase(submitCreditCardPayment.pending, fetchPending)
      .addCase(submitCreditCardPayment.rejected, (state, action) => {
        state.loading = LoadingState.REJECTED
        state.transactionStatus = TransactionStatus.FAILURE
        state.errorMessage = action.error.message
      })
      .addCase(submitCreditCardPayment.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: LoadingState.FULFILLED }
      })
      .addCase(updatePaymentMethod.pending, fetchPending)
      .addCase(updatePaymentMethod.rejected, (state, action) => {
        state.loading = LoadingState.REJECTED
        state.transactionStatus = TransactionStatus.FAILURE
        state.errorMessage = action.error.message
      })
      .addCase(updatePaymentMethod.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: LoadingState.FULFILLED }
      })
      .addCase(submitXPayPayment.pending, fetchPending)
      .addCase(submitXPayPayment.rejected, (state, action) => {
        state.loading = LoadingState.REJECTED
        state.transactionStatus = TransactionStatus.FAILURE
        state.errorMessage = action.error.message
      })
      .addCase(submitXPayPayment.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: LoadingState.FULFILLED }
      })
      .addCase(updateProductSubscription.pending, fetchPending)
      .addCase(updateProductSubscription.rejected, (state, action) => {
        state.loading = LoadingState.REJECTED
        state.transactionStatus = TransactionStatus.FAILURE
        state.errorMessage = action.error.message
      })
      .addCase(updateProductSubscription.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: LoadingState.FULFILLED }
      })
      // cannot use the sessionLogout thunk here as it causes a circular dependency
      .addMatcher(
        action => action.type === 'session/logout/fulfilled',
        state => {
          if (state.pollingHandle) {
            clearInterval(state.pollingHandle)
            state.pollingHandle = undefined
          }
        },
      )
      .addMatcher(paymentsApi.endpoints.createCoingateOrder.matchRejected, (state, action) => {
        state.loading = LoadingState.REJECTED
        state.transactionStatus = TransactionStatus.FAILURE
        state.errorMessage =
          (action.payload?.data as ApiResponse)?.error?.message || action.error.message
      })
      .addMatcher(paymentsApi.endpoints.createCoingateOrder.matchFulfilled, (state, action) => {
        state.loading = LoadingState.FULFILLED
        state.paymentUrl = action.payload.body.payment_url
      })
      .addMatcher(paymentsApi.endpoints.createStripeSubscription.matchRejected, (state, action) => {
        state.errorMessage =
          (action.payload?.data as ApiResponse)?.error?.message || action.error.message
      })
      .addMatcher(
        paymentsApi.endpoints.createOrgStripeSubscription.matchRejected,
        (state, action) => {
          state.errorMessage =
            (action.payload?.data as ApiResponse)?.error?.message ||
            (action.payload as ControlDError)?.message ||
            action.error.message
        },
      ),
})

export const {
  setLatestInvoiceState,
  startTransaction,
  updateTransaction,
  setPaymentRequest,
  setPaymentMethod,
  resetPaymentsState,
  setXPayPaymentMethod,
} = paymentsSlice.actions
