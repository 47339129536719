import React, { FC, ReactNode } from 'react'
import { useLocation } from '@reach/router'

import { useAppDispatch } from 'store/hooks'
import { setCampaignID, setEntryPage } from 'store/tracking'
import useQueryString from 'utils/useQueryString'

type StaticPageWrapperProps = {
  children?: ReactNode
}

const StaticPageWrapper: FC<StaticPageWrapperProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()
  const location = useLocation()

  if (qs.cid) {
    const entryPage = location.pathname.split('/').join('')
    dispatch(setEntryPage(entryPage))
    dispatch(setCampaignID(qs.cid))
  }

  return <>{children}</>
}

export default StaticPageWrapper
