import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import { Accordion } from 'ui'
import type { Setter } from 'utils'
import { ResolverType } from 'components/SetupGuide/SetupGuide.interface'

export default function ResolverContainer({
  type,
  title,
  isOpen,
  setIsOpen,
  shouldShowDropdownButton = true,
  children,
}: {
  type: ResolverType
  title: ReactElement
  isOpen?: boolean
  setIsOpen?: Setter<ResolverType | undefined>
  shouldShowDropdownButton?: boolean
  children: ReactElement | ReactElement[]
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          '& > :not(:last-child)': {
            mb: 4,
          },
          '& > :last-child': {
            mb: 0,
          },
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Accordion
            data-testid="help-accordion"
            id={type}
            title={title}
            sxTitleContainer={{
              width: '100%',
              pr: 0,
              mr: shouldShowDropdownButton ? '0.8rem' : 0,
            }}
            titleStyle={{
              width: '100%',
              color: 'aliceBlue60',
              fontSize: '1.2rem',
              textTransform: 'uppercase',
              textAlign: 'left',
            }}
            openTitleStyle={{
              width: '100%',
              color: 'aliceBlue',
              fontSize: '1.2rem',
              textTransform: 'uppercase',
              textAlign: 'left',
            }}
            containerStyle={{
              width: '100%',
              '& > div': {
                width: '100%',
              },
              '& > div:first-of-type': {
                widh: '100%',
                px: '1.6rem',
                py: '0.8rem',
                backgroundColor: 'blue800',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: 'blueYonder15',
              },
              alignItems: 'center',
            }}
            buttonStyle={{
              width: '100%',
              justifyContent: 'space-between',
              pl: '0.6rem',
              pr: shouldShowDropdownButton ? '0.8rem' : '1.2rem',
              py: 0,
              fontSize: '1.8rem',
              fontWeight: 'bold',
            }}
            svgStyle={{
              display: shouldShowDropdownButton ? 'flex' : 'none',
              backgroundColor: 'transparent',
              flexShrink: 0,
              '& svg': {
                flexShrink: 0,
              },
            }}
            isOpenControlled={isOpen}
            setIsOpenControlled={(): void => setIsOpen?.(type)}
          >
            {children}
          </Accordion>
        </Flex>
      </Flex>
    </Flex>
  )
}
