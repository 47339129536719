import { createSlice, Action, AnyAction, SerializedError } from '@reduxjs/toolkit'
import * as errorCodes from 'store/api/errorCodes'
import { ControlDError } from 'store/api/http'
import { sessionLogout } from 'store/session'
import { userApi } from 'store/api/user'
import { isExceptionalErrorCode } from 'store/api'

interface RTKQueryRejectedAction extends AnyAction {
  payload: SerializedError & { data: { error: ControlDError } }
  error?: SerializedError
}

interface RejectedAction extends Action {
  error: ControlDError
}

// important when we should get actions from the error
function getErrorFromJson(error: SerializedError) {
  if (error.message) {
    try {
      return JSON.parse(error.message)
    } catch (err) {}
  }

  return error
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    // only api errors have a code, filter out all other errors
    action.error?.code &&
    action.type.endsWith('rejected') &&
    (action.error?.code === errorCodes.ERROR_HARD_MAINTENANCE ||
      // these errors are handled on a different page
      action.type !== 'confirmEmail/rejected')
  )
}

function isRTKQueryRejectedAction(action: AnyAction): action is RTKQueryRejectedAction {
  return (
    !action.meta?.arg?.endpointName?.includes('detectDevice') &&
    !action.meta?.arg?.endpointName?.includes('getBillingSubscriptions') &&
    action.type.endsWith('rejected') &&
    (action.payload || action.error)
  )
}

interface FullfilledAction extends Action {
  payload: {
    body: unknown
    success: boolean
    message?: string
    error?: ControlDError
  }
}
function isFulfilledAction(action: AnyAction): action is FullfilledAction {
  return action.type.endsWith('fulfilled')
}

export interface ErrorState {
  errors: ControlDError[]
  isInMaintenanceMode: boolean
  maintenanceMessage: string
  isInAnalyticsMaintenanceMode: boolean
}

const initialState: ErrorState = {
  errors: [] as ControlDError[],
  isInMaintenanceMode: false,
  isInAnalyticsMaintenanceMode: false,
  maintenanceMessage:
    'Oops! Control D has been abducted for maintenance, come back later. Contact support for help or check our socials for updates.',
}
export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    resetMaintenanceMode(state): void {
      state.isInMaintenanceMode = false
    },
    resetAnalyticsMaintenanceMode(state): void {
      state.isInAnalyticsMaintenanceMode = false
    },
    handleError(state, { payload }: { payload: ControlDError }): void {
      state.errors = state.errors.filter(e => e.message !== payload.message)
    },
    setError(state, { payload }: { payload: ControlDError }): void {
      state.errors.push(payload)
    },
    clearErrors(state): void {
      state.errors = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, state => {
        // keep invalid session errors on logout
        state.errors = state.errors.filter(e => e.code === errorCodes.ERROR_INVALID_SESSION)
      })
      .addMatcher(userApi.endpoints.userLogin.matchFulfilled, state => {
        // keep errors:
        // 40002: configure resolver
        // 40201: needs subscription
        state.errors = state.errors.filter(e =>
          [errorCodes.ERROR_MISSING_REQUIRED, errorCodes.ERROR_PAY_ME].includes(e.code ?? ''),
        )
      })
      .addMatcher(userApi.endpoints.userLogin.matchPending, state => {
        state.errors = []
      })
      // any api call that returns successfully should reset the maintenance mode
      .addMatcher(isFulfilledAction, (state, action) => {
        if (
          action.payload?.error &&
          isExceptionalErrorCode(action.payload.error?.code?.toString())
        ) {
          state.errors.push(action.payload.error)
        }
      })
      .addMatcher(isRejectedAction, (state, action) => {
        if (action.error?.code?.toString() === errorCodes.ERROR_HARD_MAINTENANCE) {
          state.isInMaintenanceMode = true
          if (action.error.message) {
            state.maintenanceMessage = action.error.message
          }
          return
        }
        if (
          (action.type.startsWith('customRules/add') ||
            action.type.startsWith('customRules/edit') ||
            action.type.startsWith('groups/add') ||
            action.type.startsWith('groups/editName')) &&
          action.error?.code?.startsWith('400')
        ) {
          // some network errors are handled inside the rule menu
          // and should not trigger the alert presenter
          return
        }

        // GET requests for a single custom rule by hostname are made before opening
        // the rule menu from an entry in the activity log, to determine whether to
        // open the menu in create or edit mode. Since the absence of an existing rule
        // is not an error for which we wish to show an alert, we filter it out.
        if (action.type === 'customRule/get/rejected' && action.error?.code?.startsWith('404')) {
          return
        }
        // errors from checking trial eligibility to be ignored
        if (
          action.type === 'trialEligibility/get/rejected' &&
          action.error?.code?.startsWith('403')
        ) {
          return
        }
        // do not store error messages from payments
        if (action.type.startsWith('payments') || action.type.startsWith('products/get')) {
          return
        }

        if (!state.errors.some(error => error.code === action.error.code)) {
          state.errors.push(action.error)
        }
      })
      .addMatcher(isRTKQueryRejectedAction, (state, action) => {
        // different error cases from the server side response

        const error = getErrorFromJson(
          action.payload?.data?.error || action.payload || action.error,
        )
        // ignore invalid param error from call to /hello-barry
        if (
          action.meta?.arg?.endpointName === 'createToken' ||
          action.meta?.arg?.endpointName === 'getHelloBarryMessage'
        ) {
          return
        }

        if (error?.code?.toString() === errorCodes.ANALYTICS_ERROR_HARD_MAINTENANCE) {
          state.isInAnalyticsMaintenanceMode = true

          // don't need to show the alert with the error message when maintenance mode
          return
        }

        if (error?.code?.toString() === errorCodes.ERROR_HARD_MAINTENANCE) {
          state.isInMaintenanceMode = true
          if (error.message) {
            state.maintenanceMessage = error.message
          }

          // don't need to show the alert with the error message when maintenance mode
          return
        }

        if (
          !state.errors.some(error => error.code === error?.code) &&
          // some api calls are intentianally aborted if they do not succeed within a certain time
          error.name !== 'AbortError' &&
          error.name !== 'ConditionError'
        ) {
          state.errors.push(error)
        }
      })
  },
})

export const {
  resetMaintenanceMode,
  resetAnalyticsMaintenanceMode,
  handleError,
  setError,
  clearErrors,
} = errorsSlice.actions
