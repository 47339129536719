// https://www.carlrippon.com/fetch-with-async-await-and-typescript/

import { SerializedError } from '@reduxjs/toolkit'
import { BASE_URL } from 'gatsby-env-variables'
import 'isomorphic-fetch'
import merge from 'lodash/merge'
import { getLocalStorageItem } from 'utils/localStorage'
import sleep from 'utils/sleep'
import { RuleAction } from 'utils/uniteRules'
import JsonBigInt from 'json-bigint'
const jsonBigInt = JsonBigInt({ storeAsString: true })

export interface Metadata {
  PK: string
  action: RuleAction
}
export interface ControlDError extends SerializedError {
  actions?: []
  metadata?: Metadata
  alertDuration?: number
}

export interface ApiResponse<T = unknown> {
  body: T
  error?: ControlDError
  success: boolean
  message?: string
}

export type EmptyBodyResponse = ApiResponse<[]>

export interface HttpResponse<T> extends Response {
  parsedBody?: ApiResponse<T>
}

export interface Headers {
  'content-type': string
  authorization: string
}

// get from env?
const baseUrl = BASE_URL

export const makeHeaders = (): Record<string, string> => {
  let authorization = ''
  const urlParams = new URLSearchParams(window.location.search)

  const subOrganization = urlParams.get('orgId') || ''
  // @ts-ignore
  if (window.store) {
    window.location.search
    // @ts-ignore
    authorization = window.store.getState().persistData?.sessionToken || ''
  }
  return {
    'content-type': 'application/json',
    authorization: authorization,
    ...(subOrganization ? { 'x-force-org-id': subOrganization } : {}),
  }
}

export async function http<T>(url: string, options?: RequestInit): Promise<ApiResponse<T>> {
  const response: HttpResponse<T> = await fetch(url, options)

  try {
    // may error if there is no body
    const textResponse = await response.text()

    /*
     * By default the json parser will convert this to a number where the least significant digits are converted
     * to 0. So 8937204016218791264 becomes 8937204016218791000. This could lead to crashes.
     * json-bigint package helps to resolve that issue.
     * */
    response.parsedBody = jsonBigInt.parse(textResponse)
  } catch (ex) {}

  if (!response?.parsedBody?.success) {
    throw {
      ...response?.parsedBody?.error,
      code: response?.parsedBody?.error?.code?.toString(),
      // this is hack to get the actions key into the error object
      stack: JSON.stringify({
        actions: response?.parsedBody?.error?.actions ?? [],
        alertDuration: response?.parsedBody?.error?.alertDuration || 10000,
        metadata: response?.parsedBody?.error?.metadata,
      }),
    }
  }

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.parsedBody
}

export async function httpWithAbortByTimeout<T>(
  url: string,
  options?: RequestInit,
  timeout = 3000,
): Promise<ApiResponse<T>> {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => {
    controller.abort()
  }, timeout)

  let response: ApiResponse<T>

  try {
    response = await http(url, { ...options, signal: controller.signal })
  } finally {
    clearTimeout(timeoutId)
  }

  return response
}

export async function getFile(
  path: string,
  args: RequestInit = {
    method: 'get',
  },
  nonStandardBaseUrl?: string,
): Promise<Response> {
  const response = await fetch(
    (nonStandardBaseUrl ?? baseUrl) + path,
    merge(args, {
      headers: makeHeaders(),
    }),
  )
  return response
}

export async function get<T>(
  path: string,
  args: RequestInit = {
    method: 'get',
  },
  nonStandardBaseUrl?: string,
): Promise<ApiResponse<T>> {
  const delay = getLocalStorageItem('readDelay') ?? 0
  await sleep(+delay)

  const url = nonStandardBaseUrl ?? baseUrl

  return await http<T>(
    url + path,
    merge(args, {
      headers: makeHeaders(),
    }),
  )
}

export async function post<T>(
  path: string,
  body?: unknown,
  nonStandardBaseUrl?: string,
  args: RequestInit = {
    method: 'post',
    body: JSON.stringify(body),
  },
): Promise<ApiResponse<T>> {
  const delay = getLocalStorageItem('readDelay') ?? 0
  await sleep(+delay)

  const url = nonStandardBaseUrl ?? baseUrl

  return await http<T>(url + path, merge(args, { headers: makeHeaders() }))
}

export async function put<T>(
  path: string,
  body?: unknown,
  nonStandardBaseUrl?: string,
  args: RequestInit = {
    method: 'put',
    body: JSON.stringify(body),
  },
): Promise<ApiResponse<T>> {
  const delay = getLocalStorageItem('readDelay') ?? 0
  await sleep(+delay)
  const url = nonStandardBaseUrl ?? baseUrl

  return await http<T>(url + path, merge(args, { headers: makeHeaders() }))
}

export async function del<T>(
  path: string,
  body?: unknown,
  args: RequestInit = {
    method: 'delete',
    body: JSON.stringify(body),
  },
): Promise<ApiResponse<T>> {
  const delay = getLocalStorageItem('readDelay') ?? 0
  await sleep(+delay)
  return await http<T>(baseUrl + path, merge(args, { headers: makeHeaders() }))
}
