import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { ApiResponse } from 'store/api/http'
import { EnabledStatus } from 'store/api/rules'

export enum FrequencyType {
  DAILY = 'd',
  WEEKLY = 'w',
  MONTHLY = 'm',
}

export const ValidFrequencyTypes: Record<FrequencyType, string> = {
  [FrequencyType.DAILY]: 'Daily',
  [FrequencyType.WEEKLY]: 'Weekly',
  [FrequencyType.MONTHLY]: 'Monthly',
}

export interface Report {
  org: string
  target_org: string
  report_template: string
  frequency: FrequencyType
  emails: string[]
  ts: number
  status: EnabledStatus
  PK: string
  frequency_printed: (typeof ValidFrequencyTypes)[FrequencyType]
  report_template_name: string
  logo_url?: string
}

export type GetReportsResponse = ApiResponse<{
  reports: Report[]
}>

export type GetReportsTemplatesResponse = ApiResponse<{
  templates: Record<string, string>
}>

export type GetReportsFrequenciesResponse = ApiResponse<{
  frequencies: Record<FrequencyType, (typeof ValidFrequencyTypes)[FrequencyType]>
}>

export interface PostReportsRequestBody {
  target_org: string
  report_template: string
  frequency: string
  emails: string[]
  logo_url?: string
}

export interface ReportsResponseData {
  report: Report
}

export type PostReportsResponse = ApiResponse<ReportsResponseData>

export interface PutReportsRequestBody extends PostReportsRequestBody {
  status: EnabledStatus
}

export const reportsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getReports: builder.query({
      query: () => getQueryArgs('/reports'),
      transformResponse: (response: GetReportsResponse) => response.body,
      transformErrorResponse,
      providesTags: ['Reports'],
    }),
    postReport: builder.mutation({
      query: ({ body }: { body: PostReportsRequestBody }) => getQueryArgs('/reports', 'POST', body),
      transformResponse: (response: PostReportsResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            reportsApi.util.updateQueryData('getReports', '', draft => {
              draft.reports = [data.report, ...draft.reports]
            }),
          )
        } catch {}
      },
    }),
    putReport: builder.mutation({
      query: ({ pk, body }: { pk: string; body: PutReportsRequestBody }) =>
        getQueryArgs(`/reports/${pk}`, 'PUT', body),
      transformResponse: (response: PostReportsResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            reportsApi.util.updateQueryData('getReports', '', draft => {
              const index = draft.reports.findIndex(d => d.PK === data.report.PK)
              draft.reports[index] = data.report
            }),
          )
        } catch {}
      },
    }),
    deleteReport: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/reports/${pk}`, 'DELETE'),
      transformResponse: (response: ApiResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted({ pk }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            reportsApi.util.updateQueryData('getReports', '', draft => {
              draft.reports = draft.reports.filter(m => m.PK !== pk)
            }),
          )
        } catch {}
      },
    }),
    getReportTemplates: builder.query({
      query: () => getQueryArgs('/reports/templates'),
      transformResponse: (response: GetReportsTemplatesResponse) => response.body,
      transformErrorResponse,
    }),
    getReportFrequencies: builder.query({
      query: () => getQueryArgs('/reports/frequencies'),
      transformResponse: (response: GetReportsFrequenciesResponse) => response.body,
      transformErrorResponse,
    }),
    sendReport: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/reports/${pk}/send`, 'PUT'),
      transformResponse: (response: ApiResponse) => response,
      transformErrorResponse,
    }),
  }),
})

export const {
  useGetReportsQuery,
  usePostReportMutation,
  usePutReportMutation,
  useDeleteReportMutation,
  useGetReportTemplatesQuery,
  useGetReportFrequenciesQuery,
  useSendReportMutation,
} = reportsApi
