import React, { useCallback } from 'react'
import { useAlertPresenter } from 'ui'
import { useAppDispatch } from 'store/hooks'
import { Text } from 'theme-ui'
import {
  TrackingEventAction,
  TrackingEventCategory,
  TrackingEventName,
  useTrackingHelper,
} from 'utils/useTrackingHelper'

import CautionIcon from 'images/error-msg-icon.svg'
import { sessionLogout } from 'store/session'
import { navigate } from '@reach/router'
import { confirmLogoutAlertId } from 'components/Dashboard/DashboardAlerts'

export default function usePresentConfirmLogoutAlert(): () => void {
  const { presentAlert, dismissAlert } = useAlertPresenter()
  const { trackEvent } = useTrackingHelper()
  const dispatch = useAppDispatch()

  return useCallback((): void => {
    presentAlert(
      {
        message: <Text data-testid="logout-message">Are you sure you want to log out?</Text>,
        icon: CautionIcon,
        buttons: [
          {
            onClick: async (): Promise<void> => {
              await dispatch(sessionLogout())
              trackEvent({
                category: TrackingEventCategory.Nav,
                action: TrackingEventAction.Account,
                name: TrackingEventName.Logout,
              })
              navigate('/login')
            },
            text: 'Yes',
          },
          {
            onClick: (): void => dismissAlert(confirmLogoutAlertId),
            text: 'No',
          },
        ],
      },
      confirmLogoutAlertId,
    )
  }, [dismissAlert, dispatch, presentAlert, trackEvent])
}
