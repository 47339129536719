import { Flex, Label, Text, ThemeUIStyleObject } from 'theme-ui'
import React, { ReactElement, useCallback } from 'react'
import { memoOmittingProps, Setter } from 'utils'
import range from 'lodash/range'
import { Checkbox } from 'ui'
import { Weekdays } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/Scheduling/Schedule.interface'
import { DayOfWeek } from 'store/api/schedules/schedules'

const MemoizedDaySelectCheckbox = memoOmittingProps(DaySelectCheckbox, ['toggleIsSelected'])

const days: DayOfWeek[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const fullDayNames: Record<DayOfWeek, string> = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
}

export default function DaySelectCheckboxes({
  selectedDays,
  setSelectedDays,
  sx,
}: {
  selectedDays: Weekdays
  setSelectedDays: Setter<Weekdays>
  sx?: ThemeUIStyleObject
}): ReactElement {
  const toggleIsCheckboxSelected = useCallback(
    (dayIndex: number) => {
      setSelectedDays(prevSelectedDays => {
        const day = days[dayIndex]
        return {
          ...prevSelectedDays,
          [day]: prevSelectedDays[day] ^ 1,
        }
      })
    },
    [setSelectedDays],
  )

  return (
    <Flex
      sx={{
        ...sx,
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {range(7).map(dayIndex => {
        return (
          <MemoizedDaySelectCheckbox
            key={dayIndex}
            day={days[dayIndex]}
            isSelected={!!selectedDays[days[dayIndex]]}
            toggleIsSelected={() => toggleIsCheckboxSelected(dayIndex)}
          />
        )
      })}
    </Flex>
  )
}

export function DaySelectCheckbox({
  customName,
  day,
  isSelected,
  toggleIsSelected,
}: {
  customName?: string
  day?: DayOfWeek
  isSelected: boolean
  toggleIsSelected: () => void
}): ReactElement {
  const dayName = customName || fullDayNames[day || 'mon']

  return (
    <Label
      sx={{
        height: '1.9rem',
        width: '100%',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        mb: '1.2rem',
        '& > label': {
          width: 'fit-content',
        },
      }}
      data-testid={`${dayName.toLowerCase()}-checkboxrow`}
    >
      <Text
        variant="size15Weight400"
        sx={{
          color: 'aliceBlue',
        }}
      >
        {dayName}
      </Text>
      <Checkbox
        onClick={toggleIsSelected}
        isChecked={isSelected}
        data-testid={`${dayName.toLowerCase()}-checkbox`}
      />
    </Label>
  )
}
