import React, { ReactElement } from 'react'
import FocusLock from 'react-focus-lock'
import { Flex } from 'theme-ui'
import { NavMenuProps } from 'components/Dashboard/NavBar/NavMenu.interface'
import TrayWithCloseButton from 'ui/TrayWithCloseButton'
import LogoMenu from './NavBarHeader/LogoMenu'
import NavBarBottomContent from './NavBarBottomContent'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setIsMobileNavMenuOpen } from 'store/dashboard/dashboard'

const NavMenuMobile = (props: NavMenuProps): ReactElement => {
  const { children } = props
  const dispatch = useAppDispatch()
  const isMobileNavMenuOpen = useAppSelector(s => s.dashboard.isMobileNavMenuOpen)

  return (
    <FocusLock autoFocus={false} disabled={!isMobileNavMenuOpen}>
      <TrayWithCloseButton
        dismiss={() => dispatch(setIsMobileNavMenuOpen?.(false))}
        sx={{
          height: '100%',
          width: '100%',
          zIndex: 'zIndex250',
          opacity: isMobileNavMenuOpen ? 1 : 0,
          transition: 'all 0.3s ease-in-out',
          overflowY: 'auto',
        }}
        slideDimensionLength="auto"
        disableFocusLock
        dataTestId="nav-menu-mobile"
        rightHeaderContent={<NavBarBottomContent />}
        headerContent={
          <Flex
            sx={{ width: '100%', justifyContent: 'center', position: 'relative', flexShrink: 0 }}
          >
            <LogoMenu />
          </Flex>
        }
      >
        {children}
      </TrayWithCloseButton>
    </FocusLock>
  )
}

export default NavMenuMobile
