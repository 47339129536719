import { useCallback, useMemo } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { stringify, parse } from 'query-string'
import { FeaturedProfileName } from 'components/Plans/FeaturedProfilesDropdown'
import { TrayOption } from 'ui/Tray/Tray.interface'
import { PlansSteps, PlansType } from 'components/Plans/usePlanWizardSteps'
import {
  SetupGuideIntermediateStates,
  SetupGuideMode,
  SetupOs,
} from 'components/SetupGuide/SetupGuide.interface'
import { TutorialType } from 'store/tutorial/tutorial'
import { ResolverKey } from 'components/FreeDNSPage/constants/freeDNS'
import { DnsServers } from 'constants/dnsServers'
import { SubOrgDialogType } from 'components/Organization/SubOrganizations'
import { EnabledStatus } from 'store/api/rules'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { DialogType } from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog'
import { BillingDialogType } from 'components/Organization/OrganizationBilling/ModalDialogs/BillingModalDialog'
import { ReportsDialogType } from 'components/Organization/Reports'
import { FrequencyType } from 'store/api/reports'

export type LocationSearch = {
  freeResolverType?: ResolverKey | keyof DnsServers | string
  helpPane?: SetupGuideIntermediateStates | SetupOs
  setupOs?: SetupOs
  step?: string | PlansSteps //step is part of the querystring and is always a string
  prevStep?: PlansSteps
  deviceName?: string
  type?: PlansType
  tray?: TrayOption
  redirectTo?: string
  featuredProfile?: FeaturedProfileName
  cid?: string
  id?: string
  tutorial?: TutorialType
  slide?: number
  mode?: SetupGuideMode
  noRedirect?: boolean
  fullControl?: boolean
  resolverUid?: string // provisioning
  code?: string // provisioning
  device_name?: string // provisioning
  deviceDialog?: string
  provisionDialog?: string
  reportsDialog?: ReportsDialogType
  platform?: string
  deviceId?: string //device pk for tutorial
  profileDialog?: string
  parentDeviceId?: string //parent device id of client
  clientId?: string //client id of client
  ctrldDeviceId?: string //ctrld device id
  subOrgDialog?: SubOrgDialogType //sub-organization dialog
  memberModal?: string
  orgId?: string // organization id being edited
  legacyIpv4Status?: EnabledStatus //device legacy ipv4 status
  learnIp?: EnabledStatus //device learn ip status
  stats?: StatLevel //device stats level
  billingDialog?: BillingDialogType
  rs?: string // reseller
  ruleDialog?: DialogType
  barry?: string //waking up or starting barry
  reportFrequency?: FrequencyType // report frequency
  domain?: string // custom rules domain

  //deprecated. only used for redirects
  /** @deprecated do not use */
  overlay?: string
  /** @deprecated do not use */
  tab?: string
  /** @deprecated do not use */
  option?: string
}
export type Nav = (
  search: LocationSearch,
  options?: {
    shouldReplaceHistoryEntry?: boolean
    shouldMaintainState?: boolean
    shouldLeaveQueryString?: boolean
  },
) => void
interface UseQueryString {
  nav: Nav
  qs: LocationSearch
  relocate: (pathName: string) => void
  setupGuideNav: (search: LocationSearch, shouldMaintainState?: boolean) => void
}
/*
 *  useQueryString always re-renders all components it is in and child components when the query params are changed.
 *  We should avoid using this hook in wrapper or container components which contain many other components.
 *  It's best to always use this hook only for small components and don't move this hook to the top level.
 * */
export default function useQueryString(): UseQueryString {
  const { state, search, pathname } = useLocation()

  const qs = useMemo(() => parse(search) as LocationSearch, [search])
  const nav = useCallback(
    (
      search: LocationSearch,
      options?: {
        shouldReplaceHistoryEntry?: boolean
        shouldMaintainState?: boolean
        shouldLeaveQueryString?: boolean
      },
    ): void => {
      const {
        shouldReplaceHistoryEntry = false,
        shouldMaintainState = false,
        shouldLeaveQueryString = false,
      } = options ?? {}

      let localState
      if (state && shouldMaintainState) {
        localState = state
      }

      // Avoiding to inject "?" if no search parameters was passed.
      const to = Object.keys(search).length
        ? `${pathname}?${stringify({
            ...(shouldLeaveQueryString ? { ...qs, ...search } : search),
          })}`
        : pathname
      navigate(to, {
        replace: shouldReplaceHistoryEntry,
        state: localState,
      })
    },
    [qs, pathname, state],
  )

  const relocate = useCallback((pathName: string): void => {
    navigate(`${pathName}${location.search}`)
  }, [])
  // preserves the `freeResolverType` query parameter (used for opening help pane
  // for free resolvers on homepage), if present
  const setupGuideNav = useCallback(
    (params?: LocationSearch, shouldMaintainState = false): void => {
      const { freeResolverType, resolverUid, orgId } = qs
      nav(
        {
          freeResolverType,
          resolverUid,
          orgId,
          ...params,
        },
        { shouldMaintainState },
      )
    },
    [nav, qs],
  )

  return { nav, qs, relocate, setupGuideNav }
}
