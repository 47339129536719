import { PlansSteps } from 'components/Plans/usePlanWizardSteps'
import { useAppSelector } from 'store/hooks'

export const plansFullControlPath = `/plans?step=${PlansSteps.CREATE_ACCOUNT}&fullControl=true`

export default function useCreateOrManageAccountUri(plansFeaturedProfile = ''): string {
  const isAuthenticated = useAppSelector(s => !!s.persistData.sessionToken)
  return isAuthenticated
    ? '/dashboard/profiles'
    : `${plansFullControlPath}${
        plansFeaturedProfile ? `&featuredProfile=${plansFeaturedProfile}` : ''
      }`
}
