export default {
  primary: {
    borderCollapse: 'collapse',
    width: '100%',
    maxWidth: '100%',
  },
  row: {
    height: '64px',
    color: 'white',
  },
}
