import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialResponseState, ResponseState } from 'store/fetchingLogic'
import { endActivityLogSession } from '../activityLog'
import { RootState } from 'store/rootReducer'
import { devicesApi } from 'store/api/devices'
import { PreAuthData, UserData } from 'store/api/user/user.interface'
import { baseApi } from 'store/api'
import { setSessionToken } from 'store/persist'

export const sessionLogout = createAsyncThunk(
  'session/logout',
  async (
    { manuallyLoggedOut = true }: { manuallyLoggedOut?: boolean } | undefined = {},
    { dispatch, getState },
  ) => {
    const state = getState() as RootState

    dispatch(setSessionToken(''))
    await dispatch(baseApi.util.resetApiState())
    await dispatch(endActivityLogSession())

    return { manuallyLoggedOut, PK: state.session.data?.PK }
  },
)

export interface Session extends ResponseState<UserData> {
  manuallyLoggedOut: boolean
  userPk?: string
  isOrganization?: boolean
  email?: string
  region?: string
  preAuthResponse?: PreAuthData
  isOnboardingCompleted?: boolean
}

function setInitialState(): Session {
  return {
    ...initialResponseState,
    manuallyLoggedOut: false,
    isOnboardingCompleted: undefined,
    userPk: '',
    email: undefined,
    isOrganization: undefined,
  }
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState: setInitialState(),
  reducers: {
    clearCaptcha(state): void {
      state.preAuthResponse = undefined
      state.error = undefined
    },
    clearError(state): void {
      state.error = undefined
    },
    resetNewIp(state): void {
      if (!!state.data) {
        state.data.debug.new_ip = undefined
      }
    },
    setIsOnboardingCompleted(state, action: PayloadAction<boolean>): void {
      state.isOnboardingCompleted = action.payload
    },
    setManuallyLoggedOut(state, action: PayloadAction<boolean>): void {
      state.manuallyLoggedOut = action.payload
    },
    setCaptcha(state, action: PayloadAction<PreAuthData>): void {
      state.preAuthResponse = action.payload
    },
    setSessionUpdate(state, action: PayloadAction<UserData & { isPolling?: boolean }>): void {
      const { payload } = action

      if (payload.isPolling && !!payload.org?.PK && !payload.email_status) {
        return
      }

      // these are persisted
      state.userPk = payload?.PK
      state.email = payload?.email
      state.isOrganization = !!payload?.org
      state.region = payload.org?.stats_endpoint || payload.stats_endpoint
    },
    // this needs to be called from PUT organization each time the stats_endpoint is updated
    setRegion(state, action: PayloadAction<string>): void {
      state.region = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, (_, action) => {
        return {
          ...setInitialState(),
          manuallyLoggedOut: action.payload.manuallyLoggedOut,
        }
      })
      .addMatcher(devicesApi.endpoints.getDevices.matchFulfilled, (state, action) => {
        state.isOnboardingCompleted = action.payload.devices.some(device => device.status)
      })
  },
})

export const {
  setManuallyLoggedOut,
  clearCaptcha,
  clearError,
  resetNewIp,
  setIsOnboardingCompleted,
  setSessionUpdate,
  setCaptcha,
  setRegion,
} = sessionSlice.actions
