import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import {
  defaultErrorMessage,
  PaymentMethod,
  setPriceInterval,
  TransactionStatus,
  updateTransaction,
} from 'store/billing'
import omit from 'lodash/omit'
import { CommonPriceInterval, ProductType } from 'store/api/billing/payments.interface'

export enum PlansSteps {
  SELECT_PLAN = 'plans',
  CREATE_ACCOUNT = 'account',
  FINISH = 'finish',
  SUCCESS = 'success',
}

export enum PlansType {
  SELECT = 'select',
  UPGRADE = 'upgrade',
  CHANGE = 'change',
}

export const usePlanWizardSteps = (): {
  onNextClick: (currentStep: PlansSteps) => void
  onPreviousClick: () => void
  progressBarStepNames?: string[]
} => {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const currentProduct = useAppSelector(s => s.products.currentProduct)
  const [progressBarStepNames, setProgressBarStepNames] = useState<PlansSteps[] | undefined>()
  const { qs, nav } = useQueryString()
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    // page should scroll to top when qs is changed
    scrollTo(0, 0)

    if (
      sessionToken &&
      ((currentProduct && currentProduct?.name !== ProductType.NONE) ||
        qs.step === PlansSteps.SELECT_PLAN ||
        !!qs.type) &&
      !qs.prevStep
    ) {
      setProgressBarStepNames([PlansSteps.SELECT_PLAN, PlansSteps.FINISH])
    } else {
      setProgressBarStepNames([
        PlansSteps.SELECT_PLAN,
        PlansSteps.CREATE_ACCOUNT,
        PlansSteps.FINISH,
      ])
    }
  }, [currentProduct, qs.step, qs.type, sessionToken, qs.prevStep])
  // We can't test for this directly in payments, since Coingate never communicates success or
  // failure directly to the client. Instead, the API supplies a redirect URL to Coingate on
  // success and failure and we check here for a query string parameter in that url
  // https://gitlab.int.windscribe.com/controld/systems/backend/api/-/merge_requests/870
  useEffect(() => {
    if (qs['cg-success'] === 'true') {
      nav({ step: PlansSteps.SUCCESS })
      return
    }

    if (qs['cg-success'] === 'false') {
      nav({ step: PlansSteps.FINISH })
      dispatch(
        updateTransaction({
          transactionStatus: TransactionStatus.FAILURE,
          errorMessage: defaultErrorMessage,
          paymentMethod: PaymentMethod.CRYPTO,
        }),
      )
      dispatch(setPriceInterval(CommonPriceInterval.YEARLY))
      return
    }

    sessionToken && !qs.type && (qs.type = PlansType.SELECT)

    if (!qs.step && !qs['cg-success']) {
      nav({ ...qs, step: PlansSteps.SELECT_PLAN }, { shouldReplaceHistoryEntry: true })
    }
    if (qs.step === PlansSteps.CREATE_ACCOUNT && sessionToken) {
      nav(
        { ...omit(qs, 'type'), step: PlansSteps.FINISH, prevStep: PlansSteps.SELECT_PLAN },
        { shouldReplaceHistoryEntry: true },
      )
    }
  }, [dispatch, nav, qs, sessionToken])

  const onNextClick = useCallback(
    (currentStep: PlansSteps) => {
      let nextStep = currentStep
      switch (currentStep) {
        case PlansSteps.CREATE_ACCOUNT:
          nextStep = PlansSteps.FINISH
          break
        case PlansSteps.FINISH:
          nextStep = PlansSteps.SUCCESS
          break
        case PlansSteps.SELECT_PLAN:
          nextStep = sessionToken ? PlansSteps.FINISH : PlansSteps.CREATE_ACCOUNT
          break
        default:
      }

      nav({
        ...qs,
        step: nextStep,
      })
    },
    [nav, qs, sessionToken],
  )
  const onPreviousClick = useCallback(() => {
    nav({ ...qs, step: PlansSteps.SELECT_PLAN })
  }, [nav, qs])

  return {
    onPreviousClick,
    onNextClick,
    progressBarStepNames,
  }
}
