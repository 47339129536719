import { useAppSelector } from 'store/hooks'
import { useGetUserQuery } from 'store/api/user'
import { UserData } from 'store/api/user/user.interface'

// it helps to get rid of bugs associated with an unexpected useGetUserQuery call
// useGetUserQuery should always depend on sessionToken
export default function useGetUser(options?: {
  skip?: boolean
  refetchOnMountOrArgChange?: boolean
}): {
  data: UserData | undefined
  isLoading: boolean
  isFetching: boolean
  isError: boolean
} {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data, isLoading, isFetching, isError } = useGetUserQuery('', {
    skip: options ? options?.skip : !sessionToken,
    refetchOnMountOrArgChange: options?.refetchOnMountOrArgChange,
  })

  return {
    data,
    isLoading,
    isFetching,
    isError,
  }
}
