import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Currency, defaultCurrencyCode, PromoMode } from 'store/api/billing'
import { initialResponseState, LoadingState, ResponseState } from '../fetchingLogic'
import getPriceDetailsForCurrency from 'utils/getPriceDetailsForCurrency'
import { sessionLogout } from 'store/session'
import { productsApi } from 'store/api/billing/products'
import { BillingProduct, Product, ProductType } from 'store/api/billing/payments.interface'
import { ApiResponse } from 'store/api/http'
import { userApi } from 'store/api/user'

export type PaymentError = {
  message: string
  code: number
  actions: {
    name: string
    path: string
  }[]
}

interface ProductsState extends ResponseState<Product[]> {
  // the PK of the product selected
  selectedProductPk?: string
  // the number of months selected, 0 for Trial. corresponds to the duration of the price_point selected
  priceInterval: number
  /**
   * To get the selected price, first get the selected product using the selectedProductPk
   * and then get the price point that matches the priceInterval
   */
  // promo mode - either promo code or ws username
  promoMode?: PromoMode
  // actual promo code or the username
  promoCode?: string
  // the currently billed product. generally No Control at Sign up and the existing product during Change Plan
  currentProduct?: BillingProduct
  currencies: Currency[]
  selectedCurrency?: Currency // when undefined USD
  warningError?: PaymentError
}
const initialState: ProductsState = {
  ...initialResponseState,
  priceInterval: 0,
  promoMode: PromoMode.PROMO,
  currencies: [],
}

export const productsSlice = createSlice({
  name: 'products',
  initialState: { ...initialState },
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    setPriceInterval(state, action: PayloadAction<number>): void {
      state.priceInterval = action.payload
    },
    setSelectedProductPk(state, action: PayloadAction<string>): void {
      state.selectedProductPk = action.payload
    },
    setSelectedCurrency(state, action: PayloadAction<Currency | undefined>): void {
      const pp = state.data?.[0].price_points[0]
      if (pp && getPriceDetailsForCurrency(pp, action.payload?.code ?? defaultCurrencyCode).price) {
        state.selectedCurrency = action.payload
      }
    },
    setPromoMode(state, action: PayloadAction<PromoMode>): void {
      state.promoMode = action.payload
    },
    setPromoCode(state, action: PayloadAction<string | undefined>): void {
      state.promoCode = action.payload
    },
    resetPromo(state): void {
      state.promoCode = undefined
      state.promoMode = undefined
      state.error = undefined
    },
    resetLoadingState(state): void {
      state.loading = LoadingState.IDLE
    },
    resetProductsState() {
      return { ...initialState }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, () => {
        return { ...initialState }
      })
      .addMatcher(userApi.endpoints.deleteUser.matchFulfilled, () => {
        return { ...initialState }
      })
      .addMatcher(productsApi.endpoints.getBillingProducts.matchFulfilled, (state, action) => {
        if (action.payload.error) {
          state.warningError = action.payload.error
        }

        if (action.payload.products[0].subscription?.currency) {
          state.selectedCurrency = state.currencies.find(
            c => c.code === action.payload.products[0].subscription?.currency,
          )
        }
        const currentProduct = action.payload.products[0]
        state.currentProduct = currentProduct
        const currentProductType = currentProduct.name as ProductType
        if (
          !state.selectedProductPk &&
          (currentProductType === ProductType.FULL || currentProductType === ProductType.SOME)
        ) {
          state.selectedProductPk = currentProduct.PK
        }
        // the billing products call should only return the price point which was billed or none in case of No Control
        if (currentProduct?.price) {
          state.priceInterval = currentProduct.price.duration
        }
      })
      .addMatcher(productsApi.endpoints.getProducts.matchFulfilled, (state, action) => {
        state.loading = LoadingState.FULFILLED
        const { products, currencies } = action.payload

        state.promoCode = action.meta.arg.originalArgs.promoCode
        action.meta.arg.originalArgs.promoMode &&
          (state.promoMode = action.meta.arg.originalArgs.promoMode)
        state.data = products
        state.currencies = currencies
        // fall back to USD if the price points do not have the selected currency data
        if (
          state.selectedCurrency &&
          !products.every(product =>
            product.price_points.every(
              pp => !!getPriceDetailsForCurrency(pp, state.selectedCurrency?.code).price,
            ),
          )
        ) {
          state.selectedCurrency = undefined
        }
        if (state.selectedProductPk && state.priceInterval) {
          // update the price interval to match the duration returned after applying promo code
          let selectedProduct = products.find(p => p.PK === state.selectedProductPk)

          if (!selectedProduct) {
            // product has changed
            state.selectedProductPk = products[0].PK
            selectedProduct = products[0]
          }
          const selectedPrice = selectedProduct?.price_points?.find?.(
            pp => pp.duration === state.priceInterval,
          )
          if (!selectedPrice) {
            // duration has changed
            state.priceInterval = selectedProduct?.price_points?.[0]?.duration
          }
        }
      })
      .addMatcher(productsApi.endpoints.getProducts.matchRejected, (state, action) => {
        state.error = (action.payload?.data as ApiResponse)?.error || action.error
      })
  },
})

export const {
  setPriceInterval,
  setSelectedProductPk,
  setPromoMode,
  setPromoCode,
  resetPromo,
  resetProductsState,
  resetLoadingState,
  clearError,
  setSelectedCurrency,
} = productsSlice.actions
