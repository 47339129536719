import React, { ReactElement, ReactNode } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export default function ColumnContainer({
  children,
  sx,
  dataTestId,
}: {
  children: ReactNode | ReactElement[]
  sx?: ThemeUIStyleObject
  dataTestId?: string
}): ReactElement {
  return (
    <Flex data-testid={dataTestId} sx={{ flexDirection: 'row', ...sx }}>
      {children}
    </Flex>
  )
}
