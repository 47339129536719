export default {
  primary: {
    color: 'white',
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  top: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    zIndex: 1,
    height: '8px',
    '::before': {
      position: 'absolute',
      content: '""',
      width: '50%',
      top: 0,
      left: 0,
      height: '8px',
      transform: 'skewX(-60deg)',
      transformOrigin: 'left bottom',
      zIndex: ({ zIndices }): void => zIndices.hidden,
    },
    '::after': {
      position: 'absolute',
      content: '""',
      width: '50%',
      top: 0,
      right: 0,
      height: '8px',
      transform: 'skewX(60deg)',
      transformOrigin: 'right bottom',
      zIndex: ({ zIndices }): void => zIndices.hidden,
    },
  },
  table: {
    padding: 0,
    overflowX: 'auto',
    backgroundColor: 'black',
  },
}
