import React, { ReactElement, Reducer, useEffect, useReducer, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import ProfileSelectionDropDown, {
  ProfileDropDownItem,
} from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import DeviceTypeDropdown from './DeviceTypeDropdown'
import ExpiresDropdown, { ExpiresType } from './ExpiresDropdown'
import { Input, useCustomAlerts } from 'ui'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import InfoIcon from 'images/dashboard/info-icon.svg'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import {
  ActionType,
  initialState,
  InitialStateType,
  ProvisionActionType,
  provisionReducer,
} from './ProvisionState'
import {
  CreateProvisionRequestBody,
  PostProvisionResponse,
  ProvisionResponseData,
  useCreateProvisionMutation,
} from 'store/api/provision'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import moment from 'moment'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import useQueryString from 'utils/useQueryString'
import { ProvisionDialogType } from 'components/Organization/Provision'
import { setSelectProvisionPk } from 'store/organization'
import { useAppDispatch } from 'store/hooks'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { useEditOrganizationMutation } from 'store/api/organization'
import useGetUserState from 'store/api/user/useGetUserState'
import { EnabledStatus } from 'store/api/rules'
import ProvisionSettings from './ProvisionSettings'
import { isDeactivationPinLengthValid } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/AddOrEditDevice/helpers'
import { modalFooterRef } from 'ui/NewModalDialog/ModalDialogFooter'
import { createPortal } from 'react-dom'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'
import SelectRegionWarningMessage from 'components/SelectRegionWarningMessage'

const payloadProperties = ['icon', 'max', 'profile_id', 'stats', 'name_prefix', 'deactivation_pin']

export function numericalMask(value: string): string {
  return value.replace(/\D/g, '')
}

function prefixNameMask(value: string): string {
  return value.replace(/\W/g, '-')
}

const getRequestPayloadFromState = (state: InitialStateType) => {
  return {
    // create an object that contains only truthy values so that unmodified values are not included in the request payload
    ...pickBy(
      { ...pick(state, ...payloadProperties) },
      value => isString(value) || isNumber(value),
    ),
  }
}

const getRequestExpTs = label => {
  switch (label) {
    case ExpiresType.HOUR:
      return Math.floor(moment().add(1, 'hour').valueOf() / 1000)
    case ExpiresType.DAY:
      return Math.floor(moment().add(1, 'day').valueOf() / 1000)
    case ExpiresType.WEEK:
      return Math.floor(moment().add(1, 'week').valueOf() / 1000)
    case ExpiresType.MONTH:
      return Math.floor(moment().add(1, 'month').valueOf() / 1000)
    case ExpiresType.YEAR:
      return Math.floor(moment().add(1, 'year').valueOf() / 1000)
    case ExpiresType.NEVER:
      return 0
    default:
      return 0
  }
}

export function AddProvisionView({ dismiss }: { dismiss?: () => void }): ReactElement {
  const { qs, nav } = useQueryString()
  const dispatch = useAppDispatch()
  const { data: orgData } = useGetOrganization()
  const [createProvision, { isLoading }] = useCreateProvisionMutation()
  const { region: sessionRegion } = useGetUserState()
  const [editOrganization] = useEditOrganizationMutation()
  const [provisionState, provisionDispatch] = useReducer<Reducer<InitialStateType, ActionType>>(
    provisionReducer,
    {
      ...initialState,
    } as InitialStateType,
  )
  const shouldShowWarningMessage =
    !sessionRegion &&
    (provisionState.stats === StatLevel.FULL || provisionState.stats === StatLevel.SOME)

  const { presentCautionOkAlert } = useCustomAlerts()
  const isDeactivationPinInvalid =
    provisionState.deactivationStatus === EnabledStatus.ENABLED &&
    !isDeactivationPinLengthValid(provisionState.deactivation_pin)

  return (
    <Flex
      sx={{
        width: ['100%', '45.2rem'],
        pt: shouldShowWarningMessage ? 0 : '1.2rem',
        gap: '1.2rem',
        flexDirection: 'column',
        maxHeight: [`calc(100% - ${trayHeaderHeight})`, '100%', '100%'],
        overflowY: 'auto',
      }}
      className="show-scrollbar"
    >
      {shouldShowWarningMessage && <SelectRegionWarningMessage />}
      <Flex
        sx={{
          px: '1.6rem',
          flexDirection: 'column',
          gap: '1.2rem',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            gap: '1.6rem',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <DeviceTypeDropdown
            selectedType={provisionState.icon}
            setSelectedType={type => {
              provisionDispatch({ type: ProvisionActionType.DEVICE_TYPE, payload: type })
            }}
          />
          <ProfileSelectionDropDown
            globalProfilePk={orgData?.organization?.parent_profile?.PK}
            label="Enforced Profile"
            selectedProfile={provisionState.selectedProfile}
            setSelectedProfile={profile => {
              provisionDispatch({
                type: ProvisionActionType.SELECTED_PROFILE,
                payload: profile as ProfileDropDownItem | undefined,
              })
            }}
            description=""
            tooltipText="The Profile whose rules you want to enforce on this Endpoint"
            sx={{
              width: '100%',
              flex: 1,
              flexShrink: 0,
              p: 0,
            }}
            boundaryElementTestId="add-provision-dialog"
          />
        </Flex>
        <Flex
          sx={{
            width: '100%',
            gap: '1.6rem',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: ['column', 'row'],
          }}
        >
          <ExpiresDropdown
            selectedDuration={provisionState.expired}
            setSelectedDuration={duration => {
              provisionDispatch({ type: ProvisionActionType.EXPIRED, payload: duration })
            }}
            tooltipText="Provisioning Code will be invalid after this time."
          />
          <Input
            isCompact
            name="limit-input"
            data-testid="limit-input"
            aria-label="limit input"
            label="Limit"
            value={provisionState.max}
            sxWrapper={{
              flex: 1,
            }}
            rightContent={
              <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
                <IconWithTippyPopup
                  placement="top"
                  content={
                    <Flex
                      sx={{
                        alignSelf: 'center',
                        width: '15.6rem',
                        textAlign: 'center',
                        fontSize: '1.2rem',
                      }}
                    >
                      Number of times this Provisioning Code can be used.
                    </Flex>
                  }
                  ariaLabel="Number of times this Provisioning Code can be used."
                  svg={InfoIcon}
                  fill="aliceBlue60"
                  sx={{
                    width: '1.2rem',
                    height: '1.2rem',
                  }}
                />
              </Flex>
            }
            onChange={(event): void => {
              provisionDispatch({
                type: ProvisionActionType.LIMIT,
                payload: +numericalMask(event.target.value),
              })
            }}
          />
        </Flex>
        <Input
          isCompact
          name="device-prefix-input"
          data-testid="device-prefix-input"
          aria-label="device prefix input"
          label="Endpoint Name Prefix"
          value={provisionState.name_prefix}
          rightContent={
            <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
              <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
                Optional
              </Text>
              <IconWithTippyPopup
                placement="top"
                content={
                  <Flex
                    sx={{
                      alignSelf: 'center',
                      width: '15.6rem',
                      textAlign: 'center',
                      fontSize: '1.2rem',
                    }}
                  >
                    Prefix for the name of all Endpoints created with this Provisioning Code (valid
                    characters: a-z, 0-9, -)
                  </Flex>
                }
                ariaLabel="Prefix for the name of all Endpoints created with this Provisioning Code (valid
                    characters: a-z, 0-9, -)"
                svg={InfoIcon}
                fill="aliceBlue60"
                sx={{
                  width: '1.2rem',
                  height: '1.2rem',
                }}
              />
            </Flex>
          }
          onChange={(event): void => {
            provisionDispatch({
              type: ProvisionActionType.NAME_PREFIX,
              payload: prefixNameMask(event.target.value),
            })
          }}
        />
      </Flex>
      <ProvisionSettings provisionState={provisionState} provisionDispatch={provisionDispatch} />
      <CreateCodeButton
        isButtonDisabled={
          !provisionState.selectedProfile ||
          !provisionState.expired ||
          !provisionState.icon ||
          !provisionState.max ||
          isDeactivationPinInvalid ||
          shouldShowWarningMessage
        }
        testId="add-provision-code-button"
        ariaLabel="add provision code button"
        isLoading={isLoading}
        onClick={async () => {
          const isAnalyticsInvalid = !(
            provisionState.stats === StatLevel.NO ||
            sessionRegion ||
            provisionState.regionSettings
          )

          if (isAnalyticsInvalid) {
            presentCautionOkAlert('Please select a valid analytics region.')
            return
          }

          const requestDevice = {
            ...getRequestPayloadFromState(provisionState),
          } as unknown as CreateProvisionRequestBody

          requestDevice.ts_exp = getRequestExpTs(provisionState?.expired?.value || ExpiresType.DAY)

          const response = (await createProvision({
            body: requestDevice,
          })) as PostProvisionResponse & {
            data: ProvisionResponseData
          }

          if (provisionState.regionSettings && !qs.clientId) {
            editOrganization(provisionState.regionSettings)
          }

          if (!response.error) {
            dismiss?.()
            dispatch(setSelectProvisionPk(response.data.provision.PK))
            nav({ ...qs, provisionDialog: ProvisionDialogType.INSTRUCTIONS })
          }
        }}
      />
    </Flex>
  )
}

export function CreateCodeButton({
  isButtonDisabled,
  testId,
  ariaLabel,
  onClick,
  isLoading,
}: {
  isButtonDisabled: boolean
  testId?: string
  ariaLabel: string
  onClick?: () => void
  isLoading?: boolean
}): React.ReactPortal | null {
  const [shouldAppendButton, setShouldAppendButton] = useState(false)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current
    ? createPortal(
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonWithLoadingState
            isLoading={isLoading}
            variant="newPrimary"
            data-testid={testId}
            ariaLabel={ariaLabel}
            onClick={onClick}
            disabled={isButtonDisabled}
            sx={{
              width: ['100%', 'auto'],
              justifyContent: 'center',
              height: '3.2rem',
              alignItems: 'center',
              px: '1.6rem',
            }}
          >
            Create Code
          </ButtonWithLoadingState>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}
