import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import omit from 'lodash/omit'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import { Button } from 'ui'
import { setDeviceForEdit } from 'store/multiprofile'
import { DeviceDialogType } from 'components/Dashboard/Devices/DeviceModalDialog'
import { CONTROLDText } from 'ui/CONTROLD'

export default function OnlyLegacyDNS({
  selectedDevicePk,
}: {
  selectedDevicePk?: string
}): ReactElement {
  const isLoggedIn = useAppSelector(s => !!s.persistData.sessionToken)
  const dispatch = useAppDispatch()
  const { qs, nav } = useQueryString()
  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '4rem',
      }}
    >
      <Text
        variant="size14Weight400Line138"
        sx={{
          maxWidth: ['100%', '38rem'],
          textAlign: 'center',
          color: 'aliceBlue60',
          margin: 0,
        }}
      >
        This platform currently only supports Legacy DNS.
        {isLoggedIn
          ? ` Please add a Legacy Resolver to this Endpoint in order to proceed with this tutorial.`
          : ''}
        {!isLoggedIn && !!qs.resolverUid
          ? ` Please ask the person who sent you the ${CONTROLDText} provisioning link to add a Legacy DNS resolver to the Endpoint.`
          : ''}
      </Text>

      {isLoggedIn &&
        !qs.resolverUid && ( // should be logged in and not in provisioning flow to see the edit device button
          <Button
            variant="newSecondary"
            data-testid="edit-device-button"
            ariaLabel="edit device button"
            sx={{
              width: '18rem',
              height: '3.8rem',
            }}
            onClick={() => {
              dispatch(setDeviceForEdit(selectedDevicePk))
              nav({
                ...omit(qs, 'helpPane', 'setupOs', 'step'),
                deviceDialog: DeviceDialogType.EDIT,
              })
            }}
          >
            Edit Endpoint
          </Button>
        )}
    </Flex>
  )
}
