import { Currency } from 'store/api/billing'
/**
 * Use this method to format the price shown to the user using the currency they chose
 * @param selectedCurrency the currency for the price. can be undefined and will default to $
 * @param price the price that needs formatting. can be undefined and will default to 0
 * @param shouldIncludeCurrencyCode the flag that responsible for including currency code. Dollars need to be qualified by the currency code.
 * @returns formatted price with currency symbol & 2 deciemal places (no decimals for JPY)
 */
export default function getFormattedPrice(
  selectedCurrency?: Currency,
  price?: number,
  shouldIncludeCurrencyCode?: boolean,
  toFixedValue?: number,
): string {
  const currencySymbol = (selectedCurrency?.symbol || selectedCurrency?.code.toUpperCase()) ?? '$'
  const currencyCode =
    shouldIncludeCurrencyCode && currencySymbol === '$'
      ? ` (${selectedCurrency?.code?.toUpperCase() ?? 'USD'})`
      : ''
  const isYen = selectedCurrency?.code === 'jpy'
  const numberPrice = +(price ?? 0)
  const fixedPointPrice = (isNaN(numberPrice) ? 0 : numberPrice).toFixed(
    typeof toFixedValue === 'number' ? toFixedValue : isYen ? 0 : 2,
  )

  return `${currencySymbol}${fixedPointPrice}${currencyCode}`
}
