import React, { createContext, ReactElement, ReactNode, useContext } from 'react'
import { ScheduleItemData } from 'store/api/schedules'

export interface ScheduleState {
  existingSchedule?: ScheduleItemData
}

const ScheduleContext = createContext<ScheduleState>({} as ScheduleState)

interface ScheduleContextProviderProps extends ScheduleState {
  children: ReactNode
}
export const ScheduleContextProvider = ({
  existingSchedule,
  children,
}: ScheduleContextProviderProps): ReactElement => {
  return (
    <ScheduleContext.Provider value={{ existingSchedule }}>{children}</ScheduleContext.Provider>
  )
}

export const useScheduleState = (): ScheduleState => useContext(ScheduleContext)
