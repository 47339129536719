import React, { ElementType, ReactChild, ReactElement } from 'react'
import LogFilterDropdown from 'ui/LogItemWrapper/LogFilterDropdown'
import { QueriesFilterMenuType, scrollingFiltersContainerRef } from '../FiltersContainer'
import { ExternalIcon } from 'components/Dashboard/ExternalSvgIcon'
import { useAppSelector } from 'store/hooks'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'

export const hideQueryFilterDropdown = {}

export default function QueryFilterDropdown({
  children,
  isDisabled,
  selectedValue,
  icon,
  externalIcon,
  type,
  testId,
}: {
  children: ReactChild | ReactChild[]
  isDisabled?: boolean
  selectedValue: string
  icon?: ElementType
  externalIcon?: ExternalIcon
  type?: QueriesFilterMenuType
  testId?: string
}): ReactElement | null {
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)

  useHideTooltipOnScroll(
    { current: hideQueryFilterDropdown[type || ''] },
    scrollingFiltersContainerRef,
  )

  return (
    <LogFilterDropdown
      testId={testId || `${type}-queries-filters-dropdown`}
      value={selectedValue}
      isValueSelected={!!filterParameters[type || ''] && filterParameters[type || ''] !== 'all'}
      icon={icon}
      externalIcon={externalIcon}
      isDisabled={isDisabled}
      onShow={() => {
        Object.values(hideQueryFilterDropdown)?.map(value => (value as () => void)?.())
      }}
      onCreate={instance => {
        if (type) {
          hideQueryFilterDropdown[type] = instance.hide
        }
      }}
      isLocation={type === QueriesFilterMenuType.LOCATIONS}
    >
      {children}
    </LogFilterDropdown>
  )
}
