import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { ApiResponse } from 'store/api/http'

export interface ProxyLocation {
  city: string
  country: string
  country_name: string
  uid: string
  PK: string
  hidden?: boolean
  gps_lat: number
  gps_long: number
  // search matches to handle showing matched places
  matchKey?: 'city' | 'country' | 'country_name'
  isSingle?: boolean // only used for services
}

export interface ProxiesData {
  proxies: ProxyLocation[]
}

export type GetProxiesResponse = ApiResponse<ProxiesData>

export const proxiesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProxies: builder.query({
      query: () => getQueryArgs('/proxies'),
      transformResponse: (response: GetProxiesResponse) => {
        response.body.proxies = response.body.proxies.map((location, _, allLocations) => ({
          ...location,
          isSingle: allLocations.filter(l => l.country === location.country).length === 1,
        }))

        return response.body
      },
      transformErrorResponse,
    }),
    // used only for status page where the expired auth token causes issues
    getProxiesNoAuth: builder.query({
      query: () => getQueryArgs('/proxies'),
      transformResponse: (response: GetProxiesResponse) => response.body,
      transformErrorResponse,
    }),
  }),
})

export const { endpoints, useGetProxiesQuery, useGetProxiesNoAuthQuery } = proxiesApi
