import React, { ForwardedRef, forwardRef, ReactElement, ReactNode, useEffect } from 'react'
import { Flex, Input as _Input, Label, Textarea, ThemeUIStyleObject } from 'theme-ui'
import { Svg } from 'ui'
import InputProps from './Input.interface'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import customUnescape from 'utils/customUnescape'
import SmallInfoIcon from 'images/dashboard/filters/small-filter-info-icon.svg'

let lines = 1

function limitLines(e): false | void {
  if (e.keyCode == 13) {
    if (lines == e.target.rows) {
      e.preventDefault()
      return false
    } else {
      lines++
    }
  }
}

export const inputLabelNewStyles = {
  mx: 0,
  color: 'aliceBlue',
  span: {
    color: 'aliceBlue60',
  },
  opacity: 0.7,
  fontSize: '1.2rem',
  fontWeight: 400,
}

export const inputDynamicNewStyles = ({
  isCompact,
  backgroundColor,
  activeLabelColor = 'aliceBlue60',
  backgroundColorOnFocus,
  isDesktop,
}: {
  isCompact?: boolean
  backgroundColor?: string
  activeLabelColor?: string
  backgroundColorOnFocus?: string
  isDesktop?: boolean
}): ThemeUIStyleObject => ({
  fontFamily: 'body',
  height: isCompact ? '3.8rem' : '4.8rem',
  backgroundColor: backgroundColor,
  '--theme-ui-input-autofill-bg': backgroundColor,
  '&:focus': {
    '& + div > label': {
      color: activeLabelColor,
      span: {
        color: activeLabelColor,
      },
    },
    backgroundColor: backgroundColorOnFocus || backgroundColor,
  },
  '&:focus-visible': !isDesktop
    ? {
        outline: 0,
      }
    : {},
})

const errorInputNewStyles = {
  borderColor: 'errorOpaque',
  '&:hover': {
    ':not([disabled])': {
      ':not(:focus)': {
        borderColor: 'errorOpaque',
      },
    },
  },
  '&:focus': {
    borderColor: 'errorOpaque',
  },
}

export default forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(function Input(
  props: InputProps,
  ref,
): React.ReactElement {
  const {
    label,
    labelColor,
    activeLabelColor,
    id,
    name = '',
    errorMessage = '',
    onChange = (): void => {},
    onBlur = (): void => {},
    onFocus = (): void => {},
    onPaste = (): void => {},
    variant = 'newPrimary',
    hasText = false,
    containerStyle = {},
    sxWrapper = {},
    backgroundColor = 'blue',
    backgroundColorOnFocus,
    sx,
    isTextarea,
    errorMessageStyles,
    value,
    rows = 10,
    rightContent,
    isCompact,
    maxLength,
    textAreaVariant = 'input.newPrimary',
    ...attrs
  } = props

  const isDesktop = useBreakpointIndex() === 2

  const labelId: string = id + '-label'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    onChange(e)

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    onBlur(e)

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    onFocus(e)

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = onPaste

  useEffect(() => {
    lines = `${value}`.split('\n').length

    return () => {
      lines = 1
    }
  }, [value])

  return (
    <Flex sx={{ width: '100%', flexDirection: 'column', ...sxWrapper }}>
      <Flex
        sx={{
          flexDirection: 'column-reverse',
          flexGrow: 1,
          ...containerStyle,
        }}
      >
        {!isTextarea ? (
          <_Input
            variant={`input.${variant}`}
            type="text"
            tabIndex={0}
            sx={{
              ...inputDynamicNewStyles({
                isCompact,
                backgroundColor,
                activeLabelColor,
                backgroundColorOnFocus,
                isDesktop,
              }),
              ...(errorMessage && errorInputNewStyles),
              ...sx,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onPaste={handlePaste}
            id={id}
            name={name}
            ref={ref as ForwardedRef<HTMLInputElement>}
            value={typeof value === 'string' ? customUnescape(value) : value}
            {...attrs}
          />
        ) : (
          <Textarea
            data-testid={attrs['data-testid']}
            className="hide-scrollbar"
            variant={textAreaVariant}
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
            id={id}
            name={name}
            value={value}
            placeholder={attrs.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onPaste={handlePaste}
            disabled={attrs.disabled}
            rows={rows}
            maxLength={maxLength}
            onKeyDown={limitLines}
            sx={{
              ...inputDynamicNewStyles({
                isCompact,
                backgroundColor,
                activeLabelColor,
                backgroundColorOnFocus,
                isDesktop,
              }),
              height: '10rem',
              ...(errorMessage && errorInputNewStyles),
              ...sx,
            }}
          />
        )}
        {label && (
          <InputLabel
            labelColor={labelColor}
            hasText={hasText}
            labelId={labelId}
            id={id}
            label={label}
            rightContent={rightContent}
          />
        )}
      </Flex>
      {errorMessage && (
        <ErrorMessage
          name={name}
          errorMessageStyles={errorMessageStyles}
          errorMessage={errorMessage}
        />
      )}
    </Flex>
  )
})

function InputLabel({
  labelColor,
  hasText,
  labelId,
  id,
  label,
  rightContent,
}: {
  labelColor?: string
  hasText?: boolean
  labelId?: string
  id?: string
  label?: string | ReactElement
  rightContent?: ReactNode
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        mb: '0.8rem',
        alignItems: 'center',
      }}
    >
      <Label
        sx={{
          width: 'fit-content',
          mx: 0,
          color: labelColor || (hasText ? 'aliceBlue' : 'aliceBlue60'),
          span: {
            color: labelColor || (hasText ? 'aliceBlue' : 'aliceBlue60'),
          },
          fontSize: '1.2rem',
          fontWeight: 400,
        }}
        id={labelId}
        htmlFor={id}
      >
        {label}
      </Label>
      {rightContent}
    </Flex>
  )
}

function ErrorMessage({
  name,
  errorMessageStyles,
  errorMessage,
}: {
  name?: string
  errorMessageStyles?: ThemeUIStyleObject
  errorMessage?: string
}) {
  return (
    <Flex
      aria-live="polite"
      color="errorOpaque"
      data-testid={`${name}-error-message`}
      sx={{
        alignItems: 'center',
        fontSize: '1.2rem',
        fontWeight: 400,
        mt: '0.8rem',
        ...errorMessageStyles,
      }}
    >
      <Svg
        svg={SmallInfoIcon}
        fill="errorOpaque"
        sx={{ mr: '0.4rem', flexShrink: 0, '& g': { opacity: 1 } }}
      />
      {errorMessage}
    </Flex>
  )
}
