import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialResponseState } from '../fetchingLogic'
import { PresetTimeRange } from 'ui/Analytics/PresetTimeRange'
import uniq from 'lodash/uniq'

function getArrayQueryParams(value, param) {
  return `${value
    .toString()
    ?.split(',')
    .reduce((acc, currentValue, currentIndex, array) => {
      if (currentValue) {
        return acc + `${param}[]=${currentValue}${array[currentIndex + 1] ? '&' : ''}`
      }
      return acc
    }, '')}`
}

export const getLogStringFromFilters = (
  queryFilters: LogFilters,
  presetTimeRange: PresetTimeRange,
): string => {
  const partialQueryFilters = Object.assign({}, queryFilters)

  if (presetTimeRange !== PresetTimeRange.CUSTOM) {
    partialQueryFilters.endTs = new Date().getTime()
  }

  // query string should contain only uniq values
  partialQueryFilters.externalRegion = uniq(queryFilters.externalRegion?.split(',')).join(',')

  const queryFiltersArray = Object.entries(partialQueryFilters)

  return queryFiltersArray
    .map(([key, value]) => {
      if (value && value !== 'all') {
        if (key === AdminLogsFilterMenuType.ORGANIZATIONS && value) {
          return getArrayQueryParams(value, AdminLogsFilterMenuType.ORGANIZATIONS)
        }
        if (key === AdminLogsFilterMenuType.EXTERNAL_REGION && value) {
          return getArrayQueryParams(value, AdminLogsFilterMenuType.EXTERNAL_REGION)
        }
        return `${key}=${value}`
      }
      return ''
    })
    .filter(queryString => queryString !== '')
    .join('&')
}

export enum AdminLogsFilterMenuType {
  ORGANIZATIONS = 'orgId',
  ADMINS = 'userId',
  ACTIONS = 'action',
  EXTERNAL_REGION = 'externalRegion',
}

export type LogFilters = {
  [key in AdminLogsFilterMenuType]?: string
} & {
  search?: string
  endTs: number
  startTs: number
  page?: number
  pageSize?: number
}

type AdminLogState = {
  logFilters: LogFilters
  selectedPresetTimeRange: PresetTimeRange
  stringFromFilters: string
  initialExternalRegionsParam: string // need this to add correct external regions when reset filter parameters
}
const adminLogState: AdminLogState = {
  logFilters: {
    [AdminLogsFilterMenuType.ORGANIZATIONS]: '',
    [AdminLogsFilterMenuType.ADMINS]: '',
    [AdminLogsFilterMenuType.ACTIONS]: '',
    endTs: 0,
    startTs: 0,
  },
  selectedPresetTimeRange: PresetTimeRange.LAST_DAY,
  stringFromFilters: '',
  initialExternalRegionsParam: '',
}

const initialState = {
  ...adminLogState,
}
export const adminLogSlice = createSlice({
  name: 'adminLog',
  initialState: {
    ...initialState,
    ...initialResponseState,
  },
  reducers: {
    setLogFilters(state, { payload }: PayloadAction<Partial<LogFilters>>): void {
      state.logFilters = {
        ...state.logFilters,
        endTs: state.logFilters.endTs || Date.now(),
        startTs: state.logFilters.startTs || Date.now() - 1000 * 60 * 60 * 24,
        ...payload,
        page: payload.page || 0,
      }
      state.stringFromFilters = getLogStringFromFilters(
        state.logFilters,
        state.selectedPresetTimeRange,
      )
    },
    resetFilterParameters(state): void {
      state.logFilters = {
        ...initialState.logFilters,
        endTs: state.logFilters.endTs || Date.now(),
        startTs: state.logFilters.startTs || Date.now() - 1000 * 60 * 60 * 24,
        externalRegion: state.initialExternalRegionsParam,
      }
      state.stringFromFilters = getLogStringFromFilters(
        state.logFilters,
        state.selectedPresetTimeRange,
      )
    },
    setSelectedPresetTimeRange(state, { payload }: PayloadAction<PresetTimeRange>): void {
      state.selectedPresetTimeRange = payload
    },
    setInitialExternalRegionParam(state, { payload }: PayloadAction<string>): void {
      state.initialExternalRegionsParam = payload
      state.logFilters[AdminLogsFilterMenuType.EXTERNAL_REGION] = payload
    },
  },
})

export const {
  setLogFilters,
  resetFilterParameters,
  setSelectedPresetTimeRange,
  setInitialExternalRegionParam,
} = adminLogSlice.actions
