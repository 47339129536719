export enum PasswordValidationState {
  EMPTY = '',
  TOO_SHORT = 'Add more words.',
  HAS_REPEATS = 'Do not use repeats.',
  NO_NUMBER = 'Add a number.',
  VALID = 'Nice password!',
}

export const validatePassword = (password: string): PasswordValidationState => {
  if (!password) {
    return PasswordValidationState.EMPTY
  }

  if (password.length < 9) {
    return PasswordValidationState.TOO_SHORT
  }

  if (/(\S+\s*)\1{2,}/.test(password)) {
    return PasswordValidationState.HAS_REPEATS
  }

  if (!/\d/.test(password)) {
    return PasswordValidationState.NO_NUMBER
  }

  return PasswordValidationState.VALID
}
