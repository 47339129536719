import React, { ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import { QueryFilterType } from 'store/api/analytics/analytics.interface'
import QueryFilterDropdown from './QueryFilterDropdown'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import { useGetAllServicesCategoriesQuery } from 'store/api/services/services'
import { QueriesFilterMenuType } from '../FiltersContainer'
import { ExternalIcon, IconType } from 'components/Dashboard/ExternalSvgIcon'

export default function ServicesDropdown(): ReactElement | null {
  // for getting the generic services data we do not need to pass profileid
  const { data: allServicesData } = useGetAllServicesCategoriesQuery({ profileId: undefined })
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const selectedService = allServicesData?.services.find(
    service => filterParameters[QueryFilterType.SERVICES] === service.PK,
  )
  const selectedFilterName = selectedService?.name || 'Services'
  const icon: ExternalIcon = {
    icon: selectedService?.PK ?? '',
    type: IconType.SERVICES,
  }

  return (
    <QueryFilterDropdown
      selectedValue={selectedFilterName}
      externalIcon={icon}
      type={QueriesFilterMenuType.SERVICES}
    >
      <QueryFilterDropdownContent
        type={QueriesFilterMenuType.SERVICES}
        services={allServicesData?.services}
      />
    </QueryFilterDropdown>
  )
}
