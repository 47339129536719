import React from 'react'

export const getOnKeyPressWith =
  <T = HTMLElement>({
    callback,
    keys = ['Enter', 'Space'],
    stopPropagation = false,
  }: {
    callback: (event?: React.KeyboardEvent<T>) => void
    keys?: string[]
    stopPropagation?: boolean
  }) =>
  (e?: React.KeyboardEvent<T>): void => {
    if (keys.includes(e?.key ?? '') || keys.includes(e?.code ?? '')) {
      if (stopPropagation) {
        e?.stopPropagation()
      }
      callback(e)
    }
  }

export const stopPropagationOnKeyPress = getOnKeyPressWith({
  callback: e => {
    e?.stopPropagation()
  },
})
