import React, { ReactElement, ReactNode, useCallback, useMemo, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import MenuCloseIcon from 'images/menu-close.svg'
import { TrayRenderProps, Button, Svg } from 'ui'
import TrayHeader from 'components/TrayHeader'
import useOnClickOutside from 'utils/useOnClickOutside'
import { TutorialType } from 'store/tutorial/tutorial'
import useArrowNavForHelpPane from 'utils/useArrowKeyHelpPaneNavigation'
import { useHotkeys } from 'react-hotkeys-hook'
import { Footer } from './TutorialModalDialog'
import useQueryString from 'utils/useQueryString'
import { useSwipeable } from 'react-swipeable'

interface TutorialTrayProps extends TrayRenderProps {
  title: string
  tutorialType: TutorialType | string
  children: ReactNode
  handleFinish: () => void
  totalSlides: number
  footerContent?: ReactElement
  handleEarlyExit: () => void
}

const TutorialTray = ({
  title,
  tutorialType,
  children,
  dismiss,
  handleFinish,
  totalSlides,
  footerContent,
  handleEarlyExit,
}: TutorialTrayProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { nav, qs } = useQueryString()
  const currentSlideIndex = +(qs.slide ?? 0) || 0
  useOnClickOutside([containerRef], () => {
    handleEarlyExit()
  })

  useHotkeys('esc', handleEarlyExit)

  const onLeftArrowPress = useCallback(() => {
    if (currentSlideIndex !== 0) {
      nav({ ...qs, slide: currentSlideIndex - 1 })
    }
  }, [currentSlideIndex, nav, qs])

  const onRightArrowPress = useCallback(() => {
    if (currentSlideIndex !== totalSlides - 1) {
      nav({ ...qs, slide: currentSlideIndex + 1 })
    }
  }, [currentSlideIndex, nav, qs, totalSlides])
  const onClose = useMemo(
    () => (currentSlideIndex !== totalSlides ? handleFinish : handleEarlyExit),
    [currentSlideIndex, handleEarlyExit, handleFinish, totalSlides],
  )

  useOnClickOutside([containerRef], () => {
    handleEarlyExit()
  })

  useHotkeys('esc', handleEarlyExit)

  useArrowNavForHelpPane({
    onLeftArrowPress,
    onRightArrowPress,
    onClose,
    isButtonVisible: true,
  })

  const swipeHandlers = useSwipeable({
    onSwiped: (event): void => {
      switch (event.dir) {
        case 'Left':
          onRightArrowPress()
          break
        case 'Right':
          onLeftArrowPress()
          break
      }
    },
    trackTouch: true,
    trackMouse: true,
  })

  return (
    <Flex
      ref={containerRef}
      data-testid={`${tutorialType}-tutorial-tray`}
      sx={{
        height: '100vh',
        width: '100%',
        position: 'relative',
        flexDirection: 'column',
        backgroundColor: 'blue800',
      }}
      className="hide-scrollbar"
    >
      <TrayHeader
        dataTestId="tutorial-tray-header"
        sx={{
          p: '1.6rem',
        }}
        leftComponent={
          <Button
            data-testid={`${tutorialType}-tutorial-close-button`}
            ariaLabel={`${tutorialType} tutorial close button`}
            variant="simple"
            sx={{ p: '0.4rem' }}
            onClick={() => {
              handleEarlyExit()
              dismiss()
            }}
          >
            <Svg
              svg={MenuCloseIcon}
              fill="aliceBlue60"
              sx={{
                width: '2.4rem',
                height: '2.4rem',
              }}
            />
          </Button>
        }
      >
        <Text
          sx={{
            fontSize: '1.8rem',
            fontWeight: 'bold',
            textAlign: 'center',
            width: '100%',
            flex: 1,
            color: 'aliceBlue',
          }}
        >
          {title}
        </Text>
      </TrayHeader>
      <Flex
        data-testid="tutorial-tray-content"
        sx={{
          flexDirection: 'column',
          p: '1.6rem',
          flex: 1,
          overflowY: 'auto',
        }}
        {...swipeHandlers}
      >
        {children}
      </Flex>

      <Footer>{footerContent}</Footer>
    </Flex>
  )
}

export default TutorialTray
