import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import InfoIcon from 'images/freeDNS/info-icon.svg'
import ThirdPartyFilterIcon from 'images/third-party-filter-icon.svg'
import ArrowRightIcon from 'images/freeDNS/arrow-right.svg'
import { filterItemHeight } from './ThirdPartyFilters'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

interface ThirdPartyFilterItemProps {
  title?: string
  backgroundColor?: string
  isOpen?: boolean
  onInfoIconClick?: () => void
  onConfigureClick?: () => void
}

const ThirdPartyFilterItem = ({
  title,
  onInfoIconClick,
  onConfigureClick,
}: ThirdPartyFilterItemProps): ReactElement => {
  const breakpoint = useBreakpointIndex()

  return (
    <Flex
      data-testid={title}
      sx={{
        width: '100%',
        height: filterItemHeight[breakpoint],
        flexShrink: 0,
        justifyContent: 'space-between',
        flexDirection: ['column', 'row'],
        p: '2.4rem',
        backgroundColor: 'lightSkyBlue5',
        borderRadius: '1.2rem',
      }}
    >
      <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Flex sx={{ alignItems: 'center' }}>
          <Svg svg={ThirdPartyFilterIcon} fill="aliceBlue" />
          <Text
            tabIndex={0}
            variant="size17Weight600Line138"
            sx={{ ml: '1.6rem', color: 'aliceBlue' }}
          >
            {title}
          </Text>
        </Flex>
        <Flex sx={{ alignItems: 'center', gap: '3.2rem' }}>
          <Button
            variant="transparent"
            ariaLabel={`Show ${title} filter info tray`}
            sx={{ p: 0 }}
            onClick={onInfoIconClick}
          >
            <Svg
              data-testid="info-icon"
              svg={InfoIcon}
              fill="aliceBlue60"
              sx={{
                cursor: 'pointer',
                alignSelf: 'center',
                ':hover': {
                  path: {
                    fill: 'aliceBlue',
                  },
                },
              }}
            />
          </Button>
          <ConfigureButton
            tabIndex={0}
            ariaLabel={`Configure ${title} filter`}
            sx={{ display: ['none', 'flex'] }}
            onConfigureClick={onConfigureClick}
          />
        </Flex>
      </Flex>

      <ConfigureButton
        tabIndex={0}
        ariaLabel={`Configure ${title} filter`}
        sx={{ display: ['flex', 'none'] }}
        onConfigureClick={onConfigureClick}
      />
    </Flex>
  )
}

export default ThirdPartyFilterItem

function ConfigureButton({ tabIndex, ariaLabel, onConfigureClick, sx }) {
  return (
    <Button
      variant="transparent"
      tabIndex={tabIndex}
      ariaLabel={ariaLabel}
      sx={{
        width: ['100%', 'fit-content'],
        flexShrink: 0,
        px: 0,
        mt: ['0.8rem', 0],
        alignItems: 'center',
        color: 'aliceBlue60',
        justifyContent: ['space-between', 'flex-start'],
        ':hover': {
          color: 'aliceBlue',
          '& svg': {
            opacity: 1,
          },
        },
        ...sx,
      }}
      onClick={onConfigureClick}
    >
      <Text variant="size17Weight600Line138" sx={{ mr: '0.8rem' }}>
        Configure
      </Text>
      <Svg svg={ArrowRightIcon} sx={{ opacity: 0.5 }} />
    </Button>
  )
}
