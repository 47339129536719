import { RuleType, CustomRulesResponseData, CustomRuleData } from 'store/api/rules/rules.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import xor from 'lodash/xor'
import { initialResponseState, LoadingState, ResponseState } from 'store/fetchingLogic'
import { groupApi } from 'store/api/groups'

export const AllActionType = 'all'

export type RuleActionFilter =
  | typeof AllActionType
  | RuleType.BLOCK
  | RuleType.WHITELIST
  | RuleType.SPOOF_TAG

export enum customRulesUITitle {
  DEFAULT = 'Custom Rules',
  IMPORT_LOADING = 'Importing Rules...',
}

export interface RuleSelection {
  isSelecting: boolean
  selectedRuleHostnames: string[]
  isDeleteAction: boolean
}
export interface CustomRulesState extends ResponseState<CustomRulesResponseData> {
  ruleSelection: RuleSelection
  searchParam: string
  shouldSortRules: boolean
  title: customRulesUITitle
  groupUserIsIn: number
  shouldShowLargeView?: boolean
  ruleActionFilter: RuleActionFilter
}

const initialState: CustomRulesState = {
  ...initialResponseState,
  ruleSelection: {
    isSelecting: false,
    selectedRuleHostnames: [],
    isDeleteAction: false,
  },
  groupUserIsIn: 0,
  searchParam: '',
  shouldSortRules: true,
  title: customRulesUITitle.DEFAULT,
  shouldShowLargeView: true,
  ruleActionFilter: AllActionType,
}

export const customRulesSlice = createSlice({
  name: 'customRules',
  initialState,
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    resetLoadingState(state): void {
      state.loading = LoadingState.IDLE
    },
    resetRules(state): void {
      if (state.data) {
        state.data.rules = []
      }
    },
    toggleSelectionMode(state, action: PayloadAction<boolean | undefined>): void {
      state.ruleSelection.isSelecting = action.payload ?? !state.ruleSelection.isSelecting
      state.ruleSelection.isDeleteAction = false
    },
    selectRule(state, action: PayloadAction<string>): void {
      state.ruleSelection.isSelecting = true
      state.ruleSelection.selectedRuleHostnames = xor(state.ruleSelection.selectedRuleHostnames, [
        action.payload?.toString(),
      ])
    },
    deselectAllRules(state): void {
      state.ruleSelection.selectedRuleHostnames = []
    },
    selectAllRules(state, action: PayloadAction<CustomRuleData[] | []>): void {
      state.ruleSelection.selectedRuleHostnames = action.payload.map(x => x.PK?.toString()) || []
    },
    searchRules(state, action: PayloadAction<string>): void {
      state.searchParam = action.payload
    },
    setShouldSortRules(state, action: PayloadAction<boolean>): void {
      state.shouldSortRules = action.payload
    },
    selectRuleToDelete(state, action: PayloadAction<string>): void {
      // this is only used to delete single rule from the row
      state.ruleSelection.selectedRuleHostnames = [action.payload?.toString()]
      state.ruleSelection.isDeleteAction = true
    },
    selectRulesToDelete(state): void {
      state.ruleSelection.isDeleteAction = true
    },
    selectRuleToMove(state, action: PayloadAction<string | undefined>): void {
      // this is only used to move  single rule from the row
      if (action.payload) {
        state.ruleSelection.selectedRuleHostnames = [action.payload?.toString()]
      }
    },

    setShouldShowLargeView(state, action: PayloadAction<boolean>): void {
      state.shouldShowLargeView = action.payload
    },
    setGroupUserIsInUse(state, action: PayloadAction<number>): void {
      state.groupUserIsIn = action.payload
    },
    setRuleActionFilter(state, { payload }: PayloadAction<RuleActionFilter>): void {
      state.ruleActionFilter = payload
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(groupApi.endpoints.postJsonGroup.matchPending, state => {
        state.title = customRulesUITitle.IMPORT_LOADING
      })
      .addMatcher(groupApi.endpoints.postJsonGroup.matchFulfilled, state => {
        state.title = customRulesUITitle.DEFAULT
      })
      .addMatcher(groupApi.endpoints.postJsonGroup.matchRejected, state => {
        state.title = customRulesUITitle.DEFAULT
      })
  },
})

export const {
  toggleSelectionMode,
  selectRule,
  selectAllRules,
  deselectAllRules,
  searchRules,
  selectRuleToDelete,
  selectRulesToDelete,
  selectRuleToMove,
  resetLoadingState,
  setShouldShowLargeView,
  setShouldSortRules,
  setRuleActionFilter,
  setGroupUserIsInUse,
} = customRulesSlice.actions
