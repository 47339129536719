// refer to https://system-ui.com/theme/ for scales/opt keys
import { BaseTheme } from './themeObject.interface'
import palette from './palette'
import darkTheme from './themeObject.dark'
import customComponents from './customComponents'
import lightModeObj from './themeObject.light'

const desktopTabletMinHeight = '36em'
const tabletMinWidth = '48em'
const desktopMinWidth = '70em'

const breakpoints = [
  `@media screen and (min-width: ${tabletMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
  `@media screen and (min-width: ${desktopMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
]

const space = [
  '0rem', //0
  '0.4rem',
  '0.8rem',
  '1.2rem',
  '1.6rem',
  '2.0rem', //5
  '2.4rem',
  '2.8rem',
  '3.2rem',
  '3.6rem',
  '4.0rem', //10
  '4.4rem',
  '4.8rem',
  '5.0rem',
  '6.4rem',
]

// 1rem = 10px
const fontSizes = [
  '1.2rem',
  '1.4rem',
  '1.6rem',
  '1.8rem',
  '2.0rem',
  '2.4rem', //5
  '2.8rem',
  '3.2rem',
  '4.8rem',
  '6.4rem',
]

const fonts = {
  body: 'Plus Jakarta Sans Variable, sans-serif',
  title: 'Plus Jakarta Sans Variable',
  heading: 'Plus Jakarta Sans Variable',
}

const sizes = {
  iconSmall: '1.2rem',
  iconMedium: '1.6rem',
  inputHeight: '4.4rem',
  headerHeight: '5.6rem',
}

const fontWeights = [400, 700]

const baseTheme: BaseTheme = {
  space,
  fontSizes,
  fonts,
  styles: {
    root: {
      fontFamily: 'body',
    },
  },
  fontWeights,
  sizes,
  breakpoints,
  colors: { ...palette, modes: { ...darkTheme, ...lightModeObj } },
  radii: ['0rem', '1.2rem', '2rem', '99rem'],
  zIndices: {
    zIndex999: 999, // giantDefaultLocationButton, RuleInfoBadge
    zIndex650: 650, // alert needs to higher than overlay for the alerts to display on setupguide
    zIndex700: 700, // tray header
    zIndex630: 630, // Barry header
    zIndex625: 625, // Barry
    zIndex570: 570, // proxy tray
    zIndex550: 550, // rule tray
    zIndex450: 450, // help tray overlay
    zIndex275: 275, // tray
    zIndex250: 250, // mobile nav
    zIndex300: 300, // navBar
    zIndex249: 249, // trayOverlay
    zIndex595: 595, // modalDialogOverlay
    zIndex596: 596, // modalDialog
    zIndex600: 600, // popupOverlay
    zIndex601: 601, // popup
    zIndex240: 240, // header
    zIndex248: 248, // headerWarningMessage
    zIndex50: 50, // fixedButton
    zIndex40: 40, // fixedButtonBackdrop
    zIndex10: 10, // invisibleNativeSelect, section
    zIndex9: 9, // freeDNSBottomTray
    zIndex5: 5, // content
    zIndex2: 2, // ruleOptions, folderOptions
    zIndex1: 1, // progressBarKnob, pricingModal, trayFooterButtonGroup
    zIndex0: 0, // WarningMessageWrapper, Layout
    'zIndex-1': -1, // default view redirect counntry
    'zIndex-50': -50, // backgroundGradient
    'zIndex-100': -100, // hidden
  },
  ...customComponents,
}

export default baseTheme
