import { RuleType } from 'store/api/rules/rules.interface'
import BlockIcon from 'images/customRules/block.svg'
import RedirectIcon from 'images/customRules/redirect_ipv46cname.svg'
import BypassIcon from 'images/customRules/bypass.svg'

export default [
  {
    text: 'Block',
    Icon: BlockIcon,
    description: 'Prevents domain from resolving',
    ruleType: RuleType.BLOCK,
  },
  {
    text: 'Bypass',
    Icon: BypassIcon,
    description: 'Forces domain to be resolved to original IP address',
    ruleType: RuleType.WHITELIST,
  },
  {
    text: 'Redirect',
    Icon: RedirectIcon,
    description: 'Resolves domain to chosen IP address or hostname CNAME',
    ruleType: RuleType.SPOOF_IP,
  },
  {
    text: 'Redirect',
    Icon: RedirectIcon,
    description: 'Tunnels domain through a selected proxy location',
    ruleType: RuleType.SPOOF_TAG,
  },
]
