import { detectConfiguredDevice } from 'store/multiprofile'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useDetectDeviceQuery, useGetDevicesQuery } from 'store/api/devices'

export function useDetectConfiguredDevice(): void {
  const dispatch = useAppDispatch()
  const userPk = useAppSelector(s => s.session.userPk)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: devicesData, isFetching: areDevicesFetching } = useGetDevicesQuery('', {
    skip: !sessionToken,
  })

  // Device verification check
  const configuredDevice = useAppSelector(s => s.devices.configuredDevice)
  const { data: detectDeviceData } = useDetectDeviceQuery('', {
    skip: !userPk || areDevicesFetching,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !detectDeviceData) {
        dispatch(detectConfiguredDevice())
      } else {
        dispatch(detectConfiguredDevice(false))
      }
    }

    if (devicesData?.devices?.length && !configuredDevice && !detectDeviceData) {
      handleVisibilityChange()

      document.addEventListener('visibilitychange', handleVisibilityChange)
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [configuredDevice, detectDeviceData, devicesData?.devices?.length, dispatch])
}
