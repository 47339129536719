import React, { ReactElement, Suspense, useLayoutEffect } from 'react'
const RuleOrFolderDialog = React.lazy(() => import('./RuleOrFolderDialog'))
const CopyRuleDialog = React.lazy(() => import('./CopyRuleDialog'))
const MoveRuleDialog = React.lazy(() => import('./MoveRuleDialog'))
const AddRulesToFolderDialog = React.lazy(() => import('./AddRulesToExistingFolderDialog'))
const CreateFolderAndRulesDialog = React.lazy(() => import('./CreateFolderAndRulesDialog'))
import useQueryString from 'utils/useQueryString'
import omit from 'lodash/omit'
import { deselectAllRules, toggleSelectionMode } from 'store/customRules'
import { useAppDispatch } from 'store/hooks'

export enum DialogType {
  RULE_OR_FOLDER = 'rule-or-folder',
  COPY = 'copy',
  MOVE = 'move',
  ADD_RULES_TO_FOLDER = 'add-rules-to-folder',
  CREATE_FOLDER_AND_RULES = 'create-folder-and-rules',
}

export default function RuleOrFolderMainDialog(): ReactElement | null {
  const dispatch = useAppDispatch()
  const { nav, qs } = useQueryString()

  useLayoutEffect(() => {
    if (qs.ruleDialog && !qs.domain) {
      nav({ ...omit(qs, 'ruleDialog') })
    }

    // trigger this effect only on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!qs.ruleDialog) {
    return null
  }

  const Component =
    qs.ruleDialog === DialogType.COPY
      ? CopyRuleDialog
      : qs.ruleDialog === DialogType.MOVE
      ? MoveRuleDialog
      : qs.ruleDialog === DialogType.CREATE_FOLDER_AND_RULES
      ? CreateFolderAndRulesDialog
      : qs.ruleDialog === DialogType.ADD_RULES_TO_FOLDER
      ? AddRulesToFolderDialog
      : RuleOrFolderDialog

  return (
    <Suspense>
      <Component
        isOpen={!!qs.ruleDialog}
        dismiss={() => {
          dispatch(toggleSelectionMode(false))
          dispatch(deselectAllRules())
          nav({ ...omit(qs, 'ruleDialog', 'domain') })
        }}
      />
    </Suspense>
  )
}
