import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import {
  CustomRulesResponse,
  SingleCustomRuleBody,
  CustomRulesPutResponse,
  CustomRulesPostResponse,
  CustomRulesDeleteResponse,
  CustomRulesDeleteBody,
  MultipleCustomRulesPostBody,
  MultipleCustomRulesPutBody,
  CustomRuleData,
  RuleType,
} from './rules.interface'
import { groupApi, replaceEmptyViaInRequestBody } from '../groups'
import { current } from '@reduxjs/toolkit'
import { deselectAllRules, setCurrentGroup } from 'store/customRules'

export const ROOT_GROUP = 0

export const ruleApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getRules: builder.query({
      query: ({ profileId, groupPk }: { profileId: string; groupPk: number }) =>
        getQueryArgs(
          `/profiles/${profileId}/rules${groupPk === ROOT_GROUP ? '' : `/${groupPk}`}`,
          'GET',
        ),
      transformResponse: (response: CustomRulesResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      async onQueryStarted({ profileId, groupPk }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            groupApi.util.updateQueryData('getGroups', { profileId }, draft => {
              const group = current(draft?.groups)?.find(group => group.PK === groupPk)
              dispatch(setCurrentGroup(group))
            }),
          )
        } catch {}
      },
      providesTags: ['CustomRules'],
    }),
    getAllRules: builder.query({
      query: ({
        profileId,
        ignoreImpersonation = false,
      }: {
        profileId: string | number
        ignoreImpersonation?: boolean
      }) => {
        const queryArgs = getQueryArgs(`/profiles/${profileId}/rules/all`, 'GET')
        if (ignoreImpersonation) {
          queryArgs.headers = undefined
        }
        return queryArgs
      },
      transformResponse: (response: CustomRulesResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
    }),
    toggleCustomRule: builder.mutation({
      query: ({
        hostname,
        body,
        profileId,
      }: {
        hostname: string
        body: SingleCustomRuleBody
        profileId: string
      }) =>
        getQueryArgs(`/profiles/${profileId}/rules/${encodeURIComponent(hostname)}`, 'PUT', body),
      transformResponse: (response: CustomRulesResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted({ body, hostname, profileId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            ruleApi.util.updateQueryData(
              'getRules',
              { profileId, groupPk: body.group || 0 },
              draft => {
                const selectedRule = draft.rules.find(r => r.PK === hostname)
                if (!!selectedRule) {
                  selectedRule.action.status = body.status
                }
              },
            ),
          )

          dispatch(
            ruleApi.util.updateQueryData('getAllRules', { profileId }, draft => {
              const ruleFromAll = draft.rules.find(r => r.PK === hostname)

              if (ruleFromAll) {
                ruleFromAll.action.do = body.do ?? ruleFromAll.action.do
                ruleFromAll.action.status = body.status ?? ruleFromAll.action.status
              }
            }),
          )
        } catch {}
      },
    }),
    postRules: builder.mutation({
      query: ({
        body,
        profileId,
        ignoreImpersonation,
      }: {
        body: MultipleCustomRulesPostBody
        profileId: string
        ignoreImpersonation?: boolean
      }) => {
        const queryArgs = getQueryArgs(`/profiles/${profileId}/rules`, 'POST', body)
        if (ignoreImpersonation) {
          queryArgs.headers = undefined
        }
        return queryArgs
      },
      transformResponse: (response: CustomRulesPostResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      async onQueryStarted({ body, profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          const ruleData = { ...body, ...data }

          dispatch(
            ruleApi.util.updateQueryData(
              'getRules',
              { profileId, groupPk: body.group || 0 },
              draft => {
                const rule = ruleData
                const hostnames = rule.hostnames?.filter(
                  hostname => !draft.rules.some(rule => rule.PK === hostname),
                )
                const newRules = (hostnames?.map(h => {
                  return {
                    PK: h,
                    group: rule.group ?? 0,
                    action: {
                      do: rule.do as RuleType,
                      via: rule.via,
                      via_v6: rule.via_v6,
                      status: rule.status,
                      ttl: rule.ttl,
                    },
                    comment: rule.comment,
                  }
                }) || []) as CustomRuleData[]

                // don't add rules to the view if the folder the rule is in isn't the folder the user is in
                draft.rules.push(...newRules.filter(rule => rule.group === body.group || 0))
                dispatch(
                  ruleApi.util.updateQueryData('getAllRules', { profileId }, draft => {
                    draft.rules.push(...newRules)
                  }),
                )

                const rulesLength = draft.rules?.length

                dispatch(
                  groupApi.util.updateQueryData('getGroups', { profileId }, draft => {
                    const group = draft?.groups?.find(group => group.PK === body.group)

                    if (group) {
                      group.count = rulesLength
                    }
                  }),
                )
              },
            ),
          )
        } catch {}
      },
    }),
    putGroupRules: builder.mutation({
      query: ({
        body,
        profileId,
        ignoreImpersonation,
      }: {
        body: MultipleCustomRulesPutBody
        profileId: string
        ignoreImpersonation?: boolean
      }) => {
        const queryArgs = getQueryArgs(
          `/profiles/${profileId}/rules`,
          'PUT',
          replaceEmptyViaInRequestBody(body),
        )
        if (ignoreImpersonation) {
          queryArgs.headers = undefined
        }
        return queryArgs
      },
      transformResponse: (response: CustomRulesPutResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      async onQueryStarted({ body, profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const ruleData = { ...data, ...body }

          const { hostnames, do: doValue, via, via_v6, status, ttl, comment } = ruleData
          const updateRule = (rule: CustomRuleData | undefined) => {
            if (rule) {
              rule.action.do = doValue ?? rule.action.do
              // fix for #953. preserve via for multiple redirect rule edit
              rule.action.via =
                rule.action.do === RuleType.SPOOF_TAG || rule.action.do === RuleType.SPOOF_IP
                  ? via ?? rule.action.via
                  : via
              rule.action.via_v6 =
                rule.action.do === RuleType.SPOOF_TAG || rule.action.do === RuleType.SPOOF_IP
                  ? via_v6 ?? rule.action.via_v6
                  : via_v6
              rule.action.status = status ?? rule.action.status
              rule.action.ttl = ttl === -1 ? undefined : ttl ?? rule.action.ttl
              rule.comment = comment
            }
          }

          dispatch(
            ruleApi.util.updateQueryData(
              'getRules',
              { profileId, groupPk: body.group || 0 },
              draft => {
                hostnames.forEach(h => {
                  const rule = draft.rules.find(r => r.PK === h)

                  updateRule(rule)
                })
              },
            ),
          )

          dispatch(
            ruleApi.util.updateQueryData('getAllRules', { profileId }, draft => {
              hostnames.forEach(h => {
                const ruleFromAll = draft.rules.find(r => r.PK === h)

                updateRule(ruleFromAll)
              })
            }),
          )

          dispatch(deselectAllRules())
        } catch {}
      },
    }),
    moveRulesToGroup: builder.mutation({
      query: ({
        body,
        profileId,
      }: {
        body: MultipleCustomRulesPutBody
        profileId: string
        selectedGroupPk: number
      }) => getQueryArgs(`/profiles/${profileId}/rules`, 'PUT', replaceEmptyViaInRequestBody(body)),
      transformResponse: (response: CustomRulesPutResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted({ body, profileId, selectedGroupPk }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          const nonRootMovedRules: CustomRuleData[] = []

          dispatch(
            groupApi.util.updateQueryData('getGroups', { profileId }, draft => {
              dispatch(
                ruleApi.util.updateQueryData('getAllRules', { profileId }, draftRules => {
                  // for global search case, when user find rules from different groups and want to move them to its root
                  if (selectedGroupPk === ROOT_GROUP) {
                    const reducedCountsByGroups = {}
                    body.hostnames.forEach(h => {
                      const movedRule = draftRules.rules.find(r => r.PK === h)
                      if (!reducedCountsByGroups[movedRule?.group ?? '']) {
                        reducedCountsByGroups[movedRule?.group ?? ''] = 1
                      } else {
                        reducedCountsByGroups[movedRule?.group ?? '']++
                      }
                    })
                    draft?.groups.forEach(g => {
                      if (reducedCountsByGroups[g.PK] && g?.count) {
                        g.count = g.count - reducedCountsByGroups[g.PK]
                      }
                    })
                  }
                  const movedTo = draft?.groups.find(g => g.PK === body.group)
                  if (movedTo) {
                    movedTo.count = (movedTo.count ?? 0) + body.hostnames.length
                  }
                }),
              )
            }),
          )

          dispatch(
            ruleApi.util.updateQueryData('getAllRules', { profileId }, draft => {
              body.hostnames.forEach(h => {
                const movedRule = draft.rules.find(rule => h === rule.PK)
                if (movedRule && (body.group || body.group === ROOT_GROUP)) {
                  const oldGroup = movedRule.group
                  movedRule.group = body.group
                  if (oldGroup !== ROOT_GROUP) {
                    nonRootMovedRules.push(current(movedRule))
                  }
                }
              })
            }),
          )

          dispatch(
            ruleApi.util.updateQueryData(
              'getRules',
              { profileId, groupPk: selectedGroupPk },
              draft => {
                // for global search case, when user find rules from different groups and want to move them to its root
                if (selectedGroupPk === ROOT_GROUP && body.group === ROOT_GROUP) {
                  draft.rules.push(...nonRootMovedRules)
                } else {
                  draft.rules = draft.rules.filter(x => !body.hostnames.includes(x.PK))
                }
              },
            ),
          )
        } catch {}
      },
    }),
    deleteGroupRules: builder.mutation({
      query: ({
        body,
        profileId,
      }: {
        body: CustomRulesDeleteBody
        profileId: string
        group: number
      }) => getQueryArgs(`/profiles/${profileId}/rules`, 'DELETE', body),
      transformResponse: (response: CustomRulesDeleteResponse) => response.body,
      transformErrorResponse,
      async onQueryStarted({ body, profileId, group }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          const hostnames = body.hostnames

          dispatch(
            ruleApi.util.updateQueryData('getRules', { profileId, groupPk: group }, draft => {
              draft.rules = draft.rules.filter(x => !hostnames.includes(x.PK))

              const rulesLength = draft.rules?.length

              dispatch(
                groupApi.util.updateQueryData('getGroups', { profileId }, draft => {
                  const currentGroup = draft?.groups?.find(g => g.PK === group)

                  if (currentGroup && rulesLength !== undefined) {
                    currentGroup.count = rulesLength
                  }
                }),
              )
            }),
          )

          dispatch(
            ruleApi.util.updateQueryData('getAllRules', { profileId }, draft => {
              draft.rules = draft.rules.filter(x => !hostnames.includes(x.PK))
            }),
          )
        } catch {}
      },
    }),
  }),
})

export const {
  useGetRulesQuery,
  useLazyGetRulesQuery,
  useGetAllRulesQuery,
  useToggleCustomRuleMutation,
  usePostRulesMutation,
  usePutGroupRulesMutation,
  useMoveRulesToGroupMutation,
  useDeleteGroupRulesMutation,
} = ruleApi
