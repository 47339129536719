import { ReactNode, RefObject } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'

export enum TrayOption {
  ACTIVITY = 'activity',
  PROXY = 'proxy',
  LIMITATIONS = 'limitations',
  DOMAINS = 'domains',
  WHERE_TO_GO = 'where-to-go',
  SUBSCRIPTION_DEACTIVATION = 'subscription-deactivation',
  NOTIFICATION = 'notification',
}

export type EntryDirection = 'left' | 'right' | 'top' | 'bottom'

export interface TrayProps {
  entersFrom: EntryDirection
  children?: ReactNode
  isOpen: boolean
  /**
   * If `entersFrom` is "left" or "right", this is interpreted as width,
   * and height is set to 100%.
   *
   * If `entersFrom` is "top" or "bottom", this is interpreted as height
   * and width is set to 100%.
   */
  slideDimensionLength?: string | number | Array<string> | Array<number>
  sx?: ThemeUIStyleObject
  zIndexName?: string
  backgroundOverlayWhenOpen?: boolean
  dismiss?: () => void
  dataTestId?: string
  shouldVibrateOnPresent?: boolean
  showOverlay?: boolean
  overlayContent?: ReactNode
  trayRef?: RefObject<HTMLDivElement> | null
  disableFocusLock?: boolean
}
