import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from './rootReducer'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): ThunkDispatch<RootState, null, AnyAction> =>
  useDispatch<ThunkDispatch<RootState, null, AnyAction>>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
