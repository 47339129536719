import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ProfileOptionsMetaData,
  Profile,
  ProfileOptionFieldType,
  UserProfileOption,
} from 'store/api/profiles/profiles.interface'
import { initialResponseState, ResponseState } from 'store/fetchingLogic'
import { EnabledStatus } from 'store/api/rules'
import { SubHeaderPath } from 'components/Dashboard/SubHeader/SubHeaderDropdown'
import { profilesApi } from 'store/api/profiles'
import { sessionLogout } from '../session'

export interface ProfileState extends ResponseState<{ profiles: Profile[] }> {
  editProfileId?: string
  profileOptions?: ProfileOptionsMetaData[]
  shouldSortProfilesAlphabetically: boolean
  editingProfileSection?: SubHeaderPath
}

const initialState: ProfileState = {
  ...initialResponseState,
  shouldSortProfilesAlphabetically: true,
}

interface UpdateProfileOptionsArgs {
  profileId: string
  options: UserProfileOption[]
}
/**
 * only use this if you want to update multiple options at once, e.g. when the user clicks a save button
 */
export const updateProfileOptions = createAsyncThunk(
  'profile/updateOptions',
  async ({ profileId, options }: UpdateProfileOptionsArgs, { dispatch }) => {
    const response = await Promise.allSettled(
      options
        // if the option is enabled & is a dropdown or text field & it does not have any value, no need to update the option
        .filter(
          option =>
            option.type === ProfileOptionFieldType.TOGGLE ||
            option.status === EnabledStatus.DISABLED ||
            (option.value !== '' && option.value !== undefined),
        )
        .map(async option => {
          const result = await dispatch(
            profilesApi.endpoints.putProfileOptions.initiate({ ...option, profileId }),
          )

          return result
        }),
    )

    return response
  },
)

export const profileSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    setProfileForEdit(state, action: PayloadAction<string | undefined>): void {
      state.editProfileId = action.payload
    },
    setShouldSortProfilesAlphabetically(state, action: PayloadAction<boolean>): void {
      state.shouldSortProfilesAlphabetically = action.payload
    },
    setEditingProfileSection(state, action: PayloadAction<SubHeaderPath>): void {
      state.editingProfileSection = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(sessionLogout.fulfilled, () => initialState)
  },
})

export const { setProfileForEdit, setShouldSortProfilesAlphabetically, setEditingProfileSection } =
  profileSlice.actions
