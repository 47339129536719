import React, { ElementType, ReactElement } from 'react'
import IconWithTippyPopup from './IconWithTippyPopup'
import { ThemeUIStyleObject } from 'theme-ui'

export const actionIconButtonStyles = {
  height: ['2.8rem', '3.8rem'],
  width: ['2.8rem', '3.8rem'],
  color: 'aliceBlue',
  backgroundColor: ['transparent', 'white6'],
  borderRadius: '50%',
  svg: {
    opacity: 0.6,
  },
  '&:hover ': {
    ':not([disabled])': {
      svg: {
        opacity: 1,
        path: {
          fill: 'aliceBlue',
        },
      },
    },
  },
}

export default function ActionIconButton({
  ariaLabel,
  dataTestId,
  onClick,
  svgIcon,
  tooltipText,
  sxTooltipText,
  sx,
  isVisible,
  isEnabled,
  isDisabled,
  isLoading,
}: {
  ariaLabel: string
  dataTestId: string
  onClick: () => void
  svgIcon: ElementType
  tooltipText: string
  sxTooltipText?: ThemeUIStyleObject
  sx?: ThemeUIStyleObject
  isVisible?: boolean
  isEnabled?: boolean
  isDisabled?: boolean
  isLoading?: boolean
}): ReactElement {
  return (
    <IconWithTippyPopup
      content={tooltipText}
      ariaLabel={ariaLabel}
      testId={dataTestId}
      isVisible={isVisible}
      isEnabled={isEnabled}
      isDisabled={isDisabled}
      isLoading={isLoading}
      svg={svgIcon}
      fill="aliceBlue"
      onClick={onClick}
      placement="bottom"
      sx={{
        width: '2.4rem',
        height: '2.4rem',
        flexShrink: 0,
        ...sx,
      }}
      sxButton={actionIconButtonStyles}
      sxTooltipText={sxTooltipText}
    />
  )
}
