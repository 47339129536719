import React, { useCallback, useRef, useEffect, useState } from 'react'
import { SwitchProps } from './index'
import { Box, Spinner, Text } from 'theme-ui'
import { Button } from 'ui/Buttons'

export default function Switch(props: SwitchProps): React.ReactElement {
  const {
    checked,
    onClick,
    leftIcon,
    leftLabel,
    rightIcon,
    rightLabel,
    sxLeftLabel,
    sxRightLabel,
    ariaLabelledby,
    ariaLabel,
    sx,
    hasHapticFeedback = false,
    isLoading,
    isDisabled,
    sxSwitchToggle,
    isDefaultSwitchToggle = true,
    sxLeftLabelAndIconContainer,
    sxRightLabelAndIconContainer,
    sxSpinner,
    checkedColor = null,
    unCheckedColor = 'silverSpoon',
    needSaveFocus,
    isCompact,
    ...attrs
  } = props

  const [wasFocused, setWasFocused] = useState(false)

  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    // adding focus on isLoading changes
    if (needSaveFocus && wasFocused) {
      ref.current?.focus()
    }
  }, [isLoading, needSaveFocus, wasFocused])

  const memoizedOnClick = useCallback(
    event => {
      if (hasHapticFeedback && window.navigator.vibrate) {
        try {
          window.navigator.vibrate(10)
        } catch (err) {}
      }

      onClick(event)
    },
    [hasHapticFeedback, onClick],
  )

  return (
    <Button
      variant="switch"
      onFocus={() => setWasFocused(true)}
      sx={{
        backgroundColor: checked ? checkedColor ?? null : unCheckedColor,
        borderRadius: '45px',
        overflow: 'hidden',
        flexShrink: 0,
        pointerEvents: 'visible',
        cursor: isLoading || isDisabled ? 'auto' : 'pointer',
        minHeight: isCompact ? '2.4rem' : ['2.4rem', '3.2rem'],
        height: isCompact ? '2.4rem' : ['2.4rem', '3.2rem'],
        minWidth: isCompact ? '4.4rem' : ['4.4rem', '5.8rem'],
        width: isCompact ? '4.4rem' : ['4.4rem', '5.8rem'],
        border: '1px solid',
        borderColor: 'white6',
        ...sx,
      }}
      onClick={memoizedOnClick}
      data-testid="switch-base"
      role="checkbox"
      aria-checked={!!checked}
      ariaLabel={ariaLabel}
      aria-labelledby={ariaLabelledby}
      tabIndex={0}
      type="button" // to prevent getting triggered by enter inside a form
      {...attrs}
      disabled={isLoading || isDisabled}
      ref={ref}
    >
      {(leftIcon || leftLabel) && (!isLoading || checked) && (
        <Box
          sx={{
            position: 'absolute',
            left: '0.5rem',
            top: 1,
            fontSize: 2,
            fill: 'black',
            ...sxLeftLabelAndIconContainer,
          }}
        >
          <Text
            sx={{
              ...sxLeftLabel,
            }}
          >
            {leftLabel}
          </Text>
          {leftIcon}
        </Box>
      )}
      {(rightIcon || rightLabel) && (!isLoading || !checked) && (
        <Box
          sx={{
            position: 'absolute',
            right: '0.6rem',
            top: 1,
            fontSize: 2,
            fill: 'black',
            ...sxRightLabelAndIconContainer,
          }}
        >
          <Text
            sx={{
              ...sxRightLabel,
            }}
          >
            {rightLabel}
          </Text>
          {rightIcon}
        </Box>
      )}
      <Box
        className={isDefaultSwitchToggle ? 'switch-toggle' : ''}
        aria-hidden="true"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: checked ? 'translate(2.8rem)' : 'translate(0.3rem)',
          backgroundColor: 'black',
          minHeight: isCompact ? '2rem' : ['2rem', '2.6rem'],
          minWidth: isCompact ? '2rem' : ['2rem', '2.6rem'],
          ...(isCompact && {
            transform: props.checked ? 'translate(2rem)' : 'translate(0)',
          }),
          '@media screen and (max-width: 768px)': {
            transform: props.checked ? 'translate(2rem)' : 'translate(0)',
          },
          ...sxSwitchToggle,
        }}
      >
        {isLoading && (
          <Spinner
            data-testid="loading-spinner"
            color="white"
            sx={{
              height: '2.0rem',
              width: '2.0rem',
              ...sxSpinner,
            }}
          />
        )}
      </Box>
    </Button>
  )
}
