import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Flex } from '@theme-ui/components'
import { DeviceStatusIcons } from './DeviceCardStatusMenu'
import { useAppSelector } from 'store/hooks'
import { useLocation } from '@reach/router'
import { getTimeSinceNow, getTimeSinceNowShortFormat } from 'utils/getTimeSinceNow'
import { Badge, Svg } from 'ui'
import DeviceIcons, { defaultIconName } from '../DeviceIcons'
import Tippy from '@tippyjs/react'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { Link } from 'gatsby'
import { Box, Text } from 'theme-ui'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import ScheduleIcon from 'images/dashboard/devices/shedule-icon.svg'
import { EnabledStatus } from 'store/api/rules'
import { EnforcingStatus, useGetSchedulesQuery } from 'store/api/schedules'
import ParentDevice from './ParentDevice'
import DeviceInfoButtons from './DeviceInfoButtons'
import DeviceItemActionButtons from './DeviceItemActionButtons'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useGetColorMode from 'utils/useGetColorMode'

export enum DeviceAnalyticsStatus {
  ONLINE, // used in last 60 seconds
  OFFLINE, // not used in last 60 seconds
  UNAVAILABLE, // status not available
}

export const deviceStatusToColor: Record<DeviceAnalyticsStatus, string> = {
  [DeviceAnalyticsStatus.ONLINE]: 'greenApple',
  [DeviceAnalyticsStatus.OFFLINE]: 'pomegranate',
  [DeviceAnalyticsStatus.UNAVAILABLE]: 'white',
}
export interface DeviceListItemProps {
  device: DeviceInfo
  showEditDeviceTray: () => void
  isFirstEnforcedProfileShared?: boolean
  isSecondEnforcedProfileShared?: boolean
  devicesWithParentDevice?: DeviceInfo[]
  isSmallTablet: boolean
}
export default function DeviceListItem({
  device,
  isFirstEnforcedProfileShared,
  isSecondEnforcedProfileShared,
  devicesWithParentDevice,
  isSmallTablet,
}: DeviceListItemProps): ReactElement {
  const isMobile = useBreakpointIndex() === 0
  const location = useLocation()
  const deviceStatus = useMemo<DeviceAnalyticsStatus>(() => {
    if (!device.last_activity) {
      return DeviceAnalyticsStatus.UNAVAILABLE
    }
    const lastActivityTimestampMillis = device.last_activity * 1000

    return Date.now() - lastActivityTimestampMillis < 300000
      ? DeviceAnalyticsStatus.ONLINE
      : DeviceAnalyticsStatus.OFFLINE
  }, [device.last_activity])

  const timeSinceLastQuery = useMemo<string | undefined>(() => {
    return getTimeSinceNow(device.last_activity)
  }, [device.last_activity])

  const { data: schedulesData } = useGetSchedulesQuery('')
  const existingSchedule = schedulesData?.schedules.find(
    schedule => schedule?.device?.PK === device.PK,
  )
  const isActive =
    existingSchedule?.enforcing === EnforcingStatus.TRUE &&
    existingSchedule?.status === EnabledStatus.ENABLED
  const isNotActive =
    existingSchedule?.enforcing === EnforcingStatus.FALSE &&
    existingSchedule?.status === EnabledStatus.ENABLED

  const isDevicePending = device.status === 0
  const isDeviceLimited = device.status === 2
  const isDeviceDisabled = device.status === 3

  const deviceStatusBadgeText = getTimeSinceNowShortFormat(device.last_activity)

  const configuredDevice = useAppSelector(s => s.devices.configuredDevice)

  const parentDeviceId = devicesWithParentDevice?.find(
    deviceWithParentDevice => deviceWithParentDevice.PK === device.PK,
  )?.parent_device?.device_id

  const firstChildRef = useRef<HTMLDivElement>(null)
  const secondChildRef = useRef<HTMLDivElement>(null)
  const { isLightMode } = useGetColorMode()

  const [isCompressed, setIsCompressed] = useState(false)
  const [isFistOrSecondProfileNameTruncated, setIsFistOrSecondProfileNameTruncated] =
    useState(false)

  // should compress DeviceStatusIcons when there is not enough space
  useEffect(() => {
    const onResize = () => {
      if (secondChildRef.current && firstChildRef.current) {
        // 16 - 16px space
        setIsCompressed(
          secondChildRef.current.offsetLeft -
            (firstChildRef.current.offsetWidth + firstChildRef.current.offsetLeft + 16) <=
            0,
        )
      }
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <Flex
      role="cell"
      data-testid={`device-card-${device.name}`}
      sx={{
        width: '100%',
        height: [
          device?.parent_device?.device_id && device.profile2?.PK ? '10.5rem' : '9.4rem',
          '5.4rem',
        ],
        alignItems: ['flex-start', 'center'],
        justifyContent: 'space-between',
        flexDirection: ['column', 'row'],
        backgroundColor: 'cardBg',
        borderRadius: ['none', '1.2rem'],
        borderWidth: ['1px 0', '1px'],
        borderStyle: 'solid',
        borderColor: configuredDevice?.PK === device.PK ? 'greenApple' : 'blueYonder15',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.07)',
        color: 'aliceBlue',
        px: '1.2rem',
        py: ['0.8rem', 0],
        ...(isSmallTablet
          ? {
              height:
                device?.parent_device?.device_id && device.profile2?.PK ? '10.5rem' : '9.4rem',
              alignItems: 'flex-start',
              flexDirection: 'column',
              py: '0.8rem',
            }
          : {}),
      }}
    >
      <Flex ref={firstChildRef} sx={{ width: '100%' }}>
        <Tippy
          onShow={(): void | false => {
            if (!device.last_activity) {
              // returning false here stops the tooltip from showing
              return false
            }
          }}
          content={
            !!device.last_activity ? (
              <Flex
                sx={{
                  alignItems: 'center',
                  width: '100%',
                  textAlign: 'center',
                  p: '0.4rem',
                }}
              >
                Last query <br />
                {timeSinceLastQuery}
              </Flex>
            ) : (
              <></>
            )
          }
          theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
          arrow={false}
          maxWidth={148}
          offset={[0, 5]}
        >
          <Flex
            role="button"
            sx={{
              position: 'relative',
              width: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              mr: ['0.8rem', '1.2rem'],
              flexShrink: 0,
            }}
          >
            <Svg
              aria-label="endpoint icon"
              className="device-icon"
              svg={DeviceIcons[device.icon ?? defaultIconName]}
              fill="aliceBlue"
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
                mt: deviceStatusBadgeText && '-1rem',
              }}
            />

            {!!deviceStatusBadgeText && (
              <Text
                data-testid="last-used-indicator"
                variant="size8Weight700"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  left: '50%',
                  top: '2rem',
                  transform: 'translate(-50%, 0)',
                  px: '0.4rem',
                  height: '1.4rem',
                  border: '1px solid',
                  borderColor: 'darkBodyBG',
                  color: 'darkBodyBG',
                  borderRadius: '26px',
                  backgroundColor: deviceStatusToColor[deviceStatus],
                }}
              >
                {deviceStatusBadgeText}
              </Text>
            )}
          </Flex>
        </Tippy>
        <Flex sx={{ flexDirection: 'column' }}>
          <Flex
            sx={{
              alignItems: 'center',
              minWidth: 0,
              gap: '0.8rem',
              mr: [0, '1.2rem'],
            }}
          >
            <TextWithOverFlowAndTippyPopup
              variant="size15Weight700"
              content={device.name}
              ariaLabel={device.name}
              sxText={{
                maxWidth: '100%',
                color: 'aliceBlue',
              }}
            />
            <Flex sx={{ gap: '0.8rem', flexShrink: 0 }}>
              {isDeviceLimited && <Badge colorTheme="warning">Limited</Badge>}
              {isDeviceDisabled && <Badge colorTheme="error">Disabled</Badge>}
              {isDevicePending && <Badge colorTheme="warning">Not Configured</Badge>}
            </Flex>
          </Flex>
          <Flex
            sx={{
              width: '100%',
              color: 'white50',
              fontWeight: 'bold',
              flexDirection: 'row',
            }}
          >
            <Flex
              sx={{
                gap: '0.4rem',
                flexDirection: 'row',
                ...(isFistOrSecondProfileNameTruncated
                  ? {
                      flexDirection: 'column',
                      gap: 0,
                    }
                  : {}),
              }}
            >
              {parentDeviceId && (
                <ParentDevice
                  parentDeviceId={parentDeviceId}
                  sx={{ '& svg path': { fill: 'aliceBlue60' } }}
                >
                  <Box
                    sx={{
                      display: [
                        isFistOrSecondProfileNameTruncated ? 'none' : 'block',
                        isSmallTablet && isFistOrSecondProfileNameTruncated ? 'none' : 'block',
                        'block',
                      ],
                      mr: '0.4rem',
                      ml: '0.8rem',
                      width: '0.4rem',
                      height: '0.4rem',
                      borderRadius: '100%',
                      backgroundColor: 'aliceBlue60',
                    }}
                  />
                </ParentDevice>
              )}
              <Flex
                sx={{
                  width: ['100%', 'fit-content'],
                  alignItems: 'center',
                  ...(isFistOrSecondProfileNameTruncated
                    ? {
                        width: '100%',
                      }
                    : {}),
                }}
              >
                <TextWithOverFlowAndTippyPopup
                  variant="size12Weight400"
                  testId="profile-name-link"
                  onTextTruncation={
                    isSmallTablet ? () => setIsFistOrSecondProfileNameTruncated(true) : undefined
                  }
                  sxText={{
                    color: 'aliceBlue60',
                    textDecoration: 'underline',
                    maxWidth: ['100%', '100%', device.profile2?.name ? '22rem' : '100%'],
                    '&:hover': !isFirstEnforcedProfileShared && {
                      color: 'aliceBlue',
                    },
                  }}
                  content={
                    isFirstEnforcedProfileShared ? (
                      device.profile.name
                    ) : (
                      <Link
                        to={`/dashboard/profiles/${device.profile.PK}/filters${location.search}`}
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        {device.profile.name}
                      </Link>
                    )
                  }
                  ariaLabel={device.profile.name}
                />
                {device.profile2?.name && (
                  <Text
                    sx={{
                      mx: '0.4rem',
                      flexShrink: 0,
                      color: 'aliceBlue',
                      fontWeight: 'normal',
                    }}
                  >
                    &gt;
                  </Text>
                )}
                {device.profile2?.name && (
                  <TextWithOverFlowAndTippyPopup
                    variant="size12Weight400"
                    onTextTruncation={
                      isMobile || isSmallTablet
                        ? () => setIsFistOrSecondProfileNameTruncated(true)
                        : undefined
                    }
                    sxText={{
                      color: 'aliceBlue60',
                      textDecoration: 'underline',
                      maxWidth: ['100%', '100%', '25rem'],
                      '&:hover': !isSecondEnforcedProfileShared && {
                        color: 'white',
                      },
                      flexShrink: 2,
                    }}
                    content={
                      isSecondEnforcedProfileShared ? (
                        device.profile2.name
                      ) : (
                        <Link
                          to={`/dashboard/profiles/${device.profile2.PK}/filters${location.search}`}
                          style={{
                            textDecoration: 'none',
                            color: 'inherit',
                          }}
                        >
                          {device.profile2.name}
                        </Link>
                      )
                    }
                    ariaLabel={device.profile2.name}
                  />
                )}
                {existingSchedule && (
                  <Svg
                    svg={ScheduleIcon}
                    fill={isActive ? 'greenApple' : isNotActive ? 'banana' : 'aliceBlue60'}
                    sx={{ width: '1.6rem', height: '1.6rem', ml: '0.4rem' }}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        ref={secondChildRef}
        sx={{
          width: ['100%', 'auto'],
          alignItems: 'center',
          justifyContent: ['space-between', 'flex-start'],
          flexShrink: 0,
          ...(isSmallTablet
            ? {
                width: '100%',
                justifyContent: 'space-between',
              }
            : {}),
        }}
      >
        {!isSmallTablet && <DeviceStatusIcons device={device} isCompressed={isCompressed} />}
        <DeviceInfoButtons
          device={device}
          devicesWithParentDevice={devicesWithParentDevice}
          isSmallTablet={isSmallTablet}
        />
        <DeviceItemActionButtons
          isDevicePending={isDevicePending}
          devicePk={device.PK}
          deviceIcon={device.icon}
        />
      </Flex>
    </Flex>
  )
}
