// https://github.com/miguelmota/is-valid-hostname
import isValidHostname from 'is-valid-hostname'
import isIp from 'is-ip'

export function isHostnameValid(hostname?: string): boolean {
  // Converting hostname to string as sometimes hostname is treated as a number.
  hostname = '' + hostname

  return !!hostname && hostname?.length > 2 && hostname.includes('.') && isValidHostname(hostname)
}

export function isIpValid(ip?: string): boolean {
  // Converting ip to string as sometimes ip is treated as a number.
  ip = '' + ip

  return !!ip && (ip?.length > 2 || ip === '::') && isIp(ip)
}
