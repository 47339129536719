import React, { ReactElement, useEffect, useState } from 'react'
import { modalFooterRef } from 'ui/NewModalDialog/ModalDialogFooter'
import { createPortal } from 'react-dom'
import { Flex } from 'theme-ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'

export default function AddOrEditDeviceButtons({
  devicePk,
  isSaveButtonDisabled,
  isCreateButtonDisabled,
  isSavingDeviceLoading,
  isCreatingDeviceLoading,
  creatingDeviceText,
  onSubmit,
  onDeleteClick,
}: {
  devicePk?: string
  isSaveButtonDisabled: boolean
  isCreateButtonDisabled: boolean
  isSavingDeviceLoading: boolean
  isCreatingDeviceLoading: boolean
  creatingDeviceText?: string
  onSubmit: () => void
  onDeleteClick?: (devicePk: string) => void
}): ReactElement {
  return devicePk ? (
    <SaveAndDeleteButtons
      isSaveButtonDisabled={isSaveButtonDisabled}
      isDeleteButtonDisabled={!devicePk}
      isSaveLoading={isSavingDeviceLoading}
      onSaveClick={onSubmit}
      onDeleteClick={() => onDeleteClick?.(devicePk)}
    />
  ) : (
    <CreateButton
      isButtonDisabled={isCreateButtonDisabled}
      isRequestInFlight={isCreatingDeviceLoading}
      onClick={onSubmit}
      creatingDeviceText={creatingDeviceText}
    />
  )
}

function CreateButton({
  isButtonDisabled,
  isRequestInFlight,
  onClick,
  creatingDeviceText,
}: {
  isButtonDisabled: boolean
  isRequestInFlight: boolean
  onClick?: () => void
  creatingDeviceText?: string
}): ReactElement | null {
  const [shouldAppendButton, setShouldAppendButton] = useState(false)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current
    ? createPortal(
        <Flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <ButtonWithLoadingState
            shouldDisplaySpinnerAndText
            variant="newPrimary"
            disabled={isButtonDisabled}
            data-testid="add-device-button"
            ariaLabel="add device button"
            isLoading={isRequestInFlight}
            onClick={onClick}
            sxSpinner={{ width: '2rem', height: '2rem' }}
          >
            {creatingDeviceText || 'Create'}
          </ButtonWithLoadingState>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}

function SaveAndDeleteButtons({
  isSaveButtonDisabled,
  isDeleteButtonDisabled,
  isSaveLoading,
  onSaveClick,
  onDeleteClick,
}: {
  isSaveButtonDisabled: boolean
  isDeleteButtonDisabled: boolean
  isSaveLoading: boolean
  onSaveClick?: () => void
  onDeleteClick?: () => void
}): ReactElement | null {
  const [shouldAppendButton, setShouldAppendButton] = useState(false)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current
    ? createPortal(
        <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
          <ButtonWithLoadingState
            variant="newTertiary"
            disabled={isDeleteButtonDisabled}
            data-testid="delete-device-button"
            ariaLabel="delete device button"
            onClick={onDeleteClick}
            sxSpinner={{ width: '2rem', height: '2rem' }}
          >
            Delete
          </ButtonWithLoadingState>
          <ButtonWithLoadingState
            variant="newPrimary"
            disabled={isSaveButtonDisabled}
            data-testid="save-device-button"
            ariaLabel="save device button"
            isLoading={isSaveLoading}
            onClick={onSaveClick}
            sxSpinner={{ width: '2rem', height: '2rem' }}
          >
            Save
          </ButtonWithLoadingState>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}
