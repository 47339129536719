import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import {
  ActivateTrialOrFreePromoCodeBody,
  BillingPaymentsResponse,
  BillingSubscriptionsResponse,
  CancelSubscriptionBody,
  CreateCoingateOrderRequestBody,
  CreateOrgStripeSubscriptionBody,
  CreateStripeCustomerBody,
  CreateStripeCustomerResponse,
  CreateStripeSubscriptionBody,
  Invoice,
  SetupIntentData,
  StripeSubscription,
  TrialEligibilityResponse,
  UpdatePaymentMethodBody,
  UpdateStripeSubscriptionBody,
} from './payments.interface'
import { ApiResponse } from '../http'

export const paymentsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    activateTrialOrFreePromoCode: builder.mutation({
      query: (body: ActivateTrialOrFreePromoCodeBody) =>
        getQueryArgs('/billing/activate', 'POST', body),
      invalidatesTags: ['Payment'],
    }),
    getBillingPayments: builder.query({
      query: () => getQueryArgs('/billing/payments'),
      transformResponse: (response: BillingPaymentsResponse) => response.body,
      providesTags: ['Payment'],
    }),
    getBillingSubscriptions: builder.query({
      query: () => getQueryArgs('/billing/subscriptions'),
      transformResponse: (response: BillingSubscriptionsResponse) => response.body,
      providesTags: ['Payment'],
    }),
    checkTrialEligibility: builder.query({
      query: () => getQueryArgs('/billing/trial'),
      transformResponse: (response: TrialEligibilityResponse) => response.body,
      providesTags: ['Payment'],
    }),
    createStripeCustomer: builder.mutation({
      query: (body: CreateStripeCustomerBody) =>
        getQueryArgs('/billing/stripe/create-customer', 'POST', body),
      transformResponse: (response: CreateStripeCustomerResponse) => response.body,
    }),
    createStripeSubscription: builder.mutation({
      query: (body: CreateStripeSubscriptionBody) =>
        getQueryArgs('/billing/stripe/create-subscription', 'POST', body),
      transformResponse: (response: ApiResponse<{ subscription: StripeSubscription }>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
    }),
    createOrgStripeSubscription: builder.mutation({
      query: (body: CreateOrgStripeSubscriptionBody) =>
        getQueryArgs('/billing/stripe/create-org-subscription', 'POST', body),
      transformResponse: (response: ApiResponse<{ subscription: StripeSubscription }>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      invalidatesTags: ['Payment'],
    }),
    updateStripeSubscription: builder.mutation({
      query: (body: UpdateStripeSubscriptionBody) =>
        getQueryArgs('/billing/stripe/update-subscription', 'POST', body),
      transformResponse: (response: ApiResponse<{ subscription: StripeSubscription }>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
    }),
    retryStripeInvoice: builder.mutation({
      query: (body: { customerId: string; paymentMethodId: string; invoiceId: string }) =>
        getQueryArgs('/billing/stripe/retry-invoice', 'POST', body),
      transformResponse: (response: ApiResponse<{ invoice: Invoice }>) => response.body,
      invalidatesTags: ['Payment'],
    }),
    updatePaymentMethod: builder.mutation({
      query: (body: UpdatePaymentMethodBody) =>
        getQueryArgs('/billing/stripe/update-payment-method', 'POST', body),
      invalidatesTags: ['Payment'],
    }),
    createSetupIntent: builder.mutation({
      query: () => getQueryArgs('/billing/stripe/create-setup-intent', 'POST'),
      transformResponse: (response: ApiResponse<SetupIntentData>) => response.body,
      invalidatesTags: ['Payment'],
    }),
    cancelSubscription: builder.mutation({
      query: (body: CancelSubscriptionBody) =>
        getQueryArgs('/billing/stripe/cancel-subscription', 'DELETE', body),
      transformResponse: (response: ApiResponse<SetupIntentData>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      invalidatesTags: ['Payment'],
    }),
    createCoingateOrder: builder.mutation({
      query: (body: CreateCoingateOrderRequestBody) =>
        getQueryArgs('/billing/coingate/create-order', 'POST', body),
      transformErrorResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.data
      },
      invalidatesTags: ['Payment'],
    }),
  }),
})

export const {
  useActivateTrialOrFreePromoCodeMutation,
  useCheckTrialEligibilityQuery,
  useGetBillingPaymentsQuery,
  useGetBillingSubscriptionsQuery,
  useCreateCoingateOrderMutation,
} = paymentsApi
