import { EnabledStatus } from 'store/api/rules'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { DeviceInfo, DeviceStatus } from 'store/api/devices/devices.interface'
import { ProfileDropDownItem } from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import { NukeAnalyticsType, PutUserArgs } from 'store/api/user/user.interface'

export enum DeviceActionType {
  DEVICE_NAME = 'name',
  SELECTED_PROFILE = 'selectedProfile',
  SELECTED_PROFILE_2 = 'selectedProfile2',
  PROFILE_ID = 'profile_id',
  ICON = 'icon',
  COMMENT = 'desc',
  RESTRICTED_RESOLVER = 'restricted',
  LEGACY_RESOLVER = 'legacy_ipv4_status',
  AUTO_AUTH_IP = 'learn_ip',
  DDNS_STATUS = 'ddns_ext_status',
  DDNS_HOST = 'ddns_ext_host',
  EXPOSE_IP_STATUS = 'ddns_status',
  EXPOSE_IP_SUBDOMAIN = 'ddns_subdomain',
  ECH_SUPPORT = 'bump_tls',
  STATS = 'stats',
  REGION_SETTINGS = 'regionSettings',
  PREVENT_DEACTIVATION_STATUS = 'deactivationStatus',
  PREVENT_DEACTIVATION_PIN = 'prevent_deactivation_pin',
  STATUS = 'status',
  DELETE_DEVICE_DATA = 'user_nuke_analytics', // this deletes analytics data when analytics is disabled on device
  DELETE_ORG_DEVICE_DATA = 'nuke_analytics', // this deletes org analytics data when analytics is disabled on device
  RESET_SETTINGS = 'reset',
  CLIENT_COUNT = 'client_count',
}

export type RegionSettings = Partial<Pick<PutUserArgs, 'stats_endpoint' | NukeAnalyticsType.USER>> // this deletes analytics data on region change

export type ActionType = {
  type: DeviceActionType
  payload:
    | StatLevel
    | DeviceStatus
    | DeviceInfo
    | string
    | EnabledStatus
    | ProfileDropDownItem
    | RegionSettings
    | undefined
}

export interface InitialStateType extends Partial<Omit<DeviceInfo, 'status'>> {
  selectedProfile?: ProfileDropDownItem
  stats?: StatLevel
  regionSettings?: RegionSettings
  status?: DeviceStatus
  deactivationStatus?: EnabledStatus
  [NukeAnalyticsType.USER]?: EnabledStatus
  [NukeAnalyticsType.ORG]?: EnabledStatus
}

export const initialState: InitialStateType = {
  stats: StatLevel.NO,
  name: '',
}

export const settingsReducer = (state: InitialStateType, action: ActionType): InitialStateType => {
  switch (action.type) {
    case DeviceActionType.DEVICE_NAME:
      return {
        ...state,
        name: action.payload as string,
      }
    case DeviceActionType.ICON:
      return {
        ...state,
        icon: action.payload as string,
      }
    case DeviceActionType.SELECTED_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...(action.payload as ProfileDropDownItem),
        },
      }
    case DeviceActionType.SELECTED_PROFILE_2:
      return {
        ...state,
        profile2: action.payload
          ? {
              ...state.profile2,
              ...(action.payload as ProfileDropDownItem),
            }
          : undefined,
      }
    case DeviceActionType.COMMENT:
      return {
        ...state,
        desc: action.payload as string,
      }
    case DeviceActionType.RESTRICTED_RESOLVER:
      return {
        ...state,
        restricted: action.payload as EnabledStatus,
      }
    case DeviceActionType.LEGACY_RESOLVER:
      return {
        ...state,
        legacy_ipv4: {
          ...state.legacy_ipv4,
          status: action.payload as EnabledStatus,
        },
        learn_ip: (action.payload || state.learn_ip) as EnabledStatus,
      }
    case DeviceActionType.AUTO_AUTH_IP:
      return {
        ...state,
        learn_ip: action.payload as EnabledStatus,
      }
    case DeviceActionType.DDNS_STATUS:
      return {
        ...state,
        ddns_ext: {
          ...state.ddns_ext,
          status: action.payload as EnabledStatus,
        },
      }
    case DeviceActionType.DDNS_HOST:
      return {
        ...state,
        ddns_ext: {
          ...state.ddns_ext,
          host: action.payload as string,
        },
      }
    case DeviceActionType.EXPOSE_IP_STATUS:
      return {
        ...state,
        ddns: {
          ...state.ddns,
          status: action.payload as EnabledStatus,
        },
      }
    case DeviceActionType.EXPOSE_IP_SUBDOMAIN:
      return {
        ...state,
        ddns: {
          ...state.ddns,
          subdomain: action.payload as string,
        },
      }
    // TODO: should be uncommented when working on ESH
    // case DeviceActionType.ECH_SUPPORT:
    //   return {
    //     ...state,
    //     bump_tls: action.payload as EnabledStatus,
    //   }
    case DeviceActionType.STATS:
      return {
        ...state,
        ...(action.payload === StatLevel.NO ? { regionSettings: undefined } : {}),
        stats: action.payload as StatLevel,
      }
    case DeviceActionType.REGION_SETTINGS:
      return {
        ...state,
        regionSettings: action.payload as RegionSettings,
      }
    case DeviceActionType.PREVENT_DEACTIVATION_STATUS:
      return {
        ...state,
        deactivationStatus: action.payload as EnabledStatus,
      }
    case DeviceActionType.PREVENT_DEACTIVATION_PIN:
      return {
        ...state,
        deactivation_pin: action.payload as number,
      }
    case DeviceActionType.STATUS:
      return {
        ...state,
        status: action.payload as DeviceStatus,
      }
    case DeviceActionType.DELETE_DEVICE_DATA:
      return {
        ...state,
        [NukeAnalyticsType.USER]: action.payload as EnabledStatus,
      }
    case DeviceActionType.DELETE_ORG_DEVICE_DATA:
      return {
        ...state,
        [NukeAnalyticsType.ORG]: action.payload as EnabledStatus,
      }
    case DeviceActionType.CLIENT_COUNT:
      return {
        ...state,
        client_count: action.payload as number,
      }

    case DeviceActionType.RESET_SETTINGS:
      return {
        ...initialState,
        client_count: state.client_count,
      }
    default:
      throw new Error()
  }
}
