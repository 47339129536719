import { baseApi, getQueryArgs } from '../index'
import { ANALYTICS_BASE_URL } from 'gatsby-env-variables'
import { ApiResponse, getFile } from '../http'
import {
  addQuery,
  deleteLogs,
  startSessionTimer,
  updateEventSourceHandler,
} from 'store/activityLog'
import { RuleType } from '../rules'
import { ResolverTypes } from 'store/api/devices/devices.interface'
import { RootState } from 'store/rootReducer'
import { transformStatsErrorResponse } from 'store/api/analytics'

export enum ActionTrigger {
  FILTER = 'filter',
  SERVICE = 'service',
  CUSTOM = 'custom',
  LOCATION = 'location',
  DEFAULT = 'default',
  REBIND = 'rebind',
  GLOBAL = 'grule',
}

export interface GeoIPDetails {
  asn: number
  city: string
  countryCode: string
  isp: string
}

export interface ActivityLog {
  id: string // uniqueId generated on the front end for rendering purposes
  timestamp: string
  userId: string
  deviceId: string
  clientId?: string
  question: string
  rrType: string
  sourceIp: string
  statusCode: number
  protocol: ResolverTypes
  action: RuleType
  actionTrigger: ActionTrigger
  actionTriggerValue: string
  actionSpoofTarget: string
  answers: { ips: string[]; geoip: GeoIPDetails }[]
  sourceGeoip?: GeoIPDetails
}

export type HistoricalLogsMetaData = {
  page: number
  pageSize: number
}

export const getQueryLogsAsCSV = (region: string, queryString: string): Promise<Response> => {
  return getFile(
    `/queries/historical/csv?${queryString}`,
    undefined,
    `https://${region}.${ANALYTICS_BASE_URL}`,
  )
}

export const activityLogApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getActivityLogStream: builder.query({
      query: ({ region }: { isEventSourceOpen: boolean; region: string; queryString: string }) =>
        getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}/auth/token`),
      async onCacheEntryAdded(
        { region, queryString },
        { dispatch, getState, updateCachedData, cacheDataLoaded },
      ) {
        try {
          const eventSourceHandler = (getState() as RootState).activityLog.resetEventSource

          if (eventSourceHandler) {
            // close the existing event source if there is one
            eventSourceHandler()
            dispatch(deleteLogs())
          }
          const { data } = await cacheDataLoaded
          const eventSrc = new EventSource(
            `https://${region}.${ANALYTICS_BASE_URL}/queries/realtime?authToken=${data.body.token}&${queryString}`,
          )

          if (eventSrc) {
            eventSrc.onmessage = event => {
              const data = JSON.parse(event.data)

              updateCachedData(() => {
                dispatch(addQuery(data))
              })
            }

            // start the timer for the session if there is no session running
            if (!eventSourceHandler) {
              dispatch(startSessionTimer())
            }

            dispatch(
              updateEventSourceHandler(() => {
                if (eventSrc.OPEN) {
                  eventSrc.close()
                }
              }),
            )
          }
        } catch {}
      },
      transformErrorResponse: transformStatsErrorResponse,
    }),
    getHistoricalLogs: builder.query({
      query: ({ region, queryString }: { region: string; queryString: string }) =>
        getQueryArgs(`https://${region}.${ANALYTICS_BASE_URL}/queries/historical?${queryString}`),
      transformResponse: (
        response: ApiResponse<{ queries: ActivityLog[]; meta: HistoricalLogsMetaData }>,
      ) => response.body,
      transformErrorResponse: transformStatsErrorResponse,
    }),
  }),
})

export const { useGetActivityLogStreamQuery, useGetHistoricalLogsQuery } = activityLogApi
