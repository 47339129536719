import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { ApiResponse } from 'store/api/http'

export interface NotificationLink {
  title: string
  url: string
}

export interface Notification {
  PK: string
  date: number
  title: string
  message: string
  links?: NotificationLink[]
  header_img?: string
}

export type NotificationsResponse = ApiResponse<{ notifications: Notification[] }>

export const notificationsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query({
      query: () => getQueryArgs('/notifications'),
      transformResponse: (response: NotificationsResponse) => response.body,
      transformErrorResponse,
      providesTags: () => ['Notifications'],
    }),
  }),
})

export const { endpoints, useGetNotificationsQuery } = notificationsApi
