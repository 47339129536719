import { useEffect, useState } from 'react'

// set the boundary that tippy dropdown adheres to
export default function useBoundaryElement(dataTestId: string): Element | 'parent' {
  const [boundaryElement, setBoundaryElement] = useState<Element | 'parent'>('parent')

  useEffect(() => {
    if (dataTestId === 'parent') {
      return setBoundaryElement(dataTestId)
    }

    const element = document.querySelector(`[data-testid=${dataTestId}]`) as HTMLElement

    setBoundaryElement(element)
  }, [dataTestId])

  return boundaryElement
}
