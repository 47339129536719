import React, { ReactElement, ReactNode } from 'react'
import { Flex, Box } from 'theme-ui'

export default function TabOutline({
  dropdown,
  children,
}: {
  dropdown?: ReactNode
  children?: ReactNode
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: '2.4rem',
        pb: '3.2rem',
      }}
    >
      {dropdown && (
        <Box
          sx={{
            width: '100%',
          }}
        >
          {dropdown}
        </Box>
      )}
      {children}
    </Flex>
  )
}
