import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import { ApiResponse } from 'store/api/http'
import {
  DeleteUserArgs,
  LoginRequestArgs,
  PreAuthDataResponse,
  PutUserArgs,
  ResetPwdArgs,
  UserDataResponse,
  UsersRegisterArgs,
} from './user.interface'
import { V4_CHECK_URL } from 'gatsby-env-variables'
import { setSessionUpdate } from 'store/session'

export const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUnImpersonatedUser: builder.query({
      query: () => ({
        url: '/users',
      }),
      transformResponse: (response: UserDataResponse) => {
        return response.body
      },
    }),
    // use useGetUser custom hook instead of useGetUserQuery
    getUser: builder.query({
      query: () => getQueryArgs('/users'),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(setSessionUpdate(data))
        } catch {}
      },
      providesTags: ['User'],
    }),
    putUser: builder.mutation({
      query: (body: PutUserArgs) => getQueryArgs('/users', 'PUT', body),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            userApi.util.updateQueryData('getUser', '', draft => {
              Object.assign(draft, data.body)
            }),
          )
          dispatch(setSessionUpdate(data.body))
        } catch {}
      },
    }),
    deleteUser: builder.mutation({
      query: (body: DeleteUserArgs) => getQueryArgs('/users', 'DELETE', body),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response
      },
    }),
    userLogin: builder.mutation({
      query: (body: LoginRequestArgs) => getQueryArgs('/users/login', 'POST', body),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
    }),
    preAuthUserLogin: builder.mutation({
      query: (body: LoginRequestArgs) => getQueryArgs('/preauth/login', 'POST', body),
      transformResponse: (response: PreAuthDataResponse) => response.body,
    }),
    preAuthUserRegister: builder.mutation({
      query: (body: UsersRegisterArgs) => getQueryArgs('/preauth/register', 'POST', body),
      transformResponse: (response: PreAuthDataResponse) => response.body,
    }),
    userRegister: builder.mutation({
      query: (body: UsersRegisterArgs) => getQueryArgs('/users/register', 'POST', body),
      transformResponse: (response: UserDataResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          await new Promise(r => setTimeout(r, 1000))
          dispatch(
            userApi.util.updateQueryData('getUser', '', draft => {
              Object.assign(draft, data)
            }),
          )
        } catch {}
      },
    }),
    postConfirmEmailResend: builder.mutation({
      query: () => getQueryArgs('/users/confirm_email/resend', 'POST'),
      transformResponse: (response: ApiResponse<[]>) => response,
    }),
    resetPassword: builder.mutation({
      query: (body: ResetPwdArgs) => getQueryArgs('/users/forgot_pass/change', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => {
        return response.body
      },
      transformErrorResponse,
    }),
    forgotPassword: builder.mutation({
      query: (body: { email: string }) => getQueryArgs('/users/forgot_pass', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => response.body,
    }),
    confirmEmail: builder.mutation({
      query: (body: { hash: string }) => getQueryArgs('/users/confirm_email/', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => response,
      transformErrorResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            userApi.util.updateQueryData('getUser', '', draft => {
              draft.email_status = 1
            }),
          )
        } catch {}
      },
    }),
    unsubscribeEmail: builder.mutation({
      query: (body: { hash: string }) => getQueryArgs('/users/unsubscribe_email', 'POST', body),
      transformResponse: (response: ApiResponse<[]>) => response,
    }),
    learnIpv4: builder.query({
      query: () => getQueryArgs(`${V4_CHECK_URL}/users`),
      transformResponse: (response: ApiResponse<[]>) => response.body,
    }),
  }),
})

export const {
  useGetUnImpersonatedUserQuery,
  useGetUserQuery,
  usePutUserMutation,
  useDeleteUserMutation,
  useConfirmEmailMutation,
  useUnsubscribeEmailMutation,
  useForgotPasswordMutation,
  usePostConfirmEmailResendMutation,
  useResetPasswordMutation,
} = userApi
