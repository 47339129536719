import React, { ReactElement } from 'react'
import { Box, Text } from 'theme-ui'
import OrgBillingImage from 'images/tutorialFlows/OrgBilling/org-billing-slide.svg'
import OrgBillingImageLight from 'images/tutorialFlows/OrgBilling/org-billing-slide-light.svg'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import useGetColorMode from 'utils/useGetColorMode'

export function OrgBillingSlide(): ReactElement {
  const { isLightMode } = useGetColorMode()

  return (
    <TutorialBaseSlide
      illustration={isLightMode ? OrgBillingImageLight : OrgBillingImage}
      description={
        <Box>
          <Text>
            The <Highlight svg={tutorialSlideIcons.billing}>Billing</Highlight> page is where you
            can see the total number of{' '}
            <Highlight svg={tutorialSlideIcons.endpoints}>Connected Devices</Highlight> and manage
            your payment methods for this{' '}
            <Highlight svg={tutorialSlideIcons.org}>Organization</Highlight>. <br /> <br /> The
            parent <Highlight svg={tutorialSlideIcons.org}>Organization</Highlight> is responsible
            for all <Highlight svg={tutorialSlideIcons.subOrg}>Sub-Organization</Highlight> billing.
          </Text>
        </Box>
      }
    />
  )
}
