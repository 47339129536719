import { createSlice, Action, SerializedError } from '@reduxjs/toolkit'
import { QueryFilterType, StatLevel } from 'store/api/analytics/analytics.interface'
import { VerdictTypeFilter } from 'components/Dashboard/Analytics/Statistics/QueriesByVerdictLineGraph/QueriesByVerdictLineGraph.interface'
import { PieData } from 'ui/Analytics/PieChart'
import { ResolverType } from 'components/SetupGuide/SetupGuide.interface'
import { PresetTimeRange } from 'ui/Analytics/PresetTimeRange'
import { clearStateForImpersonation } from 'store/organization'
import { devicesApi } from 'store/api/devices/devices'
import { sessionLogout } from 'store/session'

export const maxDaysForGranularAnalyticsData = 32

interface PayloadAction<T> extends Action {
  payload: T
  error?: SerializedError
}

export interface TimeRange {
  startTs: number
  endTs: number
}

export interface DropdownsData {
  mode?: StatLevel
  deviceId?: string
  region?: string
}

export type ProtocolType = 'all' | ResolverType

export interface AnalyticsState {
  timeRange: TimeRange
  filterType: QueryFilterType
  filterParameter: string
  filterParameters: {
    [key in QueryFilterType]?: string
  }
  selectedDeviceId?: string
  selectedClientId?: string
  dropdownsData: DropdownsData
  verdictTypeFilter: VerdictTypeFilter
  selectedPresetTimeRange: PresetTimeRange
  activeServices: PieData[] | null
  activeFilters: PieData[] | null
  activeLocations: PieData[] | null
}

export const initialEndTs = Date.now()
export const initialAnalyticsTimeRange = {
  startTs: initialEndTs - 1000 * 60 * 60 * 24,
  endTs: initialEndTs,
}
const initialState: AnalyticsState = {
  /* The default time range is last day, though this shouldn't actually matter until it's set by the user
  because we now (#2509) default the user to the PresetTimeRange value of LAST_DAY anyway */
  timeRange: initialAnalyticsTimeRange,
  filterType: QueryFilterType.ALL,
  filterParameter: '',
  filterParameters: {
    [QueryFilterType.FILTERS]: '',
    [QueryFilterType.SERVICES]: '',
    [QueryFilterType.LOCATIONS]: '',
  },
  selectedDeviceId: undefined,
  selectedClientId: undefined,
  verdictTypeFilter: 'all',
  dropdownsData: {},
  selectedPresetTimeRange: PresetTimeRange.LAST_DAY,
  activeServices: [],
  activeFilters: [],
  activeLocations: [],
}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setTimeRange(state, { payload }: PayloadAction<TimeRange>): void {
      state.timeRange = payload
    },
    setFilterType(state, { payload }: PayloadAction<QueryFilterType>): void {
      state.filterType = payload
    },
    setFilterParameter(state, { payload }: PayloadAction<string>): void {
      state.filterParameter = payload
    },
    setFilterParameters(
      state,
      {
        payload,
      }: PayloadAction<{
        [key in QueryFilterType]?: string
      }>,
    ): void {
      state.filterParameters = { ...state.filterParameters, ...payload }
    },

    resetFilterParameters(state): void {
      state.filterParameters = initialState.filterParameters
    },

    /**
     * use this to set the selected device id but not fetch all reports
     * use `updateSelectedDeviceId` instead to fetch all reports on device change
     * @param state
     * @param param1
     */
    setSelectedDeviceId(state, { payload }: PayloadAction<string | undefined>): void {
      state.selectedDeviceId = payload
      state.selectedClientId = undefined
    },
    setSelectedClientId(state, { payload }: PayloadAction<string | undefined>): void {
      state.selectedClientId = payload
    },
    setVerdictTypeFilter(state, { payload }: PayloadAction<VerdictTypeFilter>): void {
      state.verdictTypeFilter = payload
    },
    setSelectedPresetTimeRange(state, { payload }: PayloadAction<PresetTimeRange>): void {
      state.selectedPresetTimeRange = payload
    },
    setAnalyticsDropdownsData(state, { payload }: PayloadAction<DropdownsData | undefined>): void {
      state.dropdownsData = { ...state.dropdownsData, ...payload }
    },
    setActiveFilters(state, { payload }: PayloadAction<PieData[] | null>): void {
      state.activeFilters = payload
    },
    setActiveServices(state, { payload }: PayloadAction<PieData[] | null>): void {
      state.activeServices = payload
    },
    setActiveLocations(state, { payload }: PayloadAction<PieData[] | null>): void {
      state.activeLocations = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, () => {
        return initialState
      })
      .addCase(clearStateForImpersonation.fulfilled, state => {
        state.selectedDeviceId = undefined
        state.selectedClientId = undefined
      })
      .addMatcher(devicesApi.endpoints.deleteDevice.matchFulfilled, (state, action) => {
        if (state.selectedDeviceId === action.meta.arg.originalArgs.pk) {
          state.selectedDeviceId = undefined
        }
      })
      .addMatcher(devicesApi.endpoints.deleteDeviceClient.matchFulfilled, (state, action) => {
        if (state.selectedClientId === action.meta.arg.originalArgs.clientId) {
          state.selectedClientId = undefined
        }
      })
  },
})

export const {
  setVerdictTypeFilter,
  setSelectedPresetTimeRange,
  setAnalyticsDropdownsData,
  setFilterParameters,
  resetFilterParameters,
  setSelectedDeviceId,
  setSelectedClientId,
  setActiveFilters,
  setActiveServices,
  setActiveLocations,
  setTimeRange,
} = analyticsSlice.actions
