import React, { ReactElement } from 'react'
import TabsContainer, { Tab } from 'components/HomePage/TabsContainer'
import { Text } from 'theme-ui'
import type { Setter } from 'utils'
import { Flex } from '@theme-ui/components'
import { Svg } from 'ui'
import ActiveTabIcon from 'images/dashboard/devices/active-tab.svg'
import DefaultTabIcon from 'images/dashboard/devices/default-tab.svg'
import RoutersIcon from 'images/dashboard/navBar/device-routers-icon.svg'
import UsersIcon from 'images/dashboard/navBar/device-users-icon.svg'

export enum DeviceTab {
  CLIENT = 1,
  SERVER = 5,
}

const deviceTabs = [
  { text: 'Client', value: DeviceTab.CLIENT, description: 'End-user devices' },
  { text: 'Server', value: DeviceTab.SERVER, description: 'Routers and firewalls' },
]

const AddOrEditDeviceTabs = ({
  selectedTab,
  setSelectedTab,
  resetDeviceInput,
}: {
  selectedTab: DeviceTab
  setSelectedTab: Setter<DeviceTab>
  resetDeviceInput: () => void
}): ReactElement => {
  return (
    <TabsContainer
      sx={{
        display: 'flex',
        border: 'none',
        width: '100%',
        gap: '1.2rem',
      }}
    >
      {deviceTabs.map(option => (
        <Tab
          key={option.text}
          onClick={() => {
            setSelectedTab(option.value)
            resetDeviceInput()
          }}
          isActive={selectedTab === option.value}
          sxTab={{
            px: '1.2rem',
            py: '0.8rem',
            border: '1px solid',
            borderColor: selectedTab === option.value ? 'greenApple' : 'blueYonder30',
            borderRadius: '0.8rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1.2rem',
            '&:hover': {
              '& > div > span:last-of-type': {
                color: 'aliceBlue60',
              },
              backgroundColor: selectedTab === option.value ? 'transparent' : 'white6',
            },
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Flex sx={{ gap: '0.6rem', alignItems: 'center' }}>
              <Svg
                svg={option.value === 1 ? UsersIcon : RoutersIcon}
                fill="aliceBlue"
                sx={{ width: '1.6rem', height: '1.6rem' }}
              />
              <Text variant="size15Weight600" sx={{ color: 'aliceBlue' }}>
                {option.text}
              </Text>
            </Flex>
            <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
              {option.description}
            </Text>
          </Flex>
          <Svg svg={selectedTab === option.value ? ActiveTabIcon : DefaultTabIcon} />
        </Tab>
      ))}
    </TabsContainer>
  )
}

export default AddOrEditDeviceTabs
