import React, { ReactNode, ElementType } from 'react'
import { Button, Svg } from 'ui'
import { Flex, Text, Box, ThemeUIStyleObject } from 'theme-ui'
import { ProxyLocation } from 'store/api/proxies'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'

interface ProxyMenuItemProps {
  onClick?: (pk?: string) => void
  location: ProxyLocation
  leftSvg?: ElementType
  rightSvg?: ReactNode
  rightSvgHover?: ReactNode
  isSelected: boolean

  // The color that should be used when the item is selected. For example,
  // in the default redirect menu, the selected location has "text" color when
  // disconnected and "primary" when connected.
  selectedItemColor: string
  shouldShowCountryName?: boolean
  isBackgroundHighlighted?: boolean
  sx?: ThemeUIStyleObject
}

export default function ProxyMenuItem({
  onClick = (): void => {},
  location,
  leftSvg,
  rightSvg,
  rightSvgHover,
  isSelected,
  selectedItemColor,
  shouldShowCountryName = true,
  isBackgroundHighlighted,
  sx,
  ...attrs
}: ProxyMenuItemProps): React.ReactElement {
  return (
    <Button
      variant="simple"
      onClick={(e): void => {
        e.stopPropagation()
        onClick(location.PK)
      }}
      ariaLabel={`select ${location.city}`}
      onKeyDown={stopPropagationOnKeyPress}
      sx={{
        justifyContent: 'flex-start',
        cursor: 'pointer',
        height: '3.8rem',
        ml: '2.4rem',
        pl: 0,
        pr: '2.4rem',
        width: 'calc(100% - 2.4rem)',
        color: 'aliceBlue60',
        backgroundColor: isBackgroundHighlighted && isSelected ? 'white10' : 'transparent',
        '& div': {
          color: isSelected ? selectedItemColor : 'aliceBlue60',
        },
        '.right-svg': {
          display: 'flex',
        },
        '.right-svg-hover': {
          display: 'none',
        },
        '& svg': {
          fill: selectedItemColor,
        },
        '@media (hover: hover)': {
          ':hover': {
            backgroundColor: isBackgroundHighlighted ? 'white6' : 'transparent',
            '& div': {
              color: isSelected ? selectedItemColor : 'aliceBlue',
            },
            '.right-svg-hover': {
              display: 'block',
            },
            '& svg:last-child path': {
              fill: isSelected ? 'greenApple' : 'aliceBlue',
            },
          },
        },
        ':not(:last-child)': {
          borderBottom: ({ colors }): string => `1px solid ${colors?.white15}`,
        },
        ...sx,
      }}
      {...attrs}
    >
      {leftSvg && <Svg svg={leftSvg} sx={{ mr: '0.8rem' }} />}
      <Flex sx={{ flex: '1' }}>
        <Text variant="size14Weight600">
          {`${location.city}${shouldShowCountryName ? `, ${location.country}` : ''}` || 'Locations'}
        </Text>
      </Flex>
      <Box className="right-svg">{rightSvg}</Box>
      <Box className="right-svg-hover">{rightSvgHover}</Box>
    </Button>
  )
}
