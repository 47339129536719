import React, { ReactElement, ReactNode, useState } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { format, toDate } from 'date-fns'
import { Accordion } from 'ui'
import TokenActions from 'components/Dashboard/Preferences/Api/TokenActions'
import Dots from 'components/Dashboard/Preferences/Api/Dots'
import type { TokenType, Token } from 'store/api/token/token.interface'
import customUnescape from 'utils/customUnescape'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

interface TokenItemProps {
  token: Token
  tokenTypes?: TokenType
}

export default function TokenAccordionItem({ token, tokenTypes }: TokenItemProps): ReactElement {
  const [showPassword, setShowPassword] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const tokenName = customUnescape(token.name)

  return (
    <Accordion
      key={token.PK}
      title={tokenName}
      id={`item-${tokenName}`}
      openTitleStyle={{ color: 'aliceBlue', fontSize: '1.2rem', opacity: 1 }}
      titleStyle={{ color: 'aliceBlue', fontSize: '1.2rem', opacity: 0.6 }}
      buttonStyle={{
        p: '1.2rem',
        pb: isOpen ? '0.8rem' : '1.2rem',
        '& > div svg path': {
          opacity: isOpen ? 1 : 0.6,
        },
      }}
      fillColor="aliceBlue"
      containerStyle={{
        backgroundColor: 'cardBg',
        borderBottom: '1px solid',
        borderColor: 'white6',
        ':first-of-type': { borderTop: '1px solid', borderColor: 'white6' },
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={isOpen => {
        setIsOpen(isOpen)
      }}
    >
      <Flex sx={{ flexDirection: 'column', gap: '0.8rem', px: '1.2rem', pb: '1.2rem' }}>
        <RowContainer>
          <Text variant="size15Weight700" tabIndex={0}>
            Created on
          </Text>
          <Text variant="size15Weight400" sx={{ opacity: 0.6 }} tabIndex={0}>
            {format(toDate(token.ts * 1000), 'd MMMM yyyy')}
          </Text>
        </RowContainer>
        <RowContainer>
          <Text variant="size15Weight700" tabIndex={0}>
            Type
          </Text>
          <Text variant="size15Weight400" sx={{ opacity: 0.6 }} tabIndex={0}>
            {tokenTypes?.[token.type]}
          </Text>
        </RowContainer>
        <RowContainer>
          <Text variant="size15Weight700" sx={{ mr: '1.6rem', flexShrink: 0 }} tabIndex={0}>
            Token
          </Text>
          {showPassword ? (
            <TextWithOverFlowAndTippyPopup
              theme="orgs-homepage"
              content={
                // Wrapped into Text because, otherwise the long token sticks out of tooltip on small screens
                <Text
                  variant="size15Weight400"
                  sx={{
                    opacity: 0.6,
                  }}
                  tabIndex={0}
                >
                  {token.token}
                </Text>
              }
              ariaLabel={token.token}
            />
          ) : (
            <Dots />
          )}
        </RowContainer>
        {token.PK && (
          <TokenActions
            PK={token.PK}
            token={token.token}
            {...{
              showPassword,
              setShowPassword,
            }}
          />
        )}
      </Flex>
    </Accordion>
  )
}

export function RowContainer({
  children,
  sx,
}: {
  children: ReactNode
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', height: '2.4rem', ...sx }}>
      {children}
    </Flex>
  )
}
