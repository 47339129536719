import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'

export const trackingApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    startCampaignTracking: builder.mutation({
      query: ({ path, cid, entryPage }: { path?: string; cid: string; entryPage: string }) =>
        getQueryArgs(`/pixel/${path || 'entry'}`, 'POST', { cid, entry_page: entryPage }),
      transformErrorResponse,
      invalidatesTags: ['Tracking'],
    }),
  }),
})

export const { endpoints, useStartCampaignTrackingMutation } = trackingApi
