import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationsResponse } from 'store/api/notifications'
import { ResponseState, initialResponseState } from 'store/fetchingLogic'
import difference from 'lodash/difference'
import { notificationsApi } from 'store/api/notifications/notifications'

export interface NotificationsState extends ResponseState<NotificationsResponse> {
  savedNotificationKeysByUserPk: Record<string, string[]>
  newNotificationKeys?: string[]
}
const initialState: NotificationsState = {
  ...initialResponseState,
  savedNotificationKeysByUserPk: {},
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    markAllRead(
      state,
      { payload }: PayloadAction<{ userPk: string; incomingNotificationKeys: string[] }>,
    ): void {
      state.savedNotificationKeysByUserPk[payload.userPk] = payload.incomingNotificationKeys
      state.newNotificationKeys = undefined
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      notificationsApi.endpoints.getNotifications.matchFulfilled,
      (state, action) => {
        // userPk should also be parsed, otherwise we can get "pk-value" instead of pk-value and these will be considered different keys
        const userPk =
          JSON.parse(JSON.parse(localStorage.getItem('persist:session') || '')?.userPk) || ''

        const incomingNotificationKeys = action.payload.notifications.map(n => n.PK)
        const savedNotificationKeys = state.savedNotificationKeysByUserPk?.[userPk ?? ''] ?? []
        const newNotificationKeys = difference(incomingNotificationKeys, savedNotificationKeys)
        if (savedNotificationKeys.length > 0) {
          state.newNotificationKeys = newNotificationKeys
        } else {
          // else save all the notifications that were returned to local storage
          if (userPk) {
            state.savedNotificationKeysByUserPk[userPk] = newNotificationKeys
          }
        }
      },
    )
  },
})

export const { markAllRead } = notificationsSlice.actions
