import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'

export default function Footer({
  previousButton,
  nextButton,
  textBanner,
}: {
  previousButton?: ReactElement
  nextButton?: ReactElement
  textBanner?: ReactElement
}): ReactElement {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
      }}
    >
      {previousButton}
      {textBanner}
      {nextButton}
    </Flex>
  )
}
