import React, { Dispatch, ReactElement } from 'react'
import SettingsItem from './SettingsItem'
import { Input, Switch } from 'ui'
import { EnabledStatus } from 'store/api/rules'
import {
  ActionType,
  DeviceActionType,
  InitialStateType,
} from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/SettingsState'

const DynamicDNS = ({
  settingsState,
  settingsDispatch,
}: {
  settingsState: InitialStateType
  settingsDispatch: Dispatch<ActionType>
}): ReactElement => {
  return (
    <>
      <SettingsItem
        isTopBorder={false}
        title="Dynamic DNS"
        description="Scrape a DDNS hostname for IP changes and authorize Legacy DNS access."
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '& > div': {
            p: 0,
            m: 0,
          },
        }}
        actionContent={
          <Switch
            isCompact
            data-testid="ddns-status-switch"
            ariaLabel="ddns status switch"
            checked={!!settingsState.ddns_ext?.status ?? EnabledStatus.DISABLED}
            onClick={() => {
              settingsDispatch({
                type: DeviceActionType.DDNS_STATUS,
                payload: settingsState.ddns_ext?.status
                  ? EnabledStatus.DISABLED
                  : EnabledStatus.ENABLED,
              })
            }}
          />
        }
      />
      {!!settingsState.ddns_ext?.status ? (
        <Input
          isCompact
          name="ddns-host-input"
          data-testid="ddns-host-input"
          aria-label="ddns hostname input"
          value={settingsState.ddns_ext?.host}
          placeholder="Enter DDNS Hostname"
          containerStyle={{ width: '100%' }}
          onChange={(event): void => {
            settingsDispatch({
              type: DeviceActionType.DDNS_HOST,
              payload: event.target.value,
            })
          }}
        />
      ) : null}
    </>
  )
}

export default DynamicDNS
