import React, { ReactElement, useMemo, useRef } from 'react'
import { Svg, SimpleActionMenuItemProps } from 'ui'
import { useAppSelector } from 'store/hooks'
import { Setter } from 'utils'
import { EnabledStatus, RuleType } from 'store/api/rules/rules.interface'
import { TargetGroup } from 'components/Dashboard/Profiles/CustomRules/MoveRulesMenu'
import { Flex } from '@theme-ui/components'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import CheckIcon from 'images/analytics/check.svg'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'
import { useGetGroupsQuery } from 'store/api/groups'
import { ROOT_GROUP } from 'store/api/rules'
import { useGetProfilesQuery } from 'store/api/profiles'
import customUnescape from 'utils/customUnescape'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'

interface FolderSelectionDropdownProps {
  selectedProfileId?: string
  targetGroup?: TargetGroup
  setTargetGroup: Setter<TargetGroup | undefined>
  selectedGroupPK: number
  shouldAllowMovingRuleAnywhere?: boolean
  isSharedProfile?: boolean
}

const rootFolder = {
  PK: ROOT_GROUP,
  text: 'Root Folder',
  group: '',
  action: {
    status: EnabledStatus.ENABLED,
    do: RuleType.WHITELIST,
  },
}

function FolderSelectionDropdown({
  targetGroup,
  setTargetGroup,
  selectedGroupPK,
  shouldAllowMovingRuleAnywhere,
  selectedProfileId,
  isSharedProfile,
}: FolderSelectionDropdownProps): ReactElement {
  const { searchParam } = useAppSelector(s => s.customRules)
  const hideRef = useRef<() => void>()
  useGetProfilesQuery('')
  const rulesProfile = useGetSelectedProfile()
  const { data: groupsData } = useGetGroupsQuery(
    {
      profileId: selectedProfileId ?? rulesProfile?.PK ?? '',
      ignoreImpersonation: isSharedProfile,
    },
    { skip: !selectedProfileId && !rulesProfile?.PK },
  )

  const filteredGroupsData = (
    shouldAllowMovingRuleAnywhere
      ? groupsData?.groups || []
      : (groupsData?.groups || [])?.filter(x => x.PK !== selectedGroupPK)
  ).map(x => ({
    ...x,
    text: customUnescape(x.group) || 'Root Folder',
  }))

  /* If we are not currently in the root folder, and if the current list of folders we have stored does not already include
  the root folder (in the case where we are refreshing the page from being inside a subfolder, for example) or user search
  for all rules in the root folder, or we should be allowing the user to select any folder (as in the case of adding a rule
  and picking a folder to add it to) and we are not in the root folder, add the root folder to the list of folders */
  if (
    !(groupsData?.groups || []).find(f => f.PK === ROOT_GROUP) ||
    searchParam ||
    (shouldAllowMovingRuleAnywhere && selectedGroupPK !== ROOT_GROUP)
  ) {
    filteredGroupsData.unshift(rootFolder)
  }

  const groupTitle = customUnescape(groupsData?.groups?.find(f => f.PK === selectedGroupPK)?.group)

  const options: SimpleActionMenuItemProps[] = useMemo(
    () =>
      filteredGroupsData.map(group => {
        return {
          isSelected: group.PK === (targetGroup?.PK ?? selectedGroupPK),
          ariaLabel: `${group?.text} option`,
          isBackgroundHighlighted: true,
          buttonContent: (
            <TextWithOverFlowAndTippyPopup
              content={group?.text ?? ''}
              ariaLabel={group?.text ?? ''}
              sxText={{
                fontSize: '1.4rem',
                maxWidth: '32rem',
              }}
            />
          ),
          children: (
            <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextWithOverFlowAndTippyPopup
                variant="size15Weight600"
                content={group?.text ?? ''}
                ariaLabel={group?.text ?? ''}
                sxText={{
                  maxWidth: '32rem',
                }}
              />
              {group.PK === (targetGroup?.PK ?? selectedGroupPK) && (
                <Svg svg={CheckIcon} fill="aliceBlue60" sx={{ flexShrink: 0 }} />
              )}
            </Flex>
          ),
          onClick: () => {
            setTargetGroup(group)
            hideRef.current?.()
          },
        }
      }),
    [filteredGroupsData, setTargetGroup, selectedGroupPK, targetGroup?.PK],
  )

  return (
    <SimpleDropdown
      isSearchVisible
      disabled={!groupsData?.groups.length}
      testId="select-folder-dropdown"
      ariaLabel="select folder dropdown"
      defaultValue={
        groupTitle || !searchParam
          ? `${groupTitle || 'Root Folder'} ${shouldAllowMovingRuleAnywhere ? '' : '(Current)'}`
          : 'No Folder Selected'
      }
      items={options}
      hideRef={hideRef}
      label={'Choose Folder'}
    />
  )
}

export default React.memo(FolderSelectionDropdown)
