import React, { Dispatch, ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import SettingsItem from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/SettingsItem'
import AnalyticsIcon from 'images/profileManagement/settings/analytics.svg'
import { ExternalLink, Input, Switch } from 'ui'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import AnalyticsDropdown from './AnalyticsDropdown'
import { ActionType, InitialStateType, ProvisionActionType } from './ProvisionState'
import PreventDeactivationIcon from 'images/profileManagement/settings/prevent-deactivation.svg'
import { EnabledStatus } from 'store/api/rules'
import ErrorBox from 'components/LoginSignUpForm/ErrorBox'
import { numericalMask } from './AddProvisionView'
import { isDeactivationPinLengthValid } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/AddOrEditDevice/helpers'
import AdvancedSettings from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/AdvancedSettings'

export default function ProvisionSettings({
  provisionState,
  provisionDispatch,
}: {
  provisionState: InitialStateType
  provisionDispatch: Dispatch<ActionType>
}): ReactElement {
  return (
    <AdvancedSettings>
      <Flex data-testid="provision-analytics-dropdown-container" sx={{ flexDirection: 'column' }}>
        <SettingsItem
          testId="analytics"
          icon={AnalyticsIcon}
          title="Analytics"
          description="Log DNS queries and generate activity reports."
          descriptionLink={
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/analytics-levels`}
              sx={{
                color: 'aliceBlue60',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          }
          actionContent={
            <Box
              data-testid="provision-analytics-dropdown-container"
              sx={{ position: 'relative', flexShrink: 0 }}
              className="reset-tooltip"
            >
              <AnalyticsDropdown
                deviceStats={provisionState.stats}
                settingsDispatch={provisionDispatch}
                boundaryDataTestId="provision-analytics-dropdown-container"
              />
            </Box>
          }
        />

        <SettingsItem
          testId="prevent-deactivation"
          icon={PreventDeactivationIcon}
          title="Prevent Deactivation"
          description="Set a PIN that is required to disable Control D if apps are used."
          descriptionLink={
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/prevent-deactivation`}
              sx={{
                color: 'aliceBlue60',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          }
          actionContent={
            <Switch
              isCompact
              data-testid="prevent-deactivation-switch"
              ariaLabel="prevent deactivation switch"
              checked={!!provisionState.deactivationStatus}
              onClick={() => {
                const payload = provisionState.deactivationStatus
                  ? EnabledStatus.DISABLED
                  : EnabledStatus.ENABLED

                provisionDispatch({
                  type: ProvisionActionType.PREVENT_DEACTIVATION_STATUS,
                  payload,
                })

                if (payload === EnabledStatus.DISABLED) {
                  provisionDispatch({
                    type: ProvisionActionType.PREVENT_DEACTIVATION_PIN,
                    payload: undefined,
                  })
                }
              }}
            />
          }
          expandedContent={
            !!provisionState.deactivationStatus ? (
              <Flex sx={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Input
                  isCompact
                  name="prevent-deactivation-input"
                  data-testid="prevent-deactivation-input"
                  aria-label="prevent deactivation input"
                  value={provisionState.deactivation_pin || ''}
                  placeholder="Enter a numeric PIN"
                  onChange={(event): void => {
                    provisionDispatch({
                      type: ProvisionActionType.PREVENT_DEACTIVATION_PIN,
                      payload: event.target.value.length
                        ? +numericalMask(event.target.value)
                        : undefined,
                    })
                  }}
                />
                {(!provisionState.deactivation_pin ||
                  !isDeactivationPinLengthValid(provisionState.deactivation_pin)) && (
                  <ErrorBox
                    sx={{
                      width: 'fit-content',
                      minHeight: 'auto',
                      backgroundColor: 'transparent',
                      mx: 0,
                      my: '0.2rem',
                      px: 0,
                      fontWeight: 'normal',
                      textAlign: 'left',
                      fontSize: '1.2rem',
                    }}
                    errorMessage="Please enter a deactivation PIN consisting of 1-10 characters."
                  />
                )}
              </Flex>
            ) : null
          }
        />
      </Flex>
    </AdvancedSettings>
  )
}
