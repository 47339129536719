import moment from 'moment'
import { PresetTimeRange } from 'ui/Analytics/PresetTimeRange'

export default function getDateByPresetTimeRange(presetTimeRange: PresetTimeRange): Date | null {
  let date: Date | null = null

  switch (presetTimeRange) {
    case PresetTimeRange.LAST_HOUR:
      date = moment().subtract(1, 'hour').toDate()
      break
    case PresetTimeRange.LAST_DAY:
      date = moment().subtract(1, 'day').toDate()
      break
    case PresetTimeRange.LAST_WEEK:
      date = moment().subtract(1, 'week').toDate()
      break
    case PresetTimeRange.LAST_MONTH:
      date = moment().subtract(1, 'month').toDate()
      break
    case PresetTimeRange.LAST_YEAR:
      date = moment().subtract(1, 'year').toDate()
      break
  }
  return date
}
